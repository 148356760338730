// import React from 'react'

// export default function () {
    import React, { useState, useEffect, useContext } from "react";
import {
  axiosInstance,
  base_url,
  handleError,
  imgBaseUrl,
} from "../../Config/axiosConfig";
import orderImg from "../../assets/images/order-icon.png";
import shoesImg from "../../assets/images/shoes.png";
import tvImg from "../../assets/images/tv.png";
// import cuponImg from "../../assets/images/cupon-img.png"
// import offerImg from "../../assets/images/offer-icon.png"
// import shieldImg from "../../assets/images/shield.png"
// import Coupon from './MyCoupon'
import Copun from "../Copun/Copun";
import toastify from "../../Config/toastify";
import CardContext from "../Context/CardContext";
import AccountSideBar from "./AccountSideBar";
import { NavLink } from "react-router-dom";
// import Copun from '../c'
// api/v2/carts

export default function MyCartWithOutLogin () {
  let [apiData, setApiData] = useState([]);
  let [quantityUpdate, setQuantityUpdate] = useState([]);
  let [quantity, setQuantity] = useState([]);
  const { card,setCard } = useContext(CardContext);
  let token = localStorage.getItem("token")
// console.log(token);

  useEffect(() => {
    if(!token){
      let cartStorage= JSON.parse(localStorage.getItem("guestUser"))
      // setCard(cartStorage)
      console.log(cartStorage,"arr");
      
      setApiData(cartStorage);
      let data =cartStorage.map((arr) => ({
        id: arr.product_id,
        quantity: arr?.quantity,
      }));
      setQuantityUpdate(data);
      return
    }
    (async () => {
      try {
        let res = await axiosInstance.post(`${base_url}/v2/carts`,{},{
          headers:{
            Authorization: "Bearer " + token, 
          }
        });
        setApiData(res?.data[0]?.cart_items || []);
        let data = res?.data[0]?.cart_items.map((arr) => ({
          id: arr.id,
          quantity: arr.quantity,
        }));
        setQuantityUpdate(data);
      } catch (error) {
        let errorMsg = handleError(error);
        console.log(errorMsg);
      }
    })();
  }, [quantity]);

// console.log(card,"cart");

  const handleQuantity = async (id, quantity, add) => {
    if (quantity <= 1 && !add) {
      return toastify.error("Minimum 1 Item Require");
    }
    if(!(card.some((arr)=> (arr.product_id === id && parseInt(quantity) < parseInt(arr.unit))))){
        return toastify.error(`Maximum ${quantity} Item Add In Cart`);
    }
    add ? ++quantity : --quantity;
    // console.log(id, quantity, add)
     setQuantityUpdate((curVal) => {
        // console.log(curVal);
        let data = curVal.map(
          (arr) => arr.id === id ? { ...arr, quantity: quantity } : arr
        );
        // console.log(data);
        return data;
      });
      return setCard((curVal)=>{
        let data = curVal.map(
            (arr) => arr.product_id === id ? { ...arr, quantity: quantity } : arr
          );
          localStorage.setItem("guestUser",JSON.stringify(data))
          return data 
      })
    // try {
    //   let res = await axiosInstance.post(
    //     `${base_url}/v2/carts/change-quantity`,
    //     {
    //       id: id,
    //       quantity: quantity,
    //     }
    //   );
    //   console.log(res.data);
    //   if (res?.data?.result) {
    //     toastify.success(res?.data?.message);
    //     let value = parseInt(res?.data?.cart);
    //      setQuantityUpdate((curVal) => {
    //       // console.log(curVal);
    //       let data = curVal.map(
    //         (arr) => arr.id === id ? { ...arr, quantity: value } : arr
    //       );
    //       console.log(data);
          
    //       return data;
    //     });
    //     return setCard((curVal)=>{
    //       let data = curVal.map(
    //           (arr) => arr.product_id === id ? { ...arr, quantity: quantity } : arr
    //         );
    //         return data
    //     })
    //   } else {
    //     return toastify.error(res?.data?.message);
    //   }
    // } catch (error) {
    //   let errorMsg = handleError(error);
    //   console.log(errorMsg);
    // }
  };


  let removeProduct = async (id,varinatData) => {
    console.log(id,varinatData  );
    
    try {
        setCard((curVal)=>{
          console.log(curVal);
         
          let data =curVal?.filter((arr)=>!(arr.product_id === id && arr?.variation == varinatData) )
          localStorage.setItem("guestUser",JSON.stringify(data))
          console.log(data);
          return data
        })
        setApiData((curVal)=>curVal?.filter((arr)=>!(arr.product_id === id && arr?.variation == varinatData)))
        // !(arr.product_id === id && arr.variation === variantData)
        toastify.success("Product Successfully Remove From Your Cart");
    } catch (error) {
      let errorMsg = handleError(error);
      console.log(errorMsg);
    }
  };
  return (
    <> 
    <section className="user-panel tp-space">
        <div className="container">
          <div className="row">
            

          <div className="col-lg-3 col-md-4">
        <div className="panel-left-bar">
          <ul>
             
            <li>
              <NavLink
                to="/my-cart"
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                <i className="bi bi-cart-check-fill me-2"></i>
                My Cart
              </NavLink>
            </li>
            
             
          </ul>
        </div>
      </div>


            <div className="col-lg-6 col-md-8">
            <div className="password-change w-100">
             <div className="panel-card">
              <div className="panel-cards cnfirm-ordr">
              {  apiData.length > 0 ? (
                apiData?.map((arr, i) => (
                  <div key={i}>
                    <div className="deliver-option justify-content-end mb-0">
                      <div>
                        <span className="text-secondary border-dark">
                          Delivery by Jan 21, 2020 Fri
                        </span>
                        <span className="text-success">Free </span>
                      </div>
                    </div>
                    <div className="order-dtl">
                      <div className="order-dtl-lft">
                        <div className="order-dtl-lft-img">
                          <img
                            src={`${imgBaseUrl}/${arr?.product_thumbnail_image}`}
                            alt=""
                          />
                        </div>
                        <div className="order-dtl-lft-content">
                          <h5 className="mb-0">{arr?.product_name}</h5>
                          
                          <h6>
                            {arr.currency_symbol} {arr?.price * quantityUpdate[i]?.quantity}{" "}
                            <span>₹ 1,500</span>
                            <em>8% Off</em>
                          </h6>
                        </div>
                      </div>
                    </div>
                    <div className="item-add">
                      <button
                        type="button"
                        className="minus"
                        onClick={() =>
                          handleQuantity(arr?.product_id, quantityUpdate[i]?.quantity, false)
                        }
                      >
                        <i className="bi bi-dash" />
                      </button>
                      <input type="number" disabled value={quantityUpdate[i]?.quantity} />
                      <button
                        type="button"
                        className="plus"
                        onClick={() =>
                          handleQuantity(arr?.product_id, quantityUpdate[i]?.quantity, true)
                        }
                      >
                        <i className="bi bi-plus-lg" />
                      </button>
                      <button
                        onClick={() => removeProduct(arr?. product_id,arr?.variation  )}
                        className="text-danger fw-600 fz-20 ms-3"
                      >
                        Remove
                      </button>
                    </div>
                    <hr className="mb-3 mt-3" />
                  </div>
                ))
              ) : (
                <div className="col-lg-6  ">
                  <div className="mb-4 mt-4  ms-4">
                    <b> No Items Add In Card </b>{" "}
                    <span> &nbsp; Please add Items</span>
                  </div>
                </div>
              )  
               }
              {/* <div className="deliver-option justify-content-end mb-0">
                <div>
                  <span className="text-secondary border-dark">
                    Delivery by Jan 21, 2020 Fri
                  </span>
                  <span className="text-success">Free </span>
                </div>
              </div>
              <div className="order-dtl">
                <div className="order-dtl-lft">
                  <div className="order-dtl-lft-img">
                    <img src={orderImg} alt="" />
                  </div>
                  <div className="order-dtl-lft-content">
                    <h5 className="mb-0">Women Black Printed A-Line Top x2</h5>
                    <p className="mb-0">Color: Multi/ Size: M</p>
                    <p className="mb-0 clr fw-500">Seller Cliff Fashion, Ahmedabad</p>
                    <h6>
                      ₹ 1,000 <span>₹ 1,500</span>
                      <em>8% Off</em>
                    </h6>
                  </div>
                </div>
              </div>
              <div className="item-add">
                <button type="button" className="minus">
                  <i className="bi bi-dash" />
                </button>
                <input type="number" defaultValue={1} />
                <button type="button" className="plus">
                  <i className="bi bi-plus-lg" />
                </button>
                <a
                  href="#"
                  className="text-danger fw-600 fz-20 ms-3"
                >
                  Remove
                </a>
              </div>

              <div className="deliver-option justify-content-end mb-0">
                <div>
                  <span className="text-secondary border-dark">
                    Delivery by Jan 21, 2020 Fri
                  </span>
                  <span className="text-success">Free </span>
                </div>
              </div>
              <div className="order-dtl">
                <div className="order-dtl-lft">
                  <div className="order-dtl-lft-img">
                    <img src={shoesImg} alt="" />
                  </div>
                  <div className="order-dtl-lft-content">
                    <h5 className="mb-0">Puma Cell Alien OG Sneakers for Men</h5>
                    <p className="mb-0">Color: Multi/ Size: M</p>
                    <p className="mb-0 clr fw-500">Seller Cliff Fashion, Ahmedabad</p>
                    <h6>
                      ₹ 5,000 <span>₹ 1,500</span>
                      <em>8% Off</em>
                    </h6>
                  </div>
                </div>
              </div>
              <div className="item-add">
                <button type="button" className="minus">
                  <i className="bi bi-dash" />
                </button>
                <input type="number" defaultValue={1} />
                <button type="button" className="plus">
                  <i className="bi bi-plus-lg" />
                </button>
                <a
                  href="#"
                  className="text-danger fw-600 fz-20 ms-3"
                >
                  Remove
                </a>
              </div>
              <hr className="mb-3 mt-3" />

              <div className="deliver-option justify-content-end mb-0">
                <div>
                  <span className="text-secondary border-dark">
                    Delivery by Jan 21, 2020 Fri
                  </span>
                  <span className="text-success border-0">Free </span>
                  <span className="d-block text-secondary fz-14 fw-400 border-0 text-end">
                    Installation &amp; Demo in 4-5 Days
                  </span>
                </div>
              </div>
              <div className="order-dtl">
                <div className="order-dtl-lft">
                  <div className="order-dtl-lft-img">
                    <img src={tvImg} alt="" />
                  </div>
                  <div className="order-dtl-lft-content">
                    <h5 className="mb-0">Samsung 32In LED TV 80 cm</h5>
                    <p className="mb-0">Size: 32In</p>
                    <p className="mb-0 clr fw-500">Seller Cliff Fashion, Ahmedabad</p>
                    <h6>
                      ₹ 5,000 <span>₹ 1,500</span>
                      <em>8% Off</em>
                    </h6>
                  </div>
                </div>
              </div>
              <div className="item-add">
                <button type="button" className="minus">
                  <i className="bi bi-dash" />
                </button>
                <input type="number" defaultValue={1} />
                <button type="button" className="plus">
                  <i className="bi bi-plus-lg" />
                </button>
                <a
                  href="#"
                  className="text-danger fw-600 fz-20 ms-3"
                >
                  Remove
                </a>
              </div> */}
            </div>
          </div>
          <div className="email-confrmatio">
            <p className="fw-600 fz-20">
              Order Confirmation Email will be send to sirani@gmail.com
            </p>
            <a href="#" className="org-btn-02">
              Place Order
            </a>
          </div>
        </div>
      </div>
      <Copun />
          </div>
        </div>
   </section>
    </>
  )
}
