import React from 'react'
import Copun from '../components/Copun/Copun'
// import Quickly from '../components/QuicklySection/QuicklySection'
import { Link } from 'react-router-dom'
export default function PaymentOptionPage() {
    return (
        <>
            {/* <Quickly /> */}
            <section className="select-address tp-space">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9 col-md-8">
                            <div className="panel-cards ">
                                <h4 className="title d-flex justify-content-between mb-0">
                                    Delivery Address{" "}
                                    <Link 
                                        to="#"
                                        className="org-btn-02 mb-0 text-center mt-2"
                                    >
                                        Change
                                    </Link>{" "}
                                </h4>
                                <div className="password-change w-100">
                                    <div className="deliveadd-bx change-bx justify-content-start border-0 p-0">
                                        <div className="deliveadd-bx-content">
                                            <h5>
                                                Shirin Ittarwala <span>Home</span>
                                            </h5>
                                            <p>
                                                B-1008, Samaan II, 100 Feet Anand Nagar Nagar Rd, Near Auda
                                                Garden, Prahlad Nagar, Ahmedabad, Gujarat 380015
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="panel-cards">
                                <h4 className="title d-flex justify-content-between mb-0 align-items-center">
                                    Confirm Order{" "}
                                    <Link 
                                        to="#"
                                        className="org-btn-02 mb-0 text-center mt-2"
                                    >
                                        Change
                                    </Link>{" "}
                                </h4>
                                <p className="fz-16 fw-400 clr mb-0">2 Items</p>
                            </div>
                            <div className="payment-option password-change w-100">
                                <h5 className="order-txt">Payment Options</h5>
                                <div className="panel-cards ">
                                    <div className="form-check change-bx">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="payment-option"
                                            id="card"
                                            defaultChecked=""
                                        />
                                        <label className="form-check-label" htmlFor="card">
                                            Debit/ Credit Card
                                        </label>
                                    </div>
                                    <div className="form-check change-bx">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="payment-option"
                                            id="Banking"
                                        />
                                        <label className="form-check-label" htmlFor="Banking">
                                            Net Banking
                                        </label>
                                    </div>
                                    <div className="form-check change-bx">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="payment-option"
                                            id="pay"
                                        />
                                        <label className="form-check-label" htmlFor="pay">
                                            Google Pay
                                        </label>
                                    </div>
                                    <div className="form-check change-bx">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="payment-option"
                                            id="cash"
                                        />
                                        <label className="form-check-label" htmlFor="cash">
                                            Cash On Delivery
                                        </label>
                                    </div>
                                    <Link  to="#" className="org-btn-02">
                                        Proceed
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <Copun />
                    </div>
                </div>

            </section>


        </>
    )
}
