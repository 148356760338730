// import React from 'react'
import React, { useRef } from "react";
import LeftArrow from "../../assets/img/leftArrow.svg";
import RightArrow from "../../assets/img/rightArrow.svg";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

export default function ElectronicSlider({
  id,
  title,
  productImg,
  lableImg,
  slidesPerView,
}) {
  // console.log(slidesPerView)
  const swiperRef = useRef(null);
  return (
    <>
      <div className="container">
        {/* product-list-02 tp-space-02 */}
        <section className="other-product tp-space-02 electronic-similar ">
          <div className="product-bar">
            <div className="holeHeader">
              <div className="main-head">
                <h4 className="title">Similar Products</h4>
              </div>
              <div className="holeHeaderBtn">
                {/* <a to="#" className="mainBtn">
                      View All
                    </a> */}
                <div className={`carouselPrv${id} carouselbtn`}>
                  {/* <img
                    src={LeftArrow}
                    className="carouslArrowImg"
                    onClick={() => swiperRef.current.swiper.slidePrev()}
                  /> */}
                  
                  <i className="bi bi-chevron-left" onClick={() => swiperRef.current.swiper.slideNext()}></i>

                </div>
                <div className={`carouselNext${id} carouselbtn`}>
                  {/* <img
                    src={RightArrow}
                    className="carouslArrowImg"
                    onClick={() => swiperRef.current.swiper.slideNext()}
                  /> */}
              <i className="bi bi-chevron-right" 
                    onClick={() => swiperRef.current.swiper.slidePrev()}></i>
                </div>
              </div>
            </div>
            <div className="holeSaleCarousel">
              {/* <div className="uperCarouselContainer"> */}

              <Swiper
                ref={swiperRef}
                modules={[Navigation, Pagination, Scrollbar, A11y]}
                loop={true}
                // spaceBetween={0}
                slidesPerView={slidesPerView}
                navigation={{
                  prevEl: `.carouselPrv${id}`,
                  nextEl: `.carouselNext${id}`,
                }}
                // onSlideChange={() => console.log("slide change")}
                // onSwiper={(swiper) => console.log(swiper)}
                style={{ marginLeft: "20px", marginBottom: "10px" }}
                breakpoints={{
                  100: {
                    slidesPerView: 1,
                    // spaceBetween: 20,
                  },
                  650: {
                    slidesPerView: 2,
                    // spaceBetween: 30,
                  },
                  1024: {
                    slidesPerView: slidesPerView,
                    spaceBetween: 10,
                  },
                }}
              >
                <SwiperSlide>
                  <div className="product-slider-innr">
                    <div className="product-slider-innr-img">
                      <img src={productImg} alt="" />
                    </div>
                    <h4>Redmi 8 (Onyx Black, 64GB)</h4>
                    <p>
                      <span className="bi bi-star-fill" />
                      <span className="bi bi-star-fill" />
                      <span className="bi bi-star-fill" />
                      <span className="bi bi-star-fill" />
                      <span className="bi bi-star-fill" />
                      (1,512)
                    </p>
                    <h6>
                      ₹ 12,990 <del>₹ 15,990</del>
                    </h6>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="product-slider-innr">
                    <div className="product-slider-innr-img">
                      <img src={productImg} alt="" />
                    </div>
                    <h4>Redmi 8 (Onyx Black, 64GB)</h4>
                    <p>
                      <span className="bi bi-star-fill" />
                      <span className="bi bi-star-fill" />
                      <span className="bi bi-star-fill" />
                      <span className="bi bi-star-fill" />
                      <span className="bi bi-star-fill" />
                      (1,512)
                    </p>
                    <h6>
                      ₹ 12,990 <del>₹ 15,990</del>
                    </h6>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="product-slider-innr">
                    <div className="product-slider-innr-img">
                      <img src={productImg} alt="" />
                    </div>
                    <h4>Redmi 8 (Onyx Black, 64GB)</h4>
                    <p>
                      <span className="bi bi-star-fill" />
                      <span className="bi bi-star-fill" />
                      <span className="bi bi-star-fill" />
                      <span className="bi bi-star-fill" />
                      <span className="bi bi-star-fill" />
                      (1,512)
                    </p>
                    <h6>
                      ₹ 12,990 <del>₹ 15,990</del>
                    </h6>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="product-slider-innr">
                    <div className="product-slider-innr-img">
                      <img src={productImg} alt="" />
                    </div>
                    <h4>Redmi 8 (Onyx Black, 64GB)</h4>
                    <p>
                      <span className="bi bi-star-fill" />
                      <span className="bi bi-star-fill" />
                      <span className="bi bi-star-fill" />
                      <span className="bi bi-star-fill" />
                      <span className="bi bi-star-fill" />
                      (1,512)
                    </p>
                    <h6>
                      ₹ 12,990 <del>₹ 15,990</del>
                    </h6>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="product-slider-innr">
                    <div className="product-slider-innr-img">
                      <img src={productImg} alt="" />
                    </div>
                    <h4>Redmi 8 (Onyx Black, 64GB)</h4>
                    <p>
                      <span className="bi bi-star-fill" />
                      <span className="bi bi-star-fill" />
                      <span className="bi bi-star-fill" />
                      <span className="bi bi-star-fill" />
                      <span className="bi bi-star-fill" />
                      (1,512)
                    </p>
                    <h6>
                      ₹ 12,990 <del>₹ 15,990</del>
                    </h6>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="product-slider-innr">
                    <div className="product-slider-innr-img">
                      <img src={productImg} alt="" />
                    </div>
                    <h4>Redmi 8 (Onyx Black, 64GB)</h4>
                    <p>
                      <span className="bi bi-star-fill" />
                      <span className="bi bi-star-fill" />
                      <span className="bi bi-star-fill" />
                      <span className="bi bi-star-fill" />
                      <span className="bi bi-star-fill" />
                      (1,512)
                    </p>
                    <h6>
                      ₹ 12,990 <del>₹ 15,990</del>
                    </h6>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="product-slider-innr">
                    <div className="product-slider-innr-img">
                      <img src={productImg} alt="" />
                    </div>
                    <h4>Redmi 8 (Onyx Black, 64GB)</h4>
                    <p>
                      <span className="bi bi-star-fill" />
                      <span className="bi bi-star-fill" />
                      <span className="bi bi-star-fill" />
                      <span className="bi bi-star-fill" />
                      <span className="bi bi-star-fill" />
                      (1,512)
                    </p>
                    <h6>
                      ₹ 12,990 <del>₹ 15,990</del>
                    </h6>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="product-slider-innr">
                    <div className="product-slider-innr-img">
                      <img src={productImg} alt="" />
                    </div>
                    <h4>Redmi 8 (Onyx Black, 64GB)</h4>
                    <p>
                      <span className="bi bi-star-fill" />
                      <span className="bi bi-star-fill" />
                      <span className="bi bi-star-fill" />
                      <span className="bi bi-star-fill" />
                      <span className="bi bi-star-fill" />
                      (1,512)
                    </p>
                    <h6>
                      ₹ 12,990 <del>₹ 15,990</del>
                    </h6>
                  </div>
                </SwiperSlide>
              </Swiper>

              {/* </div> */}
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
