import React, {  useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Controller,
} from "swiper/modules";
import "swiper/css";
// import 'swiper/css/pagination';
// import 'swiper/css/navigation';
import "swiper/css/scrollbar";
// import 'swiper/css/controller';
import "./style.css";

const slides = [
  "https://picsum.photos/1920/1080",
  "https://picsum.photos/1920/1081",
  "https://picsum.photos/1920/1082",
  "https://picsum.photos/1920/1083",
  "https://picsum.photos/1920/1084",
  "https://picsum.photos/1920/1085",
  "https://picsum.photos/1920/1086",
  "https://picsum.photos/1920/1087",
  "https://picsum.photos/1920/1088",
  "https://picsum.photos/1920/1089",
];

export default function VerticalSlider() {
  const [firstSwiper, setFirstSwiper] = useState(null);
  const [secondSwiper, setSecondSwiper] = useState(null);

  const handleSlideClick = (index) => {
    console.log(index);
    // console.log(secondSwiper)
    if (secondSwiper) {
      secondSwiper.slideTo(index);
    }
  };

  return (
    <div className="container">
      <div className="">
        <Swiper
          modules={[Navigation, Pagination, Scrollbar, A11y, Controller]}
          onSwiper={setFirstSwiper}
          controller={{ control: secondSwiper }}
          pagination={{ clickable: true }}
          spaceBetween={0}
          loop={true}
          navigation
          className="mySwiper"
          // slideToClickedSlide={true}
          direction={"vertical"}
          slidesPerView={4}
        >
          {slides.map((url, index) => (
            <SwiperSlide key={index} onClick={() => handleSlideClick(index)}>
              <span className="active">
                <img src={url} className="Imgcard" alt="" />
              </span>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

      <div className="">
        <Swiper
          modules={[Navigation, Pagination, Scrollbar, A11y, Controller]}
          onSwiper={setSecondSwiper}
          controller={{ control: firstSwiper }}
          pagination={{ clickable: true }}
          spaceBetween={0}
          loop={true}
          // navigation={{
          //   nextEl: '.swiper-button-next',
          //   prevEl: '.swiper-button-prev',
          // }}

          // className="mySwiper"
          slidesPerView={1}
        >
          {slides.map((url, index) => (
            <SwiperSlide key={index}>
              <span className="">
                <img src={url} className="Imgcard" alt="" />
              </span>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
}

// import React, { useRef, useState } from 'react';
// // Import Swiper React components
// import { Swiper, SwiperSlide } from 'swiper/react';
// import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
// // Import Swiper styles
// import 'swiper/css';
// import 'swiper/css/pagination';
// import { Controller } from 'swiper/modules';
// // import { Swiper, SwiperSlide } from 'swiper/react';
// import prductImg from "../assets/images/other-product.png"
// // import prductImg from "..//"
// // import './styles.css';
// import "./style.css"
// import { useSwiperSlide } from 'swiper/react';
// const slides = [
//   "https://picsum.photos/1920/1080",
//   "https://picsum.photos/1920/1081",
//   "https://picsum.photos/1920/1082",
//   "https://picsum.photos/1920/1083",
//   "https://picsum.photos/1920/1084",
//   "https://picsum.photos/1920/1085",
//   "https://picsum.photos/1920/1086",
//   "https://picsum.photos/1920/1087",
//   "https://picsum.photos/1920/1088",
//   "https://picsum.photos/1920/1089",
// ];
// let handeleFunction =()=>{
//  console.log("Active")
// }
// export default function VerticalSlider() {
//   const swiperSlide = useSwiperSlide();
//   //   const [firstSwiper, setFirstSwiper] = useState(null);
// // const [secondSwiper, setSecondSwiper] = useState(null);
// // console.log(firstSwiper)
// // console.log(secondSwiper)
//   return (
//     <>
//     <div className='container'>
//       <div className="">
//       <Swiper
//        modules={[Navigation, Pagination, Scrollbar, A11y]}
//       //  modules={[Controller]}
//         // onSwiper={setFirstSwiper}
//         // controller={{ control: secondSwiper }}
//         // direction={'vertical'}
//         pagination={{
//           clickable: true,
//         }}
//         spaceBetween={0}
//         loop={true}
//         // navigation
//         navigation= {{
//           nextEl: '.swiper-button-next',
//           prevEl:'.swiper-button-prev',
//         }}
//         // modules={[Pagination]}
//         className="mySwiper"
//         slidesPerView={4}
//       >
//         {slides.map((arr)=>
//           <SwiperSlide onClick={(e)=>{console.log(e.target)}}>
//           <span className="active">
//           {/* <p>Current slide is {swiperSlide.isActive ? 'active' : 'not active'}</p> */}
//           <img src={arr} className='Imgcard' alt="" />
//         </span>
//         </SwiperSlide>
//         )}

//       </Swiper>

//       </div>

// {/* <div> <p>Current slide is {swiperSlide.isActive ? 'active' : 'not active'}</p></div> */}
//       <div className="">
//       <Swiper
//        modules={[Navigation, Pagination, Scrollbar, A11y]}
//         // direction={'vertical'}
//         // modules={[Controller]}
//         // onSwiper={setSecondSwiper}
//         // controller={{ control: firstSwiper }}
//         pagination={{
//           clickable: true,
//         }}
//         spaceBetween={0}
//         loop={true}
//         // navigation
//         navigation= {{
//           nextEl: '.swiper-button-next',
//           prevEl:'.swiper-button-prev',
//         }}
//         // modules={[Pagination]}
//         className="mySwiper"
//         slidesPerView={1}
//       >
//         {slides.map((arr)=>
//           <SwiperSlide onClick={handeleFunction}>
//           <span className="">
//           <img src={arr} className='Imgcard' alt="" />
//         </span>
//         </SwiperSlide>
//         )}

//       </Swiper>
//       </div>
//     </div>
//     </>
//   );
// }
