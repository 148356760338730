import React, { createContext } from "react";

const CardContext = createContext();

export default CardContext;

// import { createContext, useContext } from "react";
// export const CardValContext = createContext({
//   cart: 0,
// });

// export const CardValProvider = CardValContext.Provider;

// export default function useCartVal() {
//   return useContext(CardValContext);
// }
