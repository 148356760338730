import React from 'react'
import { Link } from 'react-router-dom'
import orderIconImg_01 from "../assets/images/order-icon.png"
import orderIconImg_02 from "../assets/images/order-icon-02.png"
import trackIconImg_01 from "../assets/images/track-icon-01.svg"
import trackIconImg_02 from "../assets/images/track-icon-02.svg"
import trackIconImg_03 from "../assets/images/track-icon-03.svg"
import trackIconImg_04 from "../assets/images/track-icon-04.svg"
import Copun from "../components/Copun/Copun"
export default function TrackingOrder() {
    return (
        <>
            <section className="user-panel tp-space">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9 col-md-8">
                            <div className="panel-cards ">
                                <h4 className="title mb-0">Ship To:</h4>
                                <div className="deliveadd-bx change-bx justify-content-between border-0 p-0">
                                    <div className="deliveadd-bx-content">
                                        <h5>
                                            Shirin Ittarwala <span>Home</span>
                                        </h5>
                                        <p>
                                            B-1008, Samaan II, 100 Feet Anand Nagar Nagar Rd, Near Auda
                                            Garden, Prahlad Nagar, Ahmedabad, Gujarat 380015
                                        </p>
                                    </div>
                                    <div>
                                        <Link
                                            to="#"
                                            className="org-btn-02 mb-0 text-center mt-2"
                                        >
                                            Shipping Address
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="panel-cards ">
                                <div className="deliver-option">
                                    <div>
                                        <span className="text-secondary border-secondary">
                                            Order Id: gz13324
                                        </span>
                                        <span className="text-secondary">Items: 2</span>
                                    </div>
                                    <div>
                                        <span className="text-success">Shipped on Jan 21, 2020</span>
                                    </div>
                                </div>
                                <div className="order-dtl">
                                    <div className="order-dtl-lft">
                                        <div className="order-dtl-lft-img">
                                            <img src={orderIconImg_01} alt="" />
                                        </div>
                                        <div className="order-dtl-lft-content">
                                            <h5>Women Black Printed A-Line Top x2</h5>
                                            <p>Seller: Cliff Fashion, Ahmedabad</p>
                                            <h6 className="fz-18 fw-600 clr">₹ 299</h6>
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <div className="order-dtl">
                                    <div className="order-dtl-lft">
                                        <div className="order-dtl-lft-img">
                                            <img src={orderIconImg_02} alt="" />
                                        </div>
                                        <div className="order-dtl-lft-content">
                                            <h5>Women Black Printed A-Line Top x2</h5>
                                            <p>Seller: Cliff Fashion, Ahmedabad</p>
                                            <h6 className="fz-18 fw-600 clr">₹ 299</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="panel-cards">
                                <div className="row">
                                    <div className="col-lg-9">
                                        <ul className="delivery-track">
                                            <li className="deliver">
                                                <span>
                                                    <i className="far fa-check" />
                                                </span>
                                                <br />
                                                <img src={trackIconImg_01} alt="" />
                                                <h5>Ordered</h5>
                                                <p>Jan 21, 2020, Sun</p>
                                            </li>
                                            <li className="deliver">
                                                <span />
                                                <br />
                                                <img src={trackIconImg_02} alt="" />
                                                <h5>Packed</h5>
                                                <p>Jan 21, 2020, Sun</p>
                                            </li>
                                            <li>
                                                <span />
                                                <br />
                                                <img src={trackIconImg_03} alt="" />
                                                <h5>Shipped</h5>
                                                <p>Jan 21, 2020, Sun</p>
                                            </li>
                                            <li>
                                                <span className="" />
                                                <br />
                                                <img src={trackIconImg_04} alt="" />
                                                <h5>Delivered</h5>
                                                <p>Jan 21, 2020, Sun</p>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="delivery-txt">
                                            <p>
                                                <Link to="#">
                                                    <i className="bi bi-star-fill me-2" />
                                                    RATE &amp; REVIEW PRODUCT
                                                </Link>
                                            </p>
                                            <p>
                                                <Link to="#">
                                                    <i className="bi bi-question-circle-fill me-2" />
                                                    NEED HELP?
                                                </Link>
                                            </p>
                                            <Link
                                                to="#"
                                                className="org-btn-02 mb-0 text-center mt-2"
                                            >
                                                Cancel Order Request
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Copun/>
                        {/* <div className="col-lg-3">
                            <div className="cupon-bar">
                                <div className="cupon-bar-innr">
                                    <h5>
                                        <span>
                                            <img src="assets/images/cupon-img.png" alt="" />
                                            Coupon Applied: <strong>EXTRA20</strong>
                                        </span>
                                        <i className="fas fa-check-circle" />
                                    </h5>
                                    <ul className="cupon-bar-innr-list">
                                        <li>
                                            <span>Price Details</span>
                                        </li>
                                        <li>
                                            <span>Product Price</span>
                                            <span>₹ 4,460</span>
                                        </li>
                                        <li>
                                            <span>Discount</span>
                                            <span>₹ 892</span>
                                        </li>
                                    </ul>
                                    <h6>
                                        <span>Amount Payable</span>
                                        <span>₹ 3,568</span>
                                    </h6>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </section>

        </>
    )
}
