import React from 'react'
import Copun from '../components/Copun/Copun'
// import Quickly from '../components/QuicklySection/QuicklySection'
import { Link } from 'react-router-dom'
export default function SelectAddressPage() {
  return (
    <>
    {/* <Quickly/> */}
      <section className="select-address tp-space">
        <div className="container">
          <div className="row">
            <div className="col-lg-9 col-md-8">
              <div className="panel-cards ">
                <div className="password-change w-100">
                  <h4 className="title">Ship To:</h4>
                  <div className="deliveadd-bx change-bx justify-content-start">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="addres"
                        id=""
                        defaultChecked=""
                      />
                    </div>
                    <div className="deliveadd-bx-content">
                      <h5>
                        Shirin Ittarwala <span>Home</span>
                      </h5>
                      <p>
                        B-1008, Samaan II, 100 Feet Anand Nagar Nagar Rd, Near Auda
                        Garden, Prahlad Nagar, Ahmedabad, Gujarat 380015
                      </p>
                      <Link
                        to="#"
                        className="org-btn-02 mb-0 w-50 text-center mt-2"
                      >
                        Deliver Here
                      </Link>
                    </div>
                  </div>
                  <div className="deliveadd-bx change-bx justify-content-start">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="addres"
                        id=""
                      />
                    </div>
                    <div className="deliveadd-bx-content">
                      <h5>
                        Aizaz Ittarwala <span>Home</span>
                      </h5>
                      <p>
                        B-1008, Samaan II, 100 Feet Anand Nagar Nagar Rd, Near Auda
                        Garden, Prahlad Nagar, Ahmedabad, Gujarat 380015
                      </p>
                    </div>
                  </div>
                </div>
                <Link to="/my-account/new-address" className="org-btn-02 mt-2">
                  + Add New Address
                </Link>
              </div>
              <div className="panel-cards location-btn nxt-btn">
                <Link to="#" className="thm-btn text-center">
                  Next{" "}
                </Link>
              </div>
              <div className="panel-cards">
                <h4 className="fz-20 fw-600 clr mb-0">Payment Options</h4>
              </div>
            </div>
            <Copun />
          </div>
        </div>
      </section>


    </>
  )
}

