import React from 'react'
import { Link } from 'react-router-dom'
import comprasion from "../../assets/images/comprasion-item-01.png"
// import mobile_01 from "../../assets/imasges/mobile-01.png"
import comprasion_02 from "../../assets/images/comprasion-item-02.png"
import comprasion_03 from "../../assets/images/comprasion-item-03.png"
import mobile_02 from "../../assets/images/mobile-02.png"
import mobile_03 from "../../assets/images/mobile-03.png"
import mobile_04 from "../../assets/images/mobile-04.png"
export default function ComprasionSection() {
  return (
    <> 
      <section className="comprasion-sec tp-space">
        <div className="container">
          <div className="panel-cards rounded-0">
            <div className="row gx-2">
              <div className="col-lg-4 col-md-6 col-sm-6">
                <div className="comprasion-bx">
                  <div className="comprasion-bx-img">
                    <img src={comprasion} alt="" />
                  </div>
                  <div className="comprasion-bx-prc">
                    <h2>SAMSUNG Galaxy S23 5G (Cream, 128 GB) (8 GB RAM)</h2>
                    <h3>
                      ₹66,499 <del>₹ 79,999</del> <span>16% off</span>{" "}
                     <Link to="#">
                        <i className="far fa-exclamation-circle" />
                     </Link>
                    </h3>
                  </div>
                  <div className="comprasion-bx-content">
                    <h4>₹669 After Exchange</h4>
                    <p>
                      <span className="rating">
                        4.5 <i className="bi bi-star-fill" />
                      </span>
                    </p>
                    <p className="review">
                      26,609 Ratings &amp; 1,092 Reviews{" "}
                      <span className="d-block">All 1092 reviews </span>
                    </p>
                    <ul className="comprasion-bx-list">
                      <li>8 GB RAM | 128 GB ROM Expandable Upto 1 TB</li>
                      <li>15.49 cm (6.1 inch) Full HD+ Display</li>
                      <li>50MP + 10MP + 12MP | 12MP Front Camera</li>
                      <li>3900 mAh Lithium Ion Battery</li>
                      <li>
                        Warranty : 1 year Manufacturer Warranty for Device and 6
                        Months Manufacturer Warranty for In-Box Accessories
                      </li>
                      <li>Returns: 7 Dyas Replacement Policy</li>
                      <li>
                       <Link
                          to="#"
                          className="org-btn-02 w-100 text-center"
                        >
                          <i className="fas fa-bolt me-2" />
                          Buy Now
                       </Link>
                      </li>
                      <li>
                        8 Jun, Thursday for{" "}
                       <Link to="#" className="text-success fw-600">
                          Free
                       </Link>
                      </li>
                      <li>
                        Standard Delivery for{" "}
                       <Link to="#" className="text-success fw-600">
                          Free
                       </Link>
                      </li>
                      <li>
                        MYTHANGLORY Retail -{" "}
                        <span className="rating">
                          4.5 <i className="bi bi-star-fill" />
                        </span>
                      </li>
                      <li>Availabel with 4 seller (s)</li>
                      <li className="text-dark">Color (2)</li>
                      <li className="text-dark">Jade Purple, Opal Green</li>
                      <li>Nano Sim </li>
                      <li>Dual Sim, GSM|CDMA|4G LTE</li>
                      <li>4G, 5G ,2G</li>
                      <li>5000 mAh</li>
                      <li>-</li>
                      <li>No</li>
                      <li>Android 12 </li>
                      <li>Mediatek Helio P35 2.5 GHz</li>
                      <li>4 GB </li>
                      <li>Imagination GE8320, 269</li>
                      <li>13 MP,5 MP </li>
                      <li>Yes</li>
                      <li>Yes, Upto 10x Zoom</li>
                      <li>HD, 30fps</li>
                      <li>HD, 1600 x 720 Pixels </li>
                      <li>HD+ LCD In-cell Touch Display</li>
                      <li>Yes, OTG Compatible</li>
                      <li>Yes, 802.11</li>
                      <li>Yes, vv5.0</li>
                    </ul>
                  </div>
                 <Link to="#" className="remove-btn">
                    <i className="bi bi-x-lg" />
                    {/* <i class=""></i> */}
                 </Link>
                </div>
                <div className="comprasion-bx bttm-bx">
                  <div className="comprasion-bx-img bttm-img">
                    <img src={mobile_02} alt="" />
                  </div>
                  <div className="comprasion-bx-prc">
                    <h2>SAMSUNG Galaxy S23 5G (Cream, 128 GB) (8 GB RAM)</h2>
                    <h3>
                      ₹66,499 <del>₹ 79,999</del> <span>16% off</span>
                    </h3>
                   <Link
                      to="#"
                      className="org-btn-02 w-100 text-center mb-0 dark-btn"
                    >
                      <i className="fas fa-shopping-cart me-2" />
                      Add to card
                   </Link>
                   <Link
                      to="#"
                      className="org-btn-02 w-100 text-center mt-2"
                    >
                      <i className="fas fa-bolt me-2" />
                      Buy Now
                   </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-6">
                <div className="comprasion-bx">
                  <div className="comprasion-bx-img">
                    <img src={comprasion_02} alt="" />
                  </div>
                  <div className="comprasion-bx-prc">
                    <h2>SAMSUNG Galaxy S23 5G (Cream, 128 GB) (8 GB RAM)</h2>
                    <h3>
                      ₹66,499 <del>₹ 79,999</del> <span>16% off</span>{" "}
                     <Link to="#">
                        <i className="far fa-exclamation-circle" />
                     </Link>
                    </h3>
                  </div>
                  <div className="comprasion-bx-content">
                    <h4>₹669 After Exchange</h4>
                    <p>
                      <span className="rating">
                        4.5 <i className="bi bi-star-fill" />
                      </span>
                    </p>
                    <p className="review">
                      26,609 Ratings &amp; 1,092 Reviews{" "}
                      <span className="d-block">All 1092 reviews </span>
                    </p>
                    <ul className="comprasion-bx-list">
                      <li>8 GB RAM | 128 GB ROM Expandable Upto 1 TB</li>
                      <li>15.49 cm (6.1 inch) Full HD+ Display</li>
                      <li>50MP + 10MP + 12MP | 12MP Front Camera</li>
                      <li>3900 mAh Lithium Ion Battery</li>
                      <li>
                        Warranty : 1 year Manufacturer Warranty for Device and 6
                        Months Manufacturer Warranty for In-Box Accessories
                      </li>
                      <li>Returns: 7 Dyas Replacement Policy</li>
                      <li>
                       <Link
                          to="#"
                          className="org-btn-02 w-100 text-center"
                        >
                          <i className="fas fa-bolt me-2" />
                          Buy Now
                       </Link>
                      </li>
                      <li>
                        8 Jun, Thursday for{" "}
                       <Link to="#" className="text-success fw-600">
                          Free
                       </Link>
                      </li>
                      <li>
                        Standard Delivery for{" "}
                       <Link to="#" className="text-success fw-600">
                          Free
                       </Link>
                      </li>
                      <li>
                        MYTHANGLORY Retail -{" "}
                        <span className="rating">
                          4.5 <i className="bi bi-star-fill" />
                        </span>
                      </li>
                      <li>Availabel with 4 seller (s)</li>
                      <li className="text-dark">Color (2)</li>
                      <li className="text-dark">Jade Purple, Opal Green</li>
                      <li>Nano Sim </li>
                      <li>Dual Sim, GSM|CDMA|4G LTE</li>
                      <li>4G, 5G ,2G</li>
                      <li>5000 mAh</li>
                      <li>-</li>
                      <li>No</li>
                      <li>Android 12 </li>
                      <li>Mediatek Helio P35 2.5 GHz</li>
                      <li>4 GB </li>
                      <li>Imagination GE8320, 269</li>
                      <li>13 MP,5 MP </li>
                      <li>Yes</li>
                      <li>Yes, Upto 10x Zoom</li>
                      <li>HD, 30fps</li>
                      <li>HD, 1600 x 720 Pixels </li>
                      <li>HD+ LCD In-cell Touch Display</li>
                      <li>Yes, OTG Compatible</li>
                      <li>Yes, 802.11</li>
                      <li>Yes, vv5.0</li>
                    </ul>
                  </div>
                 <Link to="#" className="remove-btn">
                    <i className="bi bi-x-lg" />
                 </Link>
                </div>
                <div className="comprasion-bx bttm-bx">
                  <div className="comprasion-bx-img bttm-img">
                    <img src={mobile_03} alt="" />
                  </div>
                  <div className="comprasion-bx-prc">
                    <h2>SAMSUNG Galaxy S23 5G (Cream, 128 GB) (8 GB RAM)</h2>
                    <h3>
                      ₹66,499 <del>₹ 79,999</del> <span>16% off</span>
                    </h3>
                   <Link
                      to="#"
                      className="org-btn-02 w-100 text-center mb-0 dark-btn"
                    >
                      <i className="fas fa-shopping-cart me-2" />
                      Add to card
                   </Link>
                   <Link
                      to="#"
                      className="org-btn-02 w-100 text-center mt-2"
                    >
                      <i className="fas fa-bolt me-2" />
                      Buy Now
                   </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-6">
                <div className="comprasion-bx">
                  <div className="comprasion-bx-img">
                    <img src={comprasion_03} alt="" />
                  </div>
                  <div className="comprasion-bx-prc">
                    <h2>SAMSUNG Galaxy S23 5G (Cream, 128 GB) (8 GB RAM)</h2>
                    <h3>
                      ₹66,499 <del>₹ 79,999</del> <span>16% off</span>{" "}
                     <Link to="#">
                        <i className="far fa-exclamation-circle" />
                     </Link>
                    </h3>
                  </div>
                  <div className="comprasion-bx-content">
                    <h4>₹669 After Exchange</h4>
                    <p>
                      <span className="rating">
                        4.5 <i className="bi bi-star-fill" />
                      </span>
                    </p>
                    <p className="review">
                      26,609 Ratings &amp; 1,092 Reviews{" "}
                      <span className="d-block">All 1092 reviews </span>
                    </p>
                    <ul className="comprasion-bx-list">
                      <li>8 GB RAM | 128 GB ROM Expandable Upto 1 TB</li>
                      <li>15.49 cm (6.1 inch) Full HD+ Display</li>
                      <li>50MP + 10MP + 12MP | 12MP Front Camera</li>
                      <li>3900 mAh Lithium Ion Battery</li>
                      <li>
                        Warranty : 1 year Manufacturer Warranty for Device and 6
                        Months Manufacturer Warranty for In-Box Accessories
                      </li>
                      <li>Returns: 7 Dyas Replacement Policy</li>
                      <li>
                       <Link
                          to="#"
                          className="org-btn-02 w-100 text-center"
                        >
                          <i className="fas fa-bolt me-2" />
                          Buy Now
                       </Link>
                      </li>
                      <li>
                        8 Jun, Thursday for{" "}
                       <Link to="#" className="text-success fw-600">
                          Free
                       </Link>
                      </li>
                      <li>
                        Standard Delivery for{" "}
                       <Link to="#" className="text-success fw-600">
                          Free
                       </Link>
                      </li>
                      <li>
                        MYTHANGLORY Retail -{" "}
                        <span className="rating">
                          4.5 <i className="bi bi-star-fill" />
                        </span>
                      </li>
                      <li>Availabel with 4 seller (s)</li>
                      <li className="text-dark">Color (2)</li>
                      <li className="text-dark">Jade Purple, Opal Green</li>
                      <li>Nano Sim </li>
                      <li>Dual Sim, GSM|CDMA|4G LTE</li>
                      <li>4G, 5G ,2G</li>
                      <li>5000 mAh</li>
                      <li>-</li>
                      <li>No</li>
                      <li>Android 12 </li>
                      <li>Mediatek Helio P35 2.5 GHz</li>
                      <li>4 GB </li>
                      <li>Imagination GE8320, 269</li>
                      <li>13 MP,5 MP </li>
                      <li>Yes</li>
                      <li>Yes, Upto 10x Zoom</li>
                      <li>HD, 30fps</li>
                      <li>HD, 1600 x 720 Pixels </li>
                      <li>HD+ LCD In-cell Touch Display</li>
                      <li>Yes, OTG Compatible</li>
                      <li>Yes, 802.11</li>
                      <li>Yes, vv5.0</li>
                    </ul>
                  </div>
                 <Link to="#" className="remove-btn">
                    <i className="bi bi-x-lg" />
                 </Link>
                </div>
                <div className="comprasion-bx bttm-bx">
                  <div className="comprasion-bx-img ">
                    <img src={mobile_04} alt="" />
                  </div>
                  <div className="comprasion-bx-prc">
                    <h2>SAMSUNG Galaxy S23 5G (Cream, 128 GB) (8 GB RAM)</h2>
                    <h3>
                      ₹66,499 <del>₹ 79,999</del> <span>16% off</span>
                    </h3>
                   <Link
                      to="#"
                      className="org-btn-02 w-100 text-center mb-0 dark-btn"
                    >
                      <i className="fas fa-shopping-cart me-2" />
                      Add to card
                   </Link>
                   <Link
                      to="#"
                      className="org-btn-02 w-100 text-center mt-2"
                    >
                      <i className="fas fa-bolt me-2" />
                      Buy Now
                   </Link>
                  </div>
                </div>
              </div>
         
            </div>
          </div>
        </div>
      </section>

    </>
  )
}
