// import React from 'react'
import { Outlet } from 'react-router-dom'
import Header from './components/Header/Header';
import Footer from "./components/Footer/Footer"
import QuicklySection from "./components/QuicklySection/QuicklySection"
import { useLocation } from "react-router-dom";
export default function Layout() {
  let location = useLocation();
  // console.log(location.pathname)
  // localStorage.getItem("")
  
  return (
    <>
      <Header />
      {(location.pathname !== "/grocery-page" && location.pathname !== '/grocery-product') && <QuicklySection />}
      <Outlet />
      <Footer />
    </>
  );
}
