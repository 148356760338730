import React, { useState } from "react";
import CardContext from "./CardContext";
function CardProvider({ children }) {
  const [card, setCard] = useState([]);
  // res=api()
  // id=res.id
  // setCard((curval)=>(curval.map((arr)=>(arr.id == id ? arr: {id:5})))
  return (
    <CardContext.Provider value={{ card, setCard }}>
      {children}
    </CardContext.Provider>
  );
}
export default CardProvider;
 