import React, { useState, useEffect } from "react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import Card from "react-bootstrap/Card";
// import MobleImg from "../../assets/img/mobileImg.png";
import StarImg from "../../assets/img/StarImg.svg";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
// import SwiperSlideCard from "./SwiperSlideCard";
import {
  axiosInstance,
  base_url,
  handleError,
  imgBaseUrl,
} from "../../Config/axiosConfig";
// import toastify from "../../Config/toastify";
// import BeautyColImg from "../../assets/img/beautyColImg.png";
import { Link } from "react-router-dom";

const SpecialUperSection = ({ duration }) => {
  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  let [apiData, setApiData] = useState([]);

  useEffect(() => {
    const apiCall = async () => {
      try {
        let res = await axiosInstance.get(
          `${base_url}/v2/product/home_first_cat`
        );
        // console.log(res.data);
        setApiData(res?.data?.data);
      } catch (error) {
        let errorMsg = handleError(error);
        console.log(errorMsg);
      }
    };
    apiCall();
  }, []);

  useEffect(() => {
    const now = new Date().getTime();
    const endTime = now + duration * 24 * 60 * 60 * 1000;

    const calculateTimeLeft = () => {
      const difference = endTime - new Date().getTime();

      let timeLeft = {};

      if (difference > 0) {
        timeLeft = {
          days: Math.floor(difference / (1000 * 60 * 60 * 24)),
          hours: Math.floor(
            (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
          ),
          minutes: Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60)),
          seconds: Math.floor((difference % (1000 * 60)) / 1000),
        };
      } else {
        timeLeft = { days: 0, hours: 0, minutes: 0, seconds: 0 };
      }
      return timeLeft;
    };

    setTimeLeft(calculateTimeLeft()); // Initial calculation

    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, [duration]);

  return (
    <section className="specialUperSection constantPadding">
      <div className="specialContainer">
        <div className="row">
          <div className="col-lg-2 p-0">
            <div className="uperTextContiner">
              <h1 className="mainHeading">Special Offer</h1>
              <p className="smHeading">
                up to <span>50%</span> Off
              </p>
              <div className="uperTimingContainer">
                <h4 className="smHeading">Hurry Up! Offer End In:</h4>
                <div className="uperTimer">
                  <div className="timeBoxContainer">
                    <div className="timeBox days">
                      <p className="smHeading">{timeLeft.days}</p>
                    </div>
                    <p className="smHeading">Days</p>
                  </div>
                  <p className="mainHeading">:</p>
                  <div className="timeBoxContainer">
                    <div className="timeBox">
                      <p className="smHeading">{timeLeft.hours}</p>
                    </div>
                    <p className="smHeading">Hrs</p>
                  </div>
                  <p className="mainHeading">:</p>
                  <div className="timeBoxContainer">
                    <div className="timeBox min">
                      <p className="smHeading">{timeLeft.minutes}</p>
                    </div>
                    <p className="smHeading">Mins</p>
                  </div>
                  <p className="mainHeading">:</p>
                  <div className="timeBoxContainer">
                    <div className="timeBox">
                      <p className="smHeading">{timeLeft.seconds}</p>
                    </div>
                    <p className="smHeading">Secs</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-10">
            <div className="uperCarouselContainer">
              <Swiper
                modules={[Navigation, Pagination, Scrollbar, A11y]}
                loop={true}
                
                spaceBetween={50}
                slidesPerView={4}
                navigation
                // onSlideChange={() => console.log("slide change")}
                // onSwiper={(swiper) => console.log(swiper)}
                breakpoints={{
                  // when window width is >= 640px
                  100: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                  },
                  // when window width is >= 768px
                  550: {
                    slidesPerView: 3,
                    spaceBetween: 30,
                  },
                  // when window width is >= 1024px
                  1024: {
                    slidesPerView: 4,
                    spaceBetween: 40,
                  },
                }}
              >
                {apiData.map((arr) => (
                  <SwiperSlide key={arr?.id}>
                    <div className="upperCart">
                      <Link to={`/product-details/${arr?.id}`}>
                        <Card>
                          <Card.Img
                            variant="top"
                            src={`${imgBaseUrl}/${arr?.banner}`}
                            className="mobileImg SpecialUperSectionCart"
                            alt="img"
                          />
                          <Card.Body>
                            <h1 className="mainHeading">
                              {arr?.name.split(" ")[0]}{" "}
                              {arr?.name.split(" ")[1]}{" "}
                              {arr?.name.split(" ")[2]}......
                            </h1>
                            <div className="cardReview">
                              <img src={StarImg} className="starImg"  alt="img"/>
                              <p className="grayHeading">(1,512)</p>
                            </div>
                            <div className="cardPricing">
                              <p className="mainHeading">
                                ₹ {arr?.unit_price}
                                {arr.discount && (
                                  <span>₹ {arr.discount + arr.unit_price}</span>
                                )}
                              </p>
                            </div>
                          </Card.Body>
                        </Card>
                      </Link>
                    </div>
                  </SwiperSlide>
                ))}
                {/* <SwiperSlide>
                  <div className="upperCart">
                    <Card>
                      <Card.Img
                        variant="top"
                        src={MobleImg}
                        className="mobileImg"
                      />
                      <Card.Body>
                        <h1 className="mainHeading">
                          Redmi 8 (Onyx Black, 64GB)
                        </h1>
                        <div className="cardReview">
                          <img src={StarImg} className="starImg" />
                          <p className="grayHeading">(1,512)</p>
                        </div>
                        <div className="cardPricing">
                          <p className="mainHeading">
                            ₹ 12,990
                            <span>₹ 15,990</span>
                          </p>
                        </div>
                      </Card.Body>
                    </Card>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="upperCart">
                    <Card>
                      <Card.Img
                        variant="top"
                        src={MobleImg}
                        className="mobileImg"
                      />
                      <Card.Body>
                        <h1 className="mainHeading">
                          Redmi 8 (Onyx Black, 64GB)
                        </h1>
                        <div className="cardReview">
                          <img src={StarImg} className="starImg" />
                          <p className="grayHeading">(1,512)</p>
                        </div>
                        <div className="cardPricing">
                          <p className="mainHeading">
                            ₹ 12,990
                            <span>₹ 15,990</span>
                          </p>
                        </div>
                      </Card.Body>
                    </Card>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="upperCart">
                    <Card>
                      <Card.Img
                        variant="top"
                        src={MobleImg}
                        className="mobileImg"
                      />
                      <Card.Body>
                        <h1 className="mainHeading">
                          Redmi 8 (Onyx Black, 64GB)
                        </h1>
                        <div className="cardReview">
                          <img src={StarImg} className="starImg" />
                          <p className="grayHeading">(1,512)</p>
                        </div>
                        <div className="cardPricing">
                          <p className="mainHeading">
                            ₹ 12,990
                            <span>₹ 15,990</span>
                          </p>
                        </div>
                      </Card.Body>
                    </Card>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="upperCart">
                    <Card>
                      <Card.Img
                        variant="top"
                        src={MobleImg}
                        className="mobileImg"
                      />
                      <Card.Body>
                        <h1 className="mainHeading">
                          Redmi 8 (Onyx Black, 64GB)
                        </h1>
                        <div className="cardReview">
                          <img src={StarImg} className="starImg" />
                          <p className="grayHeading">(1,512)</p>
                        </div>
                        <div className="cardPricing">
                          <p className="mainHeading">
                            ₹ 12,990
                            <span>₹ 15,990</span>
                          </p>
                        </div>
                      </Card.Body>
                    </Card>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="upperCart">
                    <Card>
                      <Card.Img
                        variant="top"
                        src={MobleImg}
                        className="mobileImg"
                      />
                      <Card.Body>
                        <h1 className="mainHeading">
                          Redmi 8 (Onyx Black, 64GB)
                        </h1>
                        <div className="cardReview">
                          <img src={StarImg} className="starImg" />
                          <p className="grayHeading">(1,512)</p>
                        </div>
                        <div className="cardPricing">
                          <p className="mainHeading">
                            ₹ 12,990
                            <span>₹ 15,990</span>
                          </p>
                        </div>
                      </Card.Body>
                    </Card>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="upperCart">
                    <Card>
                      <Card.Img
                        variant="top"
                        src={MobleImg}
                        className="mobileImg"
                      />
                      <Card.Body>
                        <h1 className="mainHeading">
                          Redmi 8 (Onyx Black, 64GB)
                        </h1>
                        <div className="cardReview">
                          <img src={StarImg} className="starImg" />
                          <p className="grayHeading">(1,512)</p>
                        </div>
                        <div className="cardPricing">
                          <p className="mainHeading">
                            ₹ 12,990
                            <span>₹ 15,990</span>
                          </p>
                        </div>
                      </Card.Body>
                    </Card>
                  </div>
                </SwiperSlide> */}
              </Swiper>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SpecialUperSection;
