import React from 'react'
import { Link } from 'react-router-dom'

export default function SellerBox() {
  return (
    <>
     <section className="seller-box">
                <div className="row">
                    <div className="col-lg-3 col-md-6 col-sm-6">
                        <div className="seller-lft">
                            <div className="seler-name">
                                <h5>Shop name Nerozon online</h5>
                            </div>
                            <h4 className="text-center">All Products</h4>
                        </div>
                    </div>
                    <div className="col-lg-2 d-none d-lg-block d-xl-block" />
                    <div className="col-lg-5 col-md-6 col-sm-6">
                        <ul className="seller-txt">
                            <li>
                                <Link href="#">
                                    <img src="assets/images/clipboard.svg" className="me-2" alt="" />
                                    Product 521
                                </Link>
                            </li>
                            <li>
                                <Link href="#">
                                    <img src="assets/images/star.png" className="me-2" alt="" />
                                    Rating 4.8(911.3k Rating){" "}
                                </Link>
                            </li>
                            <li>
                                <Link href="#">
                                    <img src="assets/images/user.png" className="me-2" alt="" />
                                    Joined 5 Years Ago
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
    </>
  )
}
