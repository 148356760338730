import React, { useState } from "react";
import loginImg from "../../../assets/images/login-img.png";
export default function RegisterWithMobileNumber() {
  let [input,setInput]=useState()
  return (
    <>
      <div
        className="modal fade register-modal"
        id="otp1"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-xl">
          <div className="modal-content">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            />
            <div className="modal-body register-body">
              <div className="row gx-3">
                <div className="col-lg-5 d-none d-lg-block d-xl-block">
                  <div className="register-body-lft">
                    <h3>Forgot Password</h3>
                    <p>
                    Please provide your registered Mobile number Get started into Nerozon.
                    </p>
                    <img src={loginImg} className="w-100 h-100" alt="" />
                  </div>
                </div>
                <div className="col-lg-7 col-md-12">
                  <div className="register-body-rgt">
                    <form action="">
                      <div className="mb-4 frm-bx number">
                        <label htmlFor="">Mobile Number</label>
                        <input
                          type="number"
                          className="form-control"
                          defaultValue={7894561230}
                          onChange={setInput}
                          value={input}
                        />
                        <span>+91</span>
                      </div>
                      <div className="frm-bx">
                        <button className="org-btn w-100" type="button">
                          Get OTP
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
