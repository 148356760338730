// import React from "react";
import { Card } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
// import item1 from "../../assets/img/recmendedItem1.png";
import SalesSliderImg from "../../assets/img/salesSliderImg.png";
import LeftArrow from "../../assets/img/diwliLeftArrow.svg";
import RightArrow from "../../assets/img/diwliRightArrow.svg";
import {
  axiosInstance,
  base_url,
  handleError,
  imgBaseUrl,
} from "../../Config/axiosConfig";

const SalesSection = () => {
  let [elevenCategory, setElevenCategory] = useState();

  useEffect(() => {
    let apicall = async () => {
      try {
        let res = await axiosInstance.get(
          `${base_url}/v2/product/home_eleven_cat`
        );
        // console.log(res.data);
        setElevenCategory(res?.data?.data);
      } catch (error) {
        let errorMsg = handleError(error);
        console.log(errorMsg);
      }
    };
    apicall();
  }, []);
  return (
    <section className="SalesSection constantPadding">
      <div className="saleContainer">
        <div className="col-lg-10 px-3">
          <Swiper
            modules={[Navigation, Pagination, Scrollbar, A11y]}
            loop={true}
            id="1"
            spaceBetween={20}
            slidesPerView={4}
            navigation={{
              prevEl: `.salePrv`,
              nextEl: `.saleNext`,
            }}
            // onSlideChange={() => console.log("slide change")}
            // onSwiper={(swiper) => console.log(swiper)}
            breakpoints={{
              100: {
                slidesPerView: 1,
                spaceBetween: 0,
              },
              650: {
                slidesPerView: 2,
                spaceBetween: 15,
              },
              1024: {
                slidesPerView: 4,
                spaceBetween: 20,
              },
            }}
          >
            {elevenCategory?.map((arr, i) => (
              <SwiperSlide key={arr?.id || i}>
                <div className="saleProductContainer">
                  <div className="upperCart">
                    <Card>
                      <Card.Img
                        variant="top"
                        src={`${imgBaseUrl}/${arr?.banner}`}
                        className="itemImg"
                      />
                      <Card.Body>
                        <h1 className="mainHeading">
                          {arr?.name.split(" ")[0]} {arr?.name.split(" ")[1]}{" "}
                          {arr?.name.split(" ")[2]}.......
                        </h1>
                        <div className="cardPricing">
                          <p className="mainHeading">
                            ₹ {arr?.unit_price}
                            {arr?.discount && (
                              <span>₹ {arr?.discount + arr?.unit_price}</span>
                            )}
                          </p>
                        </div>
                      </Card.Body>
                    </Card>
                  </div>
                  <div className="upperCart">
                    <Card>
                      <Card.Img
                        variant="top"
                        src={`${imgBaseUrl}/${arr?.banner}`}
                        className="itemImg"
                      />
                      <Card.Body>
                        <h1 className="mainHeading">
                          {arr?.name.split(" ")[0]} {arr?.name.split(" ")[1]}{" "}
                          {arr?.name.split(" ")[2]}.......
                        </h1>
                        <div className="cardPricing">
                          <p className="mainHeading">
                            ₹ {arr?.unit_price}
                            {arr.discount && (
                              <span>₹ {arr.discount + arr.unit_price}</span>
                            )}
                          </p>
                        </div>
                      </Card.Body>
                    </Card>
                  </div>
                </div>
              </SwiperSlide>
            ))}
            {/* <SwiperSlide>
              <div className="saleProductContainer">
                <div className="upperCart">
                  <Card>
                    <Card.Img variant="top" src={item1} className="itemImg" />
                    <Card.Body>
                      <h1 className="mainHeading">boAt Airdopes 161/163...</h1>
                      <div className="cardPricing">
                        <p className="mainHeading">
                          ₹ 12,990
                          <span>₹ 15,990</span>
                        </p>
                      </div>
                    </Card.Body>
                  </Card>
                </div>
                <div className="upperCart">
                  <Card>
                    <Card.Img variant="top" src={item1} className="itemImg" />
                    <Card.Body>
                      <h1 className="mainHeading">boAt Airdopes 161/163...</h1>
                      <div className="cardPricing">
                        <p className="mainHeading">
                          ₹ 12,990
                          <span>₹ 15,990</span>
                        </p>
                      </div>
                    </Card.Body>
                  </Card>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="saleProductContainer">
                <div className="upperCart">
                  <Card>
                    <Card.Img variant="top" src={item1} className="itemImg" />
                    <Card.Body>
                      <h1 className="mainHeading">boAt Airdopes 161/163...</h1>
                      <div className="cardPricing">
                        <p className="mainHeading">
                          ₹ 12,990
                          <span>₹ 15,990</span>
                        </p>
                      </div>
                    </Card.Body>
                  </Card>
                </div>
                <div className="upperCart">
                  <Card>
                    <Card.Img variant="top" src={item1} className="itemImg" />
                    <Card.Body>
                      <h1 className="mainHeading">boAt Airdopes 161/163...</h1>
                      <div className="cardPricing">
                        <p className="mainHeading">
                          ₹ 12,990
                          <span>₹ 15,990</span>
                        </p>
                      </div>
                    </Card.Body>
                  </Card>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="saleProductContainer">
                <div className="upperCart">
                  <Card>
                    <Card.Img variant="top" src={item1} className="itemImg" />
                    <Card.Body>
                      <h1 className="mainHeading">boAt Airdopes 161/163...</h1>
                      <div className="cardPricing">
                        <p className="mainHeading">
                          ₹ 12,990
                          <span>₹ 15,990</span>
                        </p>
                      </div>
                    </Card.Body>
                  </Card>
                </div>
                <div className="upperCart">
                  <Card>
                    <Card.Img variant="top" src={item1} className="itemImg" />
                    <Card.Body>
                      <h1 className="mainHeading">boAt Airdopes 161/163...</h1>
                      <div className="cardPricing">
                        <p className="mainHeading">
                          ₹ 12,990
                          <span>₹ 15,990</span>
                        </p>
                      </div>
                    </Card.Body>
                  </Card>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="saleProductContainer">
                <div className="upperCart">
                  <Card>
                    <Card.Img variant="top" src={item1} className="itemImg" />
                    <Card.Body>
                      <h1 className="mainHeading">boAt Airdopes 161/163...</h1>
                      <div className="cardPricing">
                        <p className="mainHeading">
                          ₹ 12,990
                          <span>₹ 15,990</span>
                        </p>
                      </div>
                    </Card.Body>
                  </Card>
                </div>
                <div className="upperCart">
                  <Card>
                    <Card.Img variant="top" src={item1} className="itemImg" />
                    <Card.Body>
                      <h1 className="mainHeading">boAt Airdopes 161/163...</h1>
                      <div className="cardPricing">
                        <p className="mainHeading">
                          ₹ 12,990
                          <span>₹ 15,990</span>
                        </p>
                      </div>
                    </Card.Body>
                  </Card>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="saleProductContainer">
                <div className="upperCart">
                  <Card>
                    <Card.Img variant="top" src={item1} className="itemImg" />
                    <Card.Body>
                      <h1 className="mainHeading">boAt Airdopes 161/163...</h1>
                      <div className="cardPricing">
                        <p className="mainHeading">
                          ₹ 12,990
                          <span>₹ 15,990</span>
                        </p>
                      </div>
                    </Card.Body>
                  </Card>
                </div>
                <div className="upperCart">
                  <Card>
                    <Card.Img variant="top" src={item1} className="itemImg" />
                    <Card.Body>
                      <h1 className="mainHeading">boAt Airdopes 161/163...</h1>
                      <div className="cardPricing">
                        <p className="mainHeading">
                          ₹ 12,990
                          <span>₹ 15,990</span>
                        </p>
                      </div>
                    </Card.Body>
                  </Card>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="saleProductContainer">
                <div className="upperCart">
                  <Card>
                    <Card.Img variant="top" src={item1} className="itemImg" />
                    <Card.Body>
                      <h1 className="mainHeading">boAt Airdopes 161/163...</h1>
                      <div className="cardPricing">
                        <p className="mainHeading">
                          ₹ 12,990
                          <span>₹ 15,990</span>
                        </p>
                      </div>
                    </Card.Body>
                  </Card>
                </div>
                <div className="upperCart">
                  <Card>
                    <Card.Img variant="top" src={item1} className="itemImg" />
                    <Card.Body>
                      <h1 className="mainHeading">boAt Airdopes 161/163...</h1>
                      <div className="cardPricing">
                        <p className="mainHeading">
                          ₹ 12,990
                          <span>₹ 15,990</span>
                        </p>
                      </div>
                    </Card.Body>
                  </Card>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="saleProductContainer">
                <div className="upperCart">
                  <Card>
                    <Card.Img variant="top" src={item1} className="itemImg" />
                    <Card.Body>
                      <h1 className="mainHeading">boAt Airdopes 161/163...</h1>
                      <div className="cardPricing">
                        <p className="mainHeading">
                          ₹ 12,990
                          <span>₹ 15,990</span>
                        </p>
                      </div>
                    </Card.Body>
                  </Card>
                </div>
                <div className="upperCart">
                  <Card>
                    <Card.Img variant="top" src={item1} className="itemImg" />
                    <Card.Body>
                      <h1 className="mainHeading">boAt Airdopes 161/163...</h1>
                      <div className="cardPricing">
                        <p className="mainHeading">
                          ₹ 12,990
                          <span>₹ 15,990</span>
                        </p>
                      </div>
                    </Card.Body>
                  </Card>
                </div>
              </div>
            </SwiperSlide> */}
          </Swiper>
        </div>
        <div className="crouselCol">
          <div className="salesCrouselContainer">
            <div className="salesCarousel">
              <div className="upperCart">
                <img src={SalesSliderImg} className="saleSliderImg" />
              </div>
            </div>
            <h1 className="mainHeading">Minimum 50% off</h1>
            <div className="holeHeaderBtn">
              <div className="salePrv">
                <img src={LeftArrow} className="carouslArrowImg" />
              </div>
              <div className="saleNext">
                <img src={RightArrow} className="carouslArrowImg" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SalesSection;
