import React, { useEffect,useContext } from "react";
import { useNavigate } from "react-router-dom";
// import CardContext from "./components/Context/CardContext";
 
export default function Protected({ Component  }) {
  const navigate = useNavigate(); 
  // let  {setCard} =useContext(CardContext)
  useEffect(() => {
    const token = localStorage.getItem("token");
    const checkAuth = async () => {
      if (!token) {
        localStorage.clear()
        navigate("/login");
        return;
      }
    };
    // if(!token){
    //   let data= localStorage.getItem("guestUser")
    //   if(data){
    //     data = JSON.parse(data)
    //     setCard(data)
    //   }
    //  }

    checkAuth();
  }, [navigate]);

  return <Component /> 
}
