import React, { useEffect, useState } from "react";
import productImg from "../../../assets/images/prodcu.png";
// import OtherProductSection from "../OtherProductSection";
import { useNavigate, useLocation } from "react-router-dom";
import {
  handleError,
  axiosInstance,
  base_url,
  imgBaseUrl,
} from "../../../Config/axiosConfig";
import toastify from "../../../Config/toastify";
export default function EditReview() {
  let location = useLocation();
  let navigate = useNavigate();
  let token = localStorage.getItem("token")

  const [uploadImg, setUplaodImg] = useState("");
  const [formVal, setformVal] = useState({
    product_id: "",
    rating: "",
    comment: "",
    image: "",
    oldimage: "",
  });
    const [state,setState]=useState();
    useEffect(() => {
      let stateData = location.state;
      setformVal({ ...stateData, oldimage: stateData.image });
      setState(stateData);
      console.log(stateData)
    }, []);
  const handleOnchange = (e, i) => {
    if (e <= 5) {
      return setformVal((curval) => ({
        ...curval,
        ["rating"]: e,
      }));
    }
    if (e.target.name === "image") {
      let file = e?.target?.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = () => {
          setUplaodImg(reader?.result);
        };
        reader.readAsDataURL(e?.target?.files[0]);
      }
      return setformVal((curval) => ({
        ...curval,
        [e.target.name]: e.target.files[0],
      }));
    }
    return setformVal((curval) => ({
      ...curval,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log(formVal);
    // return;
    // if (
    //   !formVal.product_id ||
    //   !formVal.rating ||
    //   !formVal.comment ||
    //   !formVal.image
    // ) {
    //   return toastify.info("Empty Fields Not Allowed");
    // }

    // if (formVal.new_password !== formVal.re_enter_new_password) {
    //   return toastify.warning(
    //     "New Password & Re-Type New Password Are Not Match"
    //   );
    // }
    // retutn
    // console.log(typeof formVal.image);

    if (typeof formVal.image === "string") {
      formVal.image = "";
    }
    // delete formVal.pro-_image;
    try {
      let res = await axiosInstance.post(
        `${base_url}/v2/reviews/edit`,formVal,{
          headers: {
          Authorization: "Bearer " + token,
        }
      }
      );
      // console.log(res.data);
      navigate("/my-account/my-reviews");
      if (res.data.result) {
        toastify.success(res.data.message);
      } else {
        toastify.error(res.data.message);
      }
    } catch (error) {
      let errorMsg = handleError(error);
      console.log(errorMsg);
    }
  };
  let items = [1, 2, 3, 4, 5];

  return (
    <>
      <div className="col-lg-9">
        <div className="panel-cards review-product">
          <div>
            <h3 className="title">Rating &amp; Reviews</h3>
          </div>
          <div className="review-product-rgt">
            <div className="review-product-rgt-content text-end me-3">
              <h6> {state?.comment?.slice(0,25)} ....</h6>
              <p>
                <span  className={
                          state?.rating >= 4
                            ? " bg-warning"
                            : state?.rating >= 2
                            ? "bg-info"
                            : "bg-danger"
                        }>
                  {state?.rating}  <i className="bi bi-star-fill ms-1" />
                </span>
                {/* (10,148){" "} */}
              </p>
            </div>
            <div className="review-product-rgt-img">
              <img src={state?.image} alt="imges" />
            </div>
          </div>
        </div>
        <div className="panel-cards add-review">
          <h3 className="title">Rate this product </h3>
          <ul id="stars" className="star-list">
            {items?.map((arr, i) =>
             ( arr <= formVal?.rating) ? (
                <li
                key={i}
                  className="star selected"
                  onClick={() => handleOnchange(++i)}
                  title="Excellent"
                  value={i}
                >
                  <i className="bi bi-star-fill"></i>
                </li>
              ) : (
                <li
                key={i}
                  className="star"
                  onClick={() => handleOnchange(++i)}
                  title="WOW!!!"
                  value={i}
                >
                  <i className="bi bi-star-fill"></i>
                </li>
              )
            )}
            {/* 
            <li className="star" title="Good" data-value={3}>
              <i className="bi bi-star-fill"></i>
            </li>
            <li className="star" title="Excellent" data-value={4}>
              <i className="bi bi-star-fill"></i>
            </li>
            
            <li> */}
            {/* <span>Very Bad</span> */}
            {/* </li> */}
          </ul>
          <hr />
          <h4 className="title">Review this Product</h4>
          <form action="">
            <div>
              <label htmlFor={"text"}>Description</label>
              <textarea
                name="comment"
                id="text"
                className="form-control"
                // defaultVaslue={""}
                value={formVal?.comment}
                onChange={handleOnchange}
              />
            </div>
            {/* <div className="title-option mb-3">
              <h4>Title (optional)</h4>
              <h5>Absolute rubbish!</h5>
            </div> */}
            <div className="upload-img">
              <input
                type="file"
                id="upload"
                name="image"
                onChange={handleOnchange}
              />
              <label htmlFor="upload" className="me-3 mt-2">
                <i className="bi bi-camera-fill"></i>
              </label>
              <label className="mt-2">
                {uploadImg ? (
                  <img src={uploadImg} alt="" />
                ) : (
                  <img src={formVal?.image} alt="" />
                )}
              </label>
            </div>
            <div className="text-end mt-3">
              <button
                className="org-btn-02"
                type="button"
                onClick={handleSubmit}
              >
                SUBMIT
              </button>
            </div>
          </form>
        </div>
      </div>
      {/* <OtherProductSection/> */}
    </>
  );
}
