import React, { useContext, useEffect, useState } from "react";
import { Link, useSearchParams, useNavigate } from "react-router-dom";
import boxImg from "../../assets/images/box.png";
import productSearchItem from "../../assets/images/product-search-item-01.png";
import {
  axiosInstance,
  base_url,
  handleError,
  imgBaseUrl,
} from "../../Config/axiosConfig";
import { useSelector } from "react-redux";
 
import toastify from "../../Config/toastify";
import CardContext from "../Context/CardContext";
import FilterPoduct from "../GroceryProduct/FilterPoduct";
 
export default function SearchProductSection() {
  const [searchParams, setSearchParams] = useSearchParams();
  let navigate=useNavigate()
  let [data, setdata] = useState([]);
  let [links, setLinks] = useState([]);
  let [meta, setMeta] = useState([]);
  let [filterData, setFilterData] = useState([]);

 
  let { card, setCard } = useContext(CardContext);
  const [wishlistData, setWishlistData] = useState([]); 

  let type = searchParams.get("type");
  let productName=searchParams.get(type)
  let queryString="hello"
  let token = localStorage.getItem("token")

  useEffect(() => {
    if (type === "product") {
       let productName= searchParams.get("product");
      // console.log(productName);
      let handleSearchProduct = async () => {
        try {
          // https://nerozon-admin.objectsquare.in/api/v2/products/search
          let res = await axiosInstance.post(`${base_url}/v2/products/search`, {
            name: productName,
          });
          console.log(res?.data);
          
          setdata(res?.data?.data?.product);
          setFilterData(res?.data?.data?.product);
          setLinks(res?.data?.links);
          setMeta(res?.data?.meta);
        } catch (error) {
          let errorMsg = handleError(error);
          console.log(errorMsg);
        }
      };
      handleSearchProduct();
    }
    if (type === "category") {
      console.log("call function");
      
      let id = searchParams.get("id");
      let handleSearchProduct = async () => {
        try {
          let res = await axiosInstance.get(`${base_url}/v2/products/category/${id}`, {
          });
          console.log(res.data);
          setdata(res?.data?.data);
          setFilterData(res?.data?.data);
          setLinks(res?.data?.links);
          setMeta(res?.data?.meta);
        } catch (error) {
          let errorMsg = handleError(error);
          console.log(errorMsg);
        }
      };
      handleSearchProduct();
    }
    if(token){
      wishlistApi()
    }
  }, [type,productName]);



  // let wishlistApi=async()=>{
  //   let res = await axiosInstance.get(`${base_url}/v2/wishlists`, {
  //     headers: {
  //       Authorization: "Bearer " + token,
  //     },
  //   });
  //   console.log(res.data.data);
  //   setWishlistData(res?.data?.data)
  // }
 
  let wishlistApi=async ()=>{
    try {
      let wishlistRes = await axiosInstance.get(`${base_url}/v2/wishlists`, {
        headers: {
          Authorization: "Bearer " + token,
        },  
      });
      setWishlistData(wishlistRes.data.data)
    } catch (error) {
      let errorMsg = handleError(error);
      console.log(errorMsg);
    }
  }


  let handlePaginaton = async (url) => {
    console.log(url, links);

    if (url === null) {
      return;
    }
    try {
      let res = await axiosInstance.post(`${url}`, {
        name: queryString,
      });
      window.scrollTo(0, 1);

      setdata(res?.data?.data?.product);
      setLinks(res?.data?.links);
      setMeta(res?.data?.meta);
    } catch (error) {
      let errorMsg = handleError(error);
      console.log(errorMsg);
    }
  };
// console.log(card);

  const handleAddToCard = async (e, id,product) => {
    e.stopPropagation();
    e.preventDefault();
    console.log(card);
    console.log(product)
    // console.log(product?.choice_options[0].values[0])
    
    // let filterAddToCardData=data.filter((arr)=>(arr.id == id))
    // let jsonData=JSON.parse(filterAddToCardData[0].choice_options)
    // // console.log(JSON.parse(filterAddToCardData[0].choice_options))
    // // console.log(jsonData[0].values[0]);
    // let variantData=jsonData[0]?.values[0]


    
  let variantData=product?.variations || null
  console.log(variantData);
  
    if(variantData){
      toastify.info("Please Select Varinats First");
      return navigate(`/product-details/${id}`)
    }
  


   if(!token){
      toastify.success("Add Item In Cart");
      return setCard((curVal) =>
        {
        let dataVal =curVal.some((cartItem) => cartItem.product_id == id)
          ? curVal.map((cart) =>
              cart.product_id == id
                ? { ...cart, quantity: ++cart.quantity }
                : cart
            )
          : [...curVal, { product_id: id, quantity: 1 || null ,product_name:product?.main_price, product_thumbnail_image : product?.thumbnail_image, price:product?.calculable_price, currency_symbol : "RS", unit:product?.unit|| 1 , variant: product?.variations  || null}]
          localStorage.setItem("guestUser",JSON.stringify(dataVal))
          return dataVal
        });
    } 
 
    try {
      let res = await axiosInstance.post(`${base_url}/v2/carts/add` ,{
        id: id,
        quantity: 1,
        variant: product?.variations  || null
      },{ headers: {
        Authorization: "Bearer " + token,
      }});
      console.log(res, "add to card");
      if (res.data.result) {
        // setCard((curVal) => ++curVal); 
        // setCard((curVal)=> curVal.some((arr)=>(arr === id)) ? curVal : [...curVal,id] )
        setCard((curVal)=> curVal.some((cartItem)=>(cartItem.product_id == id)) ? curVal.map(cart=> cart.product_id == id ? {...cart,quantity :++cart.quantity} : cart) : [ ...curVal , {product_id:id,quantity:1}] )

        toastify.success(res?.data?.message);
      } else {
        toastify.error(res?.data?.message);
      }
      // setApiData(res?.data?.data[0]);
    } catch (error) {
      let errorMsg = handleError(error);
      console.log(errorMsg);
    }
  };

  let filterPriceHighToLow=()=>{
      const sortedProducts = data.sort((a, b) => {
        return parseFloat(b.main_price.replace("Rs",'')) - parseFloat(a.main_price.replace("Rs",''));
    });
      
    console.log(sortedProducts);
    setdata(sortedProducts)
    
      // let filterdata=data?.sort((main_price1, main_price2) =>parseFloat(main_price1.replace("RS" ,"")) - parseFloat(main_price2.replace("RS" ,"")));
    // });
    // console.log(filterdata) 
    // console.log(data)
  }

  let filterPriceLowLoHigh=()=>{
      const sortedProducts = data.sort(( b,a) => {
        return parseFloat(b.main_price.replace("Rs",'')) - parseFloat(a.main_price.replace("Rs",''));
    });
    setdata(sortedProducts)
  }

 
//  let filterBasedOnDate = () => {
//   data.sort((a, b) => {
//     return  new Date(b.created_at) - new Date(a.created_at) ;
//   });    
// };

const handleAddWishList = async (e,id) => {
    e.stopPropagation();
    e.preventDefault();
  if(!token){
    toastify.error("Please login first")
    let unique = document.getElementById("openLoginFormBtn");
   return  unique.click();
  }
  let data=wishlistData.some((wishitem)=>wishitem?.product?.id ==  id )
  if(data){
    let res = await axiosInstance.post(
      `${base_url}/v2/wishlists-remove-product`,
      {
        product_id: id,
      },
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    if(!(res.data.is_in_wishlist)){
      toastify.success(res.data.message)
     return wishlistApi()
    }
  }
  try {
    let res = await axiosInstance.post(`${base_url}/v2/wishlists-add-product`, {
      product_id: id,
    },{
      headers:{
        Authorization: "Bearer " + token
      }
    });
    console.log(res, "add to wishlist");
    if (res.data.is_in_wishlist) {
      wishlistApi()
        toastify.success(res?.data?.message);
    } else {
      toastify.error(res?.data?.message);
    }
    // setApiData(res?.data?.data[0]);
  } catch (error) {
    let errorMsg = handleError(error);
    console.log(errorMsg);
  }
};

  return (
    <>
    <FilterPoduct filterData={filterData} data={filterData}  setData={setdata}/>
      <div className="col-lg-9 col-md-8">
        <div className="custom-bredcump">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Home</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="#">Clothing</Link>
            </li> 
            <li className="breadcrumb-item">
              <Link to="#">Ladies</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Tops &amp; T-shirts
            </li>
          </ol>
          <p>
            Showing {data?.length} Results for "{productName}”{" "}
            <span>( {data?.length} items)</span>
          </p>
        </div>
        <div className="row">
          <div className="col-lg-9 col-md-12">
            <ul className="sort-by-list">
              <li>
                <i className="fal fa-sort-alt me-2" />
                Sort By:
              </li>
              <li>
                <Link to="#" className="active">
                  Relevance
                </Link>
              </li>
              <li>
                <Link to="#">Popularity</Link>
              </li>
              <li>
                <Link to="#" onClick={filterPriceHighToLow}>Price - High to Low</Link>
              </li>
              <li>
                <Link to="#" onClick={filterPriceLowLoHigh}>Price - Low to High</Link>
              </li>
              <li>
                <Link to="#"  >Newest First </Link>
              </li>
            </ul>
          </div>
          <div className="col-lg-3 text-end">
            <Link to="#">
              <img src={boxImg} alt="" />
            </Link>
          </div>
          {data?.length > 0 ? (
            data?.map((arr) => (
              <div key={arr?.id} className="col-lg-3 col-md-4 col-sm-6">
                <Link to={`/product-details/${arr?.id}`}>
                  <div className="other-prdct-card search-prdct">
                    <div className="search-prdct-img">
                      <img
                        src={`${imgBaseUrl}/${arr?.thumbnail_image|| arr?.thumbnail_img}`}
                        alt=""
                      />
                      <div className="search-prdct-btn">
                        <Link to="#" className="org-btn-02  w-100 text-center">
                          Buy Now
                        </Link>
                        <button
                          className="org-btn-02 w-100  text-center "
                          onClick={(e) => handleAddToCard(e, arr?.id,arr)}
                        >
                          <i className="bi bi-cart-plus me-2" />
                          Add to Cart
                        </button>
                        <span className="lyk-btn">
                          <i className="bi bi-suit-heart" />
                        </span>
                      </div>
                    </div>
                    <div className="other-prdct-content">
                      <h5>{arr?.name}</h5>
                      <p className="rating">
                        <span>
                          {arr?.rating} <i className="fas fa-star" />
                        </span>{" "}
                         
                      </p>
                       
                      <p className="prc">
                        {/* ₹ */}
                        {arr?.main_price} 
                       {/* {arr?.unit_price} */} &nbsp;
                        <del>{arr?.stroked_price}</del>{" "}
                        <em>
                          {/* {100 -
                            parseInt(
                              (parseInt(arr?.main_price?.slice(2)) /
                                parseInt(arr?.stroked_price?.slice(2))) *
                                100
                            )} */}
                          % Off
                        </em>
                      </p>
                      <div className="other-prdct-new-dtl">
                        <p className="select-color">
                          <Link to="#" className="color org select" />
                          <Link to="#" className="color grn" />
                          <Link to="#" className="color rd" />
                          <Link to="#" className="color sky" />
                        </p>
                        <p className="star-txt">
                          Or pay ₹ 1,100 + 2
                          <span className="bi bi-star-fill ms-1 " />
                        </p>
                      </div>
                    </div>
                    { wishlistData?.some((wishitem)=>wishitem?.product?.id == arr?.id)  ?  <span className="lyk-btn select" onClick={(e)=>handleAddWishList(e,arr?.id)}>
                      <i className="bi bi-heart-fill"  />
                    </span>
                 :   <span className="lyk-btn select" onClick={(e)=>handleAddWishList(e,arr?.id)}>
                 <i className="bi bi-heart"  />
               </span>}

                    {/* <span className="lyk-btn select" onClick={(e)=>handleAddWishList(e,arr?.id)}>
                      <i className="fas fa-heart-fill"  />
                    </span> */}
                  </div>
                </Link>
              </div>
            ))
          ) : (
            <div className="breadcrumb-item  mt-2 ms-3"> No data found</div>
          )}
        </div>
        {meta?.total >= 10 && (
          <div className="custom-paginate">
            <ul className="pagination">
              {links?.prev !== null && (
                <li className="page-item">
                  <Link
                    className="page-link"
                    to="#"
                    onClick={() => handlePaginaton(links?.prev)}
                  >
                    Previous
                  </Link>
                </li>
              )}
              {meta?.current_page > 1 && (
                <li className="page-item">
                  <Link
                    className="page-link "
                    to="#"
                    onClick={() =>
                      handlePaginaton(
                        `https://nerozon-admin.objectsquare.in/api/v2/products/search?page=${
                          meta?.current_page - 1
                        }`
                      )
                    }
                  >
                    {meta?.current_page - 1}
                  </Link>
                </li>
              )}
              <li className="page-item">
                <button className="page-link active">
                  {meta?.current_page}
                </button>
              </li>

              {meta?.current_page !== meta?.last_page && (
                <li className="page-item">
                  <button
                    className="page-link"
                    onClick={() => handlePaginaton(links?.last)}
                  >
                    {meta?.last_page}
                  </button>
                </li>
              )}

              {/* <li className="page-item">
              <Link className="page-link" to="#">
                4
              </Link>
            </li>
            <li className="page-item">
              <Link className="page-link" to="#">
                5
              </Link>
            </li>
            <li className="page-item">
              <Link className="page-link" to="#">
                6
              </Link>
            </li>
            <li className="page-item">
              <Link className="page-link blank" to="#">
                ....
              </Link>
            </li>
            <li className="page-item">
              <Link className="page-link" to="#">
                23
              </Link>
            </li> */}
              {links?.next !== null && (
                <li className="page-item">
                  <button
                    className="page-link"
                    onClick={() => handlePaginaton(links?.next)}
                  >
                    Next
                  </button>
                </li>
              )}
            </ul>
          </div>
        )}
      </div>
    </>
  );
}
