import React, { useState } from "react";
import googleSvg from "../../../assets/images/google.svg";
import loginImg from "../../../assets/images/login-img.png";
import facebookImg from "../../../assets/images/facebook.svg";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import {
  axiosInstance,
  base_url,
  handleError,
} from "../../../Config/axiosConfig";
import toastify from "../../../Config/toastify";
import axios from "axios";
export default function RegisterPage() {
  let navigate = useNavigate();
  let [formData, setFormData] = useState({
    register_by:"email",
    phone: "",
    otp: "",
    name: "",
    email: "",
    password: "",
    confirmed: "",
  });
  let handleChange = (e) => {
    setFormData((currVal) => ({ ...currVal, [e.target.name]: e.target.value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(formData);
    if (formData.password !== formData.confirmed) {
      return toastify.error("Password and Confirm Password Not Matching");
    }
    // delete formData.confirmed
    // delete formData.otp

    try {
    
      const res = await axios.post(`${base_url}/v2/auth/signup`, formData);
      console.log(res.data);
      // console.log(res?.data?.access_token);
      if( (res.data.result)){
        toastify.success(res?.data?.message);
        // localStorage.setItem("user", JSON.stringify(res.data.user));
        // localStorage.setItem("token", JSON.stringify(res.data.access_token));
        let unique = document.getElementById("registerButton");
        unique.click();
        navigate("/");
        setFormData({
          register_by:"email",
          phone: "",
          otp: "",
          name: "",
          email: "",
          password: "",
          confirmed: "",
        })
      }else{
        toastify.error(res?.data?.message);
      }
    } catch (error) {
      let errorMsg = handleError(error);
      console.log(errorMsg);
      toastify.error(error.response.data.message);
    }
  };
  return (
    <>
      <div
        className="modal fade register-modal"
        id="register"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-xl">
          <div className="modal-content">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              id="registerButton"
            />
            <div className="modal-body register-body">
              <div className="row gx-3">
                <div className="col-lg-5 d-none d-lg-block d-xl-block">
                  <div className="register-body-lft">
                    <h3>Register</h3>
                    <p>Enter your credentials to log into your account.</p>
                    <img src={loginImg} className="w-100 h-100" alt="" />
                  </div>
                </div>
                <div className="col-lg-7 col-md-12">
                  <div className="register-body-rgt">
                    <form action="">
                      <div className="mb-4 frm-bx number">
                        <label htmlFor="">Mobile Number</label>
                        {/* <span>+91</span>*/}
                        <input
                          type="number"
                          className="form-control"
                          onChange={handleChange}
                          name="phone"
                          placeholder="Number Enter"
                          value={formData.phone}
                        />

                        <Link to="#" className="change">
                          Change
                        </Link>
                      </div>
                      <div className="mb-4 frm-bx">
                        <label htmlFor="">Enter OTP</label>
                        <input
                          type="number"
                          className="form-control"
                          name="otp"
                          onChange={handleChange}
                          placeholder="Enter OTP"
                          value={formData.otp}
                        />
                      </div>
                      <div className="mb-4 frm-bx">
                        <label htmlFor="">Full Name</label>
                        <input
                          type="text"
                          className="form-control"
                          onChange={handleChange}
                          name="name"
                          placeholder="Enter Full Name"
                          value={formData.name}
                        />
                      </div>
                      <div className="mb-4 frm-bx">
                        <label htmlFor="">Email</label>
                        <input
                          type="email"
                          className="form-control"
                          onChange={handleChange}
                          name="email"
                          placeholder="Enter Email address"
                          value={formData?.email}
                        />
                      </div>
                      <div className="mb-4 frm-bx">
                        <label htmlFor="">Password</label>
                        <input
                          type="password"
                          className="form-control"
                          onChange={handleChange}
                          name="password"
                          placeholder="Enter Password"
                          value={formData?.password}
                        />
                      </div>
                      <div className="mb-4 frm-bx">
                        <label htmlFor="">Confirm Password</label>
                        <input
                          type="password"
                          className="form-control"
                          onChange={handleChange}
                          name="confirmed"
                          placeholder="Re-enter Password"
                          value={formData?.confirmed}
                        />
                      </div>
                      <div className="frm-bx">
                        <button
                          className="org-btn w-100"
                          type="button"
                          onClick={handleSubmit}
                        >
                          Sign Up
                        </button>
                      </div>
                      <p className="text-center mt-3 mb-3">OR</p>
                      <div className="social-btn">
                        <Link to="#">
                          <img src={googleSvg} className="me-2" alt="" />
                          Sign in with google
                        </Link>
                        <Link to="#" className="facebook">
                          <img src={facebookImg} className="me-2" alt="" />
                          Sign in with Facebook
                        </Link>
                      </div>
                      <p className="text-center">
                        Existing User?{" "}
                        <Link
                          to="#"
                          data-bs-toggle="modal"
                          data-bs-target="#signin"
                        >
                          Sign In
                        </Link>
                      </p>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
