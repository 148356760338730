import React, { useState } from "react";
import { createContext } from "react";
let userTokenContext = createContext();
export default userTokenContext;
export function UserTokenProvider({ children }) {
  let [userToken, setUserToken] = useState(null);
  return (
    <userTokenContext.Provider value={{ userToken, setUserToken }}>
      {children} 
    </userTokenContext.Provider>
  );
}
