import React, { useState, useEffect } from "react";
import Carousel from "react-bootstrap/Carousel";
import {
  axiosInstance,
  base_url,
  handleError,
  imgBaseUrl,
} from "../../Config/axiosConfig";
import toastify from "../../Config/toastify";
 import dey from  "../../assets/img/dryfriut3.png"

const DryFriutSection = ({ apiData,image }) => {
  // let [apiData, setApiData] = useState([]);
  // console.log(apiData);
  // useEffect(() => {
  //   (async () => {
  //     try {
  //       let res = await axiosInstance.get(`${base_url}/v2/home/second_slider`);
  //       setApiData(res?.data?.data);
  //     } catch (error) {
  //       let errorMsg = handleError(error);
  //       console.log(errorMsg);
  //     }
  //   })();
  // }, []);
// console.log(apiData)
// console.log(apiData !== undefined)
  return (
    <section className="dryFriutSection constantPadding">
      <Carousel
        interval={3000}
        pause={false}
        indicators={false}
        controls={false}
      >
        {apiData !== undefined ? apiData?.map((arr, i) => (
          <Carousel.Item key={i}>
            <div className="paanContainer">
              <img
                src={`${imgBaseUrl}/${arr?.banner}`}
                className="paanImg"
                alt="Dry Fruit"
              />
            </div>
          </Carousel.Item>
        ))
        :  <Carousel.Item >
          <div className="paanContainer">
            <img src={image} className="paanImg" alt="Dry Fruit" />
          </div>
        </Carousel.Item>
          }
      </Carousel>
    </section>
  );
};

{
  /* <section className="advertisment mrt-15">
       <div className="container-fluid">
         <img src={Advertisment_img} alt="" />
       </div>
     </section> */
}

export default DryFriutSection;
