import React, { useState, useEffect } from "react";
import {
  axiosInstance,
  base_url,
  handleError,
  imgBaseUrl,
} from "../../Config/axiosConfig";
import toastify from "../../Config/toastify";
import BeautyColImg from "../../assets/img/beautyColImg.png";

const BeautySection = () => {
  let [apiData, setApiData] = useState([]);

  useEffect(() => {
    let apiCall = async () => {
      try {
        let res = await axiosInstance.get(`${base_url}/v2/home/cat_fist`);
        setApiData(res?.data?.data);
      } catch (error) {
        let errorMsg = handleError(error);
        console.log(errorMsg);
      }
    };
    apiCall();
  }, []);

  return (
    <section className="beautySection constantPadding">
      <div className="beautyContainer">
        {apiData?.map((arr, i) => (
          <div key={arr.id || i} className="beautyContent">
            <div className="beautyCol">
              <img src={`${imgBaseUrl}/${arr.icon}`} className="beautyColImg" />
              <h1 className="mainHeading">
                {""} {arr?.name}
              </h1>
            </div>
          </div>
        ))}
        {/* <div className="beautyContent">
          <div className="beautyCol">
            <img src={BeautyColImg} className="beautyColImg" />
            <h1 className="mainHeading">Beauty & Huggies</h1>
          </div>
        </div>
        <div className="beautyContent">
          <div className="beautyCol">
            <img src={BeautyColImg} className="beautyColImg" />
            <h1 className="mainHeading">Beauty & Huggies</h1>
          </div>
        </div>
        <div className="beautyContent">
          <div className="beautyCol">
            <img src={BeautyColImg} className="beautyColImg" />
            <h1 className="mainHeading">Beauty & Huggies</h1>
          </div>
        </div>
        <div className="beautyContent">
          <div className="beautyCol">
            <img src={BeautyColImg} className="beautyColImg" />
            <h1 className="mainHeading">Beauty & Huggies</h1>
          </div>
        </div>
        <div className="beautyContent">
          <div className="beautyCol">
            <img src={BeautyColImg} className="beautyColImg" />
            <h1 className="mainHeading">Beauty & Huggies</h1>
          </div>
        </div>
        <div className="beautyContent">
          <div className="beautyCol">
            <img src={BeautyColImg} className="beautyColImg" />
            <h1 className="mainHeading">Beauty & Huggies</h1>
          </div>
        </div> */}
      </div>
    </section>
  );
};

export default BeautySection;
