import React from 'react'
import FilterPoduct from "../components/GroceryProduct/FilterPoduct"
import ElectronicSection from '../components/ElectronicSection/ElectronicSection'
// import OtherPopularProductSlider from '../components/MyAccountDetailsSection/OtherPopularProductSlider'
import ProductImg from "../assets/images/product-img-01.png"
// import ElectronicSection from '../components/ElectronicSection/ElectronicSection'
import ElectronicSlider from '../components/ElectronicSection/ElectronicSlider'
export default function ElectronicPage() {
    return (
        <>
            
            <section className="grocery-prdct-sec tp-space">
                <div className="container-fluid">
                    <div className="row">
                        {/* <FilterPoduct /> */}
                        <ElectronicSection/>
                    </div>
                </div>
            </section>
            <ElectronicSlider id={1} title={"Similar Products"}  productImg={ProductImg} lableImg={null} slidesPerView={5}/>
            <ElectronicSlider id={2} title={"Mobiles Products"}  productImg={ProductImg} lableImg={null} slidesPerView={5}/>
            <ElectronicSlider id={3} title={"View Products"}  productImg={ProductImg} lableImg={null} slidesPerView={5}/>
            {/* <OtherPopularProductSlider id={1} title={"Similar Products"}  productImg={ProductImg} lableImg={null} slidesPerView={5}/> */}
            {/* <OtherPopularProductSlider id={1} title={"Similar Products"}  productImg={ProductImg} lableImg={null} slidesPerView={5}/> */}
            {/* <OtherPopularProductSlider id={1} title={"Similar Products"}  productImg={ProductImg} lableImg={null} slidesPerView={5}/> */}
        </>
    )
}
