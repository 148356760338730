import React, { useState, useEffect } from "react";
import {
  axiosInstance,
  handleError,
  imgBaseUrl,
  base_url,
} from "../../Config/axiosConfig";

export default function MyCoupon() {
  // api/v2/all-coupon
  let [apiData, setApiData] = useState([]);
  let [upcommingCoupon, setUpcommingCoupon] = useState([]);
  let today = new Date().toLocaleDateString();
  // console.log(today);
  let token = localStorage.getItem("token")
  let dateCovert = function (date) {
    const dateVal = new Date(date * 1000);
    const formattedDate = dateVal.toLocaleDateString();
    return formattedDate;
  };
  useEffect(() => {
    (async () => {
      try {
        let res = await axiosInstance.get(`${base_url}/v2/all-coupon`, {headers: {
          Authorization: "Bearer " + token,
        }});
        setApiData(() => {
          let availableCoupon = res.data.data.filter((arr) => {
            let date = dateCovert(arr.start_date);
            return date < today;
          });
          return availableCoupon;
        });
        setUpcommingCoupon(() => {
          let filterdata = res.data.data.filter((arr) => {
            let date = dateCovert(arr.start_date);
            return date > today;
          });
          return filterdata;
        });
      } catch (error) {
        let errorMsg = handleError(error);
        console.log(errorMsg);
      }
    })();
  }, []);
  return (
    <>
      <div className="col-lg-9 col-md-8">
        <div className="panel-cards password-change addres w-100">
          <h4 className="title">Available Coupons</h4>
          {apiData?.map((arr) => (
            <div key={arr?.id} className="deliveadd-bx rounded-0 mt-0">
              <div className="deliveadd-bx-content">
                <h5 className="text-success">{arr?.code}</h5>
                <p>
                  Get extra {arr?.discount} {arr.discount_type ? "₹" : "%"} off
                  on {arr?.details.min_buy} item(s) {arr?.details_coupon}
                </p>
              </div>
              <div className="deliveadd-bx-icon text-end">
                <p className="fz-14 fw-600 text-secondary">
                  Valid till &nbsp;{dateCovert(arr.end_date)}
                </p>
                 
              </div>
            </div>
          ))}
{/* 
                     <div className="deliveadd-bx rounded-0 mt-0">
                        <div className="deliveadd-bx-content">
                            <h5 className="text-success">Extra 5% off on Nakpro</h5>
                            <p>
                                Get extra 5% off on 1 item(s) (price inclusive of cashback/coupon)
                            </p>
                        </div>
                        <div className="deliveadd-bx-icon text-end">
                            <p className="fz-14 fw-600 text-secondary">Valid till 5 Apr,2033</p>
                            <a href="#" className="fz-14 fw-600 org-txt">
                                View T&amp;C
                            </a>
                        </div>
                    </div>  
                    <div className="deliveadd-bx rounded-0 mt-0">
                        <div className="deliveadd-bx-content">
                            <h5 className="text-success">Extra 5% off on Nakpro</h5>
                            <p>
                                Get extra 5% off on 1 item(s) (price inclusive of cashback/coupon)
                            </p>
                        </div>
                        <div className="deliveadd-bx-icon text-end">
                            <p className="fz-14 fw-600 text-secondary">Valid till 5 Apr,2033</p>
                            <a href="#" className="fz-14 fw-600 org-txt">
                                View T&amp;C
                            </a>
                        </div>
                    </div>
                    <div className="deliveadd-bx rounded-0 mt-0">
                        <div className="deliveadd-bx-content">
                            <h5 className="text-success">Extra 5% off on Nakpro</h5>
                            <p>
                                Get extra 5% off on 1 item(s) (price inclusive of cashback/coupon)
                            </p>
                        </div>
                        <div className="deliveadd-bx-icon text-end">
                            <p className="fz-14 fw-600 text-secondary">Valid till 5 Apr,2033</p>
                            <a href="#" className="fz-14 fw-600 org-txt">
                                View T&amp;C
                            </a>
                        </div>
                    </div>
                    <div className="deliveadd-bx rounded-0 mt-0">
                        <div className="deliveadd-bx-content">
                            <h5 className="text-success">Extra 5% off on Nakpro</h5>
                            <p>
                                Get extra 5% off on 1 item(s) (price inclusive of cashback/coupon)
                            </p>
                        </div>
                        <div className="deliveadd-bx-icon text-end">
                            <p className="fz-14 fw-600 text-secondary">Valid till 5 Apr,2033</p>
                            <a href="#" className="fz-14 fw-600 org-txt">
                                View T&amp;C
                            </a>
                        </div>
                    </div>
                    <div className="deliveadd-bx rounded-0 mt-0">
                        <div className="deliveadd-bx-content">
                            <h5 className="text-success">Extra 5% off on Nakpro</h5>
                            <p>
                                Get extra 5% off on 1 item(s) (price inclusive of cashback/coupon)
                            </p>
                        </div>
                        <div className="deliveadd-bx-icon text-end">
                            <p className="fz-14 fw-600 text-secondary">Valid till 5 Apr,2033</p>
                            <a href="#" className="fz-14 fw-600 org-txt">
                                View T&amp;C
                            </a>
                        </div>
                    </div>
                    <div className="deliveadd-bx rounded-0 mt-0 justify-content-center">
                        <div className="deliveadd-bx-icon">
                            <a href="#" className="fz-16 fw-700 org-txt">
                                VIEW MORE
                            </a>
                        </div>
                    </div> */}
          <h4 className="title mt-4 mb-4">Upcoming Coupons</h4>
          {upcommingCoupon.length > 0 ? (
            upcommingCoupon.map((arr) => (
              // <div className="deliveadd-bx rounded-0">
              <div key={arr?.id} className="deliveadd-bx rounded-0 mt-0">
                <div className="deliveadd-bx-content">
                  <h5 className="text-success">{arr?.code}</h5>
                  <p>
                    Get extra {arr?.discount} {arr.discount_type ? "₹" : "%"}{" "}
                    off on {arr?.details.min_buy} item(s) {arr?.details_coupon}
                  </p>
                </div>
                <div className="deliveadd-bx-icon text-end">
                  <p className="fz-14 fw-600 text-secondary">
                    Valid till &nbsp;{dateCovert(arr.end_date)}
                  </p>
                  {/* <a href="#" className="fz-14 fw-600 org-txt">
                      View T&amp;C
                    </a> */}
                </div>
              </div>
              // </div>
            ))
          ) : (
            <div className="ms-1">
              
              <b> Not Available Coupons</b>{" "}
            </div>
          )}
          {/* <div className="deliveadd-bx-content">
              <h5 className="text-success">Extra 5% off on Nakpro</h5>
              <p>
                Get extra 5% off on 1 item(s) (price inclusive of
                cashback/coupon)
              </p>
            </div>
            <div className="deliveadd-bx-icon text-end">
              <p className="fz-14 fw-600 text-secondary">
                Valid till 5 Apr,2033
              </p>
              <a href="#" className="fz-14 fw-600 org-txt">
                View T&amp;C
              </a>
            </div> */}
        </div>
      </div>
    </>
  );
}
