import { createSlice, nanoid } from "@reduxjs/toolkit";

const initialState = {  
   searchProduct: []
};

export const searchProductSlice = createSlice({
    name: "searchProduct",
    initialState,
    reducers: {
        addSearchProduct: (state, action) => {
            const newItems = action.payload.map(item => ({
                id: nanoid(),
                ...item
            }));
            state.searchProduct=newItems;
        }
    }
});

export const { addSearchProduct } = searchProductSlice.actions;

export default searchProductSlice.reducer;
