import { createContext,useState } from "react";

let SearchProductContext=createContext()
 
export default SearchProductContext;

 
 
function SearchProductProvider({ children }) {
  let [searchProductData, setSearchProductData] = useState([]);
  return (
    <SearchProductContext.Provider value={{searchProductData, setSearchProductData }}>
      {children}
    </SearchProductContext.Provider>
  );
}
export {SearchProductProvider}