// import React from 'react'
// import { Dropdown } from "react-bootstrap";
// import { Dropdown } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import { Link } from "react-router-dom";

export default function NavListing() {
  return (
    <>
      <div className="navListingLinks">
        <div className="dorpDownSroll">
          <Dropdown>
            <Dropdown.Toggle className=" mainHeading">
              Electronics
            </Dropdown.Toggle>
          </Dropdown>
          <Dropdown>
            <Dropdown.Toggle className=" mainHeading">Grocery</Dropdown.Toggle>
          </Dropdown>
          <Dropdown>
            <Dropdown.Toggle className=" mainHeading">
              TVs & Appliance
            </Dropdown.Toggle>
          </Dropdown>
          <Dropdown>
            <Dropdown.Toggle className=" mainHeading">Men</Dropdown.Toggle>
          </Dropdown>
          <Dropdown>
            <Dropdown.Toggle className=" mainHeading">Women</Dropdown.Toggle>
          </Dropdown>
          <Dropdown>
            <Dropdown.Toggle className=" mainHeading">
              Baby & Kids
            </Dropdown.Toggle>
          </Dropdown>
          <Dropdown>
            <Dropdown.Toggle className=" mainHeading">
              Home & Furniture
            </Dropdown.Toggle>
          </Dropdown>
          <Dropdown>
            <Dropdown.Toggle className=" mainHeading">
              Sports, Books
            </Dropdown.Toggle>
          </Dropdown>
          <Dropdown>
            <Dropdown.Toggle className=" mainHeading">More</Dropdown.Toggle>
          </Dropdown>
        </div>
        <div className="hoverModal">
          <div className="hoverModalContainer">
            <div className="row">
              <div className="col">
                <h1 className="mainHeading">Pages</h1>
                <ul className="hoverList">
                  <li>
                    <Link to="/grocery-page" className="grayHeading">
                      Grocery
                    </Link>
                  </li>
                  <li>
                    <Link to="/grocery-product" className="grayHeading">
                      Grocery Product
                    </Link>
                  </li>
                  {/* <li>
                    <Link to="/clothing-details" className="grayHeading">
                    Clothing-details
                    </Link>
                  </li> */}
                  <li>
                    <Link to="/electronic-section" className="grayHeading">
                      Electronic
                    </Link>
                  </li>
                  <li>
                    <Link to="/search-product" className="grayHeading">
                      Search-product
                    </Link>
                  </li>
                  <li>
                    <Link to="/seller-product" className="grayHeading">
                      Seller-product
                    </Link>
                  </li>
                  <li>
                    <Link to="/seller" className="grayHeading">
                      Seller
                    </Link>
                  </li>
                  <li>
                    <Link to="/comprasion" className="grayHeading">
                      Comprasion
                    </Link>
                  </li>
                  <li>
                    <Link to="/payment-option" className="grayHeading">
                      Payment-option
                    </Link>
                  </li>
                  <li>
                    <Link to="/confirm-order" className="grayHeading">
                      Confirm-order
                    </Link>
                  </li>
                  <li>
                    <Link to="/select-address" className="grayHeading">
                      Select-Address
                    </Link>
                  </li>
                  <li>
                    <Link to="/tracking-order" className="grayHeading">
                      Tracking-order
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="col">
                <h1 className="mainHeading">Moblies Accessories</h1>
                <ul className="hoverList">
                  <li>
                    <Link to="/mobile-details" className="grayHeading">
                      Mobile details
                    </Link>
                  </li>
                  <li>
                    <Link to="/vertical-slider" className="grayHeading">
                      Headphones slider
                    </Link>
                  </li>
                  <li>
                    <Link to="#" className="grayHeading">
                      Power Banks{" "}
                    </Link>
                  </li>
                  <li>
                    <Link to="#" className="grayHeading">
                      Screenguards{" "}
                    </Link>
                  </li>
                  <li>
                    <Link to="#" className="grayHeading">
                      Mobile Cables
                    </Link>
                  </li>
                  <li>
                    <Link to="#" className="grayHeading">
                      Mobile Chargers{" "}
                    </Link>
                  </li>
                  <li>
                    <Link to="#" className="grayHeading">
                      Mobile holders{" "}
                    </Link>
                  </li>
                </ul>
                <h1 className="mainHeading">Smart Wearable Tech</h1>
                <ul className="hoverList">
                  <li>
                    <Link to="#" className="grayHeading">
                      Smart Watches
                    </Link>
                  </li>
                  <li>
                    <Link to="#" className="grayHeading">
                      Smart Glasses (VR)
                    </Link>
                  </li>
                  <li>
                    <Link to="#" className="grayHeading">
                      Smart Bands{" "}
                    </Link>
                  </li>
                </ul>
              </div>

              <div className="col">
                <h1 className="mainHeading">Moblies Accessories</h1>
                <ul className="hoverList">
                  <li>
                    <Link to="#" className="grayHeading">
                      Mobile Cases
                    </Link>
                  </li>
                  <li>
                    <Link to="#" className="grayHeading">
                      Headphones
                    </Link>
                  </li>
                  <li>
                    <Link to="#" className="grayHeading">
                      Power Banks{" "}
                    </Link>
                  </li>
                  <li>
                    <Link to="#" className="grayHeading">
                      Screenguards{" "}
                    </Link>
                  </li>
                  <li>
                    <Link to="#" className="grayHeading">
                      Mobile Cables
                    </Link>
                  </li>
                  <li>
                    <Link to="#" className="grayHeading">
                      Mobile Chargers{" "}
                    </Link>
                  </li>
                </ul>
                <h1 className="mainHeading">Smart Wearable Tech</h1>
                <ul className="hoverList">
                  <li>
                    <Link to="#" className="grayHeading">
                      Smart Watches
                    </Link>
                  </li>
                  <li>
                    <Link to="#" className="grayHeading">
                      Smart Glasses (VR)
                    </Link>
                  </li>
                  <li>
                    <Link to="#" className="grayHeading">
                      Smart Bands{" "}
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="col">
                <h1 className="mainHeading">Moblies Accessories</h1>
                <ul className="hoverList">
                  <li>
                    <Link to="#" className="grayHeading">
                      Mobile Cases
                    </Link>
                  </li>
                  <li>
                    <Link to="#" className="grayHeading">
                      Headphones
                    </Link>
                  </li>
                  <li>
                    <Link to="#" className="grayHeading">
                      Power Banks{" "}
                    </Link>
                  </li>
                  <li>
                    <Link to="#" className="grayHeading">
                      Screenguards{" "}
                    </Link>
                  </li>
                  <li>
                    <Link to="#" className="grayHeading">
                      Mobile Cables
                    </Link>
                  </li>
                  <li>
                    <Link to="#" className="grayHeading">
                      Mobile Chargers{" "}
                    </Link>
                  </li>
                </ul>
                <h1 className="mainHeading">Smart Wearable Tech</h1>
                <ul className="hoverList">
                  <li>
                    <Link to="#" className="grayHeading">
                      Smart Watches
                    </Link>
                  </li>
                  <li>
                    <Link to="#" className="grayHeading">
                      Smart Glasses (VR)
                    </Link>
                  </li>
                  <li>
                    <Link to="#" className="grayHeading">
                      Smart Bands{" "}
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="col">
                <h1 className="mainHeading">Moblies Accessories</h1>
                <ul className="hoverList">
                  <li>
                    <Link to="#" className="grayHeading">
                      Mobile Cases
                    </Link>
                  </li>
                  <li>
                    <Link to="#" className="grayHeading">
                      Headphones
                    </Link>
                  </li>
                  <li>
                    <Link to="#" className="grayHeading">
                      Power Banks{" "}
                    </Link>
                  </li>
                  <li>
                    <Link to="#" className="grayHeading">
                      Screenguards{" "}
                    </Link>
                  </li>
                  <li>
                    <Link to="#" className="grayHeading">
                      Mobile Cables
                    </Link>
                  </li>
                  <li>
                    <Link to="#" className="grayHeading">
                      Mobile Chargers{" "}
                    </Link>
                  </li>
                </ul>
                <h1 className="mainHeading">Smart Wearable Tech</h1>
                <ul className="hoverList">
                  <li>
                    <Link to="#" className="grayHeading">
                      Smart Watches
                    </Link>
                  </li>
                  <li>
                    <Link to="#" className="grayHeading">
                      Smart Glasses (VR)
                    </Link>
                  </li>
                  <li>
                    <Link to="#" className="grayHeading">
                      Smart Bands{" "}
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
