// import Header from "../Header/Header"
import HeroSection from "../components/HeroSection/HeroSection";
import DelivarySection from "../components/DelivarySection/DelivarySection";
import GroceryCategoryProduct from "../components/Grocery/GroceryCategoryProduct";
import Advertisment_img from "../assets/img/advertisment-img.png";
import GrocerySlider from"../components/Grocery/GrocerySlider"
import DryFriutSection from"../components/DryFriutSection/DryFriutSection";
import WatchSection from"../components/Grocery/WatchSection";
 
const GroceryPage=()=>{
       
 return (
   <>
     <HeroSection /> 
     <DelivarySection />
     <GroceryCategoryProduct />
     <DryFriutSection image={Advertisment_img} />
     <GrocerySlider id="1" tilte={"Hot 🔥  Deals"} />
     <GrocerySlider id="2" tilte={"New 🔥 Deals"} />
     <GrocerySlider id="3" tilte={"Buy Deals"} />
     <WatchSection/>
     <DryFriutSection image={Advertisment_img} />
     <GrocerySlider id="4" tilte={"Top 100 Deals"} />
     <GrocerySlider id="5" tilte={"Top Deals"} />
   </>
 );
}
export default GroceryPage;