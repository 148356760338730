import React, { useState, useEffect } from "react";
// import {
//   axiosInstance,
//   handleError,
//   imgBaseUrl,
//   base_url,
// } from "../../Config/axiosConfig";
import orderImg from "../../assets/images/order-img.png";
import orderIcon from "../../assets/images/order-icon.png";
import orderIcon2 from "../../assets/images/order-icon-02.png";
import bajajIcone from "../../assets/images/bajaj-icon.png";
// {orderIcon}/
import { ToastContainer, toast } from "react-toastify";

//   import 'react-toastify/dist/ReactToastify.css';
export default function MyOrder() {
  // let [apiData, setApiData] = useState([])
  // useEffect(() => {
  //   (async () => {
  //     try {
  //       let res = await axiosInstance.get(`${base_url}/v2/purchase-history`)
  //       console.log(res.data.data)
  //       setApiData(res.data.data)
  //     } catch (error) {
  //       let errorMsg = handleError(error)
  //       console.log(errorMsg)
  //     }
  //   })();
  // }, [])
  const notify = () => toast("Wow so easy !");
  return (
    <>
      <div className="col-lg-9 col-md-8">
        <div className="order-bnnr">
          <div className="row align-items-center">
            <div className="col-lg-7 col-md-7">
              <div className="order-bnnr-content">
                <h3>Avail No Cost EMI on</h3>
                <h5>
                  Credit Cards | Debit Cards | <img src={bajajIcone} alt="" />
                </h5>
                <h4>Enjoy credit up to ₹2 lakhs</h4>
              </div>
            </div>
            <div className="col-lg-5 col-md-5">
              <div className="order-bnnr-img">
                <img src={orderImg} alt="" />
              </div>
            </div>
          </div>
        </div>
        <div className="order-filter">
          <div className="order-filter-lft">
            <input
              type="text"
              className="form-control"
              placeholder="Search all orders"
            />
            <button className="org-btn-02" onClick={notify}>
              <i className="far fa-search me-2" />
              Search Orders
            </button>
          </div>
          <div className="order-filter-rgt">
            <label htmlFor="">ORDERS STATUS</label>
            <select name="" id="" className="form-select">
              <option value="">On the way</option>
              <option value="">Delivered</option>
              <option value="">Cancelled</option>
              <option value="">Returned</option>
            </select>
          </div>
        </div>
        <div className="panel-cards ">
          <div className="deliver-option">
            <div>
              <span>Order Id: gz13324</span>
              <span>Items: 2</span>
            </div>
            <div>
              <span className="text-secondary border-dark">
                Order Placed: 03 Jun 2020
              </span>
              <span className="text-success">
                Shipped on Jan 21, 2020 | 10:30 AM{" "}
              </span>
            </div>
          </div>
          <div className="order-dtl">
            <div className="order-dtl-lft">
              <div className="order-dtl-lft-img">
                <img src={orderIcon} alt="" />
              </div>
              <div className="order-dtl-lft-content">
                <h5>Women Black Printed A-Line Top x2</h5>
                <p>Color: Black/ Size: M</p>
                <p>Seller: Womenia</p>
              </div>
            </div>
            <div className="order-dtl-rgt">
              <div>
                <label htmlFor="">Shipped to</label>
                <select name="" id="" className="form-select">
                  <option value="">Sudarshan Kajale</option>
                  <option value="">Sudarshan Kajale</option>
                  <option value="">Sudarshan Kajale</option>
                </select>
              </div>
              <div>
                <span className="fz-16 fw-600">₹ 299</span>
              </div>
            </div>
          </div>
          <hr />
          <div className="order-dtl">
            <div className="order-dtl-lft">
              <div className="order-dtl-lft-img">
                <img src={orderIcon2} alt="" />
              </div>
              <div className="order-dtl-lft-content">
                <h5>Women Maroon Party Top x1</h5>
                <p>Color: Black/ Size: M</p>
                <p>Seller: Womenia</p>
              </div>
            </div>
            <div className="order-dtl-rgt">
              <div>
                <span className="fz-16 fw-600">₹ 149</span>
              </div>
            </div>
          </div>
        </div>
        <div className="panel-cards ">
          <div className="deliver-option">
            <div>
              <span>Order Id: gz13324</span>
              <span>Items: 2</span>
            </div>
            <div>
              <span className="text-secondary border-dark">
                Order Placed: 03 Jun 2020
              </span>
              <span className="text-secondary">
                Shipped on Jan 21, 2020 | 10:30 AM{" "}
              </span>
            </div>
          </div>
          <div className="order-dtl">
            <div className="order-dtl-lft">
              <div className="order-dtl-lft-img">
                <img src={orderIcon} alt="" />
              </div>
              <div className="order-dtl-lft-content">
                <h5>Women Black Printed A-Line Top x2</h5>
                <p>Color: Black/ Size: M</p>
                <p>Seller: Womenia</p>
              </div>
            </div>
            <div className="order-dtl-rgt">
              <div>
                <label htmlFor="">Shipped to</label>
                <select name="" id="" className="form-select">
                  <option value="">Sudarshan Kajale</option>
                  <option value="">Sudarshan Kajale</option>
                  <option value="">Sudarshan Kajale</option>
                </select>
              </div>
              <div className="ms-2">
                <label htmlFor="">Invoice</label>
                <select name="" id="" className="form-select">
                  <option value="">Invoice No.</option>
                  <option value="">1</option>
                  <option value="">2</option>
                </select>
              </div>
              <div>
                <span className="fz-16 fw-600">₹ 299</span>
              </div>
            </div>
          </div>
        </div>
        <div className="panel-cards ">
          <div className="deliver-option">
            <div>
              <span>Order Id: gz13324</span>
              <span>Items: 2</span>
            </div>
            <div>
              <span className="text-secondary border-dark">
                Order Placed: 03 Jun 2020
              </span>
              <span className="text-danger">
                Cancelled on Jan 13, 2020 | 10:30 AM
              </span>
            </div>
          </div>
          <div className="order-dtl">
            <div className="order-dtl-lft">
              <div className="order-dtl-lft-img">
                <img src={orderIcon} alt="" />
              </div>
              <div className="order-dtl-lft-content">
                <h5>Women Black Printed A-Line Top x2</h5>
                <p>Color: Black/ Size: M</p>
                <p>Seller: Womenia</p>
              </div>
            </div>
            <div className="order-dtl-rgt">
              <div>
                <label htmlFor="">Shipped to</label>
                <select name="" id="" className="form-select">
                  <option value="">Sudarshan Kajale</option>
                  <option value="">Sudarshan Kajale</option>
                  <option value="">Sudarshan Kajale</option>
                </select>
              </div>
              <div>
                <span className="fz-16 fw-600">₹ 299</span>
              </div>
            </div>
          </div>
        </div>
        <div className="panel-cards ">
          <div className="deliver-option">
            <div>
              <span>Order Id: gz13324</span>
              <span>Items: 2</span>
            </div>
            <div>
              <span className="text-secondary border-dark">
                Order Placed: 03 Jun 2020
              </span>
              <span className="txt-org">
                Return Requested on Jan 13, 2020 | 10:30 AM
              </span>
            </div>
          </div>
          <div className="order-dtl">
            <div className="order-dtl-lft">
              <div className="order-dtl-lft-img">
                <img src={orderIcon} alt="" />
              </div>
              <div className="order-dtl-lft-content">
                <h5>Women Black Printed A-Line Top x2</h5>
                <p>Color: Black/ Size: M</p>
                <p>Seller: Womenia</p>
              </div>
            </div>
            <div className="order-dtl-rgt">
              <div>
                <label htmlFor="">Shipped to</label>
                <select name="" id="" className="form-select">
                  <option value="">Sudarshan Kajale</option>
                  <option value="">Sudarshan Kajale</option>
                  <option value="">Sudarshan Kajale</option>
                </select>
              </div>
              <div>
                <span className="fz-16 fw-600">₹ 299</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
