import React from 'react'
import FilterPoduct from '../components/GroceryProduct/FilterPoduct'
import SearchProductSection from '../components/SearchProduct/SearchProductSection'
import OtherPopularProductSlider from '../components/MyAccountDetailsSection/OtherPopularProductSlider'
import PrductImg from "../assets/images/other-product.png"
// import Quickly from '../components/QuicklySection/QuicklySection'
export default function SearchProductPage() {
  return (
    <>
    {/* <Quickly/> */}
      <section className="grocery-prdct-sec tp-space">
        <div className="container-fluid">
          <div className="row">
            
            {/* <GroceryProduct /> */}
            <SearchProductSection/> 
            <OtherPopularProductSlider id={1} title={"Other Popular Products"}  productImg={PrductImg} lableImg={null} slidesPerView={4} />
            <OtherPopularProductSlider id={2} title={"Other Popular Products"}  productImg={PrductImg} lableImg={null} slidesPerView={4} />
          </div>
        </div>
      </section>
    </>
  )
}

