import React from 'react'
import Watch_Img from "../../assets/images/watch-img.png";
import Camera_Img from "../../assets/images/camera.png";
export default function WatchSection() {
    return (
        <>
            <section className="watch-sec mrt-15">
                <div className="container-fluid">
                    <div className="row gx-2">
                        <div className="col-lg-4 col-md-4 col-sm-6">
                            <div className="watch-box">
                                <div className="watch-box-content">
                                    <h4>Iwatches</h4>
                                    <p>Feels like &amp; whole new watch.</p>
                                    <a href="javascript:void(0);" className="org-btn">
                                        Shop Now
                                        {/* <i className="fas fa-caret-right ms-2" /> */}
                                        <i className="bi bi-caret-right-fill ms-2"></i>
                                    </a>
                                </div>
                                <div className="watch-box-img">
                                    <img src={Watch_Img} alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-6">
                            <div className="watch-box org-box">
                                <div className="watch-box-content">
                                    <h4 className="mb-0">Camera &amp; Accessories</h4>
                                    <p className="text-white">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                    </p>
                                    <a href="javascript:void(0);" className="org-btn">
                                        Shop Now
                                        <i className="fas fa-caret-right ms-2" />
                                        <i className="bi bi-caret-right-fill ms-2"></i>

                                    </a>
                                </div>
                                <div className="watch-box-img">
                                    <img src={Camera_Img} alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-6">
                            <div className="watch-box">
                                <div className="watch-box-content">
                                    <h4>Iwatches</h4>
                                    <p>Feels like &amp; whole new watch.</p>
                                    <a href="javascript:void(0);" className="org-btn">
                                        Shop Now
                                        <i className="bi bi-caret-right-fill ms-2"></i>
                                    </a>
                                </div>
                                <div className="watch-box-img">
                                    <img src={Watch_Img} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
