import React, { useEffect, useState } from "react";
import toastify from "../../../Config/toastify";
import {
  handleError,
  axiosInstance,
  base_url,
} from "../../../Config/axiosConfig";
export default function Notification() {
  let userId ;
  // let userId = JSON.parse(localStorage?.getItem("user"));
  // console.log(userId);
  const [notificationVal, setnotificationVal] = useState({
    csci: "",
    app_noti: "",
    order_noti: "",
  });
  let token = localStorage.getItem("token")


  useEffect(() => {
    (async () => {
      try {
        let res = await axiosInstance.get(
          `${base_url}/v2/user/notification/setting`,{headers: {
            Authorization: "Bearer " + token,
          }},
          { id: userId?.id || 193 }
        );
        // console.log(res.data);
        setnotificationVal(res?.data);
      } catch (error) {
        let errorMsg = handleError(error);
        console.log(errorMsg);
      }
    })();
  }, []);

  function handleNotification(e) {
    if (e.target.checked) {
      return setnotificationVal((curval) => ({
        ...curval,
        [e.target.name]: "yes",
      }));
    }
    if (!e.target.checked) {
      console.log(e.target.name);

      return setnotificationVal((curval) => ({
        ...curval,
        [e.target.name]: "no",
      }));
    }
  }

  const handleNotificationApi = async (e) => {
    e.preventDefault();
    try {
      let res = await axiosInstance.post(
        `${base_url}/v2/user/notification/update`,notificationVal, { headers : {
          Authorization: "Bearer " + token,
        }}
      );
      if (res.data.result) {
        toastify.success(res.data.message);
      } else {
        toastify.error(res.data.message);
      }
    } catch (error) {
      let errorMsg = handleError(error);
      console.log(errorMsg);
    }
  };

  return (
    <>
      <div className="tab-pane fade" id="tab-02" role="tabpanel">
        <div className="notification-setting">
          <ul>
            <li>
              <label className="form-check-label" htmlFor="switch-1">
                Checked switch checkbox input {notificationVal?.csci}
              </label>
              <div className="form-check form-switch">
                <input
                  className="form-check-input"
                  type="checkbox"
                  onChange={handleNotification}
                  // role="switch"
                  // id="switch-1"
                  name="csci"
                  // defaultChecked={notificationVal.csci}
                  // defaultChecked={notificationVal?.csci && true}
                  checked={notificationVal?.csci == "yes" && true}
                  defaultChecked={
                    notificationVal?.csci === "yes" ? true : false
                  }
                />
              </div>
            </li>
            <li>
              <label className="form-check-label" htmlFor="switch-2">
                App Notifications {notificationVal?.app_noti}
              </label>
              <div className="form-check form-switch">
                <input
                  onChange={handleNotification}
                  className="form-check-input"
                  type="checkbox"
                  name="app_noti"
                  checked={notificationVal?.app_noti == "yes" && true}
                  defaultChecked={
                    notificationVal?.app_noti == "yes" ? true : false
                  }
                />
              </div>
            </li>
            <li>
              <label className="form-check-label" htmlFor="switch-3">
                Order Notifications {notificationVal?.order_noti}
              </label>
              <div className="form-check form-switch">
                <input
                  className="form-check-input"
                  onChange={handleNotification}
                  type="checkbox"
                  // role="switch"
                  // id="switch-3"
                  name="order_noti"
                  // checked={notificationVal.order_noti}
                  defaultChecked={
                    notificationVal?.order_noti == "yes" ? true : false
                  }
                  checked={notificationVal?.order_noti == "yes" && true}
                />
              </div>
            </li>
          </ul>
          <div className="text-center`">
            <button
              className="org-btn-02     mt-2 mb-2"
              onClick={handleNotificationApi}
            >
              Save
            </button>
          </div>
          {/* <button
            className="org-btn-02 w-100 text-center mt-2 mb-2"
            onClick={handleNotificationApi}
          >
            Update
          </button> */}
        </div>
      </div>
    </>
  );
}
