import React from 'react'
import ProductDetailsSection from '../components/ProductDetails/ProductDetailsSection'
import OtherPopularProductSlider from '../components/MyAccountDetailsSection/OtherPopularProductSlider'
// import productImg from '../assets'
import productImg from "../assets/images/other-product-02.png"
// import lableImg from "../assets/images/offer-tag.png"
// import QuicklySection from "../components/QuicklySection/QuicklySection"
export default function ProductDetailsPage() {
  return (
    <>
    {/* <QuicklySection/> */}
    <ProductDetailsSection/>
    <OtherPopularProductSlider id={1} title={"Other Popular Products"}  productImg={productImg} lableImg={null} slidesPerView={5}/>
    <OtherPopularProductSlider id={2} title={"Other Popular Products"}  productImg={productImg} lableImg={null} slidesPerView={5}/>
    </>
  )
}
