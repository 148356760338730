 import React from 'react'
import mobileImg from "../../assets/images/mobile.png"
import BoatImg from "../../assets/images/boat.png"
import { Link } from 'react-router-dom'
export default function SellerSection() {
    return (
        <>
            <section className="seller-sec tp-space">
                <div className="container">
                    <div className="panel-cards review-product">
                        <div className="review-product-rgt">
                            <div className="review-product-rgt-img">
                                <img src={mobileImg} alt="img" />
                            </div>
                            <div className="review-product-rgt-content ms-2">
                                <h6>Apple iPhone 11(Red, 32 GB)</h6>
                                <p>
                                    Seller Cliff Fashion, Ahmedabad{" "}
                                    <span>
                                        4.2 <i className="bi bi-star-fill ms-1" />
                                    </span>
                                    (1,512)
                                </p>
                            </div>
                        </div>
                    </div> 
                    <div className="panel-cards">
                        <h5 className="fz-20 fw-600 clr mb-0">All Sellers</h5>
                    </div>
                    <div className="panel-cards">
                        <p className="d-flex justify-content-between align-items mb-0 fz-18 fw-600 clr mb-4">
                            <span>Seller</span>
                            <span>Price</span>
                            <span>Delivery</span>
                            <span />
                        </p>
                        <ul className="s-product-dtl">
                            <li>
                                <div className="product-txt">
                                    <h5>
                                        Astronet Corse Pvt. Ltd, Noida{" "}
                                        <span>
                                            4.9 <i className="bi bi-star-fill" />
                                        </span>
                                    </h5>
                                    <p>Cash On Delivery available</p>
                                    <p>30 Days Return Policy</p>
                                </div>
                                <div>
                                    <p className="dtl-price">
                                        {" "}
                                        <span className="d-inline-flex align-top">₹ 78,000</span>{" "}
                                        <del>₹ 85,000</del> <em>8% Off</em> <small>(8,912)</small>
                                    </p>
                                </div>
                                <div className="product-txt">
                                    <h5>Free Delivery by 28 Jun,</h5>
                                    <h6>Usually deliver in 3-4 days</h6>
                                </div>
                                <div className="s-product-dtl-btn">
                                    <Link to="#" className="org-btn-02 me-2">
                                        <i className="bi bi-cart-plus" />
                                    </Link>
                                    <Link to="#" className="org-btn-02">
                                        Buy Now
                                    </Link>
                                </div>
                            </li>
                            <li>
                                <div className="product-txt">
                                    <h5>
                                        Astronet Corse Pvt. Ltd, Noida{" "}
                                        <span>
                                            4.9 <i className="bi bi-star-fill" />
                                        </span>
                                    </h5>
                                    <p>Cash On Delivery available</p>
                                    <p>30 Days Return Policy</p>
                                </div>
                                <div>
                                    <p className="dtl-price">
                                        {" "}
                                        <span className="d-inline-flex align-top">₹ 78,000</span>{" "}
                                        <del>₹ 85,000</del> <em>8% Off</em> <small>(8,912)</small>
                                    </p>
                                </div>
                                <div className="product-txt">
                                    <h5>Free Delivery by 28 Jun,</h5>
                                    <h6>Usually deliver in 3-4 days</h6>
                                </div>
                                <div className="s-product-dtl-btn">
                                    <Link to="#" className="org-btn-02 me-2">
                                        <i className="bi bi-cart-plus" />
                                    </Link>
                                    <Link to="#" className="org-btn-02">
                                        Buy Now
                                    </Link>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 col-md-4 col-sm-6">
                            <div className="advertisment-seller">
                                <img src={BoatImg} alt="img" />
                                <Link to="#">
                                    <span>
                                        <del>₹ 5,990 </del>Just ₹ 1,799
                                    </span>
                                    <i className="bi bi-caret-right-fill" />
                                </Link>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-6">
                            <div className="advertisment-seller">
                                <img src={BoatImg} alt="img" />
                                <Link to="#">
                                    <span>
                                        <del>₹ 5,990 </del>Just ₹ 1,799
                                    </span>
                                    <i className="bi bi-caret-right-fill" />
                                </Link>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-6">
                            <div className="advertisment-seller">
                                <img src={BoatImg} alt="img" />
                                <Link to="#">
                                    <span>
                                        <del>₹ 5,990 </del>Just ₹ 1,799
                                    </span>
                                    <i className="bi bi-caret-right-fill" />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}
