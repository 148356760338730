// import React from "react";
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import ProfileImg from "../../../assets/images/profile-img.png";
import {
  axiosInstance,
  base_url,
  handleError,
} from "../../../Config/axiosConfig";
import toastify from "../../../Config/toastify";

export default function AddressList() {
  let token = localStorage.getItem("token")
  let [apiData, setApiData] = useState([]);
  useEffect(() => {
    (async () => {
      try {
        let res = await axiosInstance.get(
          `${base_url}/v2/user/shipping/address`,
          {headers: {
            Authorization: "Bearer " + token,
          }}
        );
        setApiData(res.data.data);
      } catch (error) {
        let errorMsg = handleError(error);
        console.log(errorMsg);
      }
    })();
  }, []);

  const handleDelete = async (id) => {
    try {
      let res = await axiosInstance.get(
        `${base_url}/v2/user/shipping/delete/${id}`,{headers: {
          Authorization: "Bearer " + token,
        }}
      );
      console.log(res.data.id);
      if (res.data.result) {
        toastify.success(res.data.message);
      } else {
        toastify.error(res.data.message);
      }
      setApiData((curval) => curval?.filter((arr) => arr.id != res?.data?.id));
    } catch (error) {
      let errorMsg = handleError(error);
      console.log(errorMsg);
    }
  };

  return (
    <>
      <div className="col-lg-9 col-md-8">
        <div className="panel-cards location-btn justify-content-center">
          <Link to="/my-account/new-address" className="org-btn-02">
            + Add New Address
          </Link>
        </div>
        <div className="panel-cards password-change addres w-100">
          <h4 className="title">
            {apiData.length > 0 ? "Delivery Addresses" : "No Address Available"}
          </h4>
          {apiData?.map((arr) => (
            <div key={arr?.id} className="deliveadd-bx">
              <div className="deliveadd-bx-content">
                <h5>
                  {arr?.name} <span>{arr?.name}</span>
                </h5>
                <p>
                  {arr?.locality}, {arr.address}, {arr?.city}, {arr?.state},{" "}
                  {arr?.postal_code}
                </p>
              </div>
              <div className="deliveadd-bx-icon">
                <button
                  onClick={() => handleDelete(arr?.id)}
                  className="text-secondary fz-16 d-block"
                >
                  <i className="bi bi-trash3-fill" />
                </button>
                <Link
                  to="/my-account/edit-adress"
                  state={arr}
                  className="text-danger fz-16 d-block"
                >
                  <i className="bi bi-pencil-square" />
                </Link>
              </div>
            </div>
          ))}

          {/* <div className="deliveadd-bx">
            <div className="deliveadd-bx-content">
              <h5>
                Shirin Ittarwala <span>Home</span>
              </h5>
              <p>
                B-1008, Samaan II, 100 Feet Anand Nagar Nagar Rd, Near Auda
                Garden, Prahlad Nagar, Ahmedabad, Gujarat 380015
              </p>
            </div>
            <div className="deliveadd-bx-icon">
              <a
                href="#"
                className="text-secondary fz-16 d-block"
              >
                <i className="bi bi-trash3-fill" />
              </a>
              <a
                href="#"
                className="text-danger fz-16 d-block"
              >
                <i className="bi bi-pencil-square" />
              </a>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
}
