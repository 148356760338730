// import React from "react";
// import { Link } from "react-router-dom";
import Grocery_Img from "../../assets/img/grocery-img-01.png";
import { Link } from "react-router-dom";
// import "bootstrap/dist/css/bootstrap.min.css";
// import "bootstrap/dist/js/bootstrap.bundle.min";
export default function GroceryCategoryProduct() {
  return (
    <>
      <section className="grocery-sec tp-space bg-white">
        <div className="container-fluid">
          <ul className="grocery-list">
            <li className="grocery-list-innr">
              <div className="grocery-list-box">
                <img src={Grocery_Img} alt="" />
                <ul className="navbar-nav">
                  <li className="nav-item dropdown">
                    <Link
                      className="nav-link dropdown-toggle"
                      to="#"
                      data-bs-toggle="dropdown"
                    >
                      Staples 
                    </Link>
                    <ul className="dropdown-menu">
                      <li>
                        <Link className="dropdown-item" to="#">
                          {" "}
                          Soaps &amp; Body Wash{" "}
                          <i className="fas fa-caret-right" />
                        </Link>
                        {/* sub-menu-list */}
                        <ul className="submenu dropdown-menu">
                          <li>
                            <h6>MORE IN SOAPS &amp; BODY WASH</h6>
                          </li>
                          <li>
                            <Link
                              className="dropdown-item"
                              to="#"
                            >
                              All
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="dropdown-item"
                              to="#"
                            >
                              Soaps
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="dropdown-item"
                              to="#"
                            >
                              Body Wash
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="dropdown-item"
                              to="#"
                            >
                              Face Wash
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="dropdown-item"
                              to="#"
                            >
                              Hand Wash
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="dropdown-item"
                              to="#"
                            >
                              Hand Sanitizer
                            </Link>
                          </li>
                        </ul>
                      </li>
                      {/* Mainmenu-list */}
                      <li>
                        <Link className="dropdown-item" to="#">
                          Hair Care
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="#">
                          Oral Care
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="#">
                          Deos, Perfume &amp; talc
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="#">
                          Creams, Lotions, Skin Care
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="#">
                          Kajal &amp; Makeup
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="#">
                          Sanitary Needs
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="#">
                          Wellness &amp; Common Pharma
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="#">
                          Shaving Needs
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="#">
                          Diapers &amp; Wipes
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="#">
                          Baby Foods
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="#">
                          Baby Bath &amp; Skin Care
                        </Link>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </li>
            <li className="grocery-list-innr">
              <div className="grocery-list-box">
                <img src={Grocery_Img} alt="" />
                <ul className="navbar-nav">
                  <li className="nav-item dropdown">
                    <Link
                      className="nav-link dropdown-toggle"
                      to="#"
                      data-bs-toggle="dropdown"
                    >
                      Snacks &amp; Beverages
                    </Link>
                    <ul className="dropdown-menu">
                      <li>
                        <Link className="dropdown-item" to="#">
                          {" "}
                          Soaps &amp; Body Wash{" "}
                          <i className="fas fa-caret-right" />
                        </Link>
                        {/* sub-menu-list */}
                        <ul className="submenu dropdown-menu">
                          <li>
                            <h6>MORE IN SOAPS &amp; BODY WASH</h6>
                          </li>
                          <li>
                            <Link
                              className="dropdown-item"
                              to="#"
                            >
                              All
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="dropdown-item"
                              to="#"
                            >
                              Soaps
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="dropdown-item"
                              to="#"
                            >
                              Body Wash
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="dropdown-item"
                              to="#"
                            >
                              Face Wash
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="dropdown-item"
                              to="#"
                            >
                              Hand Wash
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="dropdown-item"
                              to="#"
                            >
                              Hand Sanitizer
                            </Link>
                          </li>
                        </ul>
                      </li>
                      {/* Mainmenu-list */}
                      <li>
                        <Link className="dropdown-item" to="#">
                          Hair Care
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="#">
                          Oral Care
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="#">
                          Deos, Perfume &amp; talc
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="#">
                          Creams, Lotions, Skin Care
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="#">
                          Kajal &amp; Makeup
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="#">
                          Sanitary Needs
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="#">
                          Wellness &amp; Common Pharma
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="#">
                          Shaving Needs
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="#">
                          Diapers &amp; Wipes
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="#">
                          Baby Foods
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="#">
                          Baby Bath &amp; Skin Care
                        </Link>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </li>
            <li className="grocery-list-innr">
              <div className="grocery-list-box">
                <img src={Grocery_Img} alt="" />
                <ul className="navbar-nav">
                  <li className="nav-item dropdown">
                    <Link
                      className="nav-link dropdown-toggle"
                      to="#"
                      data-bs-toggle="dropdown"
                    >
                      Packages Food
                    </Link>
                    <ul className="dropdown-menu">
                      <li>
                        <Link className="dropdown-item" to="#">
                          {" "}
                          Soaps &amp; Body Wash{" "}
                          <i className="fas fa-caret-right" />
                        </Link>
                        {/* sub-menu-list */}
                        <ul className="submenu dropdown-menu">
                          <li>
                            <h6>MORE IN SOAPS &amp; BODY WASH</h6>
                          </li>
                          <li>
                            <Link
                              className="dropdown-item"
                              to="#"
                            >
                              All
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="dropdown-item"
                              to="#"
                            >
                              Soaps
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="dropdown-item"
                              to="#"
                            >
                              Body Wash
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="dropdown-item"
                              to="#"
                            >
                              Face Wash
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="dropdown-item"
                              to="#"
                            >
                              Hand Wash
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="dropdown-item"
                              to="#"
                            >
                              Hand Sanitizer
                            </Link>
                          </li>
                        </ul>
                      </li>
                      {/* Mainmenu-list */}
                      <li>
                        <Link className="dropdown-item" to="#">
                          Hair Care
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="#">
                          Oral Care
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="#">
                          Deos, Perfume &amp; talc
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="#">
                          Creams, Lotions, Skin Care
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="#">
                          Kajal &amp; Makeup
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="#">
                          Sanitary Needs
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="#">
                          Wellness &amp; Common Pharma
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="#">
                          Shaving Needs
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="#">
                          Diapers &amp; Wipes
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="#">
                          Baby Foods
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="#">
                          Baby Bath &amp; Skin Care
                        </Link>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </li>
            <li className="grocery-list-innr">
              <div className="grocery-list-box">
                <img src={Grocery_Img} alt="" />
                <ul className="navbar-nav">
                  <li className="nav-item dropdown">
                    <Link
                      className="nav-link dropdown-toggle"
                      to="#"
                      data-bs-toggle="dropdown"
                    >
                      Personal &amp; Baby Care
                    </Link>
                    <ul className="dropdown-menu">
                      <li>
                        <Link className="dropdown-item" to="#">
                          {" "}
                          Soaps &amp; Body Wash{" "}
                          <i className="fas fa-caret-right" />
                        </Link>
                        {/* sub-menu-list */}
                        <ul className="submenu dropdown-menu">
                          <li>
                            <h6>MORE IN SOAPS &amp; BODY WASH</h6>
                          </li>
                          <li>
                            <Link
                              className="dropdown-item"
                              to="#"
                            >
                              All
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="dropdown-item"
                              to="#"
                            >
                              Soaps
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="dropdown-item"
                              to="#"
                            >
                              Body Wash
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="dropdown-item"
                              to="#"
                            >
                              Face Wash
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="dropdown-item"
                              to="#"
                            >
                              Hand Wash
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="dropdown-item"
                              to="#"
                            >
                              Hand Sanitizer
                            </Link>
                          </li>
                        </ul>
                      </li>
                      {/* Mainmenu-list */}
                      <li>
                        <Link className="dropdown-item" to="#">
                          Hair Care
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="#">
                          Oral Care
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="#">
                          Deos, Perfume &amp; talc
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="#">
                          Creams, Lotions, Skin Care
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="#">
                          Kajal &amp; Makeup
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="#">
                          Sanitary Needs
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="#">
                          Wellness &amp; Common Pharma
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="#">
                          Shaving Needs
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="#">
                          Diapers &amp; Wipes
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="#">
                          Baby Foods
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="#">
                          Baby Bath &amp; Skin Care
                        </Link>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </li>
            <li className="grocery-list-innr">
              <div className="grocery-list-box">
                <img src={Grocery_Img} alt="" />
                <ul className="navbar-nav">
                  <li className="nav-item dropdown">
                    <Link
                      className="nav-link dropdown-toggle"
                      to="#"
                      data-bs-toggle="dropdown"
                    >
                      Household Care
                    </Link>
                    <ul className="dropdown-menu">
                      <li>
                        <Link className="dropdown-item" to="#">
                          {" "}
                          Soaps &amp; Body Wash{" "}
                          <i className="fas fa-caret-right" />
                        </Link>
                        {/* sub-menu-list */}
                        <ul className="submenu dropdown-menu">
                          <li>
                            <h6>MORE IN SOAPS &amp; BODY WASH</h6>
                          </li>
                          <li>
                            <Link
                              className="dropdown-item"
                              to="#"
                            >
                              All
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="dropdown-item"
                              to="#"
                            >
                              Soaps
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="dropdown-item"
                              to="#"
                            >
                              Body Wash
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="dropdown-item"
                              to="#"
                            >
                              Face Wash
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="dropdown-item"
                              to="#"
                            >
                              Hand Wash
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="dropdown-item"
                              to="#"
                            >
                              Hand Sanitizer
                            </Link>
                          </li>
                        </ul>
                      </li>
                      {/* Mainmenu-list */}
                      <li>
                        <Link className="dropdown-item" to="#">
                          Hair Care
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="#">
                          Oral Care
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="#">
                          Deos, Perfume &amp; talc
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="#">
                          Creams, Lotions, Skin Care
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="#">
                          Kajal &amp; Makeup
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="#">
                          Sanitary Needs
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="#">
                          Wellness &amp; Common Pharma
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="#">
                          Shaving Needs
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="#">
                          Diapers &amp; Wipes
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="#">
                          Baby Foods
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="#">
                          Baby Bath &amp; Skin Care
                        </Link>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </li>
            <li className="grocery-list-innr">
              <div className="grocery-list-box">
                <img src={Grocery_Img} alt="" />
                <ul className="navbar-nav">
                  <li className="nav-item dropdown">
                    <Link
                      className="nav-link dropdown-toggle"
                      to="#"
                      data-bs-toggle="dropdown"
                    >
                      Dairy &amp; Eggs
                    </Link>
                    <ul className="dropdown-menu">
                      <li>
                        <Link className="dropdown-item" to="#">
                          {" "}
                          Soaps &amp; Body Wash{" "}
                          <i className="fas fa-caret-right" />
                        </Link>
                        {/* sub-menu-list */}
                        <ul className="submenu dropdown-menu">
                          <li>
                            <h6>MORE IN SOAPS &amp; BODY WASH</h6>
                          </li>
                          <li>
                            <Link
                              className="dropdown-item"
                              to="#"
                            >
                              All
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="dropdown-item"
                              to="#"
                            >
                              Soaps
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="dropdown-item"
                              to="#"
                            >
                              Body Wash
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="dropdown-item"
                              to="#"
                            >
                              Face Wash
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="dropdown-item"
                              to="#"
                            >
                              Hand Wash
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="dropdown-item"
                              to="#"
                            >
                              Hand Sanitizer
                            </Link>
                          </li>
                        </ul>
                      </li>
                      {/* Mainmenu-list */}
                      <li>
                        <Link className="dropdown-item" to="#">
                          Hair Care
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="#">
                          Oral Care
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="#">
                          Deos, Perfume &amp; talc
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="#">
                          Creams, Lotions, Skin Care
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="#">
                          Kajal &amp; Makeup
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="#">
                          Sanitary Needs
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="#">
                          Wellness &amp; Common Pharma
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="#">
                          Shaving Needs
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="#">
                          Diapers &amp; Wipes
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="#">
                          Baby Foods
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="#">
                          Baby Bath &amp; Skin Care
                        </Link>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </li>
            <li className="grocery-list-innr">
              <div className="grocery-list-box">
                <img src={Grocery_Img} alt="" />
                <ul className="navbar-nav">
                  <li className="nav-item dropdown">
                    <Link
                      className="nav-link dropdown-toggle"
                      to="#"
                      data-bs-toggle="dropdown"
                    >
                      Fruits &amp; Vegetables
                    </Link>
                    <ul className="dropdown-menu">
                      <li>
                        <Link className="dropdown-item" to="#">
                          {" "}
                          Soaps &amp; Body Wash{" "}
                          <i className="fas fa-caret-right" />
                        </Link>
                        {/* sub-menu-list */}
                        <ul className="submenu dropdown-menu">
                          <li>
                            <h6>MORE IN SOAPS &amp; BODY WASH</h6>
                          </li>
                          <li>
                            <Link
                              className="dropdown-item"
                              to="#"
                            >
                              All
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="dropdown-item"
                              to="#"
                            >
                              Soaps
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="dropdown-item"
                              to="#"
                            >
                              Body Wash
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="dropdown-item"
                              to="#"
                            >
                              Face Wash
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="dropdown-item"
                              to="#"
                            >
                              Hand Wash
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="dropdown-item"
                              to="#"
                            >
                              Hand Sanitizer
                            </Link>
                          </li>
                        </ul>
                      </li>
                      {/* Mainmenu-list */}
                      <li>
                        <Link className="dropdown-item" to="#">
                          Hair Care
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="#">
                          Oral Care
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="#">
                          Deos, Perfume &amp; talc
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="#">
                          Creams, Lotions, Skin Care
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="#">
                          Kajal &amp; Makeup
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="#">
                          Sanitary Needs
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="#">
                          Wellness &amp; Common Pharma
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="#">
                          Shaving Needs
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="#">
                          Diapers &amp; Wipes
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="#">
                          Baby Foods
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="#">
                          Baby Bath &amp; Skin Care
                        </Link>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </li>
            <li className="grocery-list-innr">
              <div className="grocery-list-box">
                <img src={Grocery_Img} alt="" />
                <ul className="navbar-nav">
                  <li className="nav-item dropdown">
                    <Link
                      className="nav-link dropdown-toggle"
                      to="#"
                      data-bs-toggle="dropdown"
                    >
                      Home &amp; Kitchen
                    </Link>
                    <ul className="dropdown-menu">
                      <li>
                        <Link className="dropdown-item" to="#">
                          {" "}
                          Soaps &amp; Body Wash{" "}
                          <i className="fas fa-caret-right" />
                        </Link>
                        {/* sub-menu-list */}
                        <ul className="submenu dropdown-menu">
                          <li>
                            <h6>MORE IN SOAPS &amp; BODY WASH</h6>
                          </li>
                          <li>
                            <Link
                              className="dropdown-item"
                              to="#"
                            >
                              All
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="dropdown-item"
                              to="#"
                            >
                              Soaps
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="dropdown-item"
                              to="#"
                            >
                              Body Wash
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="dropdown-item"
                              to="#"
                            >
                              Face Wash
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="dropdown-item"
                              to="#"
                            >
                              Hand Wash
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="dropdown-item"
                              to="#"
                            >
                              Hand Sanitizer
                            </Link>
                          </li>
                        </ul>
                      </li>
                      {/* Mainmenu-list */}
                      <li>
                        <Link className="dropdown-item" to="#">
                          Hair Care
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="#">
                          Oral Care
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="#">
                          Deos, Perfume &amp; talc
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="#">
                          Creams, Lotions, Skin Care
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="#">
                          Kajal &amp; Makeup
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="#">
                          Sanitary Needs
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="#">
                          Wellness &amp; Common Pharma
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="#">
                          Shaving Needs
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="#">
                          Diapers &amp; Wipes
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="#">
                          Baby Foods
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="#">
                          Baby Bath &amp; Skin Care
                        </Link>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </div>
      </section>
    </>
  );
}
