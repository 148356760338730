import React, { useEffect, useState } from "react";
import ProfileImg from "../../assets/images/profile-img.png";
import {
  axiosInstance,
  handleError,
  base_url,
  imgBaseUrl,
} from "../../Config/axiosConfig";
import { Link, useNavigate } from "react-router-dom";
import toastify from "../../Config/toastify";

export default function MyAccountForm() {
  // let navigate = useNavigate();
  const [formVal, setformVal] = useState({
    password: "",
    email: "",
    phone: "",
    name: "",
    image: "",
  });
  const [uploadImg, setUplaodImg] = useState("");

  const handleOnchange = (e) => {
    if (e.target.name === "image") {
      let file = e?.target?.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = () => {
          setUplaodImg(reader?.result);
        };
        reader.readAsDataURL(e?.target?.files[0]);
      }
      return setformVal((curval) => ({
        ...curval,
        [e.target.name]: e.target.files[0],
      }));
    }
    setformVal((curval) => ({ ...curval, [e.target.name]: e.target.value }));
  };
  let token = localStorage.getItem("token");

  useEffect(() => {
    (async () => {
      try {
        let res = await axiosInstance.get(`${base_url}/v2/user/info/193`, {
          headers: {
            Authorization: "Bearer " + token,
          },
        });
        console.log(res.data.data);
        setformVal(res.data.data[0]);
      } catch (error) {
        let errorMsg = handleError(error);
        console.log(errorMsg);
      }
    })();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log(formVal)
    console.log(formVal.image);
    const postData = new FormData();
    if (formVal.image) {
      postData.append("image", formVal.image);
    }
    postData.append("name", formVal.name);
    postData.append("mobile", formVal.phone);
    // console.log(postData)
    // console.log(postData.image)
    // console.log(postData.name)
    // console.log(formVal.image)
    // return
    try {
      let res = await axiosInstance.post(
        `${base_url}/v2/profile/image-upload`,
        postData,{
          headers: {
            Authorization: "Bearer " + token,
          },
        },
      );
      console.log(res.data);
      if (res.data.result) {
        toastify.success(res.data.message);
      } else {
        toastify.error(res.data.message);
      }
      // setformVal(res.data.data[0])
    } catch (error) {
      let errorMsg = handleError(error);
      console.log(errorMsg);
    }
  };

  return (
    <>
      <div className="col-lg-9 col-md-8">
        <div className="panel-cards panel-profile">
          <div className="row">
            <div className="col-lg-3">
              <div className="panel-profile-img text-center">
                <img
                  src={uploadImg ? uploadImg : `${formVal.avatar}`}
                  className="img-fluid"
                  alt=""
                />
                <input
                  type="file"
                  className="d-none"
                  name="image"
                  onChange={handleOnchange}
                  id="edit-photo"
                />
                <label
                  htmlFor="edit-photo"
                  className="org-btn-02 w-100 text-center mt-2 mb-2"
                >
                  <i className="fas fa-edit me-2" />
                  Upload Photo
                </label>
                {/* <a
                  href="#"
                  className="text-danger fz-18 fw-600" >
                  Deactivate Account
                </a> */}
              </div>
            </div>
            <div className="col-lg-9">
              <div className="panel-profile-content password-change w-100">
                <form action="">
                  <div className="row align-items-center">
                    <div className="col-lg-8 change-bx mb-3">
                      <label htmlFor="" className="form-label">
                        Full Name
                      </label>
                      <input
                        type="text"
                        name="name"
                        className="form-control"
                        value={formVal?.name}
                        onChange={handleOnchange}
                      />
                    </div>
                    {/* <div className="col-lg-4 change-bx text-end mb-3">
                      <a href="#" className="edit">
                        <i className="bi bi-pencil-square me-2"></i>
                        Edit
                      </a>
                    </div> */}
                    <div className="col-lg-8 change-bx mobile-number mb-3">
                      <label htmlFor="" className="form-label">
                        Mobile Number
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        value={formVal?.phone}
                        name="phone"
                        onChange={handleOnchange}
                      />
                      <span>+91</span>
                      <a href="#">Verified</a>
                    </div>
                    <div className="col-lg-8 change-bx mobile-number mb-3">
                      <label htmlFor="" className="form-label">
                        Email Id
                      </label>
                      <input
                        type="email"
                        className="form-control ps-3"
                        value={formVal?.email}
                        name="email"
                        onChange={handleOnchange}
                      />
                      <a href="#" className="text-warning">
                        Verified
                      </a>
                    </div>
                    <div className="col-lg-8 change-bx mobile-number mb-3">
                      <label htmlFor="" className="form-label">
                        Password
                      </label>
                      <input
                        type="password"
                        disabled
                        className="form-control ps-3"
                        value={formVal?.password}
                        name="password"
                        placeholder="**********"
                        onChange={handleOnchange}
                      />
                    </div>
                    <div className="col-lg-4 change-bx text-end mb-3">
                      <Link
                        to="/my-account/setting-change-password"
                        className="edit text-warning"
                      >
                        <i className="bi bi-pencil-square me-2"></i>
                        Edit
                      </Link>
                    </div>
                    <div className="col-lg-8 change-bx mobile-number mb-3">
                      <button
                        className="org-btn-02 w-100 text-center mt-2 mb-2"
                        type="button"
                        onClick={handleSubmit}
                      >
                        Update
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
