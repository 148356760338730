import React, { lazy, useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import bootal from "../../assets/img/bottle.png";
import item1 from "../../assets/img/recmendedItem1.png";
import item2 from "../../assets/img/recomendedItem2.png";
import {
  axiosInstance,
  base_url,
  handleError,
  imgBaseUrl,
} from "../../Config/axiosConfig";
import { Link } from "react-router-dom";
// import uuid4 from "uuid4";
const SelectionSection = () => {
  const [sixCategory, setSixCategory] = useState();
  const [sevenCategory, setSevenCategory] = useState();
  const [eightCategory, setEightCategory] = useState();
  // useEffect(() => {
  //   (async () => {
  //     try {
  //       let res = await axiosInstance.get(
  //         `${base_url}/v2/product/home_six_cat`
  //       );
  //       // console.log(res.data);
  //       setSixCategory(res?.data?.data);
  //     } catch (error) {
  //       let errorMsg = handleError(error);
  //       console.log(errorMsg);
  //     }
  //   })();
  //   (async () => {
  //     try {
  //       let res = await axiosInstance.get(
  //         `${base_url}/v2/product/home_seven_cat`
  //       );
  //       // console.log(res.data);
  //       setSevenCategory(res?.data?.data);
  //     } catch (error) {
  //       let errorMsg = handleError(error);
  //       console.log(errorMsg);
  //     }
  //   })();
  //   (async () => {
  //     try {
  //       let res = await axiosInstance.get(
  //         `${base_url}/v2/product/home_eight_cat`
  //       );
  //       // console.log(res.data);
  //       setEightCategory(res?.data?.data);
  //     } catch (error) {
  //       let errorMsg = handleError(error);
  //       console.log(errorMsg);
  //     }
  //   })();
  // });
  useEffect(() => {
    const fetchData = async (url, setter) => {
      try {
        let res = await axiosInstance.get(url);
        setter(res?.data?.data);
      } catch (error) {
        let errorMsg = handleError(error);
        console.log(errorMsg);
      }
    };

    fetchData(`${base_url}/v2/product/home_six_cat`, setSixCategory);
    fetchData(`${base_url}/v2/product/home_seven_cat`, setSevenCategory);
    fetchData(`${base_url}/v2/product/home_eight_cat`, setEightCategory);
  }, []);

  return (
    <section className="SelectionSection constantPadding">
      <div className="selectionConatiner">
        <div className="row">
          <div className="col-lg-4  selectionEqualBox">
            <div className="topSelectionCol">
              <h1 className="mainHeading">Top Selection</h1>
              <div className="selectionTopCards">
                {sixCategory?.map((arr,i) => (
                  <div className="upperCart" key={i}>
                    <Link to={`/product-details/${arr?.id}`}>
                      <Card>
                        <Card.Img
                          variant="top"
                          src={`${imgBaseUrl}/${arr?.banner}`}
                          className="bootalImg"
                        />
                        <Card.Body>
                          <h1 className="mainHeading">
                            {arr?.name.split(" ")[0]} {arr?.name.split(" ")[1]}{" "}
                            {arr?.name.split(" ")[2]}...
                          </h1>
                          <div className="cardPricing">
                            <p className="mainHeading">
                              ₹ {arr?.unit_price}
                              {arr.discount && (
                                <span>₹ {arr.discount + arr.unit_price}</span>
                              )}
                            </p>
                          </div>
                        </Card.Body>
                      </Card>
                    </Link>
                  </div>
                ))}
                {/* <div className="upperCart">
                  <Card>
                    <Card.Img
                      variant="top"
                      src={bootal}
                      className="bootalImg"
                    />
                    <Card.Body>
                      <h1 className="mainHeading">Javiro Dishwash..</h1>
                      <div className="cardPricing">
                        <p className="mainHeading">
                          ₹ 12,990
                          <span>₹ 15,990</span>
                        </p>
                      </div>
                    </Card.Body>
                  </Card>
                </div>
                <div className="upperCart">
                  <Card>
                    <Card.Img
                      variant="top"
                      src={bootal}
                      className="bootalImg"
                    />
                    <Card.Body>
                      <h1 className="mainHeading">Javiro Dishwash..</h1>
                      <div className="cardPricing">
                        <p className="mainHeading">
                          ₹ 12,990
                          <span>₹ 15,990</span>
                        </p>
                      </div>
                    </Card.Body>
                  </Card>
                </div>
                <div className="upperCart">
                  <Card>
                    <Card.Img
                      variant="top"
                      src={bootal}
                      className="bootalImg"
                    />
                    <Card.Body>
                      <h1 className="mainHeading">Javiro Dishwash..</h1>
                      <div className="cardPricing">
                        <p className="mainHeading">
                          ₹ 12,990
                          <span>₹ 15,990</span>
                        </p>
                      </div>
                    </Card.Body>
                  </Card>
                </div> */}
              </div>
            </div>
          </div>
          <div className="col-lg-4 selectionLgBox">
            <div className="topSelectionCol">
              <h1 className="mainHeading">Recommended items</h1>
              <div className="selectionTopCards">
                {sevenCategory?.map((arr, i) => (
                  <div className="upperCart" key={arr?.id || i}>
                    <Link to={`/product-details/${arr?.id}`}>
                      <Card>
                        <Card.Img
                          variant="top"
                          src={`${imgBaseUrl}/${arr?.banner}`}
                          className="itemImg"
                        />
                        <Card.Body>
                          <h1 className="mainHeading">
                            {arr?.name.split(" ")[0]} {arr?.name.split(" ")[1]}{" "}
                            {arr?.name.split(" ")[2]}...
                          </h1>
                          <div className="cardPricing">
                            <p className="mainHeading">
                              ₹ {arr?.unit_price}
                              {arr.discount && (
                                <span>₹ {arr.discount + arr.unit_price}</span>
                              )}
                            </p>
                          </div>
                        </Card.Body>
                      </Card>
                    </Link>
                  </div>
                ))}
                {/* <div className="upperCart">
                  <Card>
                    <Card.Img variant="top" src={item1} className="itemImg" />
                    <Card.Body>
                      <h1 className="mainHeading">boAt Airdopes 161/163...</h1>
                      <div className="cardPricing">
                        <p className="mainHeading">
                          ₹ 12,990
                          <span>₹ 15,990</span>
                        </p>
                      </div>
                    </Card.Body>
                  </Card>
                </div>
                <div className="upperCart">
                  <Card>
                    <Card.Img variant="top" src={item2} className="itemImg" />
                    <Card.Body>
                      <h1 className="mainHeading">boAt Airdopes 161/163...</h1>
                      <div className="cardPricing">
                        <p className="mainHeading">
                          ₹ 12,990
                          <span>₹ 15,990</span>
                        </p>
                      </div>
                    </Card.Body>
                  </Card>
                </div>
                <div className="upperCart">
                  <Card>
                    <Card.Img variant="top" src={item2} className="itemImg" />
                    <Card.Body>
                      <h1 className="mainHeading">boAt Airdopes 161/163...</h1>
                      <div className="cardPricing">
                        <p className="mainHeading">
                          ₹ 12,990
                          <span>₹ 15,990</span>
                        </p>
                      </div>
                    </Card.Body>
                  </Card>
                </div> */}
              </div>
            </div>
          </div>
          <div className="col-lg-4 selectionEqualBox">
            <div className="topSelectionCol">
              <h1 className="mainHeading">Suggested for you</h1>
              <div className="selectionTopCards">
                {eightCategory?.map((arr, i) => (
                  <div className="upperCart" key={arr?.id || i}>
                    <Link to={`/product-details/${arr?.id}`}>
                      <Card>
                        <Card.Img
                          variant="top"
                          src={`${imgBaseUrl}/${arr?.banner}`}
                          className="bootalImg"
                        />
                        <Card.Body>
                          <h1 className="mainHeading">
                            {arr?.name.split(" ")[0]} {arr?.name.split(" ")[1]}{" "}
                            {arr?.name.split(" ")[2]}...
                          </h1>
                          <div className="cardPricing">
                            <p className="mainHeading">
                              ₹ {arr?.unit_price}
                              {arr.discount && (
                                <span>₹ {arr.discount + arr.unit_price}</span>
                              )}
                            </p>
                          </div>
                        </Card.Body>
                      </Card>
                    </Link>
                  </div>
                ))}

                {/* <div className="upperCart">
                  <Card>
                    <Card.Img
                      variant="top"
                      src={bootal}
                      className="bootalImg"
                    />
                    <Card.Body>
                      <h1 className="mainHeading">Javiro Dishwash..</h1>
                      <div className="cardPricing">
                        <p className="mainHeading">
                          ₹ 12,990
                          <span>₹ 15,990</span>
                        </p>
                      </div>
                    </Card.Body>
                  </Card>
                </div>
                <div className="upperCart">
                  <Card>
                    <Card.Img
                      variant="top"
                      src={bootal}
                      className="bootalImg"
                    />
                    <Card.Body>
                      <h1 className="mainHeading">Javiro Dishwash..</h1>
                      <div className="cardPricing">
                        <p className="mainHeading">
                          ₹ 12,990
                          <span>₹ 15,990</span>
                        </p>
                      </div>
                    </Card.Body>
                  </Card>
                </div>
                <div className="upperCart">
                  <Card>
                    <Card.Img
                      variant="top"
                      src={bootal}
                      className="bootalImg"
                    />
                    <Card.Body>
                      <h1 className="mainHeading">Javiro Dishwash..</h1>
                      <div className="cardPricing">
                        <p className="mainHeading">
                          ₹ 12,990
                          <span>₹ 15,990</span>
                        </p>
                      </div>
                    </Card.Body>
                  </Card>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SelectionSection;
