import React, { useEffect, useState } from "react";
import {
  axiosInstance,
  handleError,
  base_url,
} from "../../../Config/axiosConfig";
import { Link, useNavigate, useLocation } from "react-router-dom";
import toastify from "../../../Config/toastify";
// axiosInstance
// handleError
export default function EditAddress() {
  let navigate = useNavigate();
  let location = useLocation();
  let token = localStorage.getItem("token")

  useEffect(() => {
    let stateData = location.state;
    console.log(stateData);
    setformVal({
      ...stateData,
      house_No: stateData?.address.split(" ")[0],
      address: stateData?.address.split(" ")[1],
    });
  }, []);

  const [formval, setformVal] = useState({
    name: "",
    phone: "",
    postal_code: "",
    address: "",
    locality: "",
    state: "",
    city: "",
    house_No: "",
    type: "",
  });

  let handleOnchange = (e) => {
    setformVal((curval) => ({ ...curval, [e.target.name]: e.target.value }));
  };

  // function getLocation() {
  //   if (navigator.geolocation) {
  //     let a = navigator.geolocation.getCurrentPosition(showPosition);
  //     console.log(navigator.geolocation.getCurrentPosition(showPosition));
  //   }
  // }

  // function showPosition(position) {
  //   console.log(position.coords.latitude, position.coords.longitude);
  // }

  let handleSubmit = async (e) => {
    e.preventDefault();
    formval.address = formval.house_No + " " + formval.address;
    // console.log(formval);
    try {
      let res = await axiosInstance.post(
        `${base_url}/v2/user/shipping/update`,
        formval
        ,{headers: {
          Authorization: "Bearer " + token,
        }}        
      );
      console.log(res.data);
      if (res.data.result) {
        toastify.success(res.data.message);
        return navigate("/my-account/address");
      } else {
        return toastify.error(res.data.message);
      }
    } catch (error) {
      let errorMsg = handleError(error);
      console.log(errorMsg);
    }
  };

  return (
    <>
      <div className="col-lg-9 col-md-8">
        <div className="panel-cards location-btn">
          <Link to="/my-account/address" className="fw-600 org-txt">
            <i className="bi bi-chevron-left"></i> Back
          </Link>
          <a href="" className="org-btn-02">
            Use my current location
          </a>
        </div>
        <div className="panel-cards password-change addres w-100">
          <form action="" onSubmit={handleSubmit}>
            <div className="row justify-content-between">
              <div className="col-lg-5 mb-3 change-bx">
                <label htmlFor="" className="form-label">
                  PIN Code
                </label>
                <input
                  type="number"
                  onChange={handleOnchange}
                  name="postal_code"
                  className="form-control"
                  placeholder="PIN Code"
                  value={formval.postal_code}
                />
              </div>
              <div className="col-lg-5 mb-3 change-bx">
                <label htmlFor="" className="form-label">
                  House No./ Shop No,
                </label>
                <input
                  type="text"
                  name="house_No"
                  onChange={handleOnchange}
                  className="form-control"
                  placeholder="House No./ Shop No."
                  value={formval.house_No}
                />
              </div>
              <div className="col-lg-5 mb-3 change-bx">
                <label htmlFor="" className="form-label">
                  Address Detail
                </label>
                <input
                  type="text"
                  name="address"
                  onChange={handleOnchange}
                  value={formval?.address}
                  className="form-control"
                  placeholder="Address"
                />
              </div>
              <div className="col-lg-5 mb-3 change-bx">
                <label htmlFor="" className="form-label">
                  Landmark
                </label>
                <input
                  type="text"
                  name="locality"
                  onChange={handleOnchange}
                  className="form-control"
                  placeholder="Landmark"
                  value={formval?.locality}
                />
              </div>
              <div className="col-lg-5 mb-3 change-bx">
                <label htmlFor="" className="form-label">
                  City
                </label>
                <input
                  type="text"
                  name="city"
                  onChange={handleOnchange}
                  className="form-control"
                  placeholder="City"
                  value={formval?.city}
                />
              </div>
              <div className="col-lg-5 mb-3 change-bx">
                <label htmlFor="" className="form-label">
                  State
                </label>
                <input
                  type="text"
                  name="state"
                  onChange={handleOnchange}
                  className="form-control"
                  placeholder="State"
                  value={formval?.state}
                />
              </div>
              <div className="col-lg-5 mb-3 change-bx">
                <label htmlFor="" className="form-label">
                  Contact Person Name
                </label>
                <input
                  type="text"
                  name="name"
                  onChange={handleOnchange}
                  className="form-control"
                  placeholder="Contact Person Name"
                  value={formval?.name}
                />
              </div>
              <div className="col-lg-5 mb-3 change-bx">
                <label htmlFor="" className="form-label">
                  Mobile Number
                </label>
                <input
                  type="number"
                  name="phone"
                  onChange={handleOnchange}
                  className="form-control"
                  placeholder="Mobile Number"
                  value={formval.phone}
                />
              </div>
              <div className="col-lg-5 mb-3 change-bx">
                <label htmlFor="" className="form-label">
                  Address Type
                </label>
                <div className="d-flex">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      name="type"
                      onChange={handleOnchange}
                      type="radio"
                      id="home"
                      value="home"
                      checked={formval.type === "home"}
                    />
                    <label className="form-check-label" htmlFor="home">
                      Home
                    </label>
                  </div>
                  <div className="form-check ms-2">
                    <input
                      className="form-check-input"
                      onChange={handleOnchange}
                      name="type"
                      type="radio"
                      id="office"
                      value="office"
                      checked={formval.type === "office"}
                    />
                    <label className="form-check-label" htmlFor="office">
                      Office
                    </label>
                  </div>
                </div>
              </div>
              <div>
                <button className="org-btn-02" type="submit">
                  Save
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
