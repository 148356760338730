import React ,{useContext}from "react";
import { NavLink, useLocation } from "react-router-dom";
// import { handleError, axiosInstance, base_url } from "../../Config/axiosConfig";
import userTokenContext from "../../ContextApi/userToken/UserToken";
import CardContext from "../Context/CardContext";
export default function AccountSideBar() {
  const location = useLocation();
  const isActiveRoute = (path) =>
    location.pathname === path ||
    location.pathname.startsWith("/my-account/new-address") ||
    location.pathname.startsWith("/my-account/edit-adress");
  const isActiveRouteReview = (path) =>
    location.pathname === path ||
    location.pathname.startsWith("/my-account/reviews");
  let { userToken, setUserToken } = useContext(userTokenContext);
  let { card, setCard } = useContext(CardContext);

  const handleLogOut = async () => {
    // let res = await axiosInstance(`${base_url}/v2/auth/logout`);
    // console.log(res);
    setCard([])
    localStorage.clear()
    setUserToken(null)
  };
  return (
    <>
      <div className="col-lg-3 col-md-4">
        <div className="panel-left-bar">
          <ul>
            <li>
              <NavLink
                to="details"
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                <i className="bi bi-person-fill me-2"></i>
                My Account
              </NavLink>
            </li>
            <li>
              <NavLink
                to="address"
                className={isActiveRoute("/saved-addresses") ? "active" : ""}
              >
                <i className="bi bi-geo-alt-fill me-2"></i>
                Saved Addresses
              </NavLink>
            </li>
            <li>
              <NavLink
                to="my-order"
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                <i className="bi bi-box2-fill me-2"></i>
                My Orders
              </NavLink>
            </li>
            <li>
              <NavLink
                to="my-cart"
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                <i className="bi bi-cart-check-fill me-2"></i>
                My Cart
              </NavLink>
            </li>
            <li>
              <NavLink
                to="wish-list"
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                <i className="bi bi-list-task me-2"></i>
                My Wishlists
              </NavLink>
            </li>
            <li>
              <NavLink
                to="my-wallet"
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                <i className="bi bi-wallet-fill me-2"></i>
                My Wallet
              </NavLink>
            </li>
            <li>
              <NavLink
                to="setting-change-password"
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                <i className="bi bi-gear-fill me-2"></i>
                Settings
              </NavLink>
            </li>
            <li>
              <NavLink
                to="notification-setting"
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                <i className="bi bi-bell-fill me-2"></i>
                All Notifications
              </NavLink>
            </li>
            <li>
              <NavLink
                to="my-coupon"
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                <i className="bi bi-patch-plus-fill me-2"></i>
                My Coupons
              </NavLink>
            </li>
            <li>
              <NavLink
                to="my-reviews"
                className={
                  isActiveRouteReview("/my-account/reviews") ? "active" : ""
                }
              >
                <i className="bi bi-chat-fill  me-2"></i>
                My Reviews &amp; Ratings
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/"
                onClick={handleLogOut}
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                <i className="bi bi-arrow-right-circle-fill me-2"></i>
                Logout
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}
