import React from 'react'
import MobileDetails from '../components/MobileDetails/MobileDetails'
import OtherPopularProductSlider from '../components/MyAccountDetailsSection/OtherPopularProductSlider'
import productImg from "../assets/images/other-product-02.png"

export default function MobileSectionPage() {
  return (
    <>
    <MobileDetails/>
    <OtherPopularProductSlider id={2} title={"Other Popular Products"}  productImg={productImg} lableImg={null} slidesPerView={5}/>

    </>
  )
}
