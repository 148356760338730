import React, { useContext } from 'react'
import toastify from '../../Config/toastify';
import { Link } from 'react-router-dom';
// import DataListContext from '../Context/DataListProvider'
export default function FilterPoduct({filterData,data,setData}) {
 
 
    // let {dataList ,setDataList} = useContext(DataListContext)
    // console.log(dataList)

    // function Hello (){
        const rangeInput = document.querySelectorAll(".range-input input"),
        priceInput = document.querySelectorAll(".price-input input"),
        range = document.querySelector(".progress");
         let priceGap = 1000;
     
    priceInput.forEach((input) => {
        input.addEventListener("input", (e) => {
            let minPrice = parseInt(priceInput[0].value),
                maxPrice = parseInt(priceInput[1].value);
            if (maxPrice - minPrice >= priceGap && maxPrice <= rangeInput[1].max) {
                if (e.target.className === "input-min") {
                    rangeInput[0].value = minPrice;
                    range.style.left = (minPrice / rangeInput[0].max) * 500 + "%";
                } else {
                    rangeInput[1].value = maxPrice;
                    range.style.right = 100 - (maxPrice / rangeInput[1].max) * 500 + "%";
                }
            }
        });
    });
 
    
    rangeInput.forEach((input) => {
        input.addEventListener("input", (e) => {
            // console.log(input);
            // console.log(rangeInput[0].value);
            
            let minVal = parseInt(rangeInput[0].value),
                maxVal = parseInt(rangeInput[1].value);
  
                if (maxVal - minVal < priceGap) {
                if (e.target.className === "range-min") {
                    rangeInput[0].value = maxVal - priceGap;
                } else {
                    rangeInput[1].value = minVal + priceGap;
                }
            } else {
                priceInput[0].value = minVal;
                priceInput[1].value = maxVal;
                range.style.left = (minVal / rangeInput[0].max) * 100 + "%";
                range.style.right = 100 - (maxVal / rangeInput[1].max) * 100 + "%";
            }
        });
    });
   

    let FilterPriceRange = ()=>{
        let minPrice = parseInt(priceInput[0]?.value),
        maxPrice = parseInt(priceInput[1]?.value);
        console.log(minPrice, maxPrice);
        // if(minPrice )
        if(typeof minPrice === NaN || maxPrice == 0  ){
            console.log("not a");
          return   toastify.error("Please enter some value")
            
        }
        // return
        console.log(data);
        console.log(typeof data[0].main_price);
 
        if(typeof data[0]?.main_price == "string"){
            // console.log("work");
            
            const newLocal = data.filter((arrItem) => {
                let price=   parseInt(arrItem.main_price.replace("Rs",'').replace(",",''));
                // let price=  parseFloat(arrItem?.main_price?.replace("Rs", ""));
                // (b.unit_price)
                // let prices = price > minPrice && maxPrice < price;
                console.log((price));
                console.log(typeof price);
                
                let solveData =minPrice < price  &&   price < maxPrice
                console.log(solveData);
                return solveData;
            });
            console.log(newLocal,"filterPrice");
            setData(newLocal)
        }else{
            // console.log("sencond");
            
            const newLocal = data.filter((arrItem) => {
                let price = parseInt(arrItem?.main_price);
                // let priced = parseInt(arrItem?.main_price?.replace("RS", ""));
                // let prices = price > minPrice && maxPrice < price;
                let value = minPrice < price  &&   price < maxPrice
                // console.log();
                return value;
            });
            console.log(newLocal,"filterPrice");
            setData(newLocal)
        }
       
     
        // const newLocal = data.filter((arrItem) => {
        //     let price = parseInt(arrItem?.unit_price);
        //     // let price = parseInt(arrItem?.main_price?.replace("RS", ""));
        //     // let prices = price > minPrice && maxPrice < price;
        //     let prices = minPrice > price  && maxPrice < price;
        //     console.log(prices);
        //     return;
        // });
        // console.log(newLocal);
        
        

        

    }

    return (
        <>
            <div className="col-lg-3 col-md-4">
                <div className="grocery-prdc-fltr sticky-lg-top">
                    <h5 className="tp-title">
                        <span>FILTERS</span> <Link onClick={()=>(setData(filterData))} href="#" >Clear All</Link>
                    </h5>
                    <div className="fltr-accordion">
                        <div className="accordion" id="accordionExample">
                            <div className="accordion-item">
                                <h2 className="accordion-header">
                                    <button
                                        className="accordion-button"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#collapse1"
                                    >
                                        Price
                                    </button>
                                </h2>
                                <div
                                    id="collapse1"
                                    className="accordion-collapse collapse show"
                                    data-bs-parent="#accordionExample"
                                >
                                    <div className="accordion-body">
                                        <div className="price-fltr">
                                            <div className="range-slider">
                                                <div className="progress" />
                                            </div>
                                            <div className="range-input">
                                                <input
                                                    type="range"
                                                    className="range-min"
                                                    min={0}
                                                    max={30000}
                                                    defaultValue={2500}
                                                    step={100}
                                                />
                                                <input
                                                    type="range"
                                                    className="range-max"
                                                    min={0}
                                                    max={30000}
                                                    defaultValue={7500}
                                                    step={100}
                                                />
                                            </div>
                                            <div className="price-input">
                                                <div className="field">
                                                    ₹
                                                    <input
                                                        type="number"
                                                        className="input-min"
                                                        defaultValue="6,000"
                                                    />
                                                </div>
                                                <div className="field">
                                                    ₹
                                                    <input
                                                        type="number"
                                                        className="input-max"
                                                        defaultValue={0}
                                                    />
                                                </div>
                                                <div>
                                                    <button className="org-btn-02" type="button" onClick={FilterPriceRange}>
                                                        GO
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header">
                                    <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#collapse2"
                                    >
                                        Brand
                                    </button>
                                </h2>
                                <div
                                    id="collapse2"
                                    className="accordion-collapse collapse"
                                    data-bs-parent="#accordionExample"
                                >
                                    <div className="accordion-body">
                                        <div className="brand-fltr">
                                            <div className="brand-fltr-search">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Search"
                                                />
                                                <span className="far fa-search" />
                                            </div>
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    id="check1"
                                                    defaultValue=""
                                                />
                                                <label className="form-check-label" htmlFor="check1">
                                                    Samsung
                                                </label>
                                            </div>
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    id="check2"
                                                    defaultChecked=""
                                                    defaultValue=""
                                                />
                                                <label className="form-check-label" htmlFor="check2">
                                                    Apple
                                                </label>
                                            </div>
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    id="check3"
                                                    defaultChecked=""
                                                    defaultValue=""
                                                />
                                                <label className="form-check-label" htmlFor="check3">
                                                    Asus
                                                </label>
                                            </div>
                                        </div>
                                        <a href="#" className="text-warning">
                                            More
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header">
                                    <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#collapse3"
                                    >
                                        Rating
                                    </button>
                                </h2>
                                <div
                                    id="collapse3"
                                    className="accordion-collapse collapse"
                                    data-bs-parent="#accordionExample"
                                >
                                    <div className="accordion-body">
                                        <div className="brand-fltr">
                                            <div className="brand-fltr-search">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Search"
                                                />
                                                <span className="far fa-search" />
                                            </div>
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    id="check4"
                                                    defaultValue=""
                                                />
                                                <label className="form-check-label" htmlFor="check4">
                                                    4 <i className="bi bi-star-fill me-1 ms-1" /> &amp; up
                                                </label>
                                            </div>
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    id="check5"
                                                    defaultValue=""
                                                />
                                                <label className="form-check-label" htmlFor="check5">
                                                    3 <i className="bi bi-star-fill me-1 ms-1" /> &amp; up
                                                </label>
                                            </div>
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    id="check6"
                                                    defaultValue=""
                                                />
                                                <label className="form-check-label" htmlFor="check6">
                                                    2 <i className="bi bi-star-fill me-1 ms-1" /> &amp; up
                                                </label>
                                            </div>
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    id="check7"
                                                    defaultValue=""
                                                />
                                                <label className="form-check-label" htmlFor="check7">
                                                    1 <i className="bi bi-star-fill me-1 ms-1" /> &amp; up
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header">
                                    <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#collapse4"
                                    >
                                        Internal Storage
                                    </button>
                                </h2>
                                <div
                                    id="collapse4"
                                    className="accordion-collapse collapse"
                                    data-bs-parent="#accordionExample"
                                >
                                    <div className="accordion-body" />
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header">
                                    <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#collapse5"
                                    >
                                        Operating System
                                    </button>
                                </h2>
                                <div
                                    id="collapse5"
                                    className="accordion-collapse collapse"
                                    data-bs-parent="#accordionExample"
                                >
                                    <div className="accordion-body" />
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header">
                                    <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#collapse6"
                                    >
                                        Network Type
                                    </button>
                                </h2>
                                <div
                                    id="collapse6"
                                    className="accordion-collapse collapse"
                                    data-bs-parent="#accordionExample"
                                >
                                    <div className="accordion-body" />
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header">
                                    <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#collapse7"
                                    >
                                        SIM Type
                                    </button>
                                </h2>
                                <div
                                    id="collapse7"
                                    className="accordion-collapse collapse"
                                    data-bs-parent="#accordionExample"
                                >
                                    <div className="accordion-body" />
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header">
                                    <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#collapse8"
                                    >
                                        RAM
                                    </button>
                                </h2>
                                <div
                                    id="collapse8"
                                    className="accordion-collapse collapse"
                                    data-bs-parent="#accordionExample"
                                >
                                    <div className="accordion-body" />
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header">
                                    <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#collapse9"
                                    >
                                        Camera
                                    </button>
                                </h2>
                                <div
                                    id="collapse9"
                                    className="accordion-collapse collapse"
                                    data-bs-parent="#accordionExample"
                                >
                                    <div className="accordion-body" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
