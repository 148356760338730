import axios from "axios";
// let userstoken = user
// import { useContext } from "react";
// import loginUserContext from "../ContextApi/loginUser/LoginUser";
// import userTokenContext from "../ContextApi/userToken/UserToken";
let token=localStorage.getItem("token")

// console.log(str)
// let result = str.replace(/"/, ''); 
// console.log(result)


const axiosInstance = axios.create({
  baseURL: "https://nerozon-admin.objectsquare.in/api",
  headers: {
    // Authorization: "Bearer " + token,
    "Access-Control-Allow-Origin": "*",
    "Content-type": "application/json",
    "Content-Type": "multipart/form-data",
  }, 
  // Replace with your API base URL
  // You can add common headers here
  // headers: { 'Authorization': 'Bearer YOUR_TOKEN' }
});

const handleError = (error) => {
  if (error.response) {
    console.error("Data:", error.response.data);
    console.error("Status:", error.response.status);
    console.error("Headers:", error.response.headers);
    return `Error: ${error.response.status} - ${
      error.response.data.message || "Unknown error"
    }`;
  } else if (error.request) {
    // Request was made but no response received
    console.error("Request:", error.request);
    return "Error: No response received from the server";
  } else {
    // Something else happened while setting up the request
    console.error("Error Message:", error.message);
    return `Error: ${error.message}`;
  }
};

let imgBaseUrl = "https://nerozon-admin.objectsquare.in/public";
// let base_url = "https://nerozon-admin.objectsquare.in/api";
let base_url = "https://nerozon-admin.objectsquare.in/api";
export { axiosInstance, handleError, imgBaseUrl, base_url };
