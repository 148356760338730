import { useState } from "react";
// import React, { useEffect, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import Offcanvas from "react-bootstrap/Offcanvas";
// import Search from "../../assets/img/search.svg";
// import BrandLogo from "../../assets/img/brandLogo.svg";
// import Notificataion from "../../";
// import Notificataion from "../../assets/img/notification.svg";
// import Cart from "../../assets/img/cart.svg";
// import { IoMenu } from "react-icons/io5";
import { Link } from "react-router-dom";
import NavListing from "./NavListing";
import NavContainer from "./NavContainer";
import { useLocation } from "react-router-dom";
const Header = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  let location = useLocation();
  // const handleShow = () => setShow(true);
  // const [isOpen, setIsOpen] = useState(false);

  // const handleFocus = () => {
  //   setIsOpen(true);
  // };

  // const handleBlur = () => {
  //   setIsOpen(false);
  // }; 

  return (
    <header>
      <div className="topMenu">
        <ul className="MenuList">
          <li>
            <Link to="#" className="mainHeading">
              Advertise
            </Link>
          </li>
          <li>
            <Link to="#" className="mainHeading">
              Download App
            </Link>
          </li>
          <li>
            <Link to="#" className="mainHeading">
              24x7 Customer Support
            </Link>
          </li>
          <li>
            <Link to="#" className="mainHeading">
              Track Order
            </Link>
          </li>
        </ul>
      </div>

      <NavContainer setShow={setShow} />
      {location.pathname !== "/grocery-page" &&
        location.pathname !== "/grocery-product" && <NavListing />}
      {/* {console.log(location.pathname)}
      {console.log(location.pathname !== "/grocery_page" || location.pathname !=='/grocery-product')} */}

      {/* sidebar for mobile */}
      <div className="sideBar">
        <Offcanvas show={show} onHide={handleClose} backdrop="static">
          <Offcanvas.Header closeButton></Offcanvas.Header>
          <Offcanvas.Body>
            <Link to="#" className="mainHeading navBtn smBtn ">
              Sign In / Sign Up
            </Link>
            <div className="canvsDropdowns">
              <div className="dorpDownSroll">
                <Dropdown>
                  <Dropdown.Toggle className=" mainHeading">
                    Electronics
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <h1 className="mainHeading">Mobile</h1>
                    <ul className="dropDownList">
                      <li>
                        <Link to="#" className="grayHeading">
                          Mi
                        </Link>
                      </li>
                      <li>
                        <Link to="#" className="grayHeading">
                          Realme
                        </Link>
                      </li>
                      <li>
                        <Link to="#" className="grayHeading">
                          Samsung
                        </Link>
                      </li>
                      <li>
                        <Link to="#" className="grayHeading">
                          Infinix
                        </Link>
                      </li>
                      <li>
                        <Link to="#" className="grayHeading">
                          Appo
                        </Link>
                      </li>
                      <li>
                        <Link to="#" className="grayHeading">
                          vivo
                        </Link>
                      </li>
                      <li>
                        <Link to="#" className="grayHeading">
                          Apple
                        </Link>
                      </li>
                      <li>
                        <Link to="#" className="grayHeading">
                          realme X2
                        </Link>
                      </li>
                      <li>
                        <Link to="#" className="grayHeading">
                          Redmi 8A
                        </Link>
                      </li>
                      <li>
                        <Link to="#" className="grayHeading">
                          Vivo Z1 Pro
                        </Link>
                      </li>
                      <li>
                        <Link to="#" className="grayHeading">
                          Oppo F15
                        </Link>
                      </li>
                      <li>
                        <Link to="#" className="grayHeading">
                          Asus ROG Phone II
                        </Link>
                      </li>
                    </ul>
                  </Dropdown.Menu>
                </Dropdown>
                <Dropdown>
                  <Dropdown.Toggle className=" mainHeading">
                    Grocery
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <h1 className="mainHeading">Moblies Accessories</h1>
                    <ul className="dropDownList">
                      <li>
                        <Link to="#" className="grayHeading">
                          Mobile Casess
                        </Link>
                      </li>
                      <li>
                        <Link to="#" className="grayHeading">
                          Headphones
                        </Link>
                      </li>
                      <li>
                        <Link to="#" className="grayHeading">
                          Power Banks{" "}
                        </Link>
                      </li>
                      <li>
                        <Link to="#" className="grayHeading">
                          Screenguards{" "}
                        </Link>
                      </li>
                      <li>
                        <Link to="#" className="grayHeading">
                          Mobile Cables
                        </Link>
                      </li>
                      <li>
                        <Link to="#" className="grayHeading">
                          Mobile Chargers{" "}
                        </Link>
                      </li>
                    </ul>
                    <h1 className="mainHeading">Smart Wearable Tech</h1>
                    <ul className="dropDownList">
                      <li>
                        <Link to="#" className="grayHeading">
                          Smart Watches
                        </Link>
                      </li>
                      <li>
                        <Link to="#" className="grayHeading">
                          Smart Glasses (VR)
                        </Link>
                      </li>
                      <li>
                        <Link to="#" className="grayHeading">
                          Smart Bands{" "}
                        </Link>
                      </li>
                    </ul>
                  </Dropdown.Menu>
                </Dropdown>
                <Dropdown>
                  <Dropdown.Toggle className=" mainHeading">
                    TVs & Appliance
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <h1 className="mainHeading">Moblies Accessories</h1>
                    <ul className="dropDownList">
                      <li>
                        <Link to="#" className="grayHeading">
                          Mobile Cases
                        </Link>
                      </li>
                      <li>
                        <Link to="#" className="grayHeading">
                          Headphones
                        </Link>
                      </li>
                      <li>
                        <Link to="#" className="grayHeading">
                          Power Banks{" "}
                        </Link>
                      </li>
                      <li>
                        <Link to="#" className="grayHeading">
                          Screenguards{" "}
                        </Link>
                      </li>
                      <li>
                        <Link to="#" className="grayHeading">
                          Mobile Cables
                        </Link>
                      </li>
                      <li>
                        <Link to="#" className="grayHeading">
                          Mobile Chargers{" "}
                        </Link>
                      </li>
                    </ul>
                    <h1 className="mainHeading">Smart Wearable Tech</h1>
                    <ul className="dropDownList">
                      <li>
                        <Link to="#" className="grayHeading">
                          Smart Watches
                        </Link>
                      </li>
                      <li>
                        <Link to="#" className="grayHeading">
                          Smart Glasses (VR)
                        </Link>
                      </li>
                      <li>
                        <Link to="#" className="grayHeading">
                          Smart Bands{" "}
                        </Link>
                      </li>
                    </ul>
                  </Dropdown.Menu>
                </Dropdown>
                <Dropdown>
                  <Dropdown.Toggle className=" mainHeading">
                    Men
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <h1 className="mainHeading">Moblies Accessories</h1>
                    <ul className="dropDownList">
                      <li>
                        <Link to="#" className="grayHeading">
                          Mobile Cases
                        </Link>
                      </li>
                      <li>
                        <Link to="#" className="grayHeading">
                          Headphones
                        </Link>
                      </li>
                      <li>
                        <Link to="#" className="grayHeading">
                          Power Banks{" "}
                        </Link>
                      </li>
                      <li>
                        <Link to="#" className="grayHeading">
                          Screenguards{" "}
                        </Link>
                      </li>
                      <li>
                        <Link to="#" className="grayHeading">
                          Mobile Cables
                        </Link>
                      </li>
                      <li>
                        <Link to="#" className="grayHeading">
                          Mobile Chargers{" "}
                        </Link>
                      </li>
                    </ul>
                    <h1 className="mainHeading">Smart Wearable Tech</h1>
                    <ul className="dropDownList">
                      <li>
                        <Link to="#" className="grayHeading">
                          Smart Watches
                        </Link>
                      </li>
                      <li>
                        <Link to="#" className="grayHeading">
                          Smart Glasses (VR)
                        </Link>
                      </li>
                      <li>
                        <Link to="#" className="grayHeading">
                          Smart Bands{" "}
                        </Link>
                      </li>
                    </ul>
                  </Dropdown.Menu>
                </Dropdown>
                <Dropdown>
                  <Dropdown.Toggle className=" mainHeading">
                    Women
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <h1 className="mainHeading">Moblies Accessories</h1>
                    <ul className="dropDownList">
                      <li>
                        <Link to="#" className="grayHeading">
                          Mobile Cases
                        </Link>
                      </li>
                      <li>
                        <Link to="#" className="grayHeading">
                          Headphones
                        </Link>
                      </li>
                      <li>
                        <Link to="#" className="grayHeading">
                          Power Banks{" "}
                        </Link>
                      </li>
                      <li>
                        <Link to="#" className="grayHeading">
                          Screenguards{" "}
                        </Link>
                      </li>
                      <li>
                        <Link to="#" className="grayHeading">
                          Mobile Cables
                        </Link>
                      </li>
                      <li>
                        <Link to="#" className="grayHeading">
                          Mobile Chargers{" "}
                        </Link>
                      </li>
                    </ul>
                    <h1 className="mainHeading">Smart Wearable Tech</h1>
                    <ul className="dropDownList">
                      <li>
                        <Link to="#" className="grayHeading">
                          Smart Watches
                        </Link>
                      </li>
                      <li>
                        <Link to="#" className="grayHeading">
                          Smart Glasses (VR)
                        </Link>
                      </li>
                      <li>
                        <Link to="#" className="grayHeading">
                          Smart Bands{" "}
                        </Link>
                      </li>
                    </ul>
                  </Dropdown.Menu>
                </Dropdown>
                <Dropdown>
                  <Dropdown.Toggle className=" mainHeading">
                    Baby & Kids
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <h1 className="mainHeading">Moblies Accessories</h1>
                    <ul className="dropDownList">
                      <li>
                        <Link to="#" className="grayHeading">
                          Mobile Cases
                        </Link>
                      </li>
                      <li>
                        <Link to="#" className="grayHeading">
                          Headphones
                        </Link>
                      </li>
                      <li>
                        <Link to="#" className="grayHeading">
                          Power Banks{" "}
                        </Link>
                      </li>
                      <li>
                        <Link to="#" className="grayHeading">
                          Screenguards{" "}
                        </Link>
                      </li>
                      <li>
                        <Link to="#" className="grayHeading">
                          Mobile Cables
                        </Link>
                      </li>
                      <li>
                        <Link to="#" className="grayHeading">
                          Mobile Chargers{" "}
                        </Link>
                      </li>
                    </ul>
                    <h1 className="mainHeading">Smart Wearable Tech</h1>
                    <ul className="dropDownList">
                      <li>
                        <Link to="#" className="grayHeading">
                          Smart Watches
                        </Link>
                      </li>
                      <li>
                        <Link to="#" className="grayHeading">
                          Smart Glasses (VR)
                        </Link>
                      </li>
                      <li>
                        <Link to="#" className="grayHeading">
                          Smart Bands{" "}
                        </Link>
                      </li>
                    </ul>
                  </Dropdown.Menu>
                </Dropdown>
                <Dropdown>
                  <Dropdown.Toggle className=" mainHeading">
                    Home & Furniture
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <h1 className="mainHeading">Moblies Accessories</h1>
                    <ul className="dropDownList">
                      <li>
                        <Link to="#" className="grayHeading">
                          Mobile Cases
                        </Link>
                      </li>
                      <li>
                        <Link to="#" className="grayHeading">
                          Headphones
                        </Link>
                      </li>
                      <li>
                        <Link to="#" className="grayHeading">
                          Power Banks{" "}
                        </Link>
                      </li>
                      <li>
                        <Link to="#" className="grayHeading">
                          Screenguards{" "}
                        </Link>
                      </li>
                      <li>
                        <Link to="#" className="grayHeading">
                          Mobile Cables
                        </Link>
                      </li>
                      <li>
                        <Link to="#" className="grayHeading">
                          Mobile Chargers{" "}
                        </Link>
                      </li>
                    </ul>
                    <h1 className="mainHeading">Smart Wearable Tech</h1>
                    <ul className="dropDownList">
                      <li>
                        <Link to="#" className="grayHeading">
                          Smart Watches
                        </Link>
                      </li>
                      <li>
                        <Link to="#" className="grayHeading">
                          Smart Glasses (VR)
                        </Link>
                      </li>
                      <li>
                        <Link to="#" className="grayHeading">
                          Smart Bands{" "}
                        </Link>
                      </li>
                    </ul>
                  </Dropdown.Menu>
                </Dropdown>
                <Dropdown>
                  <Dropdown.Toggle className=" mainHeading">
                    Sports, Books
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <h1 className="mainHeading">Moblies Accessories</h1>
                    <ul className="dropDownList">
                      <li>
                        <Link to="#" className="grayHeading">
                          Mobile Cases
                        </Link>
                      </li>
                      <li>
                        <Link to="#" className="grayHeading">
                          Headphones
                        </Link>
                      </li>
                      <li>
                        <Link to="#" className="grayHeading">
                          Power Banks{" "}
                        </Link>
                      </li>
                      <li>
                        <Link to="#" className="grayHeading">
                          Screenguards{" "}
                        </Link>
                      </li>
                      <li>
                        <Link to="#" className="grayHeading">
                          Mobile Cables
                        </Link>
                      </li>
                      <li>
                        <Link to="#" className="grayHeading">
                          Mobile Chargers{" "}
                        </Link>
                      </li>
                    </ul>
                    <h1 className="mainHeading">Smart Wearable Tech</h1>
                    <ul className="dropDownList">
                      <li>
                        <Link to="#" className="grayHeading">
                          Smart Watches
                        </Link>
                      </li>
                      <li>
                        <Link to="#" className="grayHeading">
                          Smart Glasses (VR)
                        </Link>
                      </li>
                      <li>
                        <Link to="#" className="grayHeading">
                          Smart Bands{" "}
                        </Link>
                      </li>
                    </ul>
                  </Dropdown.Menu>
                </Dropdown>
                <Dropdown>
                  <Dropdown.Toggle className=" mainHeading">
                    More
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <h1 className="mainHeading">Moblies Accessories</h1>
                    <ul className="dropDownList">
                      <li>
                        <Link to="#" className="grayHeading">
                          Mobile Cases
                        </Link>
                      </li>
                      <li>
                        <Link to="#" className="grayHeading">
                          Headphones
                        </Link>
                      </li>
                      <li>
                        <Link to="#" className="grayHeading">
                          Power Banks{" "}
                        </Link>
                      </li>
                      <li>
                        <Link to="#" className="grayHeading">
                          Screenguards{" "}
                        </Link>
                      </li>
                      <li>
                        <Link to="#" className="grayHeading">
                          Mobile Cables
                        </Link>
                      </li>
                      <li>
                        <Link to="#" className="grayHeading">
                          Mobile Chargers{" "}
                        </Link>
                      </li>
                    </ul>
                    <h1 className="mainHeading">Smart Wearable Tech</h1>
                    <ul className="dropDownList">
                      <li>
                        <Link to="#" className="grayHeading">
                          Smart Watches
                        </Link>
                      </li>
                      <li>
                        <Link to="#" className="grayHeading">
                          Smart Glasses (VR)
                        </Link>
                      </li>
                      <li>
                        <Link to="#" className="grayHeading">
                          Smart Bands{" "}
                        </Link>
                      </li>
                    </ul>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </Offcanvas.Body>
        </Offcanvas>
      </div>
    </header>
  );
};

export default Header;
