// import Filpacard from "../../assets/images/flipkart.png";
import React, { useState, useEffect } from "react";
import {
  axiosInstance,
  base_url,
  handleError,
  imgBaseUrl,
} from "../../Config/axiosConfig";
export default function NotificationSetting() {
  let [apiData, setApiData] = useState([]);
  let token = localStorage.getItem("token")
  useEffect(() => {
    (async () => {
      try {
        let res = await axiosInstance.get(
          `${base_url}/v2/user/notification/list`, {headers: {
            Authorization: "Bearer " + token,
          }}
        );
        console.log(res.data.data);
        setApiData(res.data.data);
      } catch (error) {
        let errorMsg = handleError(error);
        console.log(errorMsg);
      }
    })();
  }, []);
  return (
    <>
      <div className="col-lg-9 col-md-8">
        <div className="panel-cards all-notification">
          <h3 className="title">All Notifications </h3>
          <ul className="all-notification-list">
            {apiData?.map((arr) => (
              <li key={arr.image}>
                <div className="all-notification-list-img">
                  <img src={`${imgBaseUrl}/${arr?.imgae_url}`} alt />
                </div>
                <div className="all-notification-list-content">
                  <h6>{arr.text}&gt;</h6>
                  <p>{arr?.created_at?.split("T")[0]}</p>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
}
