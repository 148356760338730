import diwaliImg from "../../assets/img/diwaliImg.png";
import React, { useEffect, useState } from "react";
import mensCard from "../../assets/img/mensCard.png";
import StarImg from "../../assets/img/StarImg.svg";
import LeftArrow from "../../assets/img/diwliLeftArrow.svg";
import RightArrow from "../../assets/img/diwliRightArrow.svg";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import Card from "react-bootstrap/Card";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { axiosInstance, base_url, imgBaseUrl,handleError } from "../../Config/axiosConfig";
import { Link } from "react-router-dom";

const MensSection = () => {
  let [tenCategory, setTenCategory] = useState();
  useEffect(() => {
    let apicall = async () => {
      try {
        let res = await axiosInstance.get(
          `${base_url}/v2/product/home_ten_cat`
        );
        setTenCategory(res?.data?.data);
      } catch (error) {
        let errorMsg = handleError(error);
        console.log(errorMsg);
      }
    };
    apicall();
  }, []);
  return (
    <section className="MensSection constantPadding">
      <div className="mensContainer ps-lg-2">
        <div className="row">
          <div className="col-lg-2 p-0">
            <div className="diwaliSale">
              <img src={diwaliImg} className="diwaliSaleImg" />
            </div>
          </div>
          <div className="col-lg-10">
            <div className="menCraousal">
              <div className="holeSaleContainer">
                <div className="holeHeader">
                  <h1 className="mainHeading">Mens fashion's</h1>
                  <div className="holeHeaderBtn">
                    <div className="mensPrv">
                      <img src={LeftArrow} className="carouslArrowImg" />
                    </div>
                    <div className="mensNext">
                      <img src={RightArrow} className="carouslArrowImg" />
                    </div>
                  </div>
                </div>
                <div className="holeSaleCarousel">
                  <div className="uperCarouselContainer">
                    <Swiper
                      modules={[Navigation, Pagination, Scrollbar, A11y]}
                      loop={true}
                      spaceBetween={3}
                      slidesPerView={4}
                      navigation={{
                        prevEl: `.mensPrv`,
                        nextEl: `.mensNext`,
                      }}
                      // onSlideChange={() => console.log("slide change")}
                      // onSwiper={(swiper) => console.log(swiper)}
                      breakpoints={{
                        100: {
                          slidesPerView: 1,
                          spaceBetween: 20,
                        },
                        650: {
                          slidesPerView: 3,
                          spaceBetween: 30,
                        },
                        1024: {
                          slidesPerView: 4,
                          spaceBetween: 40,
                        },
                      }}
                    >
                      {tenCategory?.map((arr, i) => (
                        <SwiperSlide key={i}>
                          <div className="upperCart">
                            <Link to={`/product-details/${arr?.id}`}>
                              <Card>
                                <Card.Img
                                  variant="top"
                                  // src={mensCard}
                                  src={`${imgBaseUrl}/${arr?.banner}`}
                                  className="mensImg"
                                />
                                <Card.Body>
                                  <h1 className="mainHeading">
                                    {arr?.name.split(" ")[0]}{" "}
                                    {arr?.name.split(" ")[1]}{" "}
                                    {arr?.name.split(" ")[2]}.......
                                  </h1>
                                  <div className="cardReview">
                                    <img src={StarImg} className="starImg" />
                                    <p className="grayHeading">(1,512)</p>
                                  </div>
                                  <div className="cardPricing">
                                    <p className="mainHeading">
                                      ₹ {arr?.unit_price}
                                      {arr?.discount && (
                                        <span>
                                          ₹ {arr?.discount + arr?.unit_price}
                                        </span>
                                      )}
                                    </p>
                                  </div>
                                </Card.Body>
                              </Card>
                            </Link>
                          </div>
                        </SwiperSlide>
                      ))}
                      {/* <SwiperSlide>
                        <div className="upperCart">
                          <Card>
                            <Card.Img
                              variant="top"
                              src={mensCard}
                              className="mensImg"
                            />
                            <Card.Body>
                              <h1 className="mainHeading">
                                Read monsters jem...
                              </h1>
                              <div className="cardReview">
                                <img src={StarImg} className="starImg" />
                                <p className="grayHeading">(1,512)</p>
                              </div>
                              <div className="cardPricing">
                                <p className="mainHeading">
                                  ₹ 12,990
                                  <span>₹ 15,990</span>
                                </p>
                              </div>
                            </Card.Body>
                          </Card>
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="upperCart">
                          <Card>
                            <Card.Img
                              variant="top"
                              src={mensCard}
                              className="mensImg"
                            />
                            <Card.Body>
                              <h1 className="mainHeading">
                                Read monsters jem...
                              </h1>
                              <div className="cardReview">
                                <img src={StarImg} className="starImg" />
                                <p className="grayHeading">(1,512)</p>
                              </div>
                              <div className="cardPricing">
                                <p className="mainHeading">
                                  ₹ 12,990
                                  <span>₹ 15,990</span>
                                </p>
                              </div>
                            </Card.Body>
                          </Card>
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="upperCart">
                          <Card>
                            <Card.Img
                              variant="top"
                              src={mensCard}
                              className="mensImg"
                            />
                            <Card.Body>
                              <h1 className="mainHeading">
                                Read monsters jem...
                              </h1>
                              <div className="cardReview">
                                <img src={StarImg} className="starImg" />
                                <p className="grayHeading">(1,512)</p>
                              </div>
                              <div className="cardPricing">
                                <p className="mainHeading">
                                  ₹ 12,990
                                  <span>₹ 15,990</span>
                                </p>
                              </div>
                            </Card.Body>
                          </Card>
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="upperCart">
                          <Card>
                            <Card.Img
                              variant="top"
                              src={mensCard}
                              className="mensImg"
                            />
                            <Card.Body>
                              <h1 className="mainHeading">
                                Read monsters jem...
                              </h1>
                              <div className="cardReview">
                                <img src={StarImg} className="starImg" />
                                <p className="grayHeading">(1,512)</p>
                              </div>
                              <div className="cardPricing">
                                <p className="mainHeading">
                                  ₹ 12,990
                                  <span>₹ 15,990</span>
                                </p>
                              </div>
                            </Card.Body>
                          </Card>
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="upperCart">
                          <Card>
                            <Card.Img
                              variant="top"
                              src={mensCard}
                              className="mensImg"
                            />
                            <Card.Body>
                              <h1 className="mainHeading">
                                Read monsters jem...
                              </h1>
                              <div className="cardReview">
                                <img src={StarImg} className="starImg" />
                                <p className="grayHeading">(1,512)</p>
                              </div>
                              <div className="cardPricing">
                                <p className="mainHeading">
                                  ₹ 12,990
                                  <span>₹ 15,990</span>
                                </p>
                              </div>
                            </Card.Body>
                          </Card>
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="upperCart">
                          <Card>
                            <Card.Img
                              variant="top"
                              src={mensCard}
                              className="mensImg"
                            />
                            <Card.Body>
                              <h1 className="mainHeading">
                                Read monsters jem...
                              </h1>
                              <div className="cardReview">
                                <img src={StarImg} className="starImg" />
                                <p className="grayHeading">(1,512)</p>
                              </div>
                              <div className="cardPricing">
                                <p className="mainHeading">
                                  ₹ 12,990
                                  <span>₹ 15,990</span>
                                </p>
                              </div>
                            </Card.Body>
                          </Card>
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="upperCart">
                          <Card>
                            <Card.Img
                              variant="top"
                              src={mensCard}
                              className="mensImg"
                            />
                            <Card.Body>
                              <h1 className="mainHeading">
                                Read monsters jem...
                              </h1>
                              <div className="cardReview">
                                <img src={StarImg} className="starImg" />
                                <p className="grayHeading">(1,512)</p>
                              </div>
                              <div className="cardPricing">
                                <p className="mainHeading">
                                  ₹ 12,990
                                  <span>₹ 15,990</span>
                                </p>
                              </div>
                            </Card.Body>
                          </Card>
                        </div>
                      </SwiperSlide> */}
                    </Swiper>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MensSection;
