// import React from "react";
// import Header from "./components/Header/Header";
// import QuicklySection from "./components/QuicklySection/QuicklySection";
// import HeroSection from "./components/HeroSection";
// import DelivarySection from "./components/DelivarySection";
// import BeautySection from "./components/BeautySection";
// import PaanSection from "./components/PaanSection";
// import SpecialUperSection from "./components/SpecialUperSection";
// import HoleSaleSection1 from "./components/HoleSaleSection1";
// import DryFriutSection from "./components/DryFriutSection";
// import BeautyHygiene from "./components/BeautyHygiene";
// import SelectionSection from "./components/SelectionSection";
// import MensSection from "./components/MensSection";
// import SalesSection from "./components/SalesSection";
// import SummerSaleSection from "./components/SummerSaleSection";
// import SayDraySection from "./components/SayDraySection";
// import AppDownloadSection from "./components/AppDownloadSection";
// import Footer from "./components/Footer/Footer";
// import Dryfruit from "./assets/img/dryfruit.png";
// import PaanImg from "./assets/img/paanImg.png";
// import Dryfruit3 from "./assets/img/dryfriut3.png";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "./assets/css/style.css";
import "./assets/css/responsive.css";
// import "bootstrap/dist/css/bootstrap.css";
// import "bootstrap/dist/js/bootstrap.bundle.min";
// import GroceryHome from "./components/Grocery/GroceryHome";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Layout from "./Layout";

import HomePage from "./screens/HomePage";

import MyAccountPage from "./screens/MyAccountPage";
import MyAccountForm from "./components/MyAccountDetailsSection/MyAccountForm";
import AddressList from "./components/MyAccountDetailsSection/Address/AddressList";
import NewAddress from "./components/MyAccountDetailsSection/Address/NewAddress";
import EditAddress from "./components/MyAccountDetailsSection/Address/EditAddress";
import MyCoupon from "./components/MyAccountDetailsSection/MyCoupon";
import MyOrder from "./components/MyAccountDetailsSection/MyOrder";

import WishList from "./components/MyAccountDetailsSection/WishList";
import MyWallet from "./components/MyAccountDetailsSection/MyWallet";
import ChangePassword from "./components/MyAccountDetailsSection/Settings/ChangePassword";
import NotificationSetting from "./components/MyAccountDetailsSection/NotificationSetting";
import AddReview from "./components/MyAccountDetailsSection/Review/AddReview";
import MyReview from "./components/MyAccountDetailsSection/Review/MyReview";
import EditReview from "./components/MyAccountDetailsSection/Review/EditReview";
import GroceryPage from "./screens/GroceryPage";
// import ReviewSlider from "./components/MyAccountDetailsSection/OtherPopularProductSlider";
import ProductDetailsPage from "./screens/ProductDetailsPage";
// import GroceryCategoryProduct from "./components/Grocery/GroceryCategoryProduct";
// import GroceryProductFilter from "./components/GroceryProduct/GroceryProductFilter";
import GroceryProductPage from "./screens/GroceryProductPage";
import ElectronicPage from "./screens/ElectronicPage";
import VerticalSlider from "./screens/VerticalSlider";
import SearchProductPage from "./screens/SearchProductPage";
import SellerProductPage from "./screens/SellerProductPage";
// import SellerBox from "./components/SellerBox/SellerBox";
import ComprasionPage from "./screens/ComprasionPage";
import SellerPage from "./screens/SellerPage";
import Mycart from "./components/MyAccountDetailsSection/Mycart";
import PaymentOptionPage from "./screens/PaymentOptionPage";
import ConfirmOrderPage from "./screens/ConfirmOrderPage";
import SelectAddressPage from "./screens/SelectAddressPage";
import TrackingOrder from "./screens/TrackingOrder";
// import MobileDetails from "./components/MobileDetails/MobileDetails";
import MobileSectionPage from "./screens/MobileSectionPage";
import CardProvider from "./components/Context/CardProvider";
import SearchProductContext, { SearchProductProvider } from "./ContextApi/searchProduct/searchProduct";
import NotFound from "./components/404Page/NotFound";
// import loginUserContext,{LoginUserProvider} from "./ContextApi/loginUser/loginuser";
import { createContext, useContext, useEffect } from "react";
import CardContext from "./components/Context/CardContext";
import loginUserContext from "./ContextApi/loginUser/LoginUser";
import userTokenContext from "./ContextApi/userToken/UserToken";

import Protected from "./Protected";
import MyCartWithOutLogin from "./components/MyAccountDetailsSection/MyCartWithOutLogin";

const App = () => {
  // let {loginUser,setLoginUser} = useContext(loginUserContext);
  let { userToken, setUserToken } = useContext(userTokenContext);
  let { setCard } = useContext(CardContext);
  useEffect(() => {
    let tokenData=localStorage.getItem("token")
    setUserToken(tokenData)
    if(!tokenData){
      let data= localStorage.getItem("guestUser")
      if(data){
        data = JSON.parse(data)
        setCard(data)
      }
     }
  }, [])


  return (
    <SearchProductProvider>
       
        {/* <DataListProvider> */}

        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route index element={<HomePage />} />
              <Route path="grocery-page" element={<GroceryPage />} />
              {/* <Route path="slider" element={<ReviewSlider />} /> */}
              {userToken &&
                <Route path="my-account//" element={< Protected Component={MyAccountPage} />}>
                  <Route path="details" element={<Protected Component={MyAccountForm} />} />
                  <Route path="address" element={<Protected Component={AddressList} />} />
                  <Route path="edit-adress" element={<Protected Component={EditAddress} />} />
                  <Route path="new-address" element={<Protected Component={NewAddress} />} />
                  <Route path="my-coupon" element={<Protected Component={MyCoupon} />} />
                  <Route path="my-order" element={<Protected Component={MyOrder} />} />
                  <Route path="my-cart" element={<Protected Component={Mycart} />} />
                  <Route path="wish-list" element={<Protected Component={WishList} />} />
                  <Route path="my-wallet" element={<Protected Component={MyWallet} />} />
                  <Route
                    path="setting-change-password"
                    element={<Protected Component={ChangePassword} />}
                  />
                  <Route
                    path="notification-setting"
                    element={<Protected Component={NotificationSetting} />}
                  />
                  <Route path="my-reviews" element={<Protected Component={MyReview} />} />
                  <Route path="edit-reviews" element={<Protected Component={EditReview} />} />
                  <Route path="reviews" element={<Protected Component={AddReview} />} />
                  <Route path="account/*" element={<NotFound />} />
                </Route>}
              <Route
                path="product-details/:productId"
                element={<ProductDetailsPage />}
              />
              <Route path="my-cart" element= {<MyCartWithOutLogin/>}   />
              <Route path="grocery-product" element={<GroceryProductPage />} />
              <Route path="electronic-section" element={<ElectronicPage />} />
              <Route path="vertical-slider" element={<VerticalSlider />} />   
              <Route path="search-product" element={<SearchProductPage />} />
              <Route path="seller-product" element={<SellerProductPage />} />
              <Route path="comprasion" element={<ComprasionPage />} />
              <Route path="seller" element={<SellerPage />} />
              <Route path="payment-option" element={<PaymentOptionPage />} />
              <Route path="confirm-order" element={<ConfirmOrderPage />} />
              <Route path="select-address" element={<SelectAddressPage />} />
              <Route path="tracking-order" element={<TrackingOrder />} />
              <Route path="mobile-details" element={<MobileSectionPage />} />
            </Route>
            <Route path="*" element={<NotFound />} />
          </Routes>
          <ToastContainer />
        </BrowserRouter>

        {/* </DataListProvider> */}
    </SearchProductProvider>
  );
};

export default App;
