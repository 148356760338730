import React, { useState } from "react";
import loginImg from "../../../assets/images/login-img.png";
import {
  axiosInstance,
  base_url,
  handleError,
} from "../../../Config/axiosConfig";
import toastify from "../../../Config/toastify";
import { Link } from "react-router-dom";
export default function ForgetPasswordModel() {
  let [formData, setFormData] = useState({
    phone: "",
    otp: "",
    password: "",
    cpassword: "",
  });

  let handleChange = (e) => {
    setFormData((currVal) => ({ ...currVal, [e.target.name]: e.target.value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(formData);

    if(formData.password !== formData.cpassword){
      toastify.error("Password and Confirm Password Not Matching")
    }
    return;

    try {
      let res = await axiosInstance.post(
        `${base_url}/v2/home/second_slider`,
        formData
      );
      console.log(res.data);
      // navigate("/grocery-page");
      // localStorage.setItem("user", JSON.stringify(res.data.user));
      // localStorage.setItem("token", JSON.stringify(res.data.access_token));
      let unique = document.getElementById("closebtn");
      unique.click();
      if (res.data.result) {
        toastify.success(res.data.message);
      }
    } catch (error) {
      let errorMsg = handleError(error);
      console.log(errorMsg);
    }

    // const res = await axiosInstance.post(`${base_url}/v2/auth/login`, formData);
    // console.log(res.data.user);
    // console.log(res?.data?.access_token);
    // toastify.success(res?.data?.message);
    // navigate("/grocery-page");
    // localStorage.setItem("user", JSON.stringify(res.data.user));
    // localStorage.setItem("token", JSON.stringify(res.data.access_token));
    // let unique = document.getElementById("loginFormBtn");
    // unique.click();
  };

  return (
    <>
      <div
        className="modal fade register-modal"
        id="forgot"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-xl">
          <div className="modal-content">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              id="closebtn"
            />
            <div className="modal-body register-body">
              <div className="row gx-3">
                <div className="col-lg-5 d-none d-lg-block d-xl-block">
                  <div className="register-body-lft">
                    <h3>Forgot Password</h3>
                    <p>
                      Please provide your registered Mobile number.Get started
                      into Nerozon.
                    </p>
                    <img src={loginImg} className="w-100 h-100" alt="" />
                  </div>
                </div>
                <div className="col-lg-7 col-md-12">
                  <div className="register-body-rgt">
                    <form action="" onSubmit={handleSubmit}>
                      <div className="mb-4 frm-bx number">
                        <label htmlFor="">Mobile Number</label>
                        <input
                          type="number"
                          className="form-control"
                          onChange={handleChange}
                          name="phone"
                          value={formData.phone}
                          // defaultValue={7894561230}
                        />
                        <span>+91</span>
                        <Link to="#" className="change">
                          Change
                        </Link>
                      </div>
                      <div className="mb-4 frm-bx">
                        <label htmlFor="">Enter OTP</label>
                        <input
                          type="number"
                          name="otp"
                          className="form-control"
                          onChange={handleChange}
                          value={formData.otp}
                          placeholder="Enter OTP"
                        />
                      </div>
                      <div className="mb-4 frm-bx">
                        <label htmlFor="">Password</label>
                        <input
                          type="password"
                          className="form-control"
                          name="password"
                          onChange={handleChange}
                          value={formData.password}
                          placeholder="Enter Password"
                        />
                      </div>
                      <div className="mb-4 frm-bx">
                        <label htmlFor="">Confirm Password</label>
                        <input
                          type="password"
                          className="form-control"
                          name="cpassword"
                          onChange={handleChange}
                          value={formData.cpassword}
                          placeholder="Re-enter Password"
                        />
                      </div>
                      <div className="frm-bx">
                        <button className="org-btn w-100" type="submit">
                          Sign In
                        </button>
                      </div>
                      <p className="text-center">
                        Existing User?{" "}
                        <Link
                          to="#"
                          data-bs-toggle="modal"
                          data-bs-target="#signin"
                        >
                          Sign In
                        </Link>
                      </p>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
