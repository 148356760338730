import React, { useState, useEffect } from "react";
import {
  axiosInstance,
  base_url,
  handleError,
  imgBaseUrl,
} from "../../Config/axiosConfig";
export default function MyWallet() {
  let [apiData, setApiData] = useState([]);
  let [wallet, setWallet] = useState();
  let token = localStorage.getItem("token")
  useEffect(() => {
    (async () => {
      try {
        let res = await axiosInstance.get(`${base_url}/v2/wallet/balance`, {headers: {
          Authorization: "Bearer " + token,
        }});
        console.log(res.data);
        // setApiData(res.data.data)
        setWallet(res.data.balance);
      } catch (error) {
        let errorMsg = handleError(error);
        console.log(errorMsg);
      }
    })();
    (async () => {
      try {
        let res = await axiosInstance.get(`${base_url}/v2/wallet/history`, {headers: {
          Authorization: "Bearer " + token,
        }});
        console.log(res.data.data);
        setApiData(res.data.data);
        // setWallet(res.data.balance);
      } catch (error) {
        let errorMsg = handleError(error);
        console.log(errorMsg);
      }
    })();
  }, []);
  return (
    <>
      <div className="col-lg-9 col-md-8">
        <div className="panel-cards wallet-balance">
          <h4 className="text-center text-white">
            Your wallet Balance : &nbsp; <span> ₹ {wallet}</span>
          </h4>
        </div>
        <div className="panel-cards password-change addres w-100">
          <h4 className="title">Recent Activity</h4>
          {apiData?.map((arr, i) => (
            <div key={i} className="deliveadd-bx">
              <div className="deliveadd-bx-content">
                <h5>{arr.date}</h5>
                {/* <p>Cashback Order: 13324</p> */}
              </div>
              <div className="deliveadd-bx-icon">
                <a href="#" className="text-success fz-22 fw-700">
                  {/* ₹ */}- {arr.amount}
                </a>
              </div>
            </div>
          ))}
          {/* <div className="deliveadd-bx">
            <div className="deliveadd-bx-content">
              <h5>Jan 21, 2020</h5>
              <p>Cashback Order: 13324</p>
            </div>
            <div className="deliveadd-bx-icon">
              <a
                href="#"
                className="text-danger fz-22 fw-700"
              >
                - ₹ 50
              </a>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
}
