import React, { useState } from "react";
import {
  axiosInstance,
  handleError,
  base_url,
} from "../../../Config/axiosConfig";
import { Link, useNavigate } from "react-router-dom";
import toastify from "../../../Config/toastify";
export default function NewAddress() {
  let navigate = useNavigate();
  const [formval, setformVal] = useState({
    name: "",
    phone: "",
    postal_code: "",
    address: "",
    locality: "",
    state: "",
    city: "",
    house_No: "",
    type: "",
  });
  let handleOnchange = (e) => {
    setformVal((curval) => ({ ...curval, [e.target.name]: e.target.value }));
  };
  let token = localStorage.getItem("token")

  function getLocation() {
    if (navigator.geolocation) {
      let a = navigator.geolocation.getCurrentPosition(showPosition);
      console.log(navigator.geolocation.getCurrentPosition(showPosition));
    }
  }

  function showPosition(position) {
    console.log(position.coords.latitude, position.coords.longitude);
  }

  let handleSubmit = async (e) => {
    e.preventDefault();
    // console.log(formval);
    if (
      !formval.name ||
      !formval.phone ||
      !formval.postal_code ||
      !formval.address ||
      !formval.locality ||
      !formval.state ||
      !formval.city ||
      !formval.house_No
    ) {
      return toastify.error("Empty Fields Not Allowed");
    }
    formval.address = formval.house_No + " " + formval.address;
    // return;
    try {
      let res = await axiosInstance.post(
        `${base_url}/v2/user/shipping/create`,formval,{headers: {
          Authorization: "Bearer " + token,
        }}
        
      );
      console.log(res.data);
      if (res.data.result) {
        toastify.success(res.data.message);
        return navigate("/my-account/address");
      } else {
        return toastify.error(res.data.message);
      }
    } catch (error) {
      let errorMsg = handleError(error);
      console.log(errorMsg);
    }
  };

  return (
    <>
      <div className="col-lg-9 col-md-8">
        <div className="panel-cards location-btn">
          <Link to="/my-account/address" className="fw-600 org-txt">
            <i className="bi bi-chevron-left"></i> Back
          </Link>
          <a href="" className="org-btn-02" onClick={getLocation}>
            Use my current location
          </a>
        </div>
        <div className="panel-cards password-change addres w-100">
          <form action="" onSubmit={handleSubmit}>
            <div className="row justify-content-between">
              <div className="col-lg-5 col-md-6 mb-3 change-bx">
                <label htmlFor="" className="form-label">
                  PIN Code
                </label>
                <input
                  type="number"
                  onChange={handleOnchange}
                  name="postal_code"
                  className="form-control"
                  placeholder="PIN Code"
                />
              </div>
              <div className="col-lg-5 col-md-6 mb-3 change-bx">
                <label htmlFor="" className="form-label">
                  House No./ Shop No,
                </label>
                <input
                  type="text"
                  name="house_No"
                  onChange={handleOnchange}
                  className="form-control"
                  placeholder="House No./ Shop No."
                />
              </div>
              <div className="col-lg-5 col-md-6 mb-3 change-bx">
                <label htmlFor="" className="form-label">
                  Address Detail
                </label>
                <input
                  type="text"
                  name="address"
                  onChange={handleOnchange}
                  className="form-control"
                  placeholder="Address"
                />
              </div>
              <div className="col-lg-5 col-md-6 mb-3 change-bx">
                <label htmlFor="" className="form-label">
                  Landmark
                </label>
                <input
                  type="text"
                  name="locality"
                  onChange={handleOnchange}
                  className="form-control"
                  placeholder="Landmark"
                />
              </div>
              <div className="col-lg-5 col-md-6 mb-3 change-bx">
                <label htmlFor="" className="form-label">
                  City
                </label>
                <input
                  type="text"
                  name="city"
                  onChange={handleOnchange}
                  className="form-control"
                  placeholder="City"
                />
              </div>
              <div className="col-lg-5 col-md-6 mb-3 change-bx">
                <label htmlFor="" className="form-label">
                  State
                </label>
                <input
                  type="text"
                  name="state"
                  onChange={handleOnchange}
                  className="form-control"
                  placeholder="State"
                />
              </div>
              <div className="col-lg-5 col-md-6 mb-3 change-bx">
                <label htmlFor="" className="form-label">
                  Contact Person Name
                </label>
                <input
                  type="text"
                  name="name"
                  onChange={handleOnchange}
                  className="form-control"
                  placeholder="Contact Person Name"
                />
              </div>
              <div className="col-lg-5 col-md-6 mb-3 change-bx">
                <label htmlFor="" className="form-label">
                  Mobile Number
                </label>
                <input
                  type="number"
                  name="phone"
                  onChange={handleOnchange}
                  className="form-control"
                  placeholder="Mobile Number"
                />
              </div>
              {/* //hello */}
              <div className="col-lg-5 col-md-6 mb-3 change-bx">
                <label htmlFor="" className="form-label">
                  Address Type
                </label>
                <div className="d-flex">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      onChange={handleOnchange}
                      type="radio"
                      id="address"
                      name="type"
                      value="home" 
                      checked={formval.type === "home"}
                      // checked={selectedOption === 'option1'}
                    />
                    <label className="form-check-label" htmlFor="address">
                      Home  
                    </label> 
                  </div>
                  <div className="form-check ms-2">
                    <input
                      className="form-check-input"
                      onChange={handleOnchange}
                      name="type"
                      type="radio"
                      value="office"
                      id="office"
                      checked={formval.type === "office"}
                      // name="options"
                      // value="option1"
                      // checked={selectedOption === 'option1'}
                      // onChange={handleChange}
                    />
                    <label className="form-check-label" htmlFor="office">
                      Office
                    </label>
                  </div>
                </div>
              </div>
              <div>
                <button className="org-btn-02" type="submit">
                  Save
                </button>
              </div>
            </div>
          </form>
          {/* <hr />
                    <h4 className="title">Delivery Addresses</h4>
                    <div className="deliveadd-bx">
                        <div className="deliveadd-bx-content">
                            <h5>
                                Shirin Ittarwala <span>Home</span>
                            </h5>
                            <p>
                                B-1008, Samaan II, 100 Feet Anand Nagar Nagar Rd, Near Auda Garden,
                                Prahlad Nagar, Ahmedabad, Gujarat 380015
                            </p>
                        </div>
                        <div className="deliveadd-bx-icon">
                            <a href="" className="text-secondary fz-16 d-block">
                                <i className="bi bi-trash3-fill" />
                            </a>
                            <a href="" className="text-danger fz-16 d-block">
                                <i className="bi bi-pencil-square" />
                            </a>
                        </div>
                    </div>
                    <div className="deliveadd-bx">
                        <div className="deliveadd-bx-content">
                            <h5>
                                Shirin Ittarwala <span>Home</span>
                            </h5>
                            <p>
                                B-1008, Samaan II, 100 Feet Anand Nagar Nagar Rd, Near Auda Garden,
                                Prahlad Nagar, Ahmedabad, Gujarat 380015
                            </p>
                        </div>
                        <div className="deliveadd-bx-icon">
                            <a href="" className="text-secondary fz-16 d-block">
                                <i className="bi bi-trash3-fill" />
                            </a>
                            <a href="" className="text-danger fz-16 d-block">
                                <i className="bi bi-pencil-square" />
                            </a>
                        </div>
                    </div>
                    <div id='demo' onClick={getLocation}>hello</div> */}
        </div>
      </div>
    </>
  );
}
