import React from 'react'
import Copun from '../components/Copun/Copun'
// import Quickly from '../components/QuicklySection/QuicklySection'
import showsImg from "../assets/images/shoes.png"
import orderImg from "../assets/images/order-icon.png"
import { Link } from 'react-router-dom'
export default function ConfirmOrderPage() {
    return (
        <>
            {/* <Quickly /> */}
            <section className="select-address tp-space">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9 col-md-8">
                            <div className="panel-cards ">
                                <h4 className="title d-flex justify-content-between mb-0">
                                    Delivery Address{" "}
                                    <Link
                                        to="#"
                                        className="org-btn-02 mb-0 text-center mt-2"
                                    >
                                        Change
                                    </Link>{" "}
                                </h4>
                                <div className="password-change w-100">
                                    <div className="deliveadd-bx change-bx justify-content-start border-0 p-0">
                                        <div className="deliveadd-bx-content">
                                            <h5>
                                                Shirin Ittarwala <span>Home</span>
                                            </h5>
                                            <p>
                                                B-1008, Samaan II, 100 Feet Anand Nagar Nagar Rd, Near Auda
                                                Garden, Prahlad Nagar, Ahmedabad, Gujarat 380015
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="payment-option password-change w-100">
                                <h5 className="order-txt">Confirm Order</h5>
                                <div className="panel-cards cnfirm-ordr">
                                    <div className="deliver-option justify-content-end mb-0">
                                        <div>
                                            <span className="text-secondary border-dark">
                                                Delivery by Jan 21, 2020 Fri
                                            </span>
                                            <span className="text-success">Free </span>
                                        </div>
                                    </div>
                                    <div className="order-dtl">
                                        <div className="order-dtl-lft">
                                            <div className="order-dtl-lft-img">
                                                <img src={orderImg} alt="" />
                                            </div>
                                            <div className="order-dtl-lft-content">
                                                <h5 className="mb-0">Women Black Printed A-Line Top x2</h5>
                                                <p className="mb-0">Color: Multi/ Size: M</p>
                                                <p className="mb-0 clr fw-500">
                                                    Seller Cliff Fashion, Ahmedabad
                                                </p>
                                                <h6>
                                                    ₹ 1,000 <span>₹ 1,500</span>
                                                    <em>8% Off</em>
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item-add">
                                        <button type="button" className="minus">
                                            <i className="bi bi-dash" />
                                        </button>
                                        <input type="number" defaultValue={1} />
                                        <button type="button" className="plus">
                                            <i className="bi bi-plus-lg" />
                                        </button>
                                        <Link
                                            to="#"
                                            className="text-danger fw-600 fz-20 ms-3"
                                        >
                                            Remove
                                        </Link>
                                    </div>
                                    <div className="deliver-option justify-content-end mb-0">
                                        <div>
                                            <span className="text-secondary border-dark">
                                                Delivery by Jan 21, 2020 Fri
                                            </span>
                                            <span className="text-success">Free </span>
                                        </div>
                                    </div>
                                    <div className="order-dtl">
                                        <div className="order-dtl-lft">
                                            <div className="order-dtl-lft-img">
                                                <img src={showsImg}alt="" />
                                            </div>
                                            <div className="order-dtl-lft-content">
                                                <h5 className="mb-0">Puma Cell Alien OG Sneakers for Men</h5>
                                                <p className="mb-0">Color: Multi/ Size: M</p>
                                                <p className="mb-0 clr fw-500">
                                                    Seller Cliff Fashion, Ahmedabad
                                                </p>
                                                <h6>
                                                    ₹ 5,000 <span>₹ 1,500</span>
                                                    <em>8% Off</em>
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item-add">
                                        <button type="button" className="minus">
                                            <i className="bi bi-dash" />
                                        </button>
                                        <input type="number" defaultValue={1} />
                                        <button type="button" className="plus">
                                            <i className="bi bi-plus-lg" />
                                        </button>
                                        <Link
                                            to="#"
                                            className="text-danger fw-600 fz-20 ms-3"
                                        >
                                            Remove
                                        </Link>
                                    </div>
                                    <Link to="#" className="org-btn-02 mt-2 ">
                                        Confirm Order
                                    </Link>
                                </div>
                                <div className="panel-cards">
                                    <h4 className="fz-20 fw-600 clr mb-0">Payment Options</h4>
                                </div>
                            </div>
                        </div>
                        <Copun />
                    </div>
                </div>
            </section>

        </>
    )
}
