import React from "react";
import SellerBox from "../components/SellerBox/SellerBox";
// import SearchProductPage from './SearchProductPage'
import SearchProductSection from "../components/SearchProduct/SearchProductSection";
import FilterPoduct from "../components/GroceryProduct/FilterPoduct";
// import Quickly from '../components/QuicklySection/QuicklySection'
// import OtherProductSection from '../components/MyAccountDetailsSection/OtherProductSection'
import imgbox from "../assets/images/box.png"
import imgsearch from "../assets/images/product-search-item-01.png"
export default function SellerProductPage() {
  return (
    <>
      {/* <Quickly/> */}
      <SellerBox />
      <section className="grocery-prdct-sec tp-space">
        <div className="container-fluid">
          <div className="row">
            <FilterPoduct />
            <div className="col-lg-9 col-md-8">
              <div className="custom-bredcump">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="index.html">Home</a>
                  </li>
                  <li className="breadcrumb-item">
                    <a href="#">Clothing</a>
                  </li>
                  <li className="breadcrumb-item">
                    <a href="#">Ladies</a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Tops &amp; T-shirts
                  </li>
                </ol>
                <p>
                  Showing 1-12 Results for “Ladies Top”{" "}
                  <span>(78108 items)</span>
                </p>
              </div>
              <div className="row">
                <div className="col-lg-9 col-md-12">
                  <ul className="sort-by-list">
                    <li>
                      <i className="fal fa-sort-alt me-2" />
                      Sort By:
                    </li>
                    <li>
                      <a href="javascript:void(0);" className="active">
                        Relevance
                      </a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">Popularity</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">Price - High to Low</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">Price - Low to High</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">Newest First</a>
                    </li>
                  </ul>
                </div>
                <div className="col-lg-3 text-end">
                  <a href="javascript:void(0);">
                    <img src={imgbox} alt="" />
                  </a>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6">
                  <div className="other-prdct-card search-prdct">
                    <div className="search-prdct-img">
                      <img
                        src={imgsearch}
                        alt=""
                      />
                      <div className="search-prdct-btn">
                        <a
                          href="javascript:void(0);"
                          className="org-btn-02  w-100 text-center"
                        >
                          Buy Now
                        </a>
                        <a
                          href="javascript:void(0);"
                          className="org-btn-02 w-100 text-center"
                        >
                          <i className="fal fa-cart-plus me-2" />
                          Add to Cart
                        </a>
                        <span className="lyk-btn">
                        <i class="bi bi-heart"></i>
                        </span>
                      </div>
                    </div>
                    <div className="other-prdct-content">
                      <h6>Zara</h6>
                      <h5>Women Green Ruffled...</h5>
                      <p className="rating">
                        <span>
                          4.9 <i className="fas fa-star" />
                        </span>{" "}
                        (1,512)
                      </p>
                      <p>Size: S, M, L, XL</p>
                      <p className="prc">
                        ₹ 1,125 <del>₹ 1,800</del> <em>8% Off</em>
                      </p>
                      <div className="other-prdct-new-dtl">
                        <p className="select-color">
                          <a
                            href="javascript:void(0);"
                            className="color org select"
                          />
                          <a href="javascript:void(0);" className="color grn" />
                          <a href="javascript:void(0);" className="color rd" />
                          <a href="javascript:void(0);" className="color sky" />
                        </p>
                        <p className="star-txt">
                          Or pay ₹ 1,100 + 25{" "}
                          <span className="bi bi-star ms-1" />
                        </p>
                      </div>
                    </div>
                    <span className="lyk-btn select">
                    <i class="bi bi-heart-fill"></i>
                    </span>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6">
                  <div className="other-prdct-card search-prdct">
                    <div className="search-prdct-img">
                      <img
                        src={imgsearch}
                        alt=""
                      />
                      <div className="search-prdct-btn">
                        <a
                          href="javascript:void(0);"
                          className="org-btn-02  w-100 text-center"
                        >
                          Buy Now
                        </a>
                        <a
                          href="javascript:void(0);"
                          className="org-btn-02 w-100 text-center"
                        >
                          <i className="fal fa-cart-plus me-2" />
                          Add to Cart
                        </a>
                        <span className="lyk-btn">
                          <i className="bi bi-heart" />
                        </span>
                      </div>
                    </div>
                    <div className="other-prdct-content">
                      <h6>Zara</h6>
                      <h5>Women Green Ruffled...</h5>
                      <p className="rating">
                        <span>
                          4.9 <i className="fas fa-star" />
                        </span>{" "}
                        (1,512)
                      </p>
                      <p>Size: S, M, L, XL</p>
                      <p className="prc">
                        ₹ 1,125 <del>₹ 1,800</del> <em>8% Off</em>
                      </p>
                      <div className="other-prdct-new-dtl">
                        <p className="select-color">
                          <a
                            href="javascript:void(0);"
                            className="color org select"
                          />
                          <a href="javascript:void(0);" className="color grn" />
                          <a href="javascript:void(0);" className="color rd" />
                          <a href="javascript:void(0);" className="color sky" />
                        </p>
                        <p className="star-txt">
                          Or pay ₹ 1,100 + 25{" "}
                          <span className="bi bi-star ms-1" />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6">
                  <div className="other-prdct-card search-prdct">
                    <div className="search-prdct-img">
                      <img
                        src={imgsearch}
                        alt=""
                      />
                      <div className="search-prdct-btn">
                        <a
                          href="javascript:void(0);"
                          className="org-btn-02  w-100 text-center"
                        >
                          Buy Now
                        </a>
                        <a
                          href="javascript:void(0);"
                          className="org-btn-02 w-100 text-center"
                        >
                          <i className="fal fa-cart-plus me-2" />
                          Add to Cart
                        </a>
                        <span className="lyk-btn">
                          <i className="bi bi-heart" />
                        </span>
                      </div>
                    </div>
                    <div className="other-prdct-content">
                      <h6>Zara</h6>
                      <h5>Women Green Ruffled...</h5>
                      <p className="rating">
                        <span>
                          4.9 <i className="fas fa-star" />
                        </span>{" "}
                        (1,512)
                      </p>
                      <p>Size: S, M, L, XL</p>
                      <p className="prc">
                        ₹ 1,125 <del>₹ 1,800</del> <em>8% Off</em>
                      </p>
                      <div className="other-prdct-new-dtl">
                        <p className="select-color">
                          <a
                            href="javascript:void(0);"
                            className="color org select"
                          />
                          <a href="javascript:void(0);" className="color grn" />
                          <a href="javascript:void(0);" className="color rd" />
                          <a href="javascript:void(0);" className="color sky" />
                        </p>
                        <p className="star-txt">
                          Or pay ₹ 1,100 + 25{" "}
                          <span className="bi bi-star ms-1" />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6">
                  <div className="other-prdct-card search-prdct">
                    <div className="search-prdct-img">
                      <img
                        src={imgsearch}
                        alt=""
                      />
                      <div className="search-prdct-btn">
                        <a
                          href="javascript:void(0);"
                          className="org-btn-02  w-100 text-center"
                        >
                          Buy Now
                        </a>
                        <a
                          href="javascript:void(0);"
                          className="org-btn-02 w-100 text-center"
                        >
                          <i className="fal fa-cart-plus me-2" />
                          Add to Cart
                        </a>
                        <span className="lyk-btn">
                          <i className="bi bi-heart" />
                        </span>
                      </div>
                    </div>
                    <div className="other-prdct-content">
                      <h6>Zara</h6>
                      <h5>Women Green Ruffled...</h5>
                      <p className="rating">
                        <span>
                          4.9 <i className="fas fa-star" />
                        </span>{" "}
                        (1,512)
                      </p>
                      <p>Size: S, M, L, XL</p>
                      <p className="prc">
                        ₹ 1,125 <del>₹ 1,800</del> <em>8% Off</em>
                      </p>
                      <div className="other-prdct-new-dtl">
                        <p className="select-color">
                          <a
                            href="javascript:void(0);"
                            className="color org select"
                          />
                          <a href="javascript:void(0);" className="color grn" />
                          <a href="javascript:void(0);" className="color rd" />
                          <a href="javascript:void(0);" className="color sky" />
                        </p>
                        <p className="star-txt">
                          Or pay ₹ 1,100 + 25{" "}
                          <span className="bi bi-star ms-1" />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6">
                  <div className="other-prdct-card search-prdct">
                    <div className="search-prdct-img">
                      <img
                        src={imgsearch}
                        alt=""
                      />
                      <div className="search-prdct-btn">
                        <a
                          href="javascript:void(0);"
                          className="org-btn-02  w-100 text-center"
                        >
                          Buy Now
                        </a>
                        <a
                          href="javascript:void(0);"
                          className="org-btn-02 w-100 text-center"
                        >
                          <i className="fal fa-cart-plus me-2" />
                          Add to Cart
                        </a>
                        <span className="lyk-btn">
                          <i className="bi bi-heart" />
                        </span>
                      </div>
                    </div>
                    <div className="other-prdct-content">
                      <h6>Zara</h6>
                      <h5>Women Green Ruffled...</h5>
                      <p className="rating">
                        <span>
                          4.9 <i className="fas fa-star" />
                        </span>{" "}
                        (1,512)
                      </p>
                      <p>Size: S, M, L, XL</p>
                      <p className="prc">
                        ₹ 1,125 <del>₹ 1,800</del> <em>8% Off</em>
                      </p>
                      <div className="other-prdct-new-dtl">
                        <p className="select-color">
                          <a
                            href="javascript:void(0);"
                            className="color org select"
                          />
                          <a href="javascript:void(0);" className="color grn" />
                          <a href="javascript:void(0);" className="color rd" />
                          <a href="javascript:void(0);" className="color sky" />
                        </p>
                        <p className="star-txt">
                          Or pay ₹ 1,100 + 25{" "}
                          <span className="bi bi-star ms-1" />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6">
                  <div className="other-prdct-card search-prdct">
                    <div className="search-prdct-img">
                      <img
                        src={imgsearch}
                        alt=""
                      />
                      <div className="search-prdct-btn">
                        <a
                          href="javascript:void(0);"
                          className="org-btn-02  w-100 text-center"
                        >
                          Buy Now
                        </a>
                        <a
                          href="javascript:void(0);"
                          className="org-btn-02 w-100 text-center"
                        >
                          <i className="fal fa-cart-plus me-2" />
                          Add to Cart
                        </a>
                        <span className="lyk-btn">
                          <i className="bi bi-heart" />
                        </span>
                      </div>
                    </div>
                    <div className="other-prdct-content">
                      <h6>Zara</h6>
                      <h5>Women Green Ruffled...</h5>
                      <p className="rating">
                        <span>
                          4.9 <i className="fas fa-star" />
                        </span>{" "}
                        (1,512)
                      </p>
                      <p>Size: S, M, L, XL</p>
                      <p className="prc">
                        ₹ 1,125 <del>₹ 1,800</del> <em>8% Off</em>
                      </p>
                      <div className="other-prdct-new-dtl">
                        <p className="select-color">
                          <a
                            href="javascript:void(0);"
                            className="color org select"
                          />
                          <a href="javascript:void(0);" className="color grn" />
                          <a href="javascript:void(0);" className="color rd" />
                          <a href="javascript:void(0);" className="color sky" />
                        </p>
                        <p className="star-txt">
                          Or pay ₹ 1,100 + 25{" "}
                          <span className="bi bi-star ms-1" />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6">
                  <div className="other-prdct-card search-prdct">
                    <div className="search-prdct-img">
                      <img
                        src={imgsearch}
                        alt=""
                      />
                      <div className="search-prdct-btn">
                        <a
                          href="javascript:void(0);"
                          className="org-btn-02  w-100 text-center"
                        >
                          Buy Now
                        </a>
                        <a
                          href="javascript:void(0);"
                          className="org-btn-02 w-100 text-center"
                        >
                          <i className="fal fa-cart-plus me-2" />
                          Add to Cart
                        </a>
                        <span className="lyk-btn">
                          <i className="bi bi-heart" />
                        </span>
                      </div>
                    </div>
                    <div className="other-prdct-content">
                      <h6>Zara</h6>
                      <h5>Women Green Ruffled...</h5>
                      <p className="rating">
                        <span>
                          4.9 <i className="fas fa-star" />
                        </span>{" "}
                        (1,512)
                      </p>
                      <p>Size: S, M, L, XL</p>
                      <p className="prc">
                        ₹ 1,125 <del>₹ 1,800</del> <em>8% Off</em>
                      </p>
                      <div className="other-prdct-new-dtl">
                        <p className="select-color">
                          <a
                            href="javascript:void(0);"
                            className="color org select"
                          />
                          <a href="javascript:void(0);" className="color grn" />
                          <a href="javascript:void(0);" className="color rd" />
                          <a href="javascript:void(0);" className="color sky" />
                        </p>
                        <p className="star-txt">
                          Or pay ₹ 1,100 + 25{" "}
                          <span className="bi bi-star ms-1" />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6">
                  <div className="other-prdct-card search-prdct">
                    <div className="search-prdct-img">
                      <img
                        src={imgsearch}
                        alt=""
                      />
                      <div className="search-prdct-btn">
                        <a
                          href="javascript:void(0);"
                          className="org-btn-02  w-100 text-center"
                        >
                          Buy Now
                        </a>
                        <a
                          href="javascript:void(0);"
                          className="org-btn-02 w-100 text-center"
                        >
                          <i className="fal fa-cart-plus me-2" />
                          Add to Cart
                        </a>
                        <span className="lyk-btn">
                          <i className="bi bi-heart" />
                        </span>
                      </div>
                    </div>
                    <div className="other-prdct-content">
                      <h6>Zara</h6>
                      <h5>Women Green Ruffled...</h5>
                      <p className="rating">
                        <span>
                          4.9 <i className="fas fa-star" />
                        </span>{" "}
                        (1,512)
                      </p>
                      <p>Size: S, M, L, XL</p>
                      <p className="prc">
                        ₹ 1,125 <del>₹ 1,800</del> <em>8% Off</em>
                      </p>
                      <div className="other-prdct-new-dtl">
                        <p className="select-color">
                          <a
                            href="javascript:void(0);"
                            className="color org select"
                          />
                          <a href="javascript:void(0);" className="color grn" />
                          <a href="javascript:void(0);" className="color rd" />
                          <a href="javascript:void(0);" className="color sky" />
                        </p>
                        <p className="star-txt">
                          Or pay ₹ 1,100 + 25{" "}
                          <span className="bi bi-star ms-1" />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="custom-paginate">
                <ul className="pagination">
                  <li className="page-item">
                    <a className="page-link" href="javascript:void(0);">
                      Previous
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link active" href="javascript:void(0);">
                      1
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" href="javascript:void(0);">
                      2
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" href="javascript:void(0);">
                      3
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" href="javascript:void(0);">
                      4
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" href="javascript:void(0);">
                      5
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" href="javascript:void(0);">
                      6
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link blank" href="javascript:void(0);">
                      ....
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" href="javascript:void(0);">
                      23
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" href="javascript:void(0);">
                      Next
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
