import React from 'react'
import SignInModel from './SignInModel'
import RegisterWithMobileNumber from './RegisterWithMobileNumber'
import RegisterPage from './RegisterPage'
import ForgetPasswordModel from './ForgetPasswordModel'
export default function Model() {
  return (
         
    <>
  {/* signin-modal-start */}

    <SignInModel/>
    
  {/* signin-modal-end */}
  {/* otp-modal-start */}

      <RegisterWithMobileNumber/>

  {/* otp-modal-end */}
  {/* register-modal-start */}
  {/* <div
    className="modal fade register-modal"
    id="register"
    tabIndex={-1}
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div className="modal-dialog modal-dialog-centered modal-xl">
      <div className="modal-content">
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        />
        <div className="modal-body register-body">
          <div className="row gx-3">
            <div className="col-lg-5 d-none d-lg-block d-xl-block">
              <div className="register-body-lft">
                <h3>Register</h3>
                <p>Enter your credentials to log into your account.</p>
                <img
                  src="assets/images/login-img.png"
                  className="w-100 h-100"
                  alt=""
                />
              </div>
            </div>
            <div className="col-lg-7 col-md-12">
              <div className="register-body-rgt">
                <form action="">
                  <div className="mb-4 frm-bx number">
                    <label htmlFor="">Mobile Number</label>
                    <input
                      type="number"
                      className="form-control"
                      defaultValue={7894561230}
                    />
                    <span>+91</span>
                    <a href="#" className="change">
                      Change
                    </a>
                  </div>
                  <div className="mb-4 frm-bx">
                    <label htmlFor="">Enter OTP</label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Enter OTP"
                    />
                  </div>
                  <div className="mb-4 frm-bx">
                    <label htmlFor="">Full Name</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Full Name"
                    />
                  </div>
                  <div className="mb-4 frm-bx">
                    <label htmlFor="">Email</label>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Enter Email address"
                    />
                  </div>
                  <div className="mb-4 frm-bx">
                    <label htmlFor="">Password</label>
                    <input
                      type="password"
                      className="form-control"
                      placeholder="Enter Password"
                    />
                  </div>
                  <div className="mb-4 frm-bx">
                    <label htmlFor="">Confirm Password</label>
                    <input
                      type="password"
                      className="form-control"
                      placeholder="Re-enter Password"
                    />
                  </div>
                  <div className="frm-bx">
                    <button className="org-btn w-100" type="button">
                      Sign Up
                    </button>
                  </div>
                  <p className="text-center mt-3 mb-3">OR</p>
                  <div className="social-btn">
                    <a href="#">
                      <img
                        src="assets/images/google.svg"
                        className="me-2"
                        alt=""
                      />
                      Sign in with google
                    </a>
                    <a href="#" className="facebook">
                      <img
                        src="assets/images/facebook.svg"
                        className="me-2"
                        alt=""
                      />
                      Sign in with Facebook
                    </a>
                  </div>
                  <p className="text-center">
                    Existing User?{" "}
                    <a
                      href="#"
                      data-bs-toggle="modal"
                      data-bs-target="#signin"
                    >
                      Sign In
                    </a>
                  </p>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> */}
      <RegisterPage/>
  {/* register-modal-end */}
  {/* forgot-modal-start */}
  {/* <div
    className="modal fade register-modal"
    id="forgot"
    tabIndex={-1}
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div className="modal-dialog modal-dialog-centered modal-xl">
      <div className="modal-content">
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        />
        <div className="modal-body register-body">
          <div className="row gx-3">
            <div className="col-lg-5 d-none d-lg-block d-xl-block">
              <div className="register-body-lft">
                <h3>Forgot Password</h3>
                <p>
                  Please provide your registered Mobile number.Get started into
                  Nerozon.
                </p>
                <img
                  src="assets/images/login-img.png"
                  className="w-100 h-100"
                  alt=""
                />
              </div>
            </div>
            <div className="col-lg-7 col-md-12">
              <div className="register-body-rgt">
                <form action="">
                  <div className="mb-4 frm-bx number">
                    <label htmlFor="">Mobile Number</label>
                    <input
                      type="number"
                      className="form-control"
                      defaultValue={7894561230}
                    />
                    <span>+91</span>
                    <a href="#" className="change">
                      Change
                    </a>
                  </div>
                  <div className="mb-4 frm-bx">
                    <label htmlFor="">Enter OTP</label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Enter OTP"
                    />
                  </div>
                  <div className="mb-4 frm-bx">
                    <label htmlFor="">Password</label>
                    <input
                      type="password"
                      className="form-control"
                      placeholder="Enter Password"
                    />
                  </div>
                  <div className="mb-4 frm-bx">
                    <label htmlFor="">Confirm Password</label>
                    <input
                      type="password"
                      className="form-control"
                      placeholder="Re-enter Password"
                    />
                  </div>
                  <div className="frm-bx">
                    <button className="org-btn w-100" type="button">
                      Sign In
                    </button>
                  </div>
                  <p className="text-center">
                    Existing User?{" "}
                    <a
                      href="#"
                      data-bs-toggle="modal"
                      data-bs-target="#signin"
                    >
                      Sign In
                    </a>
                  </p>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> */}
      <ForgetPasswordModel/>
  {/* forgot-modal-end */}
</>

 
  )
}
