// import React from "react";
import footerLogo from "../../assets/img/brandLogo.svg";
import FooterBg from "../../assets/img/footerBg.png";
import { Link } from "react-router-dom";
const Footer = () => {
  return (
    <footer className="Footer">
      <div className="footerContainer">
        <div className="row">
          <div className="col-lg-3">
            <div className="footerBrand">
              <img src={footerLogo} className="footerBrandLogo" />
              <p className="grayHeading">
                A new world of Online Grocery and More, shopping. 
              </p>
              <h1 className="mainHeading">
                Telephone:<span> 1800 123 95800</span>
              </h1> 
            </div>
          </div>
          <div className="col-sm">
            <div className="footerLinkCol">
              <h1 className="mainHeading">About</h1>
              <div className="footerLinks">
               <Link to="" className="grayheading">
                  Contact Us
               </Link>
               <Link to="" className="grayheading">
                  About Us
               </Link>
               <Link to="" className="grayheading">
                  Sell On Nerozon
               </Link>
              </div>
            </div>
          </div>
          <div className="col-sm">
            <div className="footerLinkCol">
              <h1 className="mainHeading">Policy</h1>
              <div className="footerLinks">
               <Link to="" className="grayheading">
                  Return Policy
               </Link>
               <Link to="" className="grayheading">
                  Privacy
               </Link>
               <Link to="" className="grayheading">
                  Terms of Use
               </Link>
              </div>
            </div>
          </div>
          <div className="col-sm">
            <div className="footerLinkCol">
              <h1 className="mainHeading">Social</h1>
              <div className="footerLinks">
               <Link to="" className="grayheading">
                  Facebook
               </Link>
               <Link to="" className="grayheading">
                  Twitter
               </Link>
               <Link to="" className="grayheading">
                  Instagram
               </Link>
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="footerLinkCol">
              <h1 className="mainHeading">Register Office</h1>
              <div className="footerLinks">
               <Link to="" className="grayheading">
                  07 Samarth Beldings,
               </Link>
               <Link to="" className="grayheading">
                  Bajarpeth Mean Road,
               </Link>
               <Link to="" className="grayheading">
                  Manchar,Maharashtra,410503, India
               </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footerCopyWriteContent">
        <h1 className="grayHeading">
          Copyright © 2024 Nerozon. All Rights Reserved
        </h1>
      </div>
      <div className="footerBgImg">
        <img src={FooterBg} className="footerBg" />
      </div>
    </footer>
  );
};

export default Footer;
