import { configureStore } from '@reduxjs/toolkit'
// import cardReducer from '../features/cart/cartSlice';
import searchReducer from "../features/searchProduct/searchProductSlice"
import userReducer from "../features/loginUser/loginUserSlice"
import loginUserReducer from "../features/loginUser/loginUserSlice"
export const store = configureStore({
  reducer:   {
    search:searchReducer,
    loginUser:loginUserReducer,
  },
})




//to add any value in reducer 
//    const dispatch = useDispatch() ;  dispatch(setLoginUser(res.data.user))

// to get any value = const loginUser = useSelector((state) => state.loginUser);