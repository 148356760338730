// import React from "react";
import { Card } from "react-bootstrap";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import item1 from "../../assets/img/recmendedItem1.png";
import SalesSliderImg from "../../assets/img/summerSliderImg.png";
import LeftArrow from "../../assets/img/diwliLeftArrow.svg";
import RightArrow from "../../assets/img/diwliRightArrow.svg";

const SummerSaleSection = () => {
  return (
    <section className="SummerSaleSection constantPadding">
      <div className="summerContainer">
        <div className="saleContainer">
          <div className="colWraper">
            <div className="col-lg-12 ">
              <Swiper
                modules={[Navigation, Pagination, Scrollbar, A11y]}
                loop={true}
                spaceBetween={0}
                slidesPerView={3}
                navigation={{
                  prevEl: `.sumSalePrv`, 
                  nextEl: `.sumSaleNext`,
                }}
                // onSlideChange={() => console.log("slide change")}
                // onSwiper={(swiper) => console.log(swiper)}
                breakpoints={{
                  100: {
                    slidesPerView: 1,
                    spaceBetween: 0,
                  },
                  650: {
                    slidesPerView: 2,
                    spaceBetween: 0,
                  },
                  1024: {
                    slidesPerView: 3,
                    spaceBetween: 0,
                  },
                }}
              >
                <SwiperSlide>
                  <div className="saleProductContainer">
                    <div className="upperCart">
                      <Card>
                        <Card.Img
                          variant="top"
                          src={item1}
                          className="itemImg"
                        />
                        <Card.Body>
                          <h1 className="mainHeading">
                            boAt Airdopes 161/163...
                          </h1>
                          <div className="cardPricing">
                            <p className="mainHeading">
                              ₹ 12,990
                              <span>₹ 15,990</span>
                            </p>
                          </div>
                        </Card.Body>
                      </Card>
                    </div>
                    <div className="upperCart">
                      <Card>
                        <Card.Img
                          variant="top"
                          src={item1}
                          className="itemImg"
                        />
                        <Card.Body>
                          <h1 className="mainHeading">
                            boAt Airdopes 161/163...
                          </h1>
                          <div className="cardPricing">
                            <p className="mainHeading">
                              ₹ 12,990
                              <span>₹ 15,990</span>
                            </p>
                          </div>
                        </Card.Body>
                      </Card>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="saleProductContainer">
                    <div className="upperCart">
                      <Card>
                        <Card.Img
                          variant="top"
                          src={item1}
                          className="itemImg"
                        />
                        <Card.Body>
                          <h1 className="mainHeading">
                            boAt Airdopes 161/163...
                          </h1>
                          <div className="cardPricing">
                            <p className="mainHeading">
                              ₹ 12,990
                              <span>₹ 15,990</span>
                            </p>
                          </div>
                        </Card.Body>
                      </Card>
                    </div>
                    <div className="upperCart">
                      <Card>
                        <Card.Img
                          variant="top"
                          src={item1}
                          className="itemImg"
                        />
                        <Card.Body>
                          <h1 className="mainHeading">
                            boAt Airdopes 161/163...
                          </h1>
                          <div className="cardPricing">
                            <p className="mainHeading">
                              ₹ 12,990
                              <span>₹ 15,990</span>
                            </p>
                          </div>
                        </Card.Body>
                      </Card>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="saleProductContainer">
                    <div className="upperCart">
                      <Card>
                        <Card.Img
                          variant="top"
                          src={item1}
                          className="itemImg"
                        />
                        <Card.Body>
                          <h1 className="mainHeading">
                            boAt Airdopes 161/163...
                          </h1>
                          <div className="cardPricing">
                            <p className="mainHeading">
                              ₹ 12,990
                              <span>₹ 15,990</span>
                            </p>
                          </div>
                        </Card.Body>
                      </Card>
                    </div>
                    <div className="upperCart">
                      <Card>
                        <Card.Img
                          variant="top"
                          src={item1}
                          className="itemImg"
                        />
                        <Card.Body>
                          <h1 className="mainHeading">
                            boAt Airdopes 161/163...
                          </h1>
                          <div className="cardPricing">
                            <p className="mainHeading">
                              ₹ 12,990
                              <span>₹ 15,990</span>
                            </p>
                          </div>
                        </Card.Body>
                      </Card>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="saleProductContainer">
                    <div className="upperCart">
                      <Card>
                        <Card.Img
                          variant="top"
                          src={item1}
                          className="itemImg"
                        />
                        <Card.Body>
                          <h1 className="mainHeading">
                            boAt Airdopes 161/163...
                          </h1>
                          <div className="cardPricing">
                            <p className="mainHeading">
                              ₹ 12,990
                              <span>₹ 15,990</span>
                            </p>
                          </div>
                        </Card.Body>
                      </Card>
                    </div>
                    <div className="upperCart">
                      <Card>
                        <Card.Img
                          variant="top"
                          src={item1}
                          className="itemImg"
                        />
                        <Card.Body>
                          <h1 className="mainHeading">
                            boAt Airdopes 161/163...
                          </h1>
                          <div className="cardPricing">
                            <p className="mainHeading">
                              ₹ 12,990
                              <span>₹ 15,990</span>
                            </p>
                          </div>
                        </Card.Body>
                      </Card>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="saleProductContainer">
                    <div className="upperCart">
                      <Card>
                        <Card.Img
                          variant="top"
                          src={item1}
                          className="itemImg"
                        />
                        <Card.Body>
                          <h1 className="mainHeading">
                            boAt Airdopes 161/163...
                          </h1>
                          <div className="cardPricing">
                            <p className="mainHeading">
                              ₹ 12,990
                              <span>₹ 15,990</span>
                            </p>
                          </div>
                        </Card.Body>
                      </Card>
                    </div>
                    <div className="upperCart">
                      <Card>
                        <Card.Img
                          variant="top"
                          src={item1}
                          className="itemImg"
                        />
                        <Card.Body>
                          <h1 className="mainHeading">
                            boAt Airdopes 161/163...
                          </h1>
                          <div className="cardPricing">
                            <p className="mainHeading">
                              ₹ 12,990
                              <span>₹ 15,990</span>
                            </p>
                          </div>
                        </Card.Body>
                      </Card>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="saleProductContainer">
                    <div className="upperCart">
                      <Card>
                        <Card.Img
                          variant="top"
                          src={item1}
                          className="itemImg"
                        />
                        <Card.Body>
                          <h1 className="mainHeading">
                            boAt Airdopes 161/163...
                          </h1>
                          <div className="cardPricing">
                            <p className="mainHeading">
                              ₹ 12,990
                              <span>₹ 15,990</span>
                            </p>
                          </div>
                        </Card.Body>
                      </Card>
                    </div>
                    <div className="upperCart">
                      <Card>
                        <Card.Img
                          variant="top"
                          src={item1}
                          className="itemImg"
                        />
                        <Card.Body>
                          <h1 className="mainHeading">
                            boAt Airdopes 161/163...
                          </h1>
                          <div className="cardPricing">
                            <p className="mainHeading">
                              ₹ 12,990
                              <span>₹ 15,990</span>
                            </p>
                          </div>
                        </Card.Body>
                      </Card>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="saleProductContainer">
                    <div className="upperCart">
                      <Card>
                        <Card.Img
                          variant="top"
                          src={item1}
                          className="itemImg"
                        />
                        <Card.Body>
                          <h1 className="mainHeading">
                            boAt Airdopes 161/163...
                          </h1>
                          <div className="cardPricing">
                            <p className="mainHeading">
                              ₹ 12,990
                              <span>₹ 15,990</span>
                            </p>
                          </div>
                        </Card.Body>
                      </Card>
                    </div>
                    <div className="upperCart">
                      <Card>
                        <Card.Img
                          variant="top"
                          src={item1}
                          className="itemImg"
                        />
                        <Card.Body>
                          <h1 className="mainHeading">
                            boAt Airdopes 161/163...
                          </h1>
                          <div className="cardPricing">
                            <p className="mainHeading">
                              ₹ 12,990
                              <span>₹ 15,990</span>
                            </p>
                          </div>
                        </Card.Body>
                      </Card>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="saleProductContainer">
                    <div className="upperCart">
                      <Card>
                        <Card.Img
                          variant="top"
                          src={item1}
                          className="itemImg"
                        />
                        <Card.Body>
                          <h1 className="mainHeading">
                            boAt Airdopes 161/163...
                          </h1>
                          <div className="cardPricing">
                            <p className="mainHeading">
                              ₹ 12,990
                              <span>₹ 15,990</span>
                            </p>
                          </div>
                        </Card.Body>
                      </Card>
                    </div>
                    <div className="upperCart">
                      <Card>
                        <Card.Img
                          variant="top"
                          src={item1}
                          className="itemImg"
                        />
                        <Card.Body>
                          <h1 className="mainHeading">
                            boAt Airdopes 161/163...
                          </h1>
                          <div className="cardPricing">
                            <p className="mainHeading">
                              ₹ 12,990
                              <span>₹ 15,990</span>
                            </p>
                          </div>
                        </Card.Body>
                      </Card>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="saleProductContainer">
                    <div className="upperCart">
                      <Card>
                        <Card.Img
                          variant="top"
                          src={item1}
                          className="itemImg"
                        />
                        <Card.Body>
                          <h1 className="mainHeading">
                            boAt Airdopes 161/163...
                          </h1>
                          <div className="cardPricing">
                            <p className="mainHeading">
                              ₹ 12,990
                              <span>₹ 15,990</span>
                            </p>
                          </div>
                        </Card.Body>
                      </Card>
                    </div>
                    <div className="upperCart">
                      <Card>
                        <Card.Img
                          variant="top"
                          src={item1}
                          className="itemImg"
                        />
                        <Card.Body>
                          <h1 className="mainHeading">
                            boAt Airdopes 161/163...
                          </h1>
                          <div className="cardPricing">
                            <p className="mainHeading">
                              ₹ 12,990
                              <span>₹ 15,990</span>
                            </p>
                          </div>
                        </Card.Body>
                      </Card>
                    </div>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>

          <div className="crouselCol">
            <div className="salesCrouselContainer">
              <div className="upperCart">
                <img src={SalesSliderImg} className="saleSliderImg" />
              </div>
              <div className="saleSliderBottom">
                <h1 className="mainHeading">Summer Sale Offer's</h1>
                <div className="holeHeaderBtn">
                  <div className="sumSalePrv">
                    <img src={LeftArrow} className="carouslArrowImg" />
                  </div>
                  <div className="sumSaleNext">
                    <img src={RightArrow} className="carouslArrowImg" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SummerSaleSection;
