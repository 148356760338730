import React from 'react'
import cuponImg from "../../assets/images/cupon-img.png"
// import cuponImg from "../../"
import offerImg from "../../assets/images/offer-icon.png" 
import shieldImg from "../../assets/images/shield.png" 
import { Link } from 'react-router-dom'
export default function Copun() {
  return (
    <>
    <div className="col-lg-3 col-md-4">
        <div className="cupon-bar">
          <div className="cupon-bar-innr">
            <h5>
              <span>
                <img src={cuponImg} alt="" className='me-2' />
                Coupon Applied: <strong>EXTRA20</strong>
              </span>
              <i className="bi bi-check-circle-fill" />
            </h5>
            <ul className="cupon-bar-innr-list">
              <li>
                <span>Product Price</span>
                <span>₹ 6,000</span>
              </li>
              <li>
                <span>Discount</span>
                <span>₹ 1,200</span>
              </li>
              <li>
                <span>Delivery Charges</span>
                <span>FREE</span>
              </li>
            </ul>
            <h6>
              <span>Amount Payable</span>
              <span>₹ 4,800</span>
            </h6>
          </div>
          <div className="cupon-bar-innr">
            <h5>
              <span>
                <img src={offerImg} alt=""  className='me-2'/>
                Offers
              </span>
            </h5>
            <h4>
              5% Unlimited Cashback on payments using HDFC Credit Card{" "}
              <Link to="#" className="org-txt fw-600">
                T&amp;C
              </Link>
            </h4>
          </div>
          <h3 className="secure-txt">
            <img src={shieldImg} className="me-2" alt="" />
            Safe &amp; Secure payments. Easy Returns.100% Authenic Products
          </h3>
        </div>
      </div>
    </>
  )
}
