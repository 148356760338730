import React from 'react'
import GroceryProduct_01 from "../../assets/images/grocery-product-01.png"
import ImgBox from "../../assets/images/box.png"

export default function GroceryProduct() {
    return (
        <>
            <div className="col-lg-9 col-md-8">
                <div className="custom-bredcump">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <a href="index.html">Home</a>
                        </li>
                        <li className="breadcrumb-item">
                            <a href="#">Clothing</a>
                        </li>
                        <li className="breadcrumb-item">
                            <a href="#">Ladies</a>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">
                            Tops &amp; T-shirts
                        </li>
                    </ol>
                    <p>
                        Showing 1-12 Results for “Ladies Top” <span>(78108 items)</span>
                    </p>
                </div>
                <div className="row">
                    <div className="col-lg-9">
                        <ul className="sort-by-list">
                            <li>
                                <i className="fal fa-sort-alt me-2" />
                                Sort By:
                            </li>
                            <li>
                                <a href="javascript:void(0);" className="rev">
                                    Relevance
                                </a>
                            </li>
                            <li>
                                <a href="javascript:void(0);">Popularity</a>
                            </li>
                            <li>
                                <a href="javascript:void(0);">Price - High to Low</a>
                            </li>
                            <li>
                                <a href="javascript:void(0);">Price - Low to High</a>
                            </li>
                            <li>
                                <a href="javascript:void(0);">Newest First</a>
                            </li>
                        </ul>
                    </div>
                    <div className="col-lg-3 text-end">
                        <a href="javascript:void(0);">
                            <img src={ImgBox} alt="" />
                        </a>
                    </div>
                </div>
                <div className="grocery-prdct-innr">
                    <div className="row">
                        <div className="col-lg-3 col-md-6 col-sm-6">
                            <div className="grocery-prdct-innr-box">
                                <div className="grocery-prdct-innr-box-img">
                                    <img src={GroceryProduct_01} alt="" />
                                </div>
                                <h6>Masoor Dal (Split)</h6>
                                <p>
                                    ₹95 <del>₹175</del> <em>44% off</em>
                                </p>
                                <div className="grocery-prdct-btn">
                                    <select name="" id="">
                                        <option value="">1 kg</option>
                                        <option value="">2 kg</option>
                                        <option value="">3 kg</option>
                                    </select>
                                    <a href="javascript:void(0);">Add Item</a>
                                </div>
                                <span className="add">Add</span>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6">
                            <div className="grocery-prdct-innr-box">
                                <div className="grocery-prdct-innr-box-img">
                                    <img src={GroceryProduct_01} alt="" />
                                </div>
                                <h6>Masoor Dal (Split)</h6>
                                <p>
                                    ₹95 <del>₹175</del> <em>44% off</em>
                                </p>
                                <div className="grocery-prdct-btn">
                                    <select name="" id="">
                                        <option value="">1 kg</option>
                                        <option value="">2 kg</option>
                                        <option value="">3 kg</option>
                                    </select>
                                    <a href="javascript:void(0);">Add Item</a>
                                </div>
                                <span className="add">Add</span>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6">
                            <div className="grocery-prdct-innr-box">
                                <div className="grocery-prdct-innr-box-img">
                                    <img src={GroceryProduct_01} alt="" />
                                </div>
                                <h6>Masoor Dal (Split)</h6>
                                <p>
                                    ₹95 <del>₹175</del> <em>44% off</em>
                                </p>
                                <div className="grocery-prdct-btn">
                                    <select name="" id="">
                                        <option value="">1 kg</option>
                                        <option value="">2 kg</option>
                                        <option value="">3 kg</option>
                                    </select>
                                    <a href="javascript:void(0);">Add Item</a>
                                </div>
                                <span className="add">Add</span>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6">
                            <div className="grocery-prdct-innr-box">
                                <div className="grocery-prdct-innr-box-img">
                                    <img src={GroceryProduct_01} alt="" />
                                </div>
                                <h6>Masoor Dal (Split)</h6>
                                <p>
                                    ₹95 <del>₹175</del> <em>44% off</em>
                                </p>
                                <div className="grocery-prdct-btn">
                                    <select name="" id="">
                                        <option value="">1 kg</option>
                                        <option value="">2 kg</option>
                                        <option value="">3 kg</option>
                                    </select>
                                    <a href="javascript:void(0);">Add Item</a>
                                </div>
                                <span className="add">Add</span>
                            </div>
                        </div>
                        <hr />
                        <div className="col-lg-3 col-md-6 col-sm-6">
                            <div className="grocery-prdct-innr-box">
                                <div className="grocery-prdct-innr-box-img">
                                    <img src={GroceryProduct_01} alt="" />
                                </div>
                                <h6>Masoor Dal (Split)</h6>
                                <p>
                                    ₹95 <del>₹175</del> <em>44% off</em>
                                </p>
                                <div className="grocery-prdct-btn">
                                    <select name="" id="">
                                        <option value="">1 kg</option>
                                        <option value="">2 kg</option>
                                        <option value="">3 kg</option>
                                    </select>
                                    <a href="javascript:void(0);">Add Item</a>
                                </div>
                                <span className="add">Add</span>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6">
                            <div className="grocery-prdct-innr-box">
                                <div className="grocery-prdct-innr-box-img">
                                    <img src={GroceryProduct_01} alt="" />
                                </div>
                                <h6>Masoor Dal (Split)</h6>
                                <p>
                                    ₹95 <del>₹175</del> <em>44% off</em>
                                </p>
                                <div className="grocery-prdct-btn">
                                    <select name="" id="">
                                        <option value="">1 kg</option>
                                        <option value="">2 kg</option>
                                        <option value="">3 kg</option>
                                    </select>
                                    <a href="javascript:void(0);">Add Item</a>
                                </div>
                                <span className="add">Add</span>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6">
                            <div className="grocery-prdct-innr-box">
                                <div className="grocery-prdct-innr-box-img">
                                    <img src={GroceryProduct_01} alt="" />
                                </div>
                                <h6>Masoor Dal (Split)</h6>
                                <p>
                                    ₹95 <del>₹175</del> <em>44% off</em>
                                </p>
                                <div className="grocery-prdct-btn">
                                    <select name="" id="">
                                        <option value="">1 kg</option>
                                        <option value="">2 kg</option>
                                        <option value="">3 kg</option>
                                    </select>
                                    <a href="javascript:void(0);">Add Item</a>
                                </div>
                                <span className="add">Add</span>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6">
                            <div className="grocery-prdct-innr-box">
                                <div className="grocery-prdct-innr-box-img">
                                    <img src={GroceryProduct_01} alt="" />
                                </div>
                                <h6>Masoor Dal (Split)</h6>
                                <p>
                                    ₹95 <del>₹175</del> <em>44% off</em>
                                </p>
                                <div className="grocery-prdct-btn">
                                    <select name="" id="">
                                        <option value="">1 kg</option>
                                        <option value="">2 kg</option>
                                        <option value="">3 kg</option>
                                    </select>
                                    <a href="javascript:void(0);">Add Item</a>
                                </div>
                                <span className="add">Add</span>
                            </div>
                        </div>
                        <hr />
                        <div className="col-lg-3 col-md-6 col-sm-6">
                            <div className="grocery-prdct-innr-box">
                                <div className="grocery-prdct-innr-box-img">
                                    <img src={GroceryProduct_01} alt="" />
                                </div>
                                <h6>Masoor Dal (Split)</h6>
                                <p>
                                    ₹95 <del>₹175</del> <em>44% off</em>
                                </p>
                                <div className="grocery-prdct-btn">
                                    <select name="" id="">
                                        <option value="">1 kg</option>
                                        <option value="">2 kg</option>
                                        <option value="">3 kg</option>
                                    </select>
                                    <a href="javascript:void(0);">Add Item</a>
                                </div>
                                <span className="add">Add</span>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6">
                            <div className="grocery-prdct-innr-box">
                                <div className="grocery-prdct-innr-box-img">
                                    <img src={GroceryProduct_01} alt="" />
                                </div>
                                <h6>Masoor Dal (Split)</h6>
                                <p>
                                    ₹95 <del>₹175</del> <em>44% off</em>
                                </p>
                                <div className="grocery-prdct-btn">
                                    <select name="" id="">
                                        <option value="">1 kg</option>
                                        <option value="">2 kg</option>
                                        <option value="">3 kg</option>
                                    </select>
                                    <a href="javascript:void(0);">Add Item</a>
                                </div>
                                <span className="add">Add</span>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6">
                            <div className="grocery-prdct-innr-box">
                                <div className="grocery-prdct-innr-box-img">
                                    <img src={GroceryProduct_01} alt="" />
                                </div>
                                <h6>Masoor Dal (Split)</h6>
                                <p>
                                    ₹95 <del>₹175</del> <em>44% off</em>
                                </p>
                                <div className="grocery-prdct-btn">
                                    <select name="" id="">
                                        <option value="">1 kg</option>
                                        <option value="">2 kg</option>
                                        <option value="">3 kg</option>
                                    </select>
                                    <a href="javascript:void(0);">Add Item</a>
                                </div>
                                <span className="add">Add</span>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6">
                            <div className="grocery-prdct-innr-box">
                                <div className="grocery-prdct-innr-box-img">
                                    <img src={GroceryProduct_01} alt="" />
                                </div>
                                <h6>Masoor Dal (Split)</h6>
                                <p>
                                    ₹95 <del>₹175</del> <em>44% off</em>
                                </p>
                                <div className="grocery-prdct-btn">
                                    <select name="" id="">
                                        <option value="">1 kg</option>
                                        <option value="">2 kg</option>
                                        <option value="">3 kg</option>
                                    </select>
                                    <a href="javascript:void(0);">Add Item</a>
                                </div>
                                <span className="add">Add</span>
                            </div>
                        </div>
                        <hr />
                        <div className="col-lg-3 col-md-6 col-sm-6">
                            <div className="grocery-prdct-innr-box">
                                <div className="grocery-prdct-innr-box-img">
                                    <img src={GroceryProduct_01} alt="" />
                                </div>
                                <h6>Masoor Dal (Split)</h6>
                                <p>
                                    ₹95 <del>₹175</del> <em>44% off</em>
                                </p>
                                <div className="grocery-prdct-btn">
                                    <select name="" id="">
                                        <option value="">1 kg</option>
                                        <option value="">2 kg</option>
                                        <option value="">3 kg</option>
                                    </select>
                                    <a href="javascript:void(0);">Add Item</a>
                                </div>
                                <span className="add">Add</span>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6">
                            <div className="grocery-prdct-innr-box">
                                <div className="grocery-prdct-innr-box-img">
                                    <img src={GroceryProduct_01} alt="" />
                                </div>
                                <h6>Masoor Dal (Split)</h6>
                                <p>
                                    ₹95 <del>₹175</del> <em>44% off</em>
                                </p>
                                <div className="grocery-prdct-btn">
                                    <select name="" id="">
                                        <option value="">1 kg</option>
                                        <option value="">2 kg</option>
                                        <option value="">3 kg</option>
                                    </select>
                                    <a href="javascript:void(0);">Add Item</a>
                                </div>
                                <span className="add">Add</span>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6">
                            <div className="grocery-prdct-innr-box">
                                <div className="grocery-prdct-innr-box-img">
                                    <img src={GroceryProduct_01} alt="" />
                                </div>
                                <h6>Masoor Dal (Split)</h6>
                                <p>
                                    ₹95 <del>₹175</del> <em>44% off</em>
                                </p>
                                <div className="grocery-prdct-btn">
                                    <select name="" id="">
                                        <option value="">1 kg</option>
                                        <option value="">2 kg</option>
                                        <option value="">3 kg</option>
                                    </select>
                                    <a href="javascript:void(0);">Add Item</a>
                                </div>
                                <span className="add">Add</span>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6">
                            <div className="grocery-prdct-innr-box">
                                <div className="grocery-prdct-innr-box-img">
                                    <img src={GroceryProduct_01} alt="" />
                                </div>
                                <h6>Masoor Dal (Split)</h6>
                                <p>
                                    ₹95 <del>₹175</del> <em>44% off</em>
                                </p>
                                <div className="grocery-prdct-btn">
                                    <select name="" id="">
                                        <option value="">1 kg</option>
                                        <option value="">2 kg</option>
                                        <option value="">3 kg</option>
                                    </select>
                                    <a href="javascript:void(0);">Add Item</a>
                                </div>
                                <span className="add">Add</span>
                            </div>
                        </div>
                        <hr />
                        <div className="col-lg-3 col-md-6 col-sm-6">
                            <div className="grocery-prdct-innr-box">
                                <div className="grocery-prdct-innr-box-img">
                                    <img src={GroceryProduct_01} alt="" />
                                </div>
                                <h6>Masoor Dal (Split)</h6>
                                <p>
                                    ₹95 <del>₹175</del> <em>44% off</em>
                                </p>
                                <div className="grocery-prdct-btn">
                                    <select name="" id="">
                                        <option value="">1 kg</option>
                                        <option value="">2 kg</option>
                                        <option value="">3 kg</option>
                                    </select>
                                    <a href="javascript:void(0);">Add Item</a>
                                </div>
                                <span className="add">Add</span>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6">
                            <div className="grocery-prdct-innr-box">
                                <div className="grocery-prdct-innr-box-img">
                                    <img src={GroceryProduct_01} alt="" />
                                </div>
                                <h6>Masoor Dal (Split)</h6>
                                <p>
                                    ₹95 <del>₹175</del> <em>44% off</em>
                                </p>
                                <div className="grocery-prdct-btn">
                                    <select name="" id="">
                                        <option value="">1 kg</option>
                                        <option value="">2 kg</option>
                                        <option value="">3 kg</option>
                                    </select>
                                    <a href="javascript:void(0);">Add Item</a>
                                </div>
                                <span className="add">Add</span>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6">
                            <div className="grocery-prdct-innr-box">
                                <div className="grocery-prdct-innr-box-img">
                                    <img src={GroceryProduct_01} alt="" />
                                </div>
                                <h6>Masoor Dal (Split)</h6>
                                <p>
                                    ₹95 <del>₹175</del> <em>44% off</em>
                                </p>
                                <div className="grocery-prdct-btn">
                                    <select name="" id="">
                                        <option value="">1 kg</option>
                                        <option value="">2 kg</option>
                                        <option value="">3 kg</option>
                                    </select>
                                    <a href="javascript:void(0);">Add Item</a>
                                </div>
                                <span className="add">Add</span>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6">
                            <div className="grocery-prdct-innr-box">
                                <div className="grocery-prdct-innr-box-img">
                                    <img src={GroceryProduct_01} alt="" />
                                </div>
                                <h6>Masoor Dal (Split)</h6>
                                <p>
                                    ₹95 <del>₹175</del> <em>44% off</em>
                                </p>
                                <div className="grocery-prdct-btn">
                                    <select name="" id="">
                                        <option value="">1 kg</option>
                                        <option value="">2 kg</option>
                                        <option value="">3 kg</option>
                                    </select>
                                    <a href="javascript:void(0);">Add Item</a>
                                </div>
                                <span className="add">Add</span>
                            </div>
                        </div>
                        <hr />
                        <div className="col-lg-3 col-md-6 col-sm-6">
                            <div className="grocery-prdct-innr-box">
                                <div className="grocery-prdct-innr-box-img">
                                    <img src={GroceryProduct_01} alt="" />
                                </div>
                                <h6>Masoor Dal (Split)</h6>
                                <p>
                                    ₹95 <del>₹175</del> <em>44% off</em>
                                </p>
                                <div className="grocery-prdct-btn">
                                    <select name="" id="">
                                        <option value="">1 kg</option>
                                        <option value="">2 kg</option>
                                        <option value="">3 kg</option>
                                    </select>
                                    <a href="javascript:void(0);">Add Item</a>
                                </div>
                                <span className="add">Add</span>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6">
                            <div className="grocery-prdct-innr-box">
                                <div className="grocery-prdct-innr-box-img">
                                    <img src={GroceryProduct_01} alt="" />
                                </div>
                                <h6>Masoor Dal (Split)</h6>
                                <p>
                                    ₹95 <del>₹175</del> <em>44% off</em>
                                </p>
                                <div className="grocery-prdct-btn">
                                    <select name="" id="">
                                        <option value="">1 kg</option>
                                        <option value="">2 kg</option>
                                        <option value="">3 kg</option>
                                    </select>
                                    <a href="javascript:void(0);">Add Item</a>
                                </div>
                                <span className="add">Add</span>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6">
                            <div className="grocery-prdct-innr-box">
                                <div className="grocery-prdct-innr-box-img">
                                    <img src={GroceryProduct_01} alt="" />
                                </div>
                                <h6>Masoor Dal (Split)</h6>
                                <p>
                                    ₹95 <del>₹175</del> <em>44% off</em>
                                </p>
                                <div className="grocery-prdct-btn">
                                    <select name="" id="">
                                        <option value="">1 kg</option>
                                        <option value="">2 kg</option>
                                        <option value="">3 kg</option>
                                    </select>
                                    <a href="javascript:void(0);">Add Item</a>
                                </div>
                                <span className="add">Add</span>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6">
                            <div className="grocery-prdct-innr-box">
                                <div className="grocery-prdct-innr-box-img">
                                    <img src={GroceryProduct_01} alt="" />
                                </div>
                                <h6>Masoor Dal (Split)</h6>
                                <p>
                                    ₹95 <del>₹175</del> <em>44% off</em>
                                </p>
                                <div className="grocery-prdct-btn">
                                    <select name="" id="">
                                        <option value="">1 kg</option>
                                        <option value="">2 kg</option>
                                        <option value="">3 kg</option>
                                    </select>
                                    <a href="javascript:void(0);">Add Item</a>
                                </div>
                                <span className="add">Add</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="custom-paginate">
                    <ul className="pagination">
                        <li className="page-item">
                            <a className="page-link" href="javascript:void(0);">
                                Previous
                            </a>
                        </li>
                        <li className="page-item">
                            <a className="page-link active" href="javascript:void(0);">
                                1
                            </a>
                        </li>
                        <li className="page-item">
                            <a className="page-link" href="javascript:void(0);">
                                2
                            </a>
                        </li>
                        <li className="page-item">
                            <a className="page-link" href="javascript:void(0);">
                                3
                            </a>
                        </li>
                        <li className="page-item">
                            <a className="page-link" href="javascript:void(0);">
                                4
                            </a>
                        </li>
                        <li className="page-item">
                            <a className="page-link" href="javascript:void(0);">
                                5
                            </a>
                        </li>
                        <li className="page-item">
                            <a className="page-link" href="javascript:void(0);">
                                6
                            </a>
                        </li>
                        <li className="page-item">
                            <a className="page-link blank" href="javascript:void(0);">
                                ....
                            </a>
                        </li>
                        <li className="page-item">
                            <a className="page-link" href="javascript:void(0);">
                                23
                            </a>
                        </li>
                        <li className="page-item">
                            <a className="page-link" href="javascript:void(0);">
                                Next
                            </a>
                        </li>
                    </ul>
                </div>
            </div>

        </>
    )
}
