import React from 'react'
// import SliderImg from "../../assets/images/slider-img-01.png"
import SliderImg from "../../assets/images/slider-img-01.png"
import ThumbImg from "../../assets/images/thumb-01.png"
import colorImg from "../../assets/images/color-img.png"
import aboutImg from "../../assets/images/about-img.png"
// import aboutImg from "../../assets/images/about-img.png"
import backIconImg from "../../assets/images/back-icon.svg"
// import ClothingSlider from './ClothingSlider'
import ClothingSlider from '../ProductDetails/ProductDetailsSlider'
import { Link } from "react-router-dom"
import VerticalSlider from './VerticalSlider'
export default function MobileDetails() {
  return (
    <>
    <section className="product-details tp-space">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="custom-bredcump">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="#">Clothing</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="#">Ladies</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    United Colors of Benetton
                  </li>
                </ol>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="product-details-innr">
                <div className="row">
                  <div className="col-lg-4 ">
                    <VerticalSlider />
                    {/* <ClothingSlider /> */}
                    <div className="product-detail-btn">
                      <Link
                        to="javascript:void(0);"
                        className="org-btn-02 w-100 text-center"
                      >
                        <i className="fal fa-cart-plus me-2" />
                        Add to Cart
                      </Link>
                      <Link
                        to="javascript:void(0);"
                        className="org-btn-02 w-100 text-center"
                      >
                        Buy Now
                      </Link>
                    </div>
                  </div>
                  <div className="col-lg-8">
                    <div className="product-details-innr-right">
                      <div className="product-details-innr-right-title">
                        <div className="">
                          <h6>United Colors of Benetton</h6>
                          <h5>Men Sea Green Solid Round Neck T-shirt</h5>
                          <p className="ratings">
                            <span>
                              4.9 <i className="bi bi-star-fill" />
                            </span>
                            (1,512)
                          </p>
                        </div>
                        <div>
                          <Link to="javascript:void(0);" className="share-btn me-2">
                            {/* <i className="far fa--alt me-2" /> */}
                            <i className="bi bi-share me-2"></i>
                            Share
                          </Link>
                          <Link to="javascript:void(0);" className="link-btn">
                            {/* <i className="far fa-heart" /> */}
                            <i className="bi bi-suit-heart"></i>
                          </Link>
                        </div>
                      </div>
                      <p className="dtl-price">
                        <span>₹ 78,000</span>
                        <del>₹ 85,000</del> <em>8% Off</em>
                      </p>
                      <div className="product-highlights">
                        <div className="product-highlights-lft">
                          <h6>Color:</h6>
                        </div>
                        <div className="product-color-bx">
                          <img
                            src={colorImg}
                            className="selected"
                            alt=""
                          />
                          <img src={colorImg} alt="" />
                          <img src={colorImg} alt="" />
                          <img src={colorImg} alt="" />
                        </div>
                      </div>
                      <div className="product-highlights">
                        <div className="product-highlights-lft">
                          <h6>Size</h6>
                        </div>
                        <div className="product-size-bx">
                          <Link
                            to="javascript:void(0);"
                            className="size-btn selected"
                          >
                            <span>S</span> <em>4 left</em>
                          </Link>
                          <Link to="javascript:void(0);" className="size-btn">
                            <span>m</span> <em>1 left</em>
                          </Link>
                          <Link to="javascript:void(0);" className="size-btn">
                            <span>l</span> <em>1 left</em>
                          </Link>
                          <Link to="javascript:void(0);" className="size-btn">
                            <span>xl</span> <em>1 left</em>
                          </Link>
                          <Link to="javascript:void(0);" className="org-btn-02">
                            Next
                          </Link>
                        </div>
                      </div>
                      <div className="product-highlights">
                        <div className="product-highlights-lft">
                          <h6>Delivery:</h6>
                        </div>
                        <div className="product-code">
                          <form action="">
                            <input
                              type="number"
                              className="form-control"
                              placeholder="Pin Code"
                            />
                            <button className="org-btn-02" type="button">
                              Check
                            </button>
                          </form>
                          <small>Usually deliver in 3-4 days.</small>
                        </div>
                      </div>
                      <div className="product-highlights">
                        <div className="product-highlights-lft">
                          <h6>Product Highlights:</h6>
                        </div>
                        <div className="product-txt">
                          <p>Striped</p>
                          <p>Summer Color</p>
                          <p>Full Sleeves</p>
                          <p>Round Neck Fancy T-shirt</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="custom-tab">
                  <ul className="nav">
                    <li className="tab-item">
                      <Link
                        to="javascript:void(0);"
                        className="tab-link active"
                        data-bs-toggle="tab"
                        data-bs-target="#tab-01"
                        role="tab"
                      >
                        Description
                      </Link>
                    </li>
                    <li className="tab-item">
                      <Link
                        to="javascript:void(0);"
                        className="tab-link"
                        data-bs-toggle="tab"
                        data-bs-target="#tab-02"
                        role="tab"
                      >
                        Specifications
                      </Link>
                    </li>
                    <li className="tab-item">
                      <Link
                        to="javascript:void(0);"
                        className="tab-link"
                        data-bs-toggle="tab"
                        data-bs-target="#tab-03"
                        role="tab"
                      >
                        More Info
                      </Link>
                    </li>
                  </ul>
                  <div className="tab-content">
                    <div
                      className="tab-pane fade show active"
                      id="tab-01"
                      role="tabpanel"
                    >
                      <div className="custom-tab-about">
                        <div className="product-txt">
                          <h4 className="title">About Product:</h4>
                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                            Sed interdum eu volutpat pulvinar fermentum leo sem
                            sagittis quis. Sit sit risus mi consectetur suspendisse
                            in. Quam vitae integer nisl ultrices fermentum malesuada
                            non in cursus. Quam integer non congue tristique orci.
                            Odio nisi, duis ut morbi tempor. Et tempus, lorem sit at
                            enim aliquet. Sed neque mattis magna tellus risus,
                            dignissim venenatis, pellentesque. Ut vel magna ut at
                            volutpat, venenatis, tortor scelerisque. In et velit
                            vestibulum accumsan non viverra tempus amet, viverra.
                          </p>
                        </div>
                        <div>
                          <img src={aboutImg} alt="" />
                        </div>
                      </div>
                      <div className="product-txt">
                        <h4 className="title">Features:</h4>
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing elit
                        </p>
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing elit
                        </p>
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing elit
                        </p>
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing elit
                        </p>
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing elit
                        </p>
                      </div>
                    </div>
                    <div className="tab-pane fade" id="tab-02" role="tabpanel">
                      <div className="product-txt">
                        <p>Striped</p>
                        <p>Summer Color</p>
                        <p>Full Sleeves</p>
                        <p>Round Neck Fancy T-shirt</p>
                        <p>Round Neck Fancy T-shirt</p>
                        <p>Round Neck Fancy T-shirt</p>
                      </div>
                      <div className="specifications-table">
                        <h4 className="title">General</h4>
                        <table className="table table-borderless">
                          <tbody>
                            <tr>
                              <td>Fabric:</td>
                              <th>Cotton</th>
                              <td>Occasion:</td>
                              <th>Casual</th>
                            </tr>
                            <tr>
                              <td>Fit:</td>
                              <th>Regular Fit</th>
                              <td>Pattern:</td>
                              <th>Solid</th>
                            </tr>
                            <tr>
                              <td>Length:</td>
                              <th>Regular</th>
                              <td>Print or Pattern Type:</td>
                              <th>Striped</th>
                            </tr>
                            <tr>
                              <td>Main Trend:</td>
                              <th>New Basics</th>
                              <td>Sleeve Length:</td>
                              <th>Long Sleeves</th>
                            </tr>
                            <tr>
                              <td>Multiple Set:</td>
                              <th>Single</th>
                              <td>Sleeve Styling:</td>
                              <th>Regular Sleeves</th>
                            </tr>
                            <tr>
                              <td>Neck:</td>
                              <th>Round Neck</th>
                              <td>Wash Care:</td>
                              <th>Machine Wash</th>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="tab-pane fade" id="tab-03" role="tabpanel">
                      ...
                    </div>
                  </div>
                </div>
              </div>
              <div className="policy-bar">
                <div className="row gx-1">
                  <div className="col-lg-6">
                    <div className="policy-bar-lft">
                      <p>
                        <img src={backIconImg} alt="" />
                        <span>Return Policy: </span>
                        30 Days Return
                      </p>
                      <Link
                        to="javascript:void(0);"
                        className="org-txt fz-16 fw-600"
                      >
                        More
                        {/* <i className="far fa-exclamation-circle ms-2" /> */}
                        <i className="bi bi-exclamation-circle ms-2"></i>
                      </Link>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="policy-bar-lft border-0 pe-0">
                      <p>
                        {" "}
                        <span>Return Policy: </span> 30 Days Return{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="rating-card">
                <h4 className="title">Ratings &amp; Reviews</h4>
                <h6>
                  <span>
                    4.9 <i class="bi bi-star-fill ms-2"></i>
                  </span>
                </h6>
                <p>657 Users have rated this product.</p>
                <div className="rating-bar">
                  <span>
                    5 <i className="bi bi-star-fill" />
                  </span>
                  <div className="progress">
                    <div
                      className="progress-bar grn"
                      role="progressbar"
                      style={{ width: "90%" }}
                    />
                  </div>
                  <span>256</span>
                </div>
                <div className="rating-bar">
                  <span>
                    4 <i className="bi bi-star-fill" />
                  </span>
                  <div className="progress">
                    <div
                      className="progress-bar lgt-grn"
                      role="progressbar"
                      style={{ width: "70%" }}
                    />
                  </div>
                  <span>220</span>
                </div>
                <div className="rating-bar">
                  <span>
                    3 <i className="bi bi-star-fill" />
                  </span>
                  <div className="progress">
                    <div
                      className="progress-bar yllw"
                      role="progressbar"
                      style={{ width: "55%" }}
                    />
                  </div>
                  <span>110</span>
                </div>
                <div className="rating-bar">
                  <span>
                    2 <i className="bi bi-star-fill" />
                  </span>
                  <div className="progress">
                    <div
                      className="progress-bar org"
                      role="progressbar"
                      style={{ width: "40%" }}
                    />
                  </div>
                  <span>47</span>
                </div>
                <div className="rating-bar">
                  <span>
                    1 <i className="bi bi-star-fill" />
                  </span>
                  <div className="progress">
                    <div
                      className="progress-bar rd"
                      role="progressbar"
                      style={{ width: "25%" }}
                      aria-valuenow={25}
                      aria-valuemin={0}
                      aria-valuemax={100}
                    />
                  </div>
                  <span>15</span>
                </div>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="product-review">
                <p className="rating-txt">
                  <span className="rating">
                    {" "}
                    <em>
                      4.9 <i className="bi bi-star-fill" />
                    </em>{" "}
                    Nice Product
                  </span>
                  <span>Rithika Minna Jan 23, 2022</span>
                </p>
                <p className="desc-txt">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tempor
                  sit sed ultricies sit ultricies sagittis nam vestibulum malesuada.
                  Amet fusce in vitae arcu, elit. Tellus amet congue sit ac sed.
                  Eget lacinia massa tristique vitae enim amet, at ultrices id...{" "}
                  <Link to="javascript:void(0)'">Read More</Link>
                </p>
                <img src={ThumbImg} alt="" />
                <img src={ThumbImg} alt="" />
              </div>
              <div className="product-review">
                <p className="rating-txt">
                  <span className="rating">
                    {" "}
                    <em>
                      4.9 <i className="bi bi-star-fill" />
                    </em>{" "}
                    Nice Product
                  </span>
                  <span>Rithika Minna Jan 23, 2022</span>
                </p>
                <p className="desc-txt">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tempor
                  sit sed ultricies sit ultricies sagittis nam vestibulum malesuada.
                  Amet fusce in vitae arcu, elit. Tellus amet congue sit ac sed.
                  Eget lacinia massa tristique vitae enim amet, at ultrices id...{" "}
                  <Link to="javascript:void(0)'">Read More</Link>
                </p>
              </div>
              <div className="product-review">
                <Link to="javascript:void(0);">View All 540 Reviews &gt;</Link>
              </div>
            </div>
          </div>
        </div>
      </section>
     </>
  )
}
