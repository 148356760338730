import React, {   useEffect, useState } from "react";
// import QuicklySection from "../components/QuicklySection/QuicklySection";
import HeroSection from "../components/HeroSection/HeroSection";
import DelivarySection from "../components/DelivarySection/DelivarySection";
import BeautySection from "../components/BeautySection/BeautySection";
// import PaanSection from "./components/PaanSection";
import SpecialUperSection from "../components/SpecialUperSection/SpecialUperSection";
// import DryFriutSection from "../components/DryFriutSection/DryFriutSection";
// import BeautyHygiene from "../components/Home/BeautyHygiene";
import SelectionSection from "../components/Home/SelectionSection";
import MensSection from "../components/MensSection/MensSectionHello";
import SalesSection from "../components/Home/SalesSection";
import SummerSaleSection from "../components/Home/SummerSaleSection";
// import SayDraySection from "./components/SayDraySection";
import AppDownloadSection from "../components/AppDownloadSection/AppDownloadSection";
// import Dryfruit from "../assets/img/dryfruit.png";
// import PaanImg from "../assets/img/paanImg.png";
import Dryfruit3 from "../assets/img/dryfriut3.png";
import HoleSaleSection1 from "../components/HoleSaleSection1/HoleSaleSection1";
// const HoleSaleSection1 = lazy(() =>
//   import("../components/HoleSaleSection1/HoleSaleSection1")
// );

import BeautyHygiene from "../components/Home/BeautyHygiene";
import DryFriutSection from "../components/DryFriutSection/DryFriutSection";
// import { axiosInstance } from "../Config/axiosConfig";
import {
  axiosInstance,
  base_url,
  handleError,
  // imgBaseUrl,
} from "../Config/axiosConfig";
import SwiperSlider from "../components/ShimmerUi/SwiperSlider";
// import HeroSimer from "../components/ShimmerUi/HeroSimmer";
import { useLocation } from "react-router-dom";
import '../index.css';
import "../resposive.css"

export default function HomePage() {
  const { pathname } = useLocation();
  const [dryfruitSection, setDryfruitSection] = useState([]);
  const [secondCategory, setSecondCategory] = useState([]);
  const [thirdCategory, setThirdCategory] = useState([]);
  const [fourthCategory, setFourthCategory] = useState([]);
  const [paanSection, setPaanSection] = useState([]);
  const [fifthCategory, setFifthCategory] = useState([]);
  const [nineCategory, setNineCategory] = useState([]);

  // useEffect(() => {
  //   // dry fruit
  //   (async () => {
  //     try {
  //       let res = await axiosInstance.get(`${base_url}/v2/home/second_slider`);
  //       setDryfruitSection(res?.data?.data);
  //     } catch (error) {
  //       let errorMsg = handleError(error);
  //       console.log(errorMsg);
  //     }
  //   })();

  //   (async () => {
  //     try {
  //       let res = await axiosInstance.get(
  //         `${base_url}/v2/product/home_second_cat`
  //       );
  //       // console.log(res.data);
  //       setSecondCategory(res?.data?.data);
  //     } catch (error) {
  //       let errorMsg = handleError(error);
  //       console.log(errorMsg);
  //     }
  //   })();
  //   (async () => {
  //     try {
  //       let res = await axiosInstance.get(
  //         `${base_url}/v2/product/home_fouth_cat`
  //       );
  //       // console.log(res.data);
  //       setThirdCategory(res?.data?.data);
  //     } catch (error) {
  //       let errorMsg = handleError(error);
  //       console.log(errorMsg);
  //     }
  //   })();
  //   (async () => {
  //     try {
  //       let res = await axiosInstance.get(
  //         `${base_url}/v2/product/home_fouth_cat`
  //       );
  //       // console.log(res.data);
  //       setFourthCategory(res?.data?.data);
  //     } catch (error) {
  //       let errorMsg = handleError(error);
  //       console.log(errorMsg);
  //     }
  //   })();
  //   (async () => {
  //     try {
  //       let res = await axiosInstance.get(`${base_url}/v2/home/third_slider`);
  //       console.log(res.data);
  //       setPaanSection(res?.data?.data);
  //     } catch (error) {
  //       let errorMsg = handleError(error);
  //       console.log(errorMsg);
  //     }
  //   })();
  //   (async () => {
  //     try {
  //       let res = await axiosInstance.get(
  //         `${base_url}/v2/product/home_nine_cat`
  //       );
  //       // console.log(res.data);
  //       setNineCategory(res?.data?.data);
  //     } catch (error) {
  //       let errorMsg = handleError(error);
  //       console.log(errorMsg);
  //     }
  //   })();
  // }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchData = async (url, setter) => {
      try {
        let res = await axiosInstance.get(url);
        setter(res?.data?.data);
      } catch (error) {
        let errorMsg = handleError(error);
        console.log(errorMsg);
      }
    };
    fetchData(`${base_url}/v2/home/second_slider`, setDryfruitSection);
    fetchData(`${base_url}/v2/product/home_second_cat`, setSecondCategory);
    fetchData(`${base_url}/v2/product/home_third_cat`, setThirdCategory);
    fetchData(`${base_url}/v2/product/home_fouth_cat`, setFourthCategory);
    fetchData(`${base_url}/v2/product/home_fifth_cat`, setFifthCategory);
    fetchData(`${base_url}/v2/home/third_slider`, setPaanSection);
    fetchData(`${base_url}/v2/product/home_nine_cat`, setNineCategory);
  }, [pathname]);


  return (
    <>
      {/* <QuicklySection /> */}
      <HeroSection />

      <DelivarySection />
      <BeautySection />
      <DryFriutSection apiData={dryfruitSection} />
      <SpecialUperSection duration={2} />
      {secondCategory.length === 0 ? (
        <SwiperSlider />
      ) : (
        <HoleSaleSection1
          id="1"
          heading="Hot Sale Offer's"
          apiData={secondCategory}
        />
      )}

      {thirdCategory?.length === 0 ? (
        <SwiperSlider />
      ) : (
        <HoleSaleSection1
          id="2"
          heading="Hot Sale Offer's"
          apiData={thirdCategory}
        />
      )}
      <BeautyHygiene apiData={fourthCategory} />
      <DryFriutSection apiData={paanSection} />
      {fifthCategory?.length === 0 ? (
        <SwiperSlider />
      ) : (
        <HoleSaleSection1
          id="3"
          heading="Woman Fashion Offer’s"
          apiData={fifthCategory}
        />
      )}
      <SelectionSection />
      {nineCategory?.length === 0 ? (
        <SwiperSlider />
      ) : (
        <HoleSaleSection1
          id="4"
          heading="Hot Sale Offer's"
          apiData={nineCategory}
        />
      )}
      <MensSection id="5" />
      <SalesSection />
      <SummerSaleSection />
      <DryFriutSection image={Dryfruit3} />
      <AppDownloadSection />
    </>
  );
 
  /* <Header />
      <QuicklySection />
      <HeroSection />
      <DelivarySection />
      <BeautySection />
      <DryFriutSection image={PaanImg} />
      <SpecialUperSection  duration={2}/> 
      <HoleSaleSection1 id="1" heading="Hot Sale Offer's" />
      <HoleSaleSection1 id="2" heading="Hot Sale Offer's" />
      <BeautyHygiene />
      <DryFriutSection image={Dryfruit} />
      <HoleSaleSection1 id="3" heading="Woman Fashion Offer’s" />
      <SelectionSection />
      <HoleSaleSection1 id="4" heading="Hot Sale Offer's" />
      <MensSection id="5" />
      <SalesSection />
      <SummerSaleSection />
      <DryFriutSection image={Dryfruit3} />
      <AppDownloadSection />
      <Footer /> */
}
