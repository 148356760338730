import React, { useContext, useEffect, useState } from "react";
// import SliderImg from "../../assets/images/slider-img-01.png";
// import ThumbImg from "../../assets/images/thumb-01.png";
// import colorImg from "../../assets/images/color-img.png";
// import aboutImg from "../../assets/images/about-img.png";
import backIconImg from "../../assets/images/back-icon.svg";
import ProductDetailsSlider from "./ProductDetailsSlider";
import { Link, NavLink, useParams } from "react-router-dom";
import CardContext from "../Context/CardContext";
import {
  axiosInstance,
  base_url,
  handleError,
  imgBaseUrl,
} from "../../Config/axiosConfig";
import toastify from "../../Config/toastify";
import { useLocation } from "react-router-dom";
// import { toHaveStyle } from "@testing-library/jest-dom/matchers";

export default function ProductDetailsSection() {
  const { pathname } = useLocation();
  let { productId } = useParams();
  let [apidata, setApiData] = useState();
  const { card,setCard } = useContext(CardContext);
  let [colorImg, setColorImg] = useState([]);
  let [varinatdata, setVarinatdata] = useState("");
  const [activeVarinatLink, setActiveVarinatLink] = useState(null);
  const [mainImage, setMainImage] = useState(null); 
  const [wishlistData, setWishlistData] = useState([]); 
  
  let token=localStorage.getItem("token")

  // console.log(card);
  useEffect(() => {
    window.scrollTo(0, 0);
    (async () => {
      try {
        let res = await axiosInstance.get(
          `${base_url}/v2/products/${productId}`
        );
        console.log(res.data.data[0], "product data");
        setApiData(res?.data?.data[0]);
        // console.log(res?.data?.data );
        
        setColorImg([
          ...res?.data?.data[0].photos.filter((arr) => arr?.variant !== ""),
        ]);

      } catch (error) {
        let errorMsg = handleError(error);
        console.log(errorMsg);
      }
    })(); 
    if(token){
      wishlistApi()
    }
    //return (Refund policy) api 
    
    // (async () => {
    //   try {
    //     let res = await axiosInstance.get(
    //       `${base_url}/v2/policies/return`
    //     );
    //     console.log(res.data,"return")
    //   } catch (error) {
    //     let errorMsg = handleError(error);
    //     console.log(errorMsg);
    //   }
    // })();
    // (async () => {
    //   try {
    //     let res = await axiosInstance.get(
    //       `${base_url}/v2/policies/term_conditions`
    //     );
    //     console.log(res,"term_condition")

    //   } catch (error) {
    //     let errorMsg = handleError(error);
    //     console.log(errorMsg);
    //   }
    // })();
    // (async () => {
    //   try {
    //     let res = await axiosInstance.get(
    //       `${base_url}/v2/policies/help`
    //     );
    //     console.log(res,"help api")
    //   } catch (error) {
    //     let errorMsg = handleError(error);
    //     console.log(errorMsg);
    //   }
    // })();
    
  }, []);

  let wishlistApi=async ()=>{
    try {
      let wishlistRes = await axiosInstance.get(`${base_url}/v2/wishlists`, {
        headers: {
          Authorization: "Bearer " + token,
        },  
      });
      setWishlistData(wishlistRes.data.data)
    } catch (error) {
      let errorMsg = handleError(error);
      console.log(errorMsg);
    }
  }

  const createMarkup = (htmlContent) => {
    return { __html: htmlContent };
  };
//  console.log(card)

  const handleAddToCard = async (id) => {
    // console.log(varinatdata)
    console.log(card)
    console.log(apidata);
    
    console.log(!varinatdata);
    if (apidata?.choice_options[0]?.title) {
      if (!varinatdata) {
        return toastify.error(
          `please Select ${apidata?.choice_options[0]?.title}`
        );
      }
    }

    if (!token) {
      console.log(card);
      toastify.success("Add Item In Cart");
      return setCard((curVal) =>{
        let dataVal = curVal.some((cartItem) => cartItem.product_id == id && cartItem.variation == varinatdata)
          ? curVal.map((cart) =>
              cart.product_id == id && cart.variation == varinatdata
                ? { ...cart, quantity: ++cart.quantity }
                : cart
            )
          : [...curVal, {  product_id: id, quantity: 1, variation: varinatdata || null ,product_name:apidata?.name, product_thumbnail_image : apidata?.thumbnail_image, price:apidata?.calculable_price, currency_symbol : "RS",  unit:apidata.unit|| 1}]
          localStorage.setItem("guestUser",JSON.stringify(dataVal))
          return dataVal
        }
        );
    } 
 
    try {
      let res = await axiosInstance.post(`${base_url}/v2/carts/add`,{
        id: id,
        quantity: 1,
        variant: varinatdata, 
      },{
        headers:{
          Authorization: "Bearer " + token
        } 
      });
      console.log(res, "add to card");
      if (res.data.result) {
        // setCard((curVal) => ++curVal);
        // setCard((curVal)=> curVal.some((arr)=>(arr === id)) ? curVal : [...curVal,id] )
        setCard((curVal) =>
          curVal.some((cartItem) => cartItem.apidata_id == id && cartItem.variant == varinatdata)
            ? curVal.map((cart) =>
                cart.product_id == id && cart.variant == varinatdata
                  ? { ...cart, quantity: ++cart.quantity}
                  : cart
              )
            : [...curVal, { product_id: id, quantity: 1, variant: varinatdata }]
        );
      
        // setCard((curVal)=> curVal.some((cartItem)=>(cartItem.product_id == id)) ? curVal.map(cart=> cart.product_id == id ? {...cart,quantity :++cart.quantity} : cart) : [ ...curVal , {product_id:id,quantity:1}] )


        toastify.success(res?.data?.message);
      } else {
        toastify.error(res?.data?.message);
      }
      // setApiData(res?.data?.data[0]);
    } catch (error) {
      let errorMsg = handleError(error);
      console.log(errorMsg);
    }
  };

  const handleAddWishList = async (id) => {
    if(!token){
      toastify.error("Please login first")
      let unique = document.getElementById("openLoginFormBtn");
     return  unique.click();
    }

    let data=wishlistData.some((wishitem)=>wishitem?.product?.id ==  id )
    if(data){
      let res = await axiosInstance.post(
        `${base_url}/v2/wishlists-remove-product`,
        {
          product_id: id,
        },
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      if(!(res.data.is_in_wishlist)){
        toastify.success(res.data.message)
       return wishlistApi()
      }
    }
    
    try {
      let res = await axiosInstance.post(`${base_url}/v2/wishlists-add-product`, {
        product_id: id,
      },{
        headers:{
          Authorization: "Bearer " + token
        }
      });
      console.log(res, "add to wishlist");
      if (res.data.is_in_wishlist) {
          toastify.success(res?.data?.message);
      } else {
        toastify.error(res?.data?.message);
      }
      // setApiData(res?.data?.data[0]);
      wishlistApi()
    } catch (error) {
      let errorMsg = handleError(error);
      console.log(errorMsg);
    }
  };


  const handleVarinats = async (varinats,link) => {
    setActiveVarinatLink(link);
    setVarinatdata(varinats);
    setMainImage(null)
    try {
      let res = await axiosInstance.post(
        `${base_url}/v2/products/variant/price`,
        {
          id: productId,
          variants: varinats,
          color: "",
        }
      );
      console.log(res?.data);
      setApiData((curVal) => ({ ...curVal, ...res?.data }));
    } catch (error) {
      let errorMsg = handleError(error);
      console.log(errorMsg);
    }
  };

  return (
    <>
      <section className="product-details tp-space">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="custom-bredcump">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="#">Clothing</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="#">Ladies</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    United Colors of Benetton
                  </li>
                </ol>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="product-details-innr">
                <div className="row">
                  <div className="col-lg-4 ">
                     <ProductDetailsSlider 
                      photos={apidata?.gallery}
                      image={apidata?.image}
                      mainImage={mainImage}
                      setMainImage={setMainImage}
                    />
                     
                    <div className="product-detail-btn">
                      <Link
                        to="#"
                        className="org-btn-02 w-100 text-center"
                        onClick={() => handleAddToCard(apidata?.id)}
                      >
                        <i className="fal fa-cart-plus me-2" />
                        Add to Cart
                      </Link>
                      <Link to="#" className="org-btn-02 w-100 text-center">
                        Buy Now
                      </Link>
                    </div>
                  </div>
                  <div className="col-lg-8">
                    <div className="product-details-innr-right">
                      <div className="product-details-innr-right-title">
                        <div className="">
                          {/* <h6>United Colors of Benetton ***** </h6> */}
                          <h5>{apidata?.name}</h5>
                          <p className="ratings">
                            <span>
                              {apidata?.rating}{" "}
                              <i className="bi bi-star-fill" />
                            </span>
                            ({apidata?.rating_count})
                          </p>
                        </div>
                        <div>
                          <Link to="#" className="share-btn me-2">
                            {/* <i className="far fa-alt me-2" /> */}
                            <i className="bi bi-share me-2"></i>
                            Share
                          </Link>
                          <Link to="#" className="link-btn">
                            {/* {wishlistData.length == 0 ? <i className='bi bi-suit-heart'   onClick={()=>handleAddWishList(apidata?.id)}> </i>:
                            <i className=  " bi bi-suit-heart-fill"  onClick={()=>handleAddWishList(apidata?.id)}> </i>} */} 
                            {wishlistData?.some((wishitem)=>wishitem?.product?.id == apidata?.id ) ? <i className='bi bi-suit-heart-fill'   onClick={()=>handleAddWishList(apidata?.id)}> </i>:
                            <i className=  " bi bi-suit-heart"  onClick={()=>handleAddWishList(apidata?.id)}> </i>}
                          </Link>
                        </div>
                      </div>
                      <p className="dtl-price">
                        <span>
                          {apidata?.currency_symbol}{" "}
                          {apidata?.price || apidata?.calculable_price}
                        </span>
                        {/* <del>₹ {apidata?.stroked_price} </del> <em>  {  parseInt(parseFloat(apidata?.stroked_price?.slice(2))/parseFloat(apidata?.calculable_price)*100)-100}  % Off</em> */}
                        <del>₹ {apidata?.stroked_price} </del>{" "}
                        <em>
                          {" "}
                          {100 -
                            parseInt(
                              (parseInt(apidata?.calculable_price) /
                                parseInt(apidata?.stroked_price?.slice(2))) *
                                100
                            )}{" "}
                          % Off
                        </em>
                      </p>
                      <div className="product-highlights">
                        <div className="product-highlights-lft">
                          <h6>Color:</h6>
                        </div>
                        <div className="product-color-bx">
                          {colorImg?.map((arr, i) => (
                            <img
                              key={i}
                              src={`${imgBaseUrl}/${arr?.path}`}
                              alt="img"
                            />
                          ))}
                          {/* <img src={colorImg} className="selected" alt="" /> */}
                          {/* <img src={colorImg} alt="" />
                            <img src={colorImg} alt="" />
                            <img src={colorImg} alt="" /> */}
                          {/* {
                              apidata?.colors?.map((arr)=>(

                              ))
                            } */}
                          {/* {apidata?.colors?.map((arr, i) => (
                            <img
                              key={i}
                              src={`${imgBaseUrl}/${arr?.path}`}
                              alt="productImg"
                            />
                          ))} */}
                        </div>
                      </div>
                      <div className="product-highlights">
                        <div className="product-highlights-lft">
                          <h6>{apidata?.choice_options[0]?.title}</h6>
                        </div>
                        <div className="product-size-bx">
                          {apidata?.choice_options[0]?.options?.map(
                            (arr, i) => (
                              <NavLink
                                key={i}
                                to="#"
                                // className={}
                                // className="size-btn "
                                className={activeVarinatLink === i  ? 'size-btn selected' : 'size-btn'}
                                onClick={() => handleVarinats(arr,i )}
                              >
                                <span>{arr}</span> <em>  left</em>
                              </NavLink>
                            )
                          )}
                          {/* <Link to="#" className="size-btn selected">
                            <span>S</span> <em>4 left</em>
                          </Link>
                          <Link to="#" className="size-btn">
                            <span>m</span> <em>1 left</em>
                          </Link>
                          <Link to="#" className="size-btn">
                            <span>l</span> <em>1 left</em>
                          </Link>
                          <Link to="#" className="size-btn">
                            <span>xl</span> <em>1 left</em>
                          </Link> */}
                          {/* <Link to="#" className="org-btn-02">
                            Next
                          </Link> */}
                        </div>
                      </div>
                      <div className="product-highlights">
                        <div className="product-highlights-lft">
                          <h6>Delivery:</h6>
                        </div>
                        <div className="product-code">
                          <form action="">
                            <input
                              type="number"
                              className="form-control"
                              placeholder="Pin Code"
                            />
                            <button className="org-btn-02" type="button">
                              Check
                            </button>
                          </form>
                          <small>Usually deliver in 3-4 days.</small>
                        </div>
                      </div>
                      {/* <div className="product-highlights">
                        <div className="product-highlights-lft">
                          <h6>Product Highlights:</h6>
                        </div>
                        <div className="product-txt">
                          <p>Striped</p>
                          <p>Summer Color</p>
                          <p>Full Sleeves</p>
                          <p>Round Neck Fancy T-shirt</p>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
                <div className="custom-tab">
                  <ul className="nav">
                    <li className="tab-item">
                      <Link
                        to="#"
                        className="tab-link active"
                        data-bs-toggle="tab"
                        data-bs-target="#tab-01"
                        role="tab"
                      >
                        Description
                      </Link>
                    </li>
                    <li className="tab-item">
                      <Link
                        to="#"
                        className="tab-link"
                        data-bs-toggle="tab"
                        data-bs-target="#tab-02"
                        role="tab"
                      >
                        Specifications
                      </Link>
                    </li>
                    <li className="tab-item">
                      <Link
                        to="#"
                        className="tab-link"
                        data-bs-toggle="tab"
                        data-bs-target="#tab-03"
                        role="tab"
                      >
                        More Info
                      </Link>
                    </li>
                  </ul>
                  <div className="tab-content">
                    <div
                      className="tab-pane fade show active"
                      id="tab-01"
                      role="tabpanel"
                    >
                      <div className="custom-tab-about">
                        <div className="product-txt">
                          <h4 className="title">About Product:</h4>
                          <p id="plainDiv">
                            <div
                              dangerouslySetInnerHTML={createMarkup(
                                apidata?.description
                              )}
                            />
                          </p>
                        </div>
                        <div>
                          <img
                            src={`${imgBaseUrl}/${apidata?.thumbnail_image}`}
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="product-txt">
                        <h4 className="title">Features:</h4>
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit
                        </p>
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit
                        </p>
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit
                        </p>
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit
                        </p>
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit
                        </p>
                      </div>
                    </div>
                    <div className="tab-pane fade" id="tab-02" role="tabpanel">
                      <div
                        className="product-txt"
                        dangerouslySetInnerHTML={createMarkup(
                          apidata?.specification
                        )}
                      />
                      <div className="specifications-table">
                        <h4 className="title">General</h4>
                        <table className="table table-borderless">
                          <tbody>
                            <tr>
                              <td>Fabric:</td>
                              <th>Cotton</th>
                              <td>Occasion:</td>
                              <th>Casual</th>
                            </tr>
                            <tr>
                              <td>Fit:</td>
                              <th>Regular Fit</th>
                              <td>Pattern:</td>
                              <th>Solid</th>
                            </tr>
                            <tr>
                              <td>Length:</td>
                              <th>Regular</th>
                              <td>Print or Pattern Type:</td>
                              <th>Striped</th>
                            </tr>
                            <tr>
                              <td>Main Trend:</td>
                              <th>New Basics</th>
                              <td>Sleeve Length:</td>
                              <th>Long Sleeves</th>
                            </tr>
                            <tr>
                              <td>Multiple Set:</td>
                              <th>Single</th>
                              <td>Sleeve Styling:</td>
                              <th>Regular Sleeves</th>
                            </tr>
                            <tr>
                              <td>Neck:</td>
                              <th>Round Neck</th>
                              <td>Wash Care:</td>
                              <th>Machine Wash</th>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="tab-pane fade" id="tab-03" role="tabpanel">
                      <div
                        dangerouslySetInnerHTML={createMarkup(
                          apidata?.more_info
                        )}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="policy-bar">
                <div className="row gx-1">
                  <div className="col-lg-6">
                    <div className="policy-bar-lft">
                      <p>
                        <img src={backIconImg} alt="" />
                        <span>Return Policy: </span>
                        30 Days Return
                      </p>
                      <Link to="#" className="org-txt fz-16 fw-600">
                        More
                        {/* <i className="far fa-exclamation-circle ms-2" /> */}
                        <i className="bi bi-exclamation-circle ms-2"></i>
                      </Link>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="policy-bar-lft border-0 pe-0">
                      <p>
                        {" "}
                        <span>Return Policy: </span> 30 Days Return{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="rating-card">
                <h4 className="title">Ratings &amp; Reviews</h4>
                <h6>
                  <span
                    className={
                      apidata?.rating >= 4
                        ? " bg-warning"
                        : apidata?.rating >= 2
                        ? "bg-info"
                        : "bg-danger "
                    }
                  >
                    {apidata?.rating}
                    <i className="bi bi-star-fill ms-2"></i>
                  </span>
                </h6>
                <p>{apidata?.rating_count} Users have rated this product.</p>
                <div className="rating-bar">
                  <span>
                    5 <i className="bi bi-star-fill" />
                  </span>
                  <div className="progress">
                    <div
                      className="progress-bar grn"
                      role="progressbar"
                      style={{ width: "90%" }}
                    />
                  </div>
                  <span>256</span>
                </div>
                <div className="rating-bar">
                  <span>
                    4 <i className="bi bi-star-fill" />
                  </span>
                  <div className="progress">
                    <div
                      className="progress-bar lgt-grn"
                      role="progressbar"
                      style={{ width: "70%" }}
                    />
                  </div>
                  <span>220</span>
                </div>
                <div className="rating-bar">
                  <span>
                    3 <i className="bi bi-star-fill" />
                  </span>
                  <div className="progress">
                    <div
                      className="progress-bar yllw"
                      role="progressbar"
                      style={{ width: "55%" }}
                    />
                  </div>
                  <span>110</span>
                </div>
                <div className="rating-bar">
                  <span>
                    2 <i className="bi bi-star-fill" />
                  </span>
                  <div className="progress">
                    <div
                      className="progress-bar org"
                      role="progressbar"
                      style={{ width: "40%" }}
                    />
                  </div>
                  <span>47</span>
                </div>
                <div className="rating-bar">
                  <span>
                    1 <i className="bi bi-star-fill" />
                  </span>
                  <div className="progress">
                    <div
                      className="progress-bar rd"
                      role="progressbar"
                      style={{ width: "25%" }}
                      aria-valuenow={25}
                      aria-valuemin={0}
                      aria-valuemax={100}
                    />
                  </div>
                  <span>15</span>
                </div>
              </div>
            </div>
            <div className="col-lg-8">
              {apidata?.reviews?.map((arr) => (
                <div className="product-review">
                  <p className="rating-txt">
                    <span className="rating">
                      {" "}
                      <em
                        className={
                          arr?.rating >= 4
                            ? " bg-warning"
                            : arr.rating >= 2
                            ? "bg-info"
                            : "bg-danger "
                        }
                      >
                        {arr?.rating} <i className="bi bi-star-fill" />
                      </em>{" "}
                      {arr?.user_name}
                    </span>
                    <span>{arr?.created_at.split("T")[0]}</span>
                  </p>
                  <p className="desc-txt">
                    {arr?.comment}{" "}
                    {/* <Link to="javascript:void(0)'">Read More</Link> */}
                  </p>
                  <img src={arr?.image} alt="" />
                  {/* <img src={ThumbImg} alt="" /> */}
                </div>
              ))}

              <div className="product-review">
                <Link style={{ color: "black" }}>
                  View All 540 Reviews &gt;
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
