// import React from "react";
import { Link } from "react-router-dom";
const Quickly = () => {
  return (
    <section className="quicklySection">
      <div className="quciklyScrolContainer">
        <h1 className="mainHeading">Go Quickly To:</h1>
        <ul className="MenuList">
          <li>
            <Link to="/seller-product" className="mainHeading">
              Shirt
            </Link>
          </li>
          <li>
            <span className="mainHeading"> |</span>
          </li>
          <li>
            <Link to="/electronic-section" className="mainHeading">
              Mobile
            </Link>
          </li>
          <li>
            <span className="mainHeading"> |</span>
          </li>
          <li>
            <Link to="/search-product" className="mainHeading">
              TV
            </Link>
          </li>
          <li>
            <span className="mainHeading"> |</span>
          </li>
          <li>
            <Link to="#" className="mainHeading">
              Washingmachine
            </Link>
          </li>
          <li>
            <span className="mainHeading"> |</span>
          </li>
          <li>
            <Link to="#" className="mainHeading">
              Cameras
            </Link>
          </li>
          <li>
            <span className="mainHeading"> |</span>
          </li>
          <li>
            <Link to="#" className="mainHeading">
              Furniture
            </Link>
          </li>
          <li>
            <span className="mainHeading"> |</span>
          </li>
          <li>
            <Link to="#" className="mainHeading">
              Refrigerator
            </Link>
          </li>
          <li>
            <span className="mainHeading"> |</span>
          </li>
          <li>
            <Link to="#" className="mainHeading">
              Bedsheet
            </Link>
          </li>
          <li>
            <span className="mainHeading"> |</span>
          </li>
          <li>
            <Link to="#" className="mainHeading">
              Sports
            </Link>
          </li>
          <li>
            <span className="mainHeading"> |</span>
          </li>
          <li>
            <Link to="#" className="mainHeading">
              Lighting
            </Link>
          </li>
          <li>
            <span className="mainHeading"> |</span>
          </li>
        </ul>
      </div>
    </section>
  );
};

export default Quickly;
