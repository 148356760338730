import React, { useState } from "react";
import {
  handleError,
  axiosInstance,
  base_url,
} from "../../../Config/axiosConfig";
import toastify from "../../../Config/toastify";
import Notification from "./Notification";
import { Link } from "react-router-dom";
export default function ChangePassword() {
  const [formVal, setformVal] = useState({
    current_password: "",
    new_password: "",
    re_enter_new_password: "",
    otp: "",
  });
  let token = localStorage.getItem("token")
  const handleOnchange = (e) => {
    setformVal((curval) => ({ ...curval, [e.target.name]: e.target.value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      !formVal.current_password ||
      !formVal.new_password ||
      !formVal.re_enter_new_password ||
      !formVal.otp
    ) {
      return toastify.info("Empty Fields Not Allowed");
    }
    if (formVal.new_password !== formVal.re_enter_new_password) {
      return toastify.warning(
        "New Password & Re-Type New Password Are Not Match"
      );
    }
    try {
      let res = await axiosInstance.post(
        `${base_url}/v2/password/update`,formVal,{headers: {
          Authorization: "Bearer " + token,
        }}        
      );
      if (res.data.result) {
        toastify.success(res.data.message);
      } else {
        toastify.error(res.data.message);
      }
    } catch (error) {
      let errorMsg = handleError(error);
      console.log(errorMsg);
    }
  };

  return (
    <>
      <div className="col-lg-9 col-md-8">
        <div className="panel-notification panel-cards">
          <div className="notification-tabs">
            <ul className="nav">
              <li className="tab-item">
                <Link
                  to="#"
                  className="tab-link active"
                  data-bs-toggle="tab"
                  data-bs-target="#tab-01"
                  role="tab"
                >
                  Change Password
                </Link>
              </li>
              <li className="tab-item">
                <Link
                  to="#"
                  className="tab-link"
                  data-bs-toggle="tab"
                  data-bs-target="#tab-02"
                  role="tab"
                >
                  Notification Settings
                </Link>
              </li>
            </ul>
            <div className="tab-content">
              <div
                className="tab-pane fade show active"
                id="tab-01"
                role="tabpanel"
              >
                <div className="password-change">
                  <form action="" onSubmit={handleSubmit}>
                    <div className="mb-3 change-bx">
                      <label htmlFor="" className="form-label">
                        Type Current Password
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Current Password"
                        value={formVal?.current_password}
                        name="current_password"
                        onChange={handleOnchange}
                      />
                    </div>
                    <div className="mb-3 change-bx">
                      <label htmlFor="" className="form-label">
                        Type New Password
                      </label>
                      <input
                        type="password"
                        className="form-control"
                        placeholder="Enter New Password"
                        value={formVal?.new_password}
                        name="new_password"
                        onChange={handleOnchange}
                      />
                    </div>
                    <div className="mb-3 change-bx">
                      <label htmlFor="" className="form-label">
                        Re-Type New Password
                      </label>
                      <input
                        type="password"
                        className="form-control"
                        onChange={handleOnchange}
                        placeholder="**********"
                        value={formVal?.re_enter_new_password}
                        name="re_enter_new_password"
                      />
                    </div>
                    <div className="mb-3 change-bx">
                      <label htmlFor="" className="form-label">
                        Enter OTP sent to +91 XXXXX XXXXX
                      </label>
                      <input
                        type="password"
                        onChange={handleOnchange}
                        className="form-control"
                        placeholder="Enter OTP"
                        value={formVal?.otp}
                        name="otp"
                      />
                      <div className="text-end mt-2">
                        <Link to="#" className="org-txt fw-600">
                          Resend OTP
                        </Link>
                      </div>
                    </div>
                    <div className="text-center">
                      <button className="org-btn-02" type="submit">
                        Change Password
                      </button>
                    </div>
                  </form>
                </div>
              </div>
              <Notification />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
