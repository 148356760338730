import React from 'react'
import FilterPoduct from '../components/GroceryProduct/FilterPoduct'
// import GroceryProduct from '../components/GroceryProduct/GroceryProduct'
import GroceryProduct from "../components/GroceryProduct/GroceryProduct"
import OtherPopularProductSlider from '../components/MyAccountDetailsSection/OtherPopularProductSlider'
import prductImg from "../assets/images/other-product.png"
export default function GroceryProductPage() {
    return (
        <>
            <section className="grocery-prdct-sec tp-space">
                <div className="container-fluid">
                    <div className="row">
                    <FilterPoduct />
                    <GroceryProduct />
                    </div>
                </div>
            </section>
            <OtherPopularProductSlider id={1} title={"Other Popular Products"}  productImg={prductImg} lableImg={null} slidesPerView={4} />
            <OtherPopularProductSlider id={2} title={"New Products"}  productImg={prductImg} lableImg={null} slidesPerView={4}/>
        </>
    )
}
