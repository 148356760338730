import   { useRef } from "react";
// import HoleCardImg from "../../../assets/img/holeCardImg.png";
// import StarImg from "../../../assets/img/StarImg.svg";
import LeftArrow from "../../assets/img/leftArrow.svg";
import RightArrow from "../../assets/img/rightArrow.svg";
import Tide_deal_img from "../../assets/img/deal-img-01.png";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { Link } from "react-router-dom"; 
import Tide_img from "../../assets/images/tide-icon.png";
// import Card from "react-bootstrap/Card";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
 
const HoleSaleSection2 = ({id,tilte}) => {

  const swiperRef = useRef(null);
  return (
    <div className="container-fluid mrt-15">
       {/* <GrocerySlider id="4" tilte={"Top 100 Deals"} /> */}
    <section className="HoleSaleSection1 constantPadding">
      <div className="holeSaleContainer">
        <div className="holeHeader">
          {/* <h1 className="mainHeading">{tilte}</h1> */}
          <h4 className="title">
            <img src={Tide_img} alt="" />
            {tilte}
          </h4>
          <div className="holeHeaderBtn">
            <a href="#" className="mainBtn">
              View All
            </a>
            <div className={`carouselPrv${id}`}>
              <img
                src={LeftArrow}
                className="carouslArrowImg"
                onClick={() => swiperRef.current.swiper.slidePrev()}
              />
            </div>
            <div className={`carouselNext${id}`}>
              <img
                src={RightArrow}
                className="carouslArrowImg"
                onClick={() => swiperRef.current.swiper.slideNext()}
              />
            </div>
          </div>
        </div>
        <div className="holeSaleCarousel">
          <div className="uperCarouselContainer">
            <Swiper
              ref={swiperRef}
              modules={[Navigation, Pagination, Scrollbar, A11y]}
              loop={true}
              spaceBetween={3}
              slidesPerView={5.1}
              navigation={{
                prevEl: `.carouselPrv${id}`,
                nextEl: `.carouselNext${id}`,
              }}
              onSlideChange={() => console.log("slide change")}
              onSwiper={(swiper) => console.log(swiper)}
              breakpoints={{
                100: {
                  slidesPerView: 1,
                  spaceBetween: 20,
                },
                650: {
                  slidesPerView: 5,
                  spaceBetween: 30,
                },
                1024: {
                  slidesPerView: 8,
                  spaceBetween: 40,
                },
              }}
            >
              <SwiperSlide>
                <div className="deal-slide-innr">
                  <img src={Tide_deal_img} alt="" />
                  <h5>Tide Double Power Jasmine and rose ...</h5>
                  <h6>
                    ₹ 12,990 <del>₹ 15,990</del> <span>8% Off</span>
                  </h6>
                  <Link to="#" className="org-btn-02">
                    Add Item
                  </Link>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="deal-slide-innr">
                  <img src={Tide_deal_img} alt="" />
                  <h5>Tide Double Power Jasmine and rose ...</h5>
                  <h6>
                    ₹ 12,990 <del>₹ 15,990</del> <span>8% Off</span>
                  </h6>
                  <Link to="#" className="org-btn-02">
                    Add Item
                  </Link>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="deal-slide-innr">
                  <img src={Tide_deal_img} alt="" />
                  <h5>Tide Double Power Jasmine and rose ...</h5>
                  <h6>
                    ₹ 12,990 <del>₹ 15,990</del> <span>8% Off</span>
                  </h6>
                  <Link to="#" className="org-btn-02">
                    Add Item
                  </Link>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="deal-slide-innr">
                  <img src={Tide_deal_img} alt="" />
                  <h5>Tide Double Power Jasmine and rose ...</h5>
                  <h6>
                    ₹ 12,990 <del>₹ 15,990</del> <span>8% Off</span>
                  </h6>
                  <Link to="#" className="org-btn-02">
                    Add Item
                  </Link>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="deal-slide-innr">
                  <img src={Tide_deal_img} alt="" />
                  <h5>Tide Double Power Jasmine and rose ...</h5>
                  <h6>
                    ₹ 12,990 <del>₹ 15,990</del> <span>8% Off</span>
                  </h6>
                  <Link to="#" className="org-btn-02">
                    Add Item
                  </Link>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="deal-slide-innr">
                  <img src={Tide_deal_img} alt="" />
                  <h5>Tide Double Power Jasmine and rose ...</h5>
                  <h6>
                    ₹ 12,990 <del>₹ 15,990</del> <span>8% Off</span>
                  </h6>
                  <Link to="#" className="org-btn-02">
                    Add Item
                  </Link>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="deal-slide-innr">
                  <img src={Tide_deal_img} alt="" />
                  <h5>Tide Double Power Jasmine and rose ...</h5>
                  <h6>
                    ₹ 12,990 <del>₹ 15,990</del> <span>8% Off</span>
                  </h6>
                  <Link to="#" className="org-btn-02">
                    Add Item
                  </Link>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="deal-slide-innr">
                  <img src={Tide_deal_img} alt="" />
                  <h5>Tide Double Power Jasmine and rose ...</h5>
                  <h6>
                    ₹ 12,990 <del>₹ 15,990</del> <span>8% Off</span>
                  </h6>
                  <Link to="#" className="org-btn-02">
                    Add Item
                  </Link>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="deal-slide-innr">
                  <img src={Tide_deal_img} alt="" />
                  <h5>Tide Double Power Jasmine and rose ...</h5>
                  <h6>
                    ₹ 12,990 <del>₹ 15,990</del> <span>8% Off</span>
                  </h6>
                  <Link to="#" className="org-btn-02">
                    Add Item
                  </Link>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="deal-slide-innr">
                  <img src={Tide_deal_img} alt="" />
                  <h5>Tide Double Power Jasmine and rose ...</h5>
                  <h6>
                    ₹ 12,990 <del>₹ 15,990</del> <span>8% Off</span>
                  </h6>
                  <Link to="#" className="org-btn-02">
                    Add Item
                  </Link>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="deal-slide-innr">
                  <img src={Tide_deal_img} alt="" />
                  <h5>Tide Double Power Jasmine and rose ...</h5>
                  <h6>
                    ₹ 12,990 <del>₹ 15,990</del> <span>8% Off</span>
                  </h6>
                  <Link to="#" className="org-btn-02">
                    Add Item
                  </Link>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="deal-slide-innr">
                  <img src={Tide_deal_img} alt="" />
                  <h5>Tide Double Power Jasmine and rose ...</h5>
                  <h6>
                    ₹ 12,990 <del>₹ 15,990</del> <span>8% Off</span>
                  </h6>
                  <Link to="#" className="org-btn-02">
                    Add Item
                  </Link>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>
    </section>

     </div>
  );
};

export default HoleSaleSection2;
