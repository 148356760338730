import SliderImg from "../../assets/images/slider-img-01.png";
import { Swiper, SwiperSlide } from "swiper/react";
import React, { useRef, useState ,useEffect} from "react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/scrollbar";
import ThumbImg from "../../assets/images/thumb-01.png";
import LeftArrow from "../../assets/img/leftArrow.svg";
import RightArrow from "../../assets/img/rightArrow.svg";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Controller,
} from "swiper/modules";
import { imgBaseUrl } from "../../Config/axiosConfig";
import "./style.css"
export default function ProductDetailsSlider({
  photos,
  image,
  mainImage,
  setMainImage,
}) {
  const imgRef = useRef(null);
  const resultRef = useRef(null);
  const lensRef = useRef(null);
  const [firstSwiper, setFirstSwiper] = useState(null);
  const [secondSwiper, setSecondSwiper] = useState(null);
  // console.log(photos[0].file_name);
  
  photos=photos?.sort((a, b) => parseInt(a.s_num) - parseInt(b.s_num))
  // console.log(photos);
  let id = 1;
  const swiperRef = useRef(null);

  const handleSlideClick = (index) => {
    if (firstSwiper) {
      firstSwiper.slideTo(index);
    }
  };

  // photos2 = photos?.filter((arr) => arr?.variant === "");
  // photos?.reverse();
  // let img2= photos 
  //  img2?.reverse();


  const handleMouseEnter = (path) => {
    setMainImage(path);
  };

  document.querySelectorAll(".hoverEffect").forEach((anchor) => {
    anchor.addEventListener("mouseenter", (evt) => {
      const popup = document.querySelector(".popup");
      popup.style.left = `${evt.pageX + 30}px`;
      popup.style.top = `${evt.pageY - 15}px`;
      popup.style.display = "block";

      anchor.addEventListener("mouseleave", function () {
        popup.style.display = "none";
      });
    });
  });
  // console.log(data);
  // let imgPreview =()=>{

  // }

 
function imageZoom(e ) {
 
  const img=e.target
  const  result = document.querySelector(".img-zoom-result")
  result.classList.remove("hidden")
  const lens = lensRef.current;

  const cx = result.offsetWidth / lens.offsetWidth;
  const cy = result.offsetHeight / lens.offsetHeight;

  result.style.backgroundImage = `url('${img.src}')`;
  result.style.backgroundSize = `${img.width * cx}px ${img.height * cy}px`;

  function moveLens(e) {
    e.preventDefault();
    const pos = getCursorPos(e);
    let x = pos.x - lens.offsetWidth / 2;
    let y = pos.y - lens.offsetHeight / 2;

    if (x > img.width - lens.offsetWidth) x = img.width - lens.offsetWidth;
    if (x < 0) x = 0;
    if (y > img.height - lens.offsetHeight) y = img.height - lens.offsetHeight;
    if (y < 0) y = 0;

    lens.style.left = `${x}px`;
    lens.style.top = `${y}px`;
    result.style.backgroundPosition = `-${x * cx}px -${y * cy}px`;
  }

  function getCursorPos(e) {
    const a = img.getBoundingClientRect();
    let x = e.pageX - a.left;
    let y = e.pageY - a.top;

    x = x - window.pageXOffset;
    y = y - window.pageYOffset;
    return { x, y };
  }

  lens.addEventListener('mousemove', moveLens);
  img.addEventListener('mousemove', moveLens);
  lens.addEventListener('touchmove', moveLens);
  img.addEventListener('touchmove', moveLens);

      return () => {
        lens.removeEventListener('mousemove', moveLens);
        img.removeEventListener('mousemove', moveLens);
        lens.removeEventListener('touchmove', moveLens);
        img.removeEventListener('touchmove', moveLens);
    };
}

 
   
  return (
    <>
      <div className="product-details-slide">
        <div className="swiper-container product-details-slide-innr  mb-3">
          <Swiper
            modules={[Navigation, Pagination, Scrollbar,A11y, Controller]}
            loop={true}
            spaceBetween={0}
            onSwiper={setFirstSwiper}
            controller={{ control: secondSwiper }}
            slidesPerView={1}
            loopedSlides={1}
            onSlideChange={() => {
              setMainImage(null);
            }}
          >
            {image ? (
              <SwiperSlide>
                <div className="swiper-slide " onMouseEnter={(e) =>imageZoom(e , "img-zoom-result")} onMouseLeave={() => document.querySelector(".img-zoom-result").classList.add("hidden")}>
                  <img
                    src={`${imgBaseUrl}/${mainImage || image}`}
                    alt="poductImg"
                  />
                </div>
              </SwiperSlide>
            ) : (
              photos?.map((arr, i) => (
                <SwiperSlide key={i}>
                  <div className="swiper-slide" onMouseEnter={
                    (e) =>imageZoom(e,"img-zoom-result")} onMouseLeave={() => document.querySelector(".img-zoom-result").classList.add("hidden") }>
                    <img ref={imgRef} id="myimage"
                      src={`${imgBaseUrl}/${arr?.file_name}`}
                      alt="poductImg"
                    />
                    <div className="popup"></div>
                  </div>
                </SwiperSlide>
              ))
            )}
            <div ref={lensRef} className="img-zoom-lens"  ></div>
          </Swiper>
        </div>

        {
          <div className="swiper-container product-details-slide-thumbs">
            <Swiper
              ref={swiperRef}
              modules={[Navigation, Pagination, Scrollbar, A11y, Controller]}
              onSwiper={setSecondSwiper}
              loop={true}
              controller={{ control: firstSwiper }}
              slidesPerView={5}
              loopedSlides={5}
              spaceBetween={1}
              navigation
              slideToClickedSlide={true}
            >
              {photos?.map((arr, i) => (
                <SwiperSlide key={i}>
                  <div
                    className="swiper-slide"
                    onClick={() => handleSlideClick(i)}
                    onMouseEnter={() => handleMouseEnter(arr?.file_name)}
                  >
                    <img src={`${imgBaseUrl}/${arr?.file_name}`} alt="poductImg" />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        }
         <div   className="img-zoom-result hidden"  ></div> 
      </div>
    </>
  );
}
