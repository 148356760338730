import React from 'react'
import SellerSection from '../components/Seller/SellerSection'
// import Quickly from '../components/QuicklySection/QuicklySection'

export default function SellerPage() {
  return (
    <>
    {/* <Quickly/> */}
    <SellerSection/>
    </>
  )
}
