import React from 'react'
import ComprasionSection from '../components/Comprasion/ComprasionSection'
// import Quickly from '../components/QuicklySection/QuicklySection'
export default function ComprasionPage() {
  return (
    <>
    {/* <Quickly/> */}
      <ComprasionSection/>
    </>
  )
}
