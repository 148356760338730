import React, { useState, useEffect } from "react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import Card from "react-bootstrap/Card";
import beautyHygoneImg from "../../assets/img/beautyHygine.png";
import hygoneCardImg from "../../assets/img/beautyHygoneCardImg.png";
import StarEmtyImg from "../../assets/img/startEmty.svg";
import btnLeft from "../../assets/img/btnLeft.svg";
import btnRight from "../../assets/img/btnRight.svg";
import {
  axiosInstance,
  base_url,
  handleError,
  imgBaseUrl,
} from "../../Config/axiosConfig";
import toastify from "../../Config/toastify";
import BeautyColImg from "../../assets/img/beautyColImg.png";
import { Link } from "react-router-dom";

const BeautyHygiene = ({ apiData }) => {
  // let [apiData, setApiData] = useState([]);

  // useEffect(() => {
  //   (async () => {
  //     try {
  //       let res = await axiosInstance.get(
  //         `${base_url}/v2/product/home_fouth_cat`
  //       );
  //       console.log(res.data);
  //       setApiData(res?.data?.data);
  //     } catch (error) {
  //       let errorMsg = handleError(error);
  //       console.log(errorMsg);
  //     }
  //   })();
  // }, []);

  return (
    <section className="BeautyHygiene constantPadding">
      <div className="beautyHygieneContainer">
        <div className="row">
          <div className="col-lg-3 pe-lg-4 ">
            <div className="beautyHygoneImgSide">
              <img src={beautyHygoneImg} alt="" className="beutyHygoneImg" />
            </div>
          </div>
          <div className="col-lg-9  ">
            <div className="beautyHygoneTextSide">
              <div className="row">
                <div className="col-lg-5 p-lg-0 ">
                  <div className="hygoneDoubleContainer">
                    <Swiper
                      modules={[Navigation, Pagination, Scrollbar, A11y]}
                      loop={true}
                      spaceBetween={3}
                      slidesPerView={1}
                      navigation={{
                        prevEl: ".hygoneCarouselPrv",
                        nextEl: ".hygoneCarouselNext",
                      }}
                      // onSlideChange={() => console.log("slide change")}
                      // onSwiper={(swiper) => console.log(swiper)}
                      breakpoints={{
                        // when window width is >= 640px
                        100: {
                          slidesPerView: 1,
                          spaceBetween: 20,
                        },
                        // when window width is >= 768px
                        650: {
                          slidesPerView: 1,
                          spaceBetween: 30,
                        },
                        // when window width is >= 1024px
                        1024: {
                          slidesPerView: 1,
                          spaceBetween: 40,
                        },
                      }}
                    >
                      {apiData?.map((arr, i) => (
                        <SwiperSlide key={arr?.id || i}>
                          <div className="upperCart ">
                            <Link to={`/product-details/${arr?.id}`}>
                              <Card>
                                <Card.Img
                                  variant="top"
                                  // src={hygoneCardImg}
                                  src={`${imgBaseUrl}/${arr?.banner}`}
                                  className="hygoneCardImg"
                                />
                                <Card.Body>
                                  <h1 className="mainHeading">
                                    {arr?.name.split(" ")[0]}{" "}
                                    {arr?.name.split(" ")[1]}{" "}
                                    {arr?.name.split(" ")[2]}.......
                                  </h1>

                                  <img
                                    src={StarEmtyImg}
                                    className="starEmtyImg"
                                  />
                                  <div className="cardPricing">
                                    <p className="mainHeading">
                                      ₹ {arr?.unit_price}
                                      {arr.discount && (
                                        <span>
                                          ₹ {arr.discount + arr.unit_price}
                                        </span>
                                      )}
                                    </p>
                                  </div>
                                </Card.Body>
                              </Card>
                            </Link>
                          </div>

                          <div className="upperCart">
                            <Link to={`/product-details/${arr?.id}`}>
                              <Card>
                                <Card.Img
                                  variant="top"
                                  // src={hygoneCardImg}
                                  src={`${imgBaseUrl}/${arr?.banner}`}
                                  className="hygoneCardImg"
                                />
                                <Card.Body>
                                  <h1 className="mainHeading">
                                    {arr?.name.split(" ")[0]}{" "}
                                    {arr?.name.split(" ")[1]}{" "}
                                    {arr?.name.split(" ")[2]}.......
                                  </h1>
                                  <img
                                    src={StarEmtyImg}
                                    className="starEmtyImg"
                                  />
                                  <div className="cardPricing">
                                    <p className="mainHeading">
                                      ₹ {arr?.unit_price}
                                      {arr?.discount && (
                                        <span>
                                          ₹ {arr?.discount + arr?.unit_price}
                                        </span>
                                      )}
                                    </p>
                                  </div>
                                </Card.Body>
                              </Card>
                            </Link>
                          </div>

                          <div className="upperCart">
                            <Link to={`/product-details/${arr?.id}`}>
                              <Card>
                                <Card.Img
                                  variant="top"
                                  // src={hygoneCardImg}
                                  src={`${imgBaseUrl}/${arr?.banner}`}
                                  className="hygoneCardImg"
                                />
                                <Card.Body>
                                  <h1 className="mainHeading">
                                    {arr?.name.split(" ")[0]}{" "}
                                    {arr?.name.split(" ")[1]}{" "}
                                    {arr?.name.split(" ")[2]}.......
                                  </h1>
                                  <img
                                    src={StarEmtyImg}
                                    className="starEmtyImg"
                                  />
                                  <div className="cardPricing">
                                    <p className="mainHeading">
                                      ₹ {arr?.unit_price}
                                      {arr.discount && (
                                        <span>
                                          ₹ {arr.discount + arr.unit_price}
                                        </span>
                                      )}
                                    </p>
                                  </div>
                                </Card.Body>
                              </Card>
                            </Link>
                          </div>
                        </SwiperSlide>
                      ))}

                      {/* <SwiperSlide>
                        <div className="upperCart">
                          <Card>
                            <Card.Img
                              variant="top"
                              src={hygoneCardImg}
                              className="hygoneCardImg"
                            />
                            <Card.Body>
                              <h1 className="mainHeading">
                                Double Phoenijjjx....
                              </h1>
                              <img src={StarEmtyImg} className="starEmtyImg" />
                              <div className="cardPricing">
                                <p className="mainHeading">
                                  ₹ 12,990
                                  <span>₹ 15,990</span>
                                </p>
                              </div>
                            </Card.Body>
                          </Card>
                        </div>

                        <div className="upperCart">
                          <Card>
                            <Card.Img
                              variant="top"
                              src={hygoneCardImg}
                              className="hygoneCardImg"
                            />
                            <Card.Body>
                              <h1 className="mainHeading">
                                Double Phoenijjjx....
                              </h1>
                              <img src={StarEmtyImg} className="starEmtyImg" />
                              <div className="cardPricing">
                                <p className="mainHeading">
                                  ₹ 12,990
                                  <span>₹ 15,990</span>
                                </p>
                              </div>
                            </Card.Body>
                          </Card>
                        </div>

                        <div className="upperCart">
                          <Card>
                            <Card.Img
                              variant="top"
                              src={hygoneCardImg}
                              className="hygoneCardImg"
                            />
                            <Card.Body>
                              <h1 className="mainHeading">
                                Double Phoenijjjx....
                              </h1>
                              <img src={StarEmtyImg} className="starEmtyImg" />
                              <div className="cardPricing">
                                <p className="mainHeading">
                                  ₹ 12,990
                                  <span>₹ 15,990</span>
                                </p>
                              </div>
                            </Card.Body>
                          </Card>
                        </div>
                      </SwiperSlide> */}
                    </Swiper>
                  </div>
                </div>
                <div className="col-lg-2 p-lg-0 ">
                  <div className="hygoneCarsoulSiderContent">
                    <div className="carouslBtn">
                      <img
                        src={btnLeft}
                        className="carouslBtnImg hygoneCarouselPrv"
                      />
                      <img
                        src={btnRight}
                        className="carouslBtnImg hygoneCarouselNext"
                      />
                    </div>
                    <div className=" hygoneCarsoulSide">
                      <h1 className="mainHeading">Beauty & Hygiene</h1>
                      <a href="#" className="mainBtn">
                        View All
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-5 p-lg-0">
                  <div className="hygoneDoubleContainer">
                    <Swiper
                      modules={[Navigation, Pagination, Scrollbar, A11y]}
                      loop={true}
                      spaceBetween={3}
                      slidesPerView={1}
                      navigation={{
                        prevEl: ".hygoneCarouselPrv",
                        nextEl: ".hygoneCarouselNext",
                      }}
                      // onSlideChange={() => console.log("slide change")}
                      // onSwiper={(swiper) => console.log(swiper)}
                      breakpoints={{
                        // when window width is >= 640px
                        100: {
                          slidesPerView: 1,
                          spaceBetween: 20,
                        },
                        // when window width is >= 768px
                        650: {
                          slidesPerView: 1,
                          spaceBetween: 30,
                        },
                        // when window width is >= 1024px
                        1024: {
                          slidesPerView: 1,
                          spaceBetween: 40,
                        },
                      }}
                    >
                      {apiData?.map((arr, i) => (
                        <SwiperSlide key={arr?.id || i}>
                          <div className="upperCart ">
                            <Link to={`/product-details/${arr?.id}`}>
                              <Card>
                                <Card.Img
                                  variant="top"
                                  // src={hygoneCardImg}
                                  src={`${imgBaseUrl}/${arr?.banner}`}
                                  className="hygoneCardImg"
                                />
                                <Card.Body>
                                  <h1 className="mainHeading">
                                    {arr?.name.split(" ")[0]}{" "}
                                    {arr?.name.split(" ")[1]}{" "}
                                    {arr?.name.split(" ")[2]}.......
                                  </h1>

                                  <img
                                    src={StarEmtyImg}
                                    className="starEmtyImg"
                                  />
                                  <div className="cardPricing">
                                    <p className="mainHeading">
                                      ₹ {arr?.unit_price}
                                      {arr.discount && (
                                        <span>
                                          ₹ {arr.discount + arr.unit_price}
                                        </span>
                                      )}
                                    </p>
                                  </div>
                                </Card.Body>
                              </Card>
                            </Link>
                          </div>

                          <div className="upperCart">
                            <Link to={`/product-details/${arr?.id}`}>
                              <Card>
                                <Card.Img
                                  variant="top"
                                  // src={hygoneCardImg}
                                  src={`${imgBaseUrl}/${arr?.banner}`}
                                  className="hygoneCardImg"
                                />
                                <Card.Body>
                                  <h1 className="mainHeading">
                                    {arr?.name.split(" ")[0]}{" "}
                                    {arr?.name.split(" ")[1]}{" "}
                                    {arr?.name.split(" ")[2]}.......
                                  </h1>
                                  <img
                                    src={StarEmtyImg}
                                    className="starEmtyImg"
                                  />
                                  <div className="cardPricing">
                                    <p className="mainHeading">
                                      ₹ {arr?.unit_price}
                                      {arr.discount && (
                                        <span>
                                          ₹ {arr.discount + arr.unit_price}
                                        </span>
                                      )}
                                    </p>
                                  </div>
                                </Card.Body>
                              </Card>
                            </Link>
                          </div>

                          <div className="upperCart">
                            <Link to={`/product-details/${arr?.id}`}>
                              <Card>
                                <Card.Img
                                  variant="top"
                                  // src={hygoneCardImg}
                                  src={`${imgBaseUrl}/${arr?.banner}`}
                                  className="hygoneCardImg"
                                />
                                <Card.Body>
                                  <h1 className="mainHeading">
                                    {arr?.name.split(" ")[0]}{" "}
                                    {arr?.name.split(" ")[1]}{" "}
                                    {arr?.name.split(" ")[2]}.......
                                  </h1>
                                  <img
                                    src={StarEmtyImg}
                                    className="starEmtyImg"
                                  />
                                  <div className="cardPricing">
                                    <p className="mainHeading">
                                      ₹ {arr?.unit_price}
                                      {arr.discount && (
                                        <span>
                                          ₹ {arr.discount + arr.unit_price}
                                        </span>
                                      )}
                                    </p>
                                  </div>
                                </Card.Body>
                              </Card>
                            </Link>
                          </div>
                        </SwiperSlide>
                      ))}
                      {/* <SwiperSlide>
                        <div className="upperCart">
                          <Card>
                            <Card.Img
                              variant="top"
                              src={hygoneCardImg}
                              className="hygoneCardImg"
                            />
                            <Card.Body>
                              <h1 className="mainHeading">
                                Double Phoenijjjx....
                              </h1>
                              <img src={StarEmtyImg} className="starEmtyImg" />
                              <div className="cardPricing">
                                <p className="mainHeading">
                                  ₹ 12,990
                                  <span>₹ 15,990</span>
                                </p>
                              </div>
                            </Card.Body>
                          </Card>
                        </div>

                        <div className="upperCart">
                          <Card>
                            <Card.Img
                              variant="top"
                              src={hygoneCardImg}
                              className="hygoneCardImg"
                            />
                            <Card.Body>
                              <h1 className="mainHeading">
                                Double Phoenijjjx....
                              </h1>
                              <img src={StarEmtyImg} className="starEmtyImg" />
                              <div className="cardPricing">
                                <p className="mainHeading">
                                  ₹ 12,990
                                  <span>₹ 15,990</span>
                                </p>
                              </div>
                            </Card.Body>
                          </Card>
                        </div>

                        <div className="upperCart">
                          <Card>
                            <Card.Img
                              variant="top"
                              src={hygoneCardImg}
                              className="hygoneCardImg"
                            />
                            <Card.Body>
                              <h1 className="mainHeading">
                                Double Phoenijjjx....
                              </h1>
                              <img src={StarEmtyImg} className="starEmtyImg" />
                              <div className="cardPricing">
                                <p className="mainHeading">
                                  ₹ 12,990
                                  <span>₹ 15,990</span>
                                </p>
                              </div>
                            </Card.Body>
                          </Card>
                        </div>
                      </SwiperSlide> */}

                      {/* <SwiperSlide>
                        <div className="upperCart">
                          <Card>
                            <Card.Img
                              variant="top"
                              src={hygoneCardImg}
                              className="hygoneCardImg"
                            />
                            <Card.Body>
                              <h1 className="mainHeading">
                                Double Phoenijjjx....
                              </h1>
                              <img src={StarEmtyImg} className="starEmtyImg" />
                              <div className="cardPricing">
                                <p className="mainHeading">
                                  ₹ 12,990
                                  <span>₹ 15,990</span>
                                </p>
                              </div>
                            </Card.Body>
                          </Card>
                        </div>

                        <div className="upperCart">
                          <Card>
                            <Card.Img
                              variant="top"
                              src={hygoneCardImg}
                              className="hygoneCardImg"
                            />
                            <Card.Body>
                              <h1 className="mainHeading">
                                Double Phoenijjjx....
                              </h1>
                              <img src={StarEmtyImg} className="starEmtyImg" />
                              <div className="cardPricing">
                                <p className="mainHeading">
                                  ₹ 12,990
                                  <span>₹ 15,990</span>
                                </p>
                              </div>
                            </Card.Body>
                          </Card>
                        </div>

                        <div className="upperCart">
                          <Card>
                            <Card.Img
                              variant="top"
                              src={hygoneCardImg}
                              className="hygoneCardImg"
                            />
                            <Card.Body>
                              <h1 className="mainHeading">
                                Double Phoenijjjx....
                              </h1>
                              <img src={StarEmtyImg} className="starEmtyImg" />
                              <div className="cardPricing">
                                <p className="mainHeading">
                                  ₹ 12,990
                                  <span>₹ 15,990</span>
                                </p>
                              </div>
                            </Card.Body>
                          </Card>
                        </div>
                      </SwiperSlide> */}
                    </Swiper>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BeautyHygiene;
