import React from "react";
import AccountSideBar from "../components/MyAccountDetailsSection/AccountSideBar";
// import Quickly from "../components/QuicklySection/QuicklySection";
import { Outlet } from "react-router-dom";
import {   Route, Routes } from "react-router-dom";
// import OtherProductSection from "../components/MyAccountDetailsSection/OtherProductSection";
// import GrocerySlider from "../components/Grocery/GrocerySlider"
// import productImg from "../assets
import productImg from "../assets/images/other-product-02.png"
import lableImg from "../assets/images/offer-tag.png"
// import OtherPopularProductSlider from "../components/MyAccountDetailsSection/OtherPopularProductSlider";
import OtherPopularProductSlider from "../components/MyAccountDetailsSection/OtherPopularProductSlider";
import ElectronicSlider from "../components/ElectronicSection/ElectronicSlider"
import Tshirt from "../assets/images/t-shirt.png"
export default function MyAccountPage() {
  return (
    <>
      {/* <Quickly /> */}
      <section className="user-panel tp-space">
        <div className="container">
          <div className="row">
            <AccountSideBar />
            <Outlet />
          </div>
        </div>
      </section>
      {/* gfhfgh */}
      <Routes>
        {/* Define a route for MyReview */}
        <Route path="/my-reviews" element={<OtherPopularProductSlider id={1} title={"Other Popular Products"}  productImg={productImg} lableImg={lableImg} slidesPerView={4}/>} />
        <Route path="/reviews" element={<OtherPopularProductSlider id={2} title={"Other Popular Products"}  productImg={productImg} lableImg={lableImg} slidesPerView={4}/>} />
        <Route path="wish-list" element={ <><ElectronicSlider id={1} title={"Similar Products"}  productImg={Tshirt} lableImg={null} slidesPerView={5}/>
        <ElectronicSlider id={2} title={"Similar Products"}  productImg={Tshirt} lableImg={null} slidesPerView={5}/></>} />       
        <Route path="my-cart" element={ <><ElectronicSlider id={1} title={"Similar Products"}  productImg={Tshirt} lableImg={null} slidesPerView={5}/>
        <ElectronicSlider id={2} title={"Similar Products"}  productImg={Tshirt} lableImg={null} slidesPerView={5}/></>} />       
      </Routes>
    </>
  );
}
