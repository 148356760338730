import React, { useState, useEffect } from "react";
// import DelivaryImg1 from "../../assets/img/delivryImg1.svg";
// import DelivaryImg2 from "../../assets/img/delivryImg2.svg";
// import DelivaryImg3 from "../../assets/img/delivryImg3.svg";
// import DelivaryImg4 from "../../assets/img/delivryImg4.svg";
import {
  axiosInstance,
  base_url,
  handleError,
  imgBaseUrl,
} from "../../Config/axiosConfig";
// import toastify from "../../Config/toastify";
// import BeautyColImg from "../../assets/img/beautyColImg.png";
const DelivarySection = () => {
  let [apiData, setApiData] = useState([]);

  useEffect(() => {
    const apiCall = async () => {
      try {
        let res = await axiosInstance.get(`${base_url}/v2/home/blog`);
        // console.log(res.data);
        setApiData(res?.data?.data);
      } catch (error) {
        let errorMsg = handleError(error);
        console.log(errorMsg);
      }
    };
    apiCall();
  }, []);

  return (
    <section className="delivarySection">
      <div className="container-lg">
        <div className="row">
          {apiData?.map((arr, i) => (
            <div key={i} className="col-md-3">
              <div className="delivaryColum">
                <img
                  src={`${imgBaseUrl}/${arr?.banner}`}
                  className="deliryImg"
                  alt="deliryImg"
                />
                <div className="delivryTextContent">
                  <h2 className="mainHeading">{arr?.title}</h2>
                  <p className="grayHeading">{arr?.title}</p>
                </div>
              </div>
            </div>
          ))}
          {/* <div className="col-md-3">
            <div className="delivaryColum">
              <img src={DelivaryImg2} className="deliryImg" />
              <div className="delivryTextContent">
                <h2 className="mainHeading">90 DAYS RETURN</h2>
                <p className="grayHeading">If goods have problems</p>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="delivaryColum">
              <img src={DelivaryImg3} className="deliryImg" />
              <div className="delivryTextContent">
                <h2 className="mainHeading">24/7 SUPPORT</h2>
                <p className="grayHeading">Dedicated support</p>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="delivaryColum">
              <img src={DelivaryImg4} className="deliryImg" />
              <div className="delivryTextContent">
                <h2 className="mainHeading">SECURE PAYMENT</h2>
                <p className="grayHeading">100% secure payment</p>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </section>
  );
};

export default DelivarySection;
