import { useRef, useState } from 'react'
import SliderImg from "../../assets/images/slider-img-01.png"
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";

// import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/thumbs";
import "swiper/css/navigation";
import 'swiper/css/free-mode';
// import { Controller } from 'swiper/modules';
import ThumbImg from "../../assets/images/thumb-01.png"
import LeftArrow from "../../assets/img/leftArrow.svg";
import RightArrow from "../../assets/img/rightArrow.svg";
// import mobileSlider from "../../assets/images/iphone.jfif"

import { Navigation, Pagination, Scrollbar, A11y, Controller,FreeMode, Thumbs } from "swiper/modules";
import "./slider.css"
export default function VerticalSlider() {
    const [firstSwiper, setFirstSwiper] = useState(null);
    const [secondSwiper, setSecondSwiper] = useState(null);
  const [imagesNavSlider, setImagesNavSlider] = useState(null);


    let id = 1 
    const swiperRef = useRef(null);
    // onclick
 
                        // <SwiperSlide><div className="swiper-slide">
                        //     <img src={SliderImg} alt="..." />
                        // </div></SwiperSlide>
                        const slides = [
                            "https://picsum.photos/1920/1080",
                            "https://picsum.photos/1920/1081",
                            "https://picsum.photos/1920/1082",
                            "https://picsum.photos/1920/1083",
                            "https://picsum.photos/1920/1084",
                            "https://picsum.photos/1920/1085",
                            "https://picsum.photos/1920/1085",
                          ];
    return (
        <>
        <div className="App">
          <section className="slider">
            <div className="slider__flex">
              <div className="slider__col">
                <div className="slider__prev">Prev</div>
  
                <div className="slider__thumbs">
                  <Swiper
                    onSwiper={() => setImagesNavSlider()}
                    direction="vertical"
                    spaceBetween={10}
                    slidesPerView={5}
                    navigation={{
                      nextEl: ".slider__next",
                      prevEl: ".slider__prev",
                    }}
                    loop={true}
                    className="swiper-container1"
                    breakpoints={{
                      0: {
                        direction: "horizontal",
                      },
                      768: {
                        direction: "vertical",
                      },
                    }}
                    modules={[FreeMode,Navigation, Thumbs]}
  
                  >
                    {slides.map((slide, index) => {
                      return (
                        <SwiperSlide key={index}>
                          <div className="slider__image">
                            <img src={slide} alt="" />
                          </div>
                        </SwiperSlide>
                      );
                    })}
                  </Swiper>
                </div>
  
                <div className="slider__next">Next</div>
              </div>
  
              <div className="slider__images">
                {imagesNavSlider !== null && (
                  <Swiper
                    thumbs={{ swiper: imagesNavSlider }}
                    direction="horizontal"
                    slidesPerView={1}
                    spaceBetween={32}
                    mousewheel={true}
                    navigation={{
                      nextEl: ".slider__next",
                      prevEl: ".slider__prev",
                    }}
                    
                    loop={true}
                    breakpoints={{
                      0: {
                        direction: "horizontal",
                      },
                      768: {
                        direction: "horizontal",
                      },
                    }}
                    className="swiper-container2"
                    // autoplay={{
                    //   delay: 2500,
                    //   disableOnInteraction: false
                    // }}
                    modules={[FreeMode,Navigation, Thumbs]}
                  >
                    {slides.map((slide, index) => {
                      return (
                        <SwiperSlide key={index}>
                          <div className="slider__image">
                            <img src={slide} alt="" />
                          </div>
                        </SwiperSlide>
                      );
                    })}
                  </Swiper>
                )}
              </div>
            </div>
          </section>
        </div>
      </>
    )
}
