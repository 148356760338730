import React from "react";
import appImg from "../../assets/img/appImg.png";
import googleStoreImg from "../../assets/img/googleStoreImg.png";
import appStoreImg from "../../assets/img/appStoreImg.png";
import { Link } from "react-router-dom";

const AppDownloadSection = () => {
  return (
    <section className="AppDownloadSection constantPadding">
      <div className="appDownloadContainer">
        <div className="row">
          <div className="col-lg-6">
            <div className="appImgContainer">
              <img src={appImg} className="appImg"  alt="img"/>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="appTextContainer">
              <h1 className="mainHeading">
                Download Nerozon App <span> Now </span>{" "}
              </h1>
              <div className="appSmtext">
                <p className="grayHeading">Fast, Simple & Delightful.</p>
                <p className="grayHeading">
                  All it takes is 15 seconds to Download.
                </p>
              </div>
              <div className="appDownloadBtns">
                <Link to="#">
                  <img src={googleStoreImg} className="appDownloadImg" alt="img"/>
                </Link>
                <Link to="#">
                  <img src={appStoreImg} className="appDownloadImg"  alt="img" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AppDownloadSection;
