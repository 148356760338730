import React, { useEffect, useState } from "react";

// import OtherProductSection from "../OtherPopularProductSlider";

// import OtherProductSection from "../OtherPopularProductSlider";

// import OtherProductSection from "../../OtherProductSection";
import { Link } from "react-router-dom";
import addImg_04 from "../../../assets/images/add-03.png";
import productImg_02 from "../../../assets/images/prodcu-02.png"
import productImg_03 from "../../../assets/images/prodcu-03.png"
import productImg_04 from "../../../assets/images/prodcu-04.png"
import {
  handleError,
  axiosInstance,
  base_url,
  imgBaseUrl,
} from "../../../Config/axiosConfig";
import toastify from "../../../Config/toastify";
export default function MyReview() {
  let [apiData, setApiData] = useState([]);
  let token = localStorage.getItem("token")
  useEffect(() => {
    (async () => {
      try {
        let res = await axiosInstance.get(`${base_url}/v2/reviews/list`,{headers: {
          Authorization: "Bearer " + token,
        }});
        console.log(res.data);
        setApiData(res?.data?.data);
      } catch (error) {
        let errorMsg = handleError(error);
        console.log(errorMsg);
      }
    })();
  }, []);

  let handleDelete = async (id) => {
    try {
      let res = await axiosInstance.get(`${base_url}/v2/reviews/delete/${id}`,{headers: {
        Authorization: "Bearer " + token,
      }});
      console.log(res.data);
      console.log(res.data.data);
      setApiData((curval) => {
        return curval.filter((arr) => arr.id != res?.data?.data);
      });

      if (res?.data?.result) {
        toastify.success(res.data.message);
      } else {
        toastify.error(res.data.message);
      }
    } catch (error) {
      let errorMsg = handleError(error);
      console.log(errorMsg);
    }
  };

  return (
    <>
      <div className="col-lg-6 col-md-8">
        <div className="panel-cards">
          {apiData.map((arr) => (
            <div key={arr?.id}>
              <h3 className="title mb-3 fz-18 fw-700">
                My Reviews <span className="text-secondary"> </span>
              </h3>
              <div className="review-product my-review">
                <div className="review-product-rgt">
                  <div className="review-product-rgt-img me-2">
                    <img src={`${arr?.image}`} alt />
                  </div>
                  <div className="review-product-rgt-content text-start me-3">
                    <h6>
                      {" "}
                      {arr?.comment}
                     
                    </h6>
                    <p>
                      <span
                        className={
                          arr?.rating >= 4
                            ? " bg-warning"
                            : arr.rating >= 2
                            ? "bg-info"
                            : "bg-danger"
                        }
                      >
                        {arr?.rating}
                        <i className="bi bi-star-fill ms-1" />
                      </span>
                     
                    </p>
                    <div className="review-lyk">
                      <div>
                        <p className="fz-12 fw-400">
                           
                        </p>
                        <Link
                          to="/my-account/edit-reviews"
                          className="org-txt fz-12 fw-700 me-2"
                          state={arr}
                        >
                          Edit
                        </Link>
                        <a
                          href="#"
                          className="org-txt fz-12 fw-700 me-2"
                          onClick={() => handleDelete(arr?.id)}
                        >
                          Delete
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr />
            </div>
          ))}
          {/* <h3 className="title mb-3 fz-18 fw-700">
            My Reviews <span className="text-secondary">(7)</span>
          </h3>
          <div className="review-product my-review">
            <div className="review-product-rgt">
              <div className="review-product-rgt-img">
                <img src={productImg_02} alt />
              </div>
              <div className="review-product-rgt-content text-start me-3">
                <h6>
                  {" "}
                  Bodywise 1% Salicylic Acid Body Wash - Prevents Body Acne -
                  SLS &amp; Paraben Free - Shower Gel
                </h6>
                <p>
                  <span>
                    4<i className="bi bi-star-fill ms-1" />
                  </span>
                  Absolute rubbish!
                </p>
                <div className="review-lyk">
                  <div>
                    <p className="fz-12 fw-400">
                      {" "}
                      Nerozon Customer <i className="fas fa-check-circle" />{" "}
                      Certified Buyer 27 Oct, 2022{" "}
                    </p>
                    <a
                      href="#"
                      className="org-txt fz-12 fw-700 me-2"
                    >
                      Edit
                    </a>
                    <a
                      href="#"
                      className="org-txt fz-12 fw-700 me-2"
                    >
                      Delete
                    </a>
                    <a
                      href="#"
                      className="org-txt fz-12 fw-700 me-2"
                    >
                      Share
                    </a>
                  </div>
                  <div className="review-lyk-thumb">
                    <a href="#">
                      <i className="bi bi-hand-thumbs-up-fill" />0
                    </a>
                    <a href="#">
                      <i className="bi bi-hand-thumbs-down-fill" />0
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <h3 className="title mb-3 fz-18 fw-700">
            My Reviews <span className="text-secondary">(7)</span>
          </h3>
          <div className="review-product my-review">
            <div className="review-product-rgt">
              <div className="review-product-rgt-img">
                <img src={productImg_03} alt />
              </div>
              <div className="review-product-rgt-content text-start me-3">
                <h6>
                  {" "}
                  Bodywise 1% Salicylic Acid Body Wash - Prevents Body Acne -
                  SLS &amp; Paraben Free - Shower Gel
                </h6>
                <p>
                  <span className="bg-danger">
                    1<i className="bi bi-star-fill ms-1" />
                  </span>
                  Absolute rubbish!
                </p>
                <div className="review-lyk">
                  <div>
                    <p className="fz-12 fw-400">
                      {" "}
                      Nerozon Customer <i className="fas fa-check-circle" />{" "}
                      Certified Buyer 27 Oct, 2022{" "}
                    </p>
                    <a
                      href="#"
                      className="org-txt fz-12 fw-700 me-2"
                    >
                      Edit
                    </a>
                    <a
                      href="#"
                      className="org-txt fz-12 fw-700 me-2"
                    >
                      Delete
                    </a>
                    <a
                      href="#"
                      className="org-txt fz-12 fw-700 me-2"
                    >
                      Share
                    </a>
                  </div>
                  <div className="review-lyk-thumb">
                    <a href="#">
                      <i className="bi bi-hand-thumbs-up-fill" />0
                    </a>
                    <a href="#">
                      <i className="bi bi-hand-thumbs-down-fill" />0
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <h3 className="title mb-3 fz-18 fw-700">
            My Reviews <span className="text-secondary">(7)</span>
          </h3>
          <div className="review-product my-review">
            <div className="review-product-rgt">
              <div className="review-product-rgt-img">
                <img src={productImg_04} alt />
              </div>
              <div className="review-product-rgt-content text-start me-3">
                <h6>
                  {" "}
                  Bodywise 1% Salicylic Acid Body Wash - Prevents Body Acne -
                  SLS &amp; Paraben Free - Shower Gel
                </h6>
                <p>
                  <span className="bg-danger">
                    1<i className="bi bi-star-fill ms-1" />
                  </span>
                  Absolute rubbish!
                </p>
                <div className="review-lyk">
                  <div>
                    <p className="fz-12 fw-400">
                      {" "}
                      Nerozon Customer <i className="fas fa-check-circle" />{" "}
                      Certified Buyer 27 Oct, 2022{" "}
                    </p>
                    <a
                      href="#"
                      className="org-txt fz-12 fw-700 me-2"
                    >
                      Edit
                    </a>
                    <a
                      href="#"
                      className="org-txt fz-12 fw-700 me-2"
                    >
                      Delete
                    </a>
                    <a
                      href="#"
                      className="org-txt fz-12 fw-700 me-2"
                    >
                      Share
                    </a>
                  </div>
                  <div className="review-lyk-thumb">
                    <a href="#">
                      <i className="bi bi-hand-thumbs-up-fill" />0
                    </a>
                    <a href="#">
                      <i className="bi bi-hand-thumbs-down-fill" />0
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          <hr />
        </div>
      </div>
      {/* <OtherProductSection/> */}
      <div className="col-lg-3 d-none d-lg-block d-xl-block">
        <img src={addImg_04} className="w-100" alt="" />
      </div>
    </>
  );
}
