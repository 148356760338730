import React from 'react'
import erroimg from '../../assets/images/error.png'
import { Link } from 'react-router-dom'

export default function NotFound() {
  return (
    < > 
    <section className="error">
  <div className="container">
    <div className="row justify-content-center">
      <div className="col-lg-8 col-md-12 text-center">
        <img src={erroimg} />
        <div>
        <Link to="/" className="org-btn-02  ">Back To home</Link>
        </div>
      </div>
    </div>
  </div>
</section>

    </>
  )
}
