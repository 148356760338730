import React, { useContext, useEffect, useState } from "react";
import { Link, useSearchParams, useNavigate } from "react-router-dom";
import BoxImg from "../../assets/images/box.png";
import ElectronicImg from "../../assets/images/electronic-item-01.png";
import {
  axiosInstance,
  base_url,
  handleError,
  imgBaseUrl,
} from "../../Config/axiosConfig";
import toastify from "../../Config/toastify";
import CardContext from "../Context/CardContext";
import FilterPoduct from "../GroceryProduct/FilterPoduct";
export default function ElectronicSection() {
  // const navigate=useNavigate()
  let navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  let [data, setdata] = useState([]); 
  let [filterData, setFilterData] = useState([]);
  let [links, setLinks] = useState([]);
  let [meta, setMeta] = useState([]);
  let { card, setCard } = useContext(CardContext);
  const [wishlistData, setWishlistData] = useState([]); 
  // const [handleWishlist, setHandleWishlist] = useState(false); 

  let type = searchParams.get("type");
  let productName = searchParams.get(type);
  let token = localStorage.getItem("token")
  // console.log(token);
  
  useEffect(() => {
    if(token){
      wishlistApi()
    }
    if (type === "product") {
      let productName = searchParams.get("product");
      // console.log(productName);
      let handleSearchProduct = async () => {
        try {
          let res = await axiosInstance.post(`${base_url}/v2/products/search`, {
            name: productName,
          });
          console.log(res?.data);
          setdata(res?.data?.data?.product);
          setFilterData(res?.data?.data?.product);
          setLinks(res?.data?.links);
          setMeta(res?.data?.meta);
        } catch (error) {
          let errorMsg = handleError(error);
          console.log(errorMsg);
        }
      };
      handleSearchProduct();
    }
    if (type === "category") {
      let id = searchParams.get("id");
      let handleSearchProduct = async () => {
        try {
          let res = await axiosInstance.get(
            `${base_url}/v2/products/category/${id}`,
            {}
          );
          console.log(res.data);
          setdata(res?.data?.data);
          setFilterData(res?.data?.data);

          setLinks(res?.data?.links);
          setMeta(res?.data?.meta);
        } catch (error) {
          let errorMsg = handleError(error);
          console.log(errorMsg);
        }
      };
      handleSearchProduct();
    }
  }, [type, productName]);


 
 
  let wishlistApi=async ()=>{
    try {
      let wishlistRes = await axiosInstance.get(`${base_url}/v2/wishlists`, {
        headers: {
          Authorization: "Bearer " + token,
        },  
      });
      // console.log(wishlistRes.data.data);
      setWishlistData(wishlistRes.data.data)
    } catch (error) {
      let errorMsg = handleError(error);
      console.log(errorMsg);
    }
  }

  let priceLowToHigh = () => {
    const sortedProducts = data.sort((a, b) =>parseFloat(a.unit_price) - parseFloat(b.unit_price));
    setdata(sortedProducts);
  };

  let priceHighToLow = () => {
    const sortedProducts = data.sort((a, b) =>  parseFloat(b.unit_price) - parseFloat(a.unit_price));
    setdata(sortedProducts);
  };

  

  const handleAddToCard = async (e, id,product) => {
    // console.log(card);
    // console.log(id);
    // console.log(product.unit);
    console.log(product);
    
    
    
    
    // return
    // console.log(product);
    // console.log(product.unit );
    // card.forEach(element => {
    //   // if(element.product_id  === id){
    //   //   console.log(element.quantity, "element quantity " ,element.product_id,id)
    //   // }
    // });
    // return
    // if(product.unit )
    // return
    //for variant data
    // let filterAddToCardData=data.filter((arr)=>(arr?.id == id))
    // let jsonData=JSON.parse(filterAddToCardData[0].choice_options)
    // let variantData=jsonData[0]?.values[0] ||null
  // let jsonData=JSON.parse(product?.choice_options)
  let variantData=product.variations  || null  
  console.log(variantData);

  if(variantData){
    toastify.info("Please Select Varinats First");
    return navigate(`/product-details/${id}`)
  }

  // let cart=card?.filter((arr)=>arr.product_id === id)
  // console.log(cart);
  // if( cart !== undefined && product.unit <= cart[0]?.quantity){
  //   return  toastify.error("max item add to cart")
  // } 
 
  
    if (!token) {
      console.log(card);
      toastify.success("Add Item In Cart");
      return setCard((curVal) =>{
        let dataVal =curVal.some((cartItem) => cartItem.product_id == id && cartItem.variation == variantData)
          ? curVal.map((cart) =>
              cart.product_id == id && cart.variation == variantData && cart.quantity < product.unit
                ? { ...cart, quantity: ++cart.quantity }
                : cart
            )
          : [...curVal, {  product_id: id, quantity: 1, variation: variantData || null ,product_name:product?.name, product_thumbnail_image : product?.thumbnail_img, price:product?.unit_price, currency_symbol : "RS" ,
            unit:product.unit || 1 }]
            localStorage.setItem("guestUser",JSON.stringify(dataVal))
            return dataVal
      });
    } 
 
    try {
      let res = await axiosInstance.post(`${base_url}/v2/carts/add`, {
        id: id,
        quantity: 1,
        variant:variantData
      },{
        headers:{
          Authorization: "Bearer " + token
        }
      });
      if (res.data.result) {
        setCard((curVal) =>
          curVal?.some((cartItem) => cartItem?.product_id == id)
            ? curVal?.map((cart) =>
                cart?.product_id == id && cart.variant == variantData
                  ? { ...cart, quantity: ++cart.quantity }
                  : cart
              )
            : [...curVal , { product_id: id, quantity: 1 ,variation : variantData || null}]
        );
        // setCard((curVal)=> curVal.some((cartItem)=>(cartItem.product_id == id)) ? curVal.map(cart=> cart.product_id == id ? {...cart,quantity :++cart.quantity} : cart) : [ ...curVal , {product_id:id,quantity:1}] )
        // setCard((curVal)=> curVal.some((arr)=>(arr === id)) ? curVal : [...curVal,id])
        // setCard((curVal)=> curVal.some((arr)=>(arr === id)) ? curVal : [...curVal,id])
        // setCard((curVal)=> curVal.id === id ? curVal:[...curVal,{id:id}] )
        // setCard((curVal) => ++curVal);
        toastify.success(res?.data?.message);
      } else {
        toastify.error(res?.data?.message); 
      }
    } catch (error) {
      // toastify.error("Some error")
      let errorMsg = handleError(error);
      console.log(errorMsg);
    }
  };

  let filterBasedOnDate = () => {
    data.sort((a, b) => {
      return  new Date(b.created_at) - new Date(a.created_at) ;
    });    
  };

  const handleAddWishList = async (id,i) => {
    // setHandleWishlist(true)
    if(!token){
      toastify.error("Please login first")
      let unique = document.getElementById("openLoginFormBtn");
     return  unique.click();
    }
    let data=wishlistData.some((wishitem)=>wishitem?.product?.id ==  id )
    if(data){
      let res = await axiosInstance.post(
        `${base_url}/v2/wishlists-remove-product`,
        {
          product_id: id,
        },
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      if(!(res.data.is_in_wishlist)){
        toastify.success(res.data.message)
       return wishlistApi()
      }
    }
    try {
      let res = await axiosInstance.post(`${base_url}/v2/wishlists-add-product`, {
        product_id: id,
      },{
        headers:{
          Authorization: "Bearer " + token
        }
      });
      console.log(res, "add to wishlist");
      if (res.data.is_in_wishlist) {
        // setHandleWishlist((prv)=>++prv)
        wishlistApi()
          toastify.success(res?.data?.message);
      } else {
        toastify.error(res?.data?.message);
      }
      // setApiData(res?.data?.data[0]);
    } catch (error) {

      let errorMsg = handleError(error);
      console.log(errorMsg);
    }
    
  };
 
  return (
    <>
  {/* let [data, setdata] = useState([]);  */}
    <FilterPoduct filterData={filterData} data={data} setData={setdata} />
      <div className="col-lg-9">
        <div className="custom-bredcump">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Home</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="#">Clothing</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="#">Ladies</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Tops &amp; T-shirts
            </li>
          </ol>
          <p>
            Showing {data?.length} Results for “{productName}”{" "}
            <span>( {data?.length} )</span>
          </p>
        </div>
        <div className="row">
          <div className="col-lg-9">
            <ul className="sort-by-list">
              <li>
                <i className="bi bi-arrow-down-up me-2"></i>
                Sort By:
              </li>
              <li>
                <Link to="#" className="active">
                  Relevance
                </Link>
              </li>
              <li>
                <Link to="#">Popularity</Link>
              </li>
              <li>
                <Link to="#" onClick={priceHighToLow} >
                  Price - High to Low
                </Link>
              </li>
              <li>
                <Link onClick={priceLowToHigh}> Price - Low to High</Link>
              </li>
              <li>
                <Link to="#" onClick={filterBasedOnDate}>
                  Newest First
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-lg-3 text-end">
            <Link to="#">
              <img src={BoxImg} alt="" />
            </Link>
          </div>
        </div>
        {data?.length > 0 ? (
          data?.map((arr,i ) => (
          <div key={arr?.id} className="electronics-product-main">
            <div className="electronics-product-lft">
              <Link to={`/product-details/${arr?.id}`}>
                <div className="electronics-product-lft-img">
                  <img src={`${imgBaseUrl}/${arr?.thumbnail_img|| arr?.thumbnail_image}`} alt="" />
                </div>
              </Link>
              <div className="electronics-product-lft-content ">
                <h5>{arr?.name} </h5>
                <p>
                  <span>
                    {arr?.rating} <i className="bi bi-star-fill" />
                  </span>{" "}
                  {/* (1,512) {" "} */}
                  {/* <Link to="/comprasion" className="org-txt fz-18 fw-500">
                                    Add to Compare
                                </Link> */}
                </p>
                <div
                  className="product-txt"
                  dangerouslySetInnerHTML={{ __html: arr?.description }}
                >
                  {/* <p> 6 GB RAM | 64 GB ROM | Expandable Upto 512 GB</p>
                                <p>16.94 cm (6.67 inch) Full HD+ Display</p>
                                <p>64MP + 8MP + 2MP + 2MP | 20MP + 2MP Dual Front Camera</p>
                                <p>4500 mAh Lithium-ion Polymer Battery</p>
                                <p>Qualcomm Snapdragon 730 Processor</p> */}
                </div>
              </div>

              
            </div>
            <div className="electronics-product-rgt">
              <h5>
                <Link>
                { wishlistData?.some((wishitem)=>wishitem?.product?.id == arr?.id)  ? <span className="bi bi-suit-heart-fill"  onClick={()=>handleAddWishList(arr.id,i)}/>
                 :  <span className="bi bi-suit-heart"  onClick={()=>handleAddWishList(arr.id ,i)}/>}
                </Link> 
    
              </h5>
              <p className="dtl-price">
                {/* <span> ₹ {arr?.purchase_price || 0} </span> */}
                <span> ₹ {arr?.unit_price || 0} </span>
                <del>₹ {arr?.unit_price}</del> <em> 
                    {/* 8% Off */}
                     </em>
                {/* <del>₹ {arr?.unit_price }</del> <em>  8% Off { "₹ " + arr?.discount}</em> */}
              </p>
              <button
                to="#"
                onClick={(e) => handleAddToCard(e, arr?.id,arr)}
                className="org-btn-02"
              >
                <i className="fal fa-cart-plus me-2" />
                Add to Cart
              </button>
              <Link to="#" className="org-btn-02 ">
                Buy Now
              </Link>
            </div>
          </div>
        ))) : (
          <div className="breadcrumb-item  mt-2 ms-3"> No data found</div>
        )}
      </div>
    </>
  );
}

// add filter data gird page
