import React, {  useRef } from "react";
import HoleCardImg from "../../assets/img/holeCardImg.png";
import StarImg from "../../assets/img/StarImg.svg";
import LeftArrow from "../../assets/img/leftArrow.svg";
import RightArrow from "../../assets/img/rightArrow.svg";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import Card from "react-bootstrap/Card";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { Link } from "react-router-dom";
// import {
//   axiosInstance,
//   base_url,
//   handleError,
//   imgBaseUrl,
// } from "../../Config/axiosConfig";
// import toastify from "../../Config/toastify";
// import BeautyColImg from "../../assets/img/beautyColImg.png";
// import { Link } from "react-router-dom";

const SwiperSlider = ({ id, heading }) => {
  const swiperRef = useRef(null);

  return (
    <section className="HoleSaleSection1 constantPadding">
      <div className="holeSaleContainer">
        <div className="holeHeader">
          <h1 className="mainHeading">{heading}</h1>
          <div className="holeHeaderBtn">
            <Link to="#" className="mainBtn">
              View All
            </Link>
            <div className={`carouselPrv${id}`}>
              <img
                src={LeftArrow}
                className="carouslArrowImg"
                alt="sliderimg"
                onClick={() => swiperRef.current.swiper.slidePrev()}
              />
            </div>
            <div className={`carouselNext${id}`}>
              <img
                src={RightArrow}
                alt="sliderimg"
                className="carouslArrowImg"
                onClick={() => swiperRef.current.swiper.slideNext()}
              />
            </div>
          </div>
        </div>
        <div className="holeSaleCarousel">
          <div className="uperCarouselContainer">
            <Swiper
              ref={swiperRef}
              modules={[Navigation, Pagination, Scrollbar, A11y]}
              loop={true}
              spaceBetween={3}
              slidesPerView={5.1}
              navigation={{
                prevEl: `.carouselPrv${id}`,
                nextEl: `.carouselNext${id}`,
              }}
              breakpoints={{
                100: {
                  slidesPerView: 1,
                  spaceBetween: 20,
                },
                650: {
                  slidesPerView: 3,
                  spaceBetween: 30,
                },
                1024: {
                  slidesPerView: 5,
                  spaceBetween: 40,
                },
              }}
            >
              <SwiperSlide>
                <div className="upperCart upperCartNew">
                  <Card>
                    <Card.Img
                      variant="top"
                      src={HoleCardImg}
                      className="mobileImg"
                      alt="sliderimg"
                    />
                    <Card.Body>
                      <h1 className="mainHeading">Read monsters jem...</h1>
                      <div className="cardReview">
                        <img src={StarImg} className="starImg"  alt="sliderimg"/>
                        <p className="grayHeading">(1,512)</p>
                      </div>
                      <div className="cardPricing">
                        <p className="mainHeading">
                          ₹ 12,990
                          <span>₹ 15,990</span>
                        </p>
                      </div>
                    </Card.Body>
                  </Card>
                </div>
                <div className="shimmer-cart"> </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="upperCart">
                  <Card>
                    <Card.Img
                      variant="top"
                      src={HoleCardImg}
                      className="mobileImg"
                      alt="sliderimg"
                    />
                    <Card.Body>
                      <h1 className="mainHeading">Read monsters jem...</h1>
                      <div className="cardReview">
                        <img src={StarImg} className="starImg"  alt="sliderimg"/>
                        <p className="grayHeading">(1,512)</p>
                      </div>
                      <div className="cardPricing">
                        <p className="mainHeading">
                          ₹ 12,990
                          <span>₹ 15,990</span>
                        </p>
                      </div>
                    </Card.Body>
                  </Card>
                </div>
                <div className="shimmer-cart"> </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="upperCart">
                  <Card>
                    <Card.Img
                      variant="top"
                      src={HoleCardImg}
                      className="mobileImg"
                      alt="sliderimg"
                    />
                    <Card.Body>
                      <h1 className="mainHeading">Read monsters jem...</h1>
                      <div className="cardReview">
                        <img src={StarImg} className="starImg"  alt="sliderimg"/>
                        <p className="grayHeading">(1,512)</p>
                      </div>
                      <div className="cardPricing">
                        <p className="mainHeading">
                          ₹ 12,990
                          <span>₹ 15,990</span>
                        </p>
                      </div>
                    </Card.Body>
                  </Card>
                </div>
                <div className="shimmer-cart"> </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="upperCart">
                  <Card>
                    <Card.Img
                      variant="top"
                      src={HoleCardImg}
                      className="mobileImg"
                      alt="sliderimg"
                    />
                    <Card.Body>
                      <h1 className="mainHeading">Read monsters jem...</h1>
                      <div className="cardReview">
                        <img src={StarImg} className="starImg" alt="sliderimg" />
                        <p className="grayHeading">(1,512)</p>
                      </div>
                      <div className="cardPricing">
                        <p className="mainHeading">
                          ₹ 12,990
                          <span>₹ 15,990</span>
                        </p>
                      </div>
                    </Card.Body>
                  </Card>
                </div>
                <div className="shimmer-cart"> </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="upperCart">
                  <Card>
                    <Card.Img
                      variant="top"
                      src={HoleCardImg}
                      className="mobileImg"
                      alt="sliderimg"
                    />
                    <Card.Body>
                      <h1 className="mainHeading">Read monsters jem...</h1>
                      <div className="cardReview">
                        <img src={StarImg} className="starImg" alt="sliderimg" />
                        <p className="grayHeading">(1,512)</p>
                      </div>
                      <div className="cardPricing">
                        <p className="mainHeading">
                          ₹ 12,990
                          <span>₹ 15,990</span>
                        </p>
                      </div>
                    </Card.Body>
                  </Card>
                </div>
                <div className="shimmer-cart"> </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="upperCart">
                  <Card>
                    <Card.Img
                      variant="top"
                      src={HoleCardImg}
                      className="mobileImg"
                      alt="sliderimg"
                    />
                    <Card.Body>
                      <h1 className="mainHeading">Read monsters jem...</h1>
                      <div className="cardReview">
                        <img src={StarImg} className="starImg"  alt="sliderimg"/>
                        <p className="grayHeading">(1,512)</p>
                      </div>
                      <div className="cardPricing">
                        <p className="mainHeading">
                          ₹ 12,990
                          <span>₹ 15,990</span>
                        </p>
                      </div>
                    </Card.Body>
                  </Card>
                </div>
                <div className="shimmer-cart"> </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="upperCart">
                  <Card>
                    <Card.Img
                      variant="top"
                      src={HoleCardImg}
                      className="mobileImg"
                      alt="sliderimg"
                    />
                    <Card.Body>
                      <h1 className="mainHeading">Read monsters jem...</h1>
                      <div className="cardReview">
                        <img src={StarImg} className="starImg"  alt="sliderimg"/>
                        <p className="grayHeading">(1,512)</p>
                      </div>
                      <div className="cardPricing">
                        <p className="mainHeading">
                          ₹ 12,990
                          <span>₹ 15,990</span>
                        </p>
                      </div>
                    </Card.Body>
                  </Card>
                </div>
                <div className="shimmer-cart"> </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SwiperSlider;
