import React, {  useRef } from "react";
// import HoleCardImg from "../../assets/img/holeCardImg.png";
import StarImg from "../../assets/img/StarImg.svg";
import LeftArrow from "../../assets/img/leftArrow.svg";
import RightArrow from "../../assets/img/rightArrow.svg";
import {Autoplay, Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import Card from "react-bootstrap/Card";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import {

  imgBaseUrl,
} from "../../Config/axiosConfig";
import toastify from "../../Config/toastify";
import BeautyColImg from "../../assets/img/beautyColImg.png";
import { Link } from "react-router-dom";
 

const HoleSaleSection1 = ({ id, heading, apiData }) => {
  const swiperRef = useRef(null);
 
 
  // let [apiData, setApiData] = useState([]);

  // useEffect(() => {
  //   (async () => {
  //     try {
  //       let res = await axiosInstance.get(`${base_url}${api}`);
  //       console.log(res.data);
  //       setApiData(res?.data?.data);
  //     } catch (error) {
  //       let errorMsg = handleError(error);
  //       console.log(errorMsg);
  //     }
  //   })();
  // }, []);

  return (
    <section className="HoleSaleSection1 constantPadding">
      <div className="holeSaleContainer">
        <div className="holeHeader">
          <h1 className="mainHeading">{heading}</h1>
          <div className="holeHeaderBtn">
            <Link to="#" className="mainBtn">
              View All
            </Link>
            <div className={`carouselPrv${id}`}>
              <img
                src={LeftArrow}
                className="carouslArrowImg"
                onClick={() => swiperRef.current.swiper.slidePrev()}
              />
            </div>
            <div className={`carouselNext${id}`}>
              <img
                src={RightArrow}
                className="carouslArrowImg"
                onClick={() => swiperRef.current.swiper.slideNext()}
              />
            </div>
          </div>
        </div>
        <div className="holeSaleCarousel">
          <div className="uperCarouselContainer">
            <Swiper
              ref={swiperRef}
              modules={[ Autoplay, Navigation, Pagination, Scrollbar, A11y]}
              loop={true}
              // autoplay={{
              //   delay: 2500,
              //   disableOnInteraction: false,
              // }}
              spaceBetween={3}
              slidesPerView={5.1}
              navigation={{
                prevEl: `.carouselPrv${id}`,
                nextEl: `.carouselNext${id}`,
              }}
              // loop={apiData?.length > 1}
              // onSlideChange={() => console.log("slide change")}
              // onSwiper={(swiper) => console.log(swiper)}
              breakpoints={{
                100: {
                  slidesPerView: 1,
                  spaceBetween: 20,
                },
                650: {
                  slidesPerView: 3,
                  spaceBetween: 30,
                },
                1024: {
                  slidesPerView: 5 ,
                  spaceBetween: 40,
                },
              }}
            >
              {apiData == "undefined " ? (
                <div className="upperCart upperCartNew">
                  <div className="shimmer-cart"> </div>
                </div>
              ) : (
                apiData?.map((arr, i) => (
                  <SwiperSlide key={i}>
                    <div className="upperCart">
                      <Link to={`/product-details/${arr?.id}`}>
                        <Card>
                          <Card.Img
                            variant="top"
                            src={`${imgBaseUrl}/${arr?.banner}`}
                            className="mobileImg"
                          />
                          <Card.Body>
                            <h1 className="mainHeading">
                              {arr?.name.split(" ")[0]}{" "}
                              {arr?.name.split(" ")[1]}{" "}
                              {arr?.name.split(" ")[2]} .... 
                            </h1>
                            <div className="cardReview">
                              <img src={StarImg} className="starImg" />
                              <p className="grayHeading">(1,512)</p>
                            </div>
                            <div className="cardPricing">
                              <p className="mainHeading">
                                ₹ {arr?.unit_price}
                                {arr?.discount && (
                                  <span>
                                    ₹ {arr?.discount + arr?.unit_price}
                                  </span>
                                )}
                              </p>
                            </div>
                          </Card.Body>
                        </Card>
                      </Link>
                    </div>
                  </SwiperSlide>
                ))
              )}
              {/* <SwiperSlide>
                <div className="upperCart">
                  <Card>
                    <Card.Img
                      variant="top"
                      src={HoleCardImg}
                      className="mobileImg"
                    />
                    <Card.Body>
                      <h1 className="mainHeading">Read monsters jem...</h1>
                      <div className="cardReview">
                        <img src={StarImg} className="starImg" />
                        <p className="grayHeading">(1,512)</p>
                      </div>
                      <div className="cardPricing">
                        <p className="mainHeading">
                          ₹ 12,990
                          <span>₹ 15,990</span>
                        </p>
                      </div>
                    </Card.Body>
                  </Card>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="upperCart">
                  <Card>
                    <Card.Img
                      variant="top"
                      src={HoleCardImg}
                      className="mobileImg"
                    />
                    <Card.Body>
                      <h1 className="mainHeading">Read monsters jem...</h1>
                      <div className="cardReview">
                        <img src={StarImg} className="starImg" />
                        <p className="grayHeading">(1,512)</p>
                      </div>
                      <div className="cardPricing">
                        <p className="mainHeading">
                          ₹ 12,990
                          <span>₹ 15,990</span>
                        </p>
                      </div>
                    </Card.Body>
                  </Card>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="upperCart">
                  <Card>
                    <Card.Img
                      variant="top"
                      src={HoleCardImg}
                      className="mobileImg"
                    />
                    <Card.Body>
                      <h1 className="mainHeading">Read monsters jem...</h1>
                      <div className="cardReview">
                        <img src={StarImg} className="starImg" />
                        <p className="grayHeading">(1,512)</p>
                      </div>
                      <div className="cardPricing">
                        <p className="mainHeading">
                          ₹ 12,990
                          <span>₹ 15,990</span>
                        </p>
                      </div>
                    </Card.Body>
                  </Card>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="upperCart">
                  <Card>
                    <Card.Img
                      variant="top"
                      src={HoleCardImg}
                      className="mobileImg"
                    />
                    <Card.Body>
                      <h1 className="mainHeading">Read monsters jem...</h1>
                      <div className="cardReview">
                        <img src={StarImg} className="starImg" />
                        <p className="grayHeading">(1,512)</p>
                      </div>
                      <div className="cardPricing">
                        <p className="mainHeading">
                          ₹ 12,990
                          <span>₹ 15,990</span>
                        </p>
                      </div>
                    </Card.Body>
                  </Card>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="upperCart">
                  <Card>
                    <Card.Img
                      variant="top"
                      src={HoleCardImg}
                      className="mobileImg"
                    />
                    <Card.Body>
                      <h1 className="mainHeading">Read monsters jem...</h1>
                      <div className="cardReview">
                        <img src={StarImg} className="starImg" />
                        <p className="grayHeading">(1,512)</p>
                      </div>
                      <div className="cardPricing">
                        <p className="mainHeading">
                          ₹ 12,990
                          <span>₹ 15,990</span>
                        </p>
                      </div>
                    </Card.Body>
                  </Card>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="upperCart">
                  <Card>
                    <Card.Img
                      variant="top"
                      src={HoleCardImg}
                      className="mobileImg"
                    />
                    <Card.Body>
                      <h1 className="mainHeading">Read monsters jem...</h1>
                      <div className="cardReview">
                        <img src={StarImg} className="starImg" />
                        <p className="grayHeading">(1,512)</p>
                      </div>
                      <div className="cardPricing">
                        <p className="mainHeading">
                          ₹ 12,990
                          <span>₹ 15,990</span>
                        </p>
                      </div>
                    </Card.Body>
                  </Card>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="upperCart">
                  <Card>
                    <Card.Img
                      variant="top"
                      src={HoleCardImg}
                      className="mobileImg"
                    />
                    <Card.Body>
                      <h1 className="mainHeading">Read monsters jem...</h1>
                      <div className="cardReview">
                        <img src={StarImg} className="starImg" />
                        <p className="grayHeading">(1,512)</p>
                      </div>
                      <div className="cardPricing">
                        <p className="mainHeading">
                          ₹ 12,990
                          <span>₹ 15,990</span>
                        </p>
                      </div>
                    </Card.Body>
                  </Card>
                </div>
              </SwiperSlide> */}
            </Swiper>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HoleSaleSection1;
