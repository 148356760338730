import React, { useState, useEffect } from "react";
import {
  axiosInstance,
  handleError,
  imgBaseUrl,
  base_url,
} from "../../Config/axiosConfig";
// import Order_icon from "../../assets/images/order-icon.png"
import Add_icon from "../../assets/images/add-02.png";
import toastify from "../../Config/toastify";
import { Link } from "react-router-dom";

export default function WishList() {
  let [apiData, setApiData] = useState([]);
  let token = localStorage.getItem("token");
  useEffect(() => {
    (async () => {
      try {
        let res = await axiosInstance.get(`${base_url}/v2/wishlists`, {
          headers: {
            Authorization: "Bearer " + token,
          },
        });
        console.log(res.data.data);
        setApiData(res.data.data);
      } catch (error) {
        let errorMsg = handleError(error);
        console.log(errorMsg);
      }
    })();
  }, []);

  let removeProduct = async (id) => {
    try {
      let res = await axiosInstance.post(
        `${base_url}/v2/wishlists-remove-product`,
        {
          product_id: id,
        },
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      setApiData((curval) => {
        return curval.filter((arr) => arr.product.id !== res?.data?.product_id);
      });
      if (!res.data.is_in_wishlist) {
        toastify.success(res.data.message);
      } else {
        toastify.error(res.data.message);
      }
    } catch (error) {
      let errorMsg = handleError(error);
      console.log(errorMsg);
    }
  };

  // let addToCart = async (id) => {
  //   console.log(id)
  //   https://nerozon-admin.objectsquare.in/api/v2/products/variant/price
  //   try {
  //     let res = await axiosInstance.post(
  //       `${base_url}/v2/carts/add`,
  //       {
  //         id: id,
  //         quantity: 1,
  //         // variant: "M"
  //       },
  //       {
  //        headers: {
  //           Authorization: "Bearer " + token,
  //         },
  //       }
  //     );
  //     console.log(res.data);
  //     if (res.data.result) {
  //       toastify.success(res.data.message);
  //     } else {
  //       toastify.error(res.data.message);
  //     }
  //   } catch (error) {
  //     let errorMsg = handleError(error);
  //     console.log(errorMsg);
  //   }
  // };

  return (
    <>
      <div className="col-lg-6 col-md-8">
        <div className="panel-cards Wishlist"> 
          {apiData?.map((arr) => (
            <div key={arr?.product.id} className="order-dtl">
              <div className="order-dtl-lft">
                <div className="order-dtl-lft-img">
                  <img
                    src={`${imgBaseUrl}/${arr?.product?.thumbnail_image}`}
                    alt=""
                  />
                  <button
                    onClick={() => removeProduct(arr?.product?.id)}
                    className="text-danger fz-18 fw-600"
                  >
                    Remove
                  </button>
                </div>
                <div className="order-dtl-lft-content">
                  <h5>{arr?.product?.name}</h5>
                  
                  <p className="prc">
                    {arr?.product?.base_price} 
                    {/* <del>₹ 1,500</del>{" "}
                    <span>8% Off</span> */}
                  </p>
                </div>
              </div>
              <div className="order-dtl-rgt">
                <Link
                  // onClick={() => addToCart(arr?.product?.id)}
                  className="org-btn-02"
                  to={`/product-details/${arr?.product?.id}`}
                >
                  {/* <i className="bi bi-cart-check-fill me-2" /> */}
                  View Product 
                </Link>
              </div>
            </div>
          ))}
          <hr />
          {/* <div className="order-dtl">
            <div className="order-dtl-lft">
              <div className="order-dtl-lft-img">
                <img src={Order_icon} alt="" />
                <a href="#" className="text-danger fz-18 fw-600">
                  Remove
                </a>
              </div>
              <div className="order-dtl-lft-content">
                <h5>Women Black Printed A-Line Top x2</h5>
                <p>Color: Multi/ Size: M</p>
                <p>Seller Cliff Fashion, Ahmedabad</p>
                <p className="prc">
                  ₹ 1,000 <del>₹ 1,500</del> <span>8% Off</span>
                </p>
              </div>
            </div>
            <div className="order-dtl-rgt">
              <a href="#" className="org-btn-02">
                <i className="bi bi-cart-check-fill me-2" />
                Add to Cart
              </a>
            </div>
          </div>
          <hr />
          <div className="order-dtl">
            <div className="order-dtl-lft">
              <div className="order-dtl-lft-img">
                <img src={Order_icon} alt="" />
                <a href="#" className="text-danger fz-18 fw-600">
                  Remove
                </a>
              </div>
              <div className="order-dtl-lft-content">
                <h5>Women Black Printed A-Line Top x2</h5>
                <p>Color: Multi/ Size: M</p>
                <p>Seller Cliff Fashion, Ahmedabad</p>
                <p className="prc">
                  ₹ 1,000 <del>₹ 1,500</del> <span>8% Off</span>
                </p>
              </div>
            </div>
            <div className="order-dtl-rgt">
              <a href="#" className="org-btn-02">
                <i className="bi bi-cart-check-fill me-2" />
                Add to Cart
              </a>
            </div>
          </div>
          <hr />
          <div className="order-dtl">
            <div className="order-dtl-lft">
              <div className="order-dtl-lft-img">
                <img src={Order_icon} alt="" />
                <a href="#" className="text-danger fz-18 fw-600">
                  Remove
                </a>
              </div>
              <div className="order-dtl-lft-content">
                <h5>Women Black Printed A-Line Top x2</h5>
                <p>Color: Multi/ Size: M</p>
                <p>Seller Cliff Fashion, Ahmedabad</p>
                <p className="prc">
                  ₹ 1,000 <del>₹ 1,500</del> <span>8% Off</span>
                </p>
              </div>
            </div>
            <div className="order-dtl-rgt">
              <a href="#" className="org-btn-02">
                <i className="bi bi-cart-check-fill me-2" />
                Add to Cart
              </a>
            </div>
          </div>
          <hr />
          <div className="order-dtl">
            <div className="order-dtl-lft">
              <div className="order-dtl-lft-img">
                <img src={Order_icon} alt="" />
                <a href="#" className="text-danger fz-18 fw-600">
                  Remove
                </a>
              </div>
              <div className="order-dtl-lft-content">
                <h5>Women Black Printed A-Line Top x2</h5>
                <p>Color: Multi/ Size: M</p>
                <p>Seller Cliff Fashion, Ahmedabad</p>
                <p className="prc">
                  ₹ 1,000 <del>₹ 1,500</del> <span>8% Off</span>
                </p>
              </div>
            </div>
            <div className="order-dtl-rgt">
              <a href="#" className="org-btn-02">
                <i className="bi bi-cart-check-fill me-2" />
                Add to Cart
              </a>
            </div>
          </div> */}
        </div>
      </div>
      <div className="col-lg-3 d-none d-lg-block d-xl-block">
        <img src={Add_icon} className="w-100 h-100" alt="" />
      </div>
    </>
  );
}
