import { createSlice } from "@reduxjs/toolkit";

const initialState={
    loginUser:null
}

const loginUserSlice=createSlice({
    name:'loginUser',
    initialState,
    reducers:{
        setLoginUser:(state, action)=>{
            state.loginUser = action.payload; 
        },
        clearLoginUser:(state,action)=>{
            state.loginUser=null;
        }
    }
})

export const { setLoginUser, clearLoginUser } = loginUserSlice.actions;
export default loginUserSlice.reducer;

// import { useDispatch,useSelector } from "react-redux";
// const dispatch = useDispatch();
// to get state data
// let loginUser=useSelector((state)=>state.loginUser.loginUser)
