import React, { useState ,useContext} from "react";
import googleSvg from "../../../assets/images/google.svg";
import loginImg from "../../../assets/images/login-img.png";
import facebookImg from "../../../assets/images/facebook.svg";
 
import { useNavigate,Link } from "react-router-dom";
import {
  axiosInstance,
  handleError, 
  base_url,
} from "../../../Config/axiosConfig";
import toastify from "../../../Config/toastify";
import { useDispatch, useSelector } from "react-redux";
// import { setLoginUser , clearLoginUser} from "../../../features/loginUser/loginUserSlice";
// import loginUserContext  from "./ContextApi/loginUser/LoginUser";
import loginUserContext from "../../../ContextApi/loginUser/LoginUser";
import userTokenContext from "../../../ContextApi/userToken/UserToken";
import CardContext from "../../Context/CardContext";
import Header from "../Header";
export default function SignInModel() {
  let navigate = useNavigate();
  let [formData, setFormData] = useState({ email: "", password: "" });
  let handleChange = (e) => {
    setFormData((currVal) => ({ ...currVal, [e.target.name]: e.target.value }));
  };
  const dispatch = useDispatch();
  // const user = useSelector((state) => state.user.user);
  // const loginUser = useSelector((state) => state.loginUser);

  // let {loginUser,setLoginUser} = useContext(loginUserContext);
  let { userToken, setUserToken } = useContext(userTokenContext);
  let { card, setCard } = useContext(CardContext);

// console.log(loginUser); 
// console.log(loginUser.loginUser); 

  const handleLogin = async (e) => {
    e.preventDefault();
    if(!formData.email || !formData?.password ){
      return toastify.error("Empty filed is Not Allowed")
    }
    // const respconse2 = axiosInstance.post('/submit', formData);
    //  let response  = await axios('/api/v2/auth/login', {
    //    method: "POST",
    //    data: formData,
    //  }).catch(function (error) {
    //   console.log(error);
    // });
    // console.log(response," this is data")
    //  try {
    //    let response = await axios(`https://e-com.fluttertrends.com/api/v2/auth/login`, {
    //      method: "POST",
    //      data: formData,
    //    });
    //  } catch (error) {
    //   console.log(`some error from backend Error -,${error.message}`);
    //  }

    // const res = await axios.post(`${base_url}/v2/auth/login`, formData);
    // console.log(res.data.user);
    // console.log(res?.data?.access_token);
    // toastify.success(res?.data?.message);
    // navigate("/grocery-page");
    // localStorage.setItem("user", JSON.stringify(res.data.user));
    // localStorage.setItem("token", JSON.stringify(res.data.access_token));
    // let unique = document.getElementById("loginFormBtn");
    // unique.click();


    try {
      const res = await axiosInstance.post(`${base_url}/v2/auth/login`, formData);
      // console.log(res.data );
      // console.log(res?.data?.access_token);
      if( (res.data.result)){
        console.log(card)
        console.log(res?.data?.access_token)
        let countError=0;
        card.forEach(async element => {
          let apiResult =  await axiosInstance.post(`${base_url}/v2/carts/add`, {
             id: element.product_id,
              quantity: element.quantity,
              variant:element.variant || element.variation              
            },{ 
              headers:{
                Authorization: "Bearer " + res?.data?.access_token
              }   
            });
            // console.log(apiResult);
            // console.log(apiResult.data.result);
            if(!(apiResult.data.result)){
              ++countError
              // console.log("apiResult");
              // toastify.error(element?.product_name + " " + apiResult?.data?.message )
              // toastify.error(apiResult.data.message)
            }
            // return
        }); 
        setTimeout(() => {
          console.log(countError,"setTimeout");
          
          if((countError > 0)){
            console.log("enter");
            
            toastify.error(`${countError} Items Not Add In Card `)
            setTimeout(() => {
              toastify.info("Please add Item Again")
            }, 2000);
            //   // ++countError
            //   // console.log("apiResult");
              // toastify.error(element?.product_name + " " + apiResult?.data?.message )
    
            }
        }, 3000);

        // if(!(countError > 0)){
        //   // ++countError
        //   // console.log("apiResult");
        //   // toastify.error(element?.product_name + " " + apiResult?.data?.message )
        //   toastify.error(`${countError} Items Not Add In Card `)
        // }
        // return 

        setTimeout(() => {
          toastify.success(res?.data?.message);
          localStorage.setItem("user", JSON.stringify(res.data.user));
          localStorage.setItem("token",  res.data.access_token );
          setUserToken(res.data.access_token)
          let unique = document.getElementById("loginFormBtn");
          unique.click();
          localStorage.removeItem("guestUser");
          setFormData({ email: "", password: "" })
          navigate("/");
        }, 1500);
      }else{
        toastify.error(res?.data?.message);
      }
    } catch (error) {
      let errorMsg = handleError(error);
      // console.log(errorMsg);
      toastify.error(error.response.data.message);
    }
  };

  return (
    <>
      <div
        className="modal fade register-modal"
        id="signin"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-xl">
          <div className="modal-content">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              id="loginFormBtn"
            />
            <div className="modal-body register-body">
              <div className="row gx-3">
                <div className="col-lg-5 d-none d-lg-block d-xl-block">
                  <div className="register-body-lft">
                    <h3>Sign In</h3>
                    <p>Enter your credentials to log into your account.</p>
                    <img src={loginImg} className="w-100 h-100" alt="" />
                  </div>
                </div>
                <div className="col-lg-7 col-md-12">
                  <div className="register-body-rgt">
                    <form action="">
                      <div className="mb-4 frm-bx number">
                        <label htmlFor="">Email</label>
                        <input
                          type="email"
                          className="form-control"
                          // defaultValue={7894561230}
                          placeholder="Email"
                          onChange={handleChange}
                          value={formData.email}
                          name="email"
                        />
                      </div>
                      <div className="mb-4 frm-bx">
                        <label htmlFor="">Password</label>
                        <input
                          type="password"
                          className="form-control"
                          placeholder="password"
                          onChange={handleChange}
                          value={formData.password}
                          name="password"
                        />
                        <div className="text-end mt-2 forgot">
                          <Link 
                            to="#"
                            data-bs-toggle="modal"
                            data-bs-target="#forgot"
                          >
                            Forgot Password
                          </Link> 
                        </div>
                      </div>
                      <div className="frm-bx">
                        <button
                          className="org-btn w-100"
                          type="button"
                          onClick={handleLogin}
                        >
                          Sign In
                        </button>
                      </div>
                      <p className="text-center mt-3 mb-3">OR</p>
                      <div className="social-btn">
                        <Link  to="#">
                          <img src={googleSvg} className="me-2" alt="" />
                          Sign in with google
                        </Link> 
                        <Link  to="#" className="facebook">
                          <img src={facebookImg} className="me-2" alt="" />
                          Sign in with Facebook
                        </Link> 
                        <Link 
                          to="#"
                          data-bs-toggle="modal"
                          data-bs-target="#otp1"
                          className="otp"
                        >
                          Request OTP
                        </Link> 
                      </div>
                      <p className="text-center">
                        New to Nerozon?{" "}
                        <Link 
                          to="#"
                          data-bs-toggle="modal"
                          data-bs-target="#register"
                        >
                          Create an account
                        </Link> 
                      </p>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
