import React, { useEffect, useState, useRef } from "react";
import {
  axiosInstance,
  base_url,
  handleError,
  imgBaseUrl,
} from "../../Config/axiosConfig";
import Carousel from "react-bootstrap/Carousel";
import HeroSiderImg from "../../assets/img/heroSliderImg.png";
import HeroLabelImg from "../../assets/img/heroLabelImg.png";
import SliderArrow from "../../assets/img/heroSliderArrow.svg";
import SliderArrow2 from "../../assets/img/heroSliderArrow-02.svg";
import HeroSimmer from "../ShimmerUi/HeroSimmer";
const HeroSection = () => {
  let [apiData, setApiData] = useState([]);

  useEffect(() => {
    let apicall = async () => {
      try {
        let res = await axiosInstance.get(`${base_url}/v2/home/slider`);
        setApiData(res.data.data);
      } catch (error) {
        let errorMsg = handleError(error);
        console.log(errorMsg);
      }
    };
    apicall();
  }, []);

  const carouselRef = useRef(null);

  const handleNext = () => {
    if (carouselRef.current) {
      carouselRef.current.next();
    }
  };

  const handlePrev = () => {
    if (carouselRef.current) {
      carouselRef.current.prev();
    }
  };
  if (apiData.length === 0) {
    return <HeroSimmer />;
  }
  return (
    <section className="heroSection constantPadding">
      <Carousel ref={carouselRef} controls={false}>
        {apiData.map((arr, i) => (
          <Carousel.Item key={i}>
            <img
              src={`${imgBaseUrl}/${arr.banner}`}
              className="heroSliderImg"
              alt="Slide 1"
            />
            <Carousel.Caption>
              <div className="container-fluid">
                <div className="row">
                  <div className="col-3">
                    <div className="heroSliderLabel">
                      <img
                        src={HeroLabelImg}
                        alt=""
                        className="heroSliderLabelImg"
                      />
                      <div className="sliderText">
                        <h1 className="mainHeading">{arr?.title}</h1>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Carousel.Caption>
          </Carousel.Item>
        ))}
        {/* <Carousel.Item>
          <img src={HeroSiderImg} className="heroSliderImg" alt="Slide 2" />
          <Carousel.Caption>
            <div className="container-fluid">
              <div className="row">
                <div className="col-3">
                  <div className="heroSliderLabel">
                    <img
                      src={HeroLabelImg}
                      alt=""
                      className="heroSliderLabelImg"
                    />
                    <div className="sliderText">
                      <h1 className="mainHeading">60%</h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Carousel.Caption>
        </Carousel.Item> */}
        {/* <Carousel.Item>
          <img src={HeroSiderImg} className="heroSliderImg" alt="Slide 3" />
          <Carousel.Caption>
            <div className="container-fluid">
              <div className="row">
                <div className="col-3">
                  <div className="heroSliderLabel">
                    <img
                      src={HeroLabelImg}
                      alt=""
                      className="heroSliderLabelImg"
                    />
                    <div className="sliderText">
                      <h1 className="mainHeading">60%</h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Carousel.Caption>
        </Carousel.Item> */}
      </Carousel>
      <button
        className="carousel-control-prev custom-control"
        onClick={handlePrev}
      >
        <img
          src={SliderArrow}
          alt="Previous"
          className="heroSliderChangingImg"
        />
      </button>
      <button
        className="carousel-control-next custom-control"
        onClick={handleNext}
      >
        <img src={SliderArrow2} alt="Next" className="heroSliderChangingImg" />
      </button>
    </section>
  );
};

export default HeroSection;
