import React, { useRef } from 'react'
import LeftArrow from "../../assets/img/leftArrow.svg";
import RightArrow from "../../assets/img/rightArrow.svg";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

export default function OtherPopularProductSlider({ id, title, productImg, lableImg ,slidesPerView }) {
    // console.log(slidesPerView)
    const swiperRef = useRef(null);
    return (
        <>
            <div className="container mrt-15">
                <section className="HoleSaleSection1 constantPadding">
                    <div className="holeSaleContainer">
                        <div className="holeHeader">
                            <h4 className="title">
                                {title}
                            </h4>
                            <div className="holeHeaderBtn">
                                <a href="#" className="mainBtn">
                                    View All
                                </a>
                                <div className={`carouselPrv${id}`}>
                                    <img
                                        src={LeftArrow}
                                        className="carouslArrowImg"
                                        onClick={() => swiperRef.current.swiper.slidePrev()}
                                    />
                                </div>
                                <div className={`carouselNext${id}`}>
                                    <img
                                        src={RightArrow}
                                        className="carouslArrowImg"
                                        onClick={() => swiperRef.current.swiper.slideNext()}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="holeSaleCarousel">
                            <div className="uperCarouselContainer">

                                <Swiper ref={swiperRef}
                                    modules={[Navigation, Pagination, Scrollbar, A11y]}
                                    loop={true}
                                    // spaceBetween={0}
                                    slidesPerView={slidesPerView}
                                    navigation={{
                                        prevEl: `.carouselPrv${id}`,
                                        nextEl: `.carouselNext${id}`,
                                    }}
                                    // onSlideChange={() => console.log("slide change")}
                                    // onSwiper={(swiper) => console.log(swiper)}
                                    style={{ marginLeft: "40px"  ,marginBottom:"10px"}}
                                    breakpoints={{
                                        100: {
                                            slidesPerView: 1,
                                            // spaceBetween: 20, 
                                        },
                                        650: {
                                            slidesPerView: 2,
                                            // spaceBetween: 30,
                                        },
                                        1024: {
                                            slidesPerView: slidesPerView,
                                            spaceBetween: 0,
                                        },
                                    }}>
                                    <SwiperSlide>
                                        <a href="#">
                                            <div className="other-prdct-card">
                                                <img src={productImg} alt="" />
                                                <div className="other-prdct-content">
                                                    <h6>Zara</h6>
                                                    <h5>Women Green Ruffled...</h5>
                                                    <p className="rating">
                                                        <span>
                                                            4.9 <i className="bi bi-star-fill" />
                                                        </span>{" "}
                                                        (1,512)
                                                    </p>
                                                    <p>Size: S, M, L, XL</p>
                                                    <p className="prc">
                                                        ₹ 1,125 <del>₹ 1,800</del> <em>8% Off</em>
                                                    </p>
                                                   { lableImg && <span className="offr-tag">
                                                        <img src={lableImg} alt="" />
                                                    </span>}
                                                </div>
                                            </div>
                                        </a>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <a href="#">
                                            <div className="other-prdct-card">
                                                <img src={productImg} alt="" />
                                                <div className="other-prdct-content">
                                                    <h6>Zara</h6>
                                                    <h5>Women Green Ruffled...</h5>
                                                    <p className="rating">
                                                        <span>
                                                            4.9 <i className="bi bi-star-fill" />
                                                        </span>{" "}
                                                        (1,512)
                                                    </p>
                                                    <p>Size: S, M, L, XL</p>
                                                    <p className="prc">
                                                        ₹ 1,125 <del>₹ 1,800</del> <em>8% Off</em>
                                                    </p>
                                                    { lableImg && <span className="offr-tag">
                                                        <img src={lableImg} alt="" />
                                                    </span>}
                                                </div>
                                            </div>
                                        </a>
                                    </SwiperSlide>

                                    <SwiperSlide>
                                        <a href="#">
                                            <div className="other-prdct-card">
                                                <img src={productImg} alt="" />
                                                <div className="other-prdct-content">
                                                    <h6>Zara</h6>
                                                    <h5>Women Green Ruffled...</h5>
                                                    <p className="rating">
                                                        <span>
                                                            4.9 <i className="bi bi-star-fill" />
                                                        </span>{" "}
                                                        (1,512)
                                                    </p>
                                                    <p>Size: S, M, L, XL</p>
                                                    <p className="prc">
                                                        ₹ 1,125 <del>₹ 1,800</del> <em>8% Off</em>
                                                    </p>
                                                    { lableImg && <span className="offr-tag">
                                                        <img src={lableImg} alt="" />
                                                    </span>}
                                                </div>
                                            </div>
                                        </a>
                                    </SwiperSlide>

                                    <SwiperSlide>
                                        <a href="#">
                                            <div className="other-prdct-card">
                                                <img src={productImg} alt="" />
                                                <div className="other-prdct-content">
                                                    <h6>Zara</h6>
                                                    <h5>Women Green Ruffled...</h5>
                                                    <p className="rating">
                                                        <span>
                                                            4.9 <i className="bi bi-star-fill" />
                                                        </span>{" "}
                                                        (1,512)
                                                    </p>
                                                    <p>Size: S, M, L, XL</p>
                                                    <p className="prc">
                                                        ₹ 1,125 <del>₹ 1,800</del> <em>8% Off</em>
                                                    </p>
                                                    { lableImg && <span className="offr-tag">
                                                        <img src={lableImg} alt="" />
                                                    </span> }
                                                </div>
                                            </div>
                                        </a>
                                    </SwiperSlide>

                                    <SwiperSlide>
                                        <a href="#">
                                            <div className="other-prdct-card">
                                                <img src={productImg} alt="" />
                                                <div className="other-prdct-content">
                                                    <h6>Zara</h6>
                                                    <h5>Women Green Ruffled...</h5>
                                                    <p className="rating">
                                                        <span>
                                                            4.9 <i className="bi bi-star-fill" />
                                                        </span>{" "}
                                                        (1,512)
                                                    </p>
                                                    <p>Size: S, M, L, XL</p>
                                                    <p className="prc">
                                                        ₹ 1,125 <del>₹ 1,800</del> <em>8% Off</em>
                                                    </p>
                                                    { lableImg && <span className="offr-tag">
                                                        <img src={lableImg} alt="" />
                                                    </span> }
                                                </div>
                                            </div>
                                        </a>
                                    </SwiperSlide>

                                    <SwiperSlide>
                                        <a href="#">
                                            <div className="other-prdct-card">
                                                <img src={productImg} alt="" />
                                                <div className="other-prdct-content">
                                                    <h6>Zara</h6>
                                                    <h5>Women Green Ruffled...</h5>
                                                    <p className="rating">
                                                        <span>
                                                            4.9 <i className="bi bi-star-fill" />
                                                        </span>{" "}
                                                        (1,512)
                                                    </p>
                                                    <p>Size: S, M, L, XL</p>
                                                    <p className="prc">
                                                        ₹ 1,125 <del>₹ 1,800</del> <em>8% Off</em>
                                                    </p>
                                                    { lableImg && <span className="offr-tag">
                                                        <img src={lableImg} alt="" />
                                                    </span> }
                                                </div>
                                            </div>
                                        </a>
                                    </SwiperSlide>

                                </Swiper>

                            </div>
                        </div>
                    </div>
                </section>

            </div>
        </>
    )
}
