import { useContext, useState, useEffect, useRef } from "react";
import Search from "../../assets/img/search.svg";
import BrandLogo from "../../assets/img/brandLogo.svg";
import { IoMenu } from "react-icons/io5";
import Notificataion from "../../assets/img/notification.svg";
import Cart from "../../assets/img/cart.svg";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Model from "./Models/Model";
import CardContext from "../Context/CardContext";
import { base_url, handleError, axiosInstance } from "../../Config/axiosConfig";
import { addSearchProduct } from "../../features/searchProduct/searchProductSlice";
import toastify from "../../Config/toastify";
export default function NavbarContainer(prop) {
  let { setShow } = prop;
  let navigate = useNavigate();
  // console.log(prop.setShow)
  // useContext
  // CardContext
  // const [show, setShow] = useState(false);
  //   const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [isOpen, setIsOpen] = useState(false);
  let location = useLocation();
  const { card, setCard } = useContext(CardContext);
  
  const inputRef = useRef(null); 
  let [searchProduct, setSearchProduct] = useState("");
  
  let [dataList, setDataList] = useState([]);
  let [checkApicall, setCheckApicall] = useState(false);
 

  // const [searchParams, setSearchParams] = useSearchParams();
  // let userData= JSON.parse(localStorage.getItem("user"))
  // console.log(loginUser); 

  let loginUser=JSON.parse(localStorage?.getItem("user")  )
  let token =localStorage?.getItem("token")

// console.log(loginUser);
  useEffect(() => {
    if(token){
    (async () => {
      try {
        let res = await axiosInstance.post(`${base_url}/v2/carts`,{},{
          headers: {
            Authorization: "Bearer " + token,
          }
        });
        let arr = res?.data[0]?.cart_items;
        // let cartData=arr.map((arr)=>({id:arr?.id,product_id:arr?.product_id,quantity:arr?.quantity,variation:arr?.variation}))
        // console.log(datbase);
        // localStorage.setItem('guestCart', JSON.stringify(cart));
        setCard(arr);
      } catch (error) {
        let errorMsg = handleError(error);
        console.log(errorMsg);
      }
    })();
    setCheckApicall(false);
  }
  }, [navigate]);

  useEffect(() => {
    if(location?.pathname !== "/electronic-section" &&  location?.pathname !== "/search-product" ){
      setSearchProduct("")
    }
  }, [location]);
  
  
  // useEffect(() => {
  //   (async () => {
  //     try {
  // let res = await axiosInstance.post(`${base_url}/v2/carts`);
  //       console.log(res.data.data);
  //       let arr = res?.data[0]?.cart_items;
  //       setCard(arr?.length);

  //       // setApiData(res.data.data);
  //     } catch (error) {
  //       let errorMsg = handleError(error);
  //       console.log(errorMsg);
  //     }
  //   })();
  // }, []);

  const handleFocus = () => {
    if(!searchProduct){
      return
    }
    setIsOpen(true);
  };

  const handleBlur = () => {
    setIsOpen(false);
  };


  let handleChange = (e) => {
    console.log(e.target.value.trim().length);
    if(e.target.value.trim().length == 0){
        setSearchProduct(e.target.value.trim() );
    }else{
      setSearchProduct(e.target.value );
    }
    
 
    if(e.target.value === ""){
      return setDataList([])
    }
    if(!(e.target.value.trim())){
      return setDataList([])
    }
    setIsOpen(true);
    apicall();

    async function apicall() {
      try {
        let res = await axiosInstance.post(`${base_url}/v2/products/search`, {
          name: e?.target?.value,
        });
        console.log("callApi,searchList", e.target.value, dataList.length > 0);
        // console.log(res?.data?.data?.product);
        // console.log(res?.data?.data?.category);
        let product = res?.data?.data.product?.slice(0, 5);
        let category = res?.data?.data.category?.slice(0, 5);
        let allData = [...product, ...category];
        setCheckApicall(true)
        setDataList(allData);
      } catch (error) {
        let errorMsg = handleError(error);
        console.log(errorMsg);
      }
    }
  };




  let handleSearchProduct = async (data) => {
    // console.log("onClick icon's Click");
    console.log(checkApicall)
   
    try {
      // let res = await axiosInstance.post(`${base_url}/v2/products/search`, {
      //   name: data,
      // });
      // console.log(res, "apicalling Succesfully");
      // dispatch(addSearchProduct(res?.data?.data || []) );
      // console.log("searchProduct");
      // console.log();
      // navigate(`/search-product?search=${searchProduct}`);
      // console.log(dataList[0].name)
      searchProduct=searchProduct.trim()
      if(!searchProduct){
        return toastify.warning("Please Enter Some value")
      }
      let filterData = dataList?.filter(
        (arr) => arr.name.toUpperCase().includes(searchProduct.toUpperCase())
      );
      console.log(filterData);
      // if(filterData.length >1){
      //      filterData.
      // }
      
      // return 
      let datafilter = filterData[0];
      // console.log(datafilter);
      data = datafilter ? datafilter : data
      if(data.length > 0){
        setSearchProduct("")
        toastify.info("No Data Found ....!")
        setIsOpen(false);
          return 
      }
      console.log("enter");
      
      
      // arr.name.toUpperCase().includes(searchProduct.toUpperCase())
      // {
      //   console.log(arr.name.toUpperCase().includes(searchProduct.toUpperCase()));
      //   return  arr?.name?.toUpperCase() == searchProduct.toUpperCase()
      // }
     

      if(!data){
        return toastify.warning("Please Enter Some Value")
      }

      if (data?.view === "grid") {
        if (data?.type === "product") {
          handleBlur();
          inputRef.current.blur();
          navigate(`/search-product?type=${data?.type}&product=${data?.name}`);
        }
        if (data?.type === "category") {
          handleBlur();
          inputRef.current.blur();
          return navigate(
            `/search-product?type=${data?.type}&category=${data?.name}&id=${data?.id}`
          );
        }else{
            navigate(`/search-product?type=product&product=${searchProduct}`);
            handleBlur();
            inputRef.current.blur();
        }
      }else{
          navigate(`/search-product?type=product&product=${searchProduct}`);
          handleBlur();
          inputRef.current.blur();
      }



    if (data?.view !== "grid") {  
      // console.log(data.type === "product")
      if (data?.type === "product") {
        handleBlur();
        inputRef.current.blur();
        navigate(`/search-product?type=${data?.type}&product=${data?.name}`);
      }
      if (data?.type === "category") {
        handleBlur();
        inputRef.current.blur();
        return navigate(
          `/electronic-section?type=${data?.type}&category=${data?.name}&id=${data?.id}`
        );
      }else{
        handleBlur();
        inputRef.current.blur();
        return navigate(`/electronic-section?type=product&product=${searchProduct}`);
        // return navigate(`/search-product?type=product&product=${searchProduct}`);
      }
    } 
      // console.log(data.view)
      // navigate(`/search-product?type=product&product=${searchProduct}`);
      // handleBlur();
    } catch (error) {
      let errorMsg = handleError(error);
      console.log(errorMsg);
    }
  };

  

  const handleKeyPress = (event) => {
    if (event.key === "Enter") { 
      event.preventDefault();
      searchProduct= searchProduct.trim() 
    if(!searchProduct){
      return toastify.warning("Please Enter Some Value")
    }
      let filterData = dataList?.filter(
        (arr) => arr.name.toUpperCase().includes(searchProduct.toUpperCase())
      );
      let data = filterData[0];
      console.log(data);
// console.log();

if(!data){
        setSearchProduct("")
        toastify.info("No Data Found ...!")
        setIsOpen(false);
        return 
      }

      if (data?.view !== "grid") {  
        console.log("view in grid ",data);
        
        if (data?.type === "product") {
        handleBlur();
        inputRef.current.blur();
        navigate(`/search-product?type=${data?.type}&product=${data?.name}`);
      }
      if (data?.type === "category") {
        handleBlur();
        inputRef.current.blur();
        return navigate(
          `/electronic-section?type=${data?.type}&category=${data?.name}&id=${data?.id}`
        );
      }else{
        navigate(`/search-product?type=product&product=${searchProduct}`);
        handleBlur();
        inputRef.current.blur();
      }
    }

    if (data?.view === "grid") {
      // if (data?.type === "product") {
      //   handleBlur();
      //   inputRef.current.blur();
      //   navigate(`/search-product?type=${data?.type}&product=${data?.name}`);
      // }
      // if (data?.type === "category") {
      //   handleBlur();
      //   inputRef.current.blur();
      //   return navigate(
      //     `/search-product?type=${data?.type}&category=${data?.name}&id=${data?.id}`
      //   );
      // }else{
      //   navigate(`/search-product?type=product&product=${searchProduct}`);
      //   handleBlur();
      //   inputRef.current.blur();
      // }
      // console.log("working");
      
      if (data?.type == "product") {
      console.log("product");

        inputRef.current.blur();
        return navigate(`/search-product?type=${data?.type}&product=${searchProduct}`);
      }
      if (data?.type === "category") {
        inputRef.current.blur();
        console.log("category");

        return navigate(
          `/search-product?type=${data?.type}&category=${data?.name}&id=${data?.id}`
        );
      }
        // handleBlur();
        // navigate(`/search-product?type=${data?.type}&category=${data?.name}&id=${data?.id}`)
    }else{
        navigate(`/electronic-section?type=product&product=${searchProduct}`);
        handleBlur();
        inputRef.current.blur();
    }
       
    }
  };

  let handleSearchClick = (e, data) => {
    e.preventDefault();
    e.stopPropagation();
  
    // if(data.length < 0){
    //   toastify.info("NO data fonund ....!")
    //     return 
    // }
    
  
    // console.log(data);
    if (data?.view === "grid") {
      if (data?.type === "product") {
        setSearchProduct(data?.name);
        navigate(`/search-product?type=${data?.type}&product=${data?.name}`);
      }
      if (data?.type === "category") {
        setSearchProduct(data?.name);
        return navigate(
          `/search-product?type=${data?.type}&category=${data?.name}&id=${data?.id}`
        );
      }
    }
    if (data?.view !== "grid") {
      if (data?.type === "product") {
        setSearchProduct(data?.name);
        navigate(
          `/electronic-section?type=${data?.type}&product=${data?.name}`
        );
      }
      if (data?.type === "category") {
        setSearchProduct(data?.name);
        return navigate(
          `/electronic-section?type=${data?.type}&category=${data?.name}&id=${data?.id}`
        );
      }
    }
  };


  // let handlteFunction = (data)=>{
  //   if (data?.view !== "grid") {
  //     if (data?.type === "product") {
  //       console.log("enter  2");
  //       setSearchProduct(data?.name);
  //       navigate(`/search-product?type=${data?.type}&product=${data?.name}`);
  //     }
  //     if (data?.type === "category") {
  //       console.log("enter");
  //       setSearchProduct(data?.name);
  //       return navigate(
  //         `/search-product?type=${data?.type}&category=${data?.name}&id=${data?.id}`
  //       );
  //     }
  //   }
  //   if (data?.view === "grid") {
  //     if (data?.type === "product") {
  //       setSearchProduct(data?.name);
  //       navigate(
  //         `/electronic-section?type=${data?.type}&product=${data?.name}`
  //       );
  //     }
  //     if (data?.type === "category") {
  //       setSearchProduct(data?.name);
  //       return navigate(
  //         `/electronic-section?type=${data?.type}&category=${data?.name}&id=${data?.id}`
  //       );
  //     }
  //   } 
  // }
 


  return (
    <>
      <nav>
        <div className="navContainer">
          <div className="navBrand">
            <Link to="/" className="navBrandLogo">
              <img src={BrandLogo} className="BrandLogoImg" />
            </Link>
          </div>
          <div className="navMenu" >
            <div className="navSearchBar" >
              <input
                type="text"
                placeholder="Search for product, brands and more"
                className="textArea"
                onFocus={handleFocus}
                onMouseEnter={handleFocus}  
                // onBlur={handleBlur}
                onChange={handleChange}
                value={searchProduct}
                ref={inputRef}
                name="serchApi"
                onKeyPress={handleKeyPress}
              />
              <span>
                <img
                  src={Search}
                  className="searchIconImg"
                  onClick={() => handleSearchProduct(searchProduct)}
                />
              </span>

              {  (
                <div
                  onMouseLeave={handleBlur}//
                  className={`searchHistory ${ isOpen ? "open" : ""}`}
                >
                   {  dataList?.length > 0 ? <ul className="searchItem">
                    {dataList?.map((arr) => (
                      <li key={arr?.id}>
                        <Link
                          // to={`/search-product?search=${arr?.name}`}
                          className="searchLinks mainHeading"
                          onClick={(e) => {
                            handleBlur();
                            handleSearchClick(e, arr);
                          }}
                        >
                          <span>
                            <img src={Search} className="searchIconImg" />
                          </span>
                          {arr?.name}
                        </Link>
                      </li>
                    ))}
                  </ul> : <div class="spinner"></div> }
                </div>
              )}
            </div>
            <div className="navMenuList">
              <div className="navBrand">
                <div className="hambarger">
                  <IoMenu color="#fff" onClick={handleShow} />
                </div>
                <Link to="#" className="navBrandLogo">
                  <img src={BrandLogo} className="BrandLogoImg" />
                </Link>
              </div>
              <div className="navMenuButton">
                <Link to={loginUser ? `/my-account/my-cart` :"/my-cart"} className=" mainHeading navBtn">
                  <img src={Cart} alt="" className="navBtnImg" />
                  <span className="cartNotification">{card?.length}</span>
                  <span className="cartText">Cart</span>
                </Link>
                <Link to="#" className="mainHeading navBtn">
                  <span>
                    <img src={Notificataion} alt="" className="navBtnImg" />
                  </span>
                </Link>
                {/* {userData ? (
                  <Link
                    className="mainHeading navBtn desktopSingBtn"
                    
                     to="/my-account/details"
                  >
                    {userData?.name} {console.log(userData,"this is data")}
                  </Link>
                ) : (
                  <Link
                  data-bs-toggle="modal"
                    data-bs-target="#signin"
                    className="mainHeading navBtn-2 desktopSingBtn"
                    // to="/my-account/details"
                  >
                    <i className="bi bi-person-fill"></i>Manish2 
                  </Link>
                )} */}
                {loginUser ? (
                  <Link
                  className="mainHeading navBtn-2 desktopSingBtn"
                  to="/my-account/details"
                >
                  <i className="bi bi-person-fill"></i>{loginUser?.name}  
                </Link>
                ) : (
                  
                  <Link
                  className="mainHeading navBtn desktopSingBtn"
                  data-bs-toggle="modal"
                  data-bs-target="#signin"
                  id="openLoginFormBtn"
                >
                  Sign In / Sign Up
                </Link>
                )}
              </div>
            </div>
          </div>
        </div>
      </nav>
      <Model />
    </>
  );
}







// result=[{
//   symbol:"helo1"
// },{
//   symbol:"helo2"
// },{
//   symbol:"helo2"
// },{
//   symbol:"helo3"
// },{
//   symbol:"helo4"
// }]
