import React, {   useRef } from "react";
// import {
//   axiosInstance,
//   base_url,
//   handleError,
//   imgBaseUrl,
// } from "../../Config/axiosConfig";
import Carousel from "react-bootstrap/Carousel";
import HeroSiderImg from "../../assets/img/heroSliderImg.png";
import HeroLabelImg from "../../assets/img/heroLabelImg.png";
import SliderArrow from "../../assets/img/heroSliderArrow.svg";
import SliderArrow2 from "../../assets/img/heroSliderArrow-02.svg";
const HeroSection = () => {
  const carouselRef = useRef(null);

  const handleNext = () => {
    if (carouselRef.current) {
      carouselRef.current.next();
    }
  };

  const handlePrev = () => {
    if (carouselRef.current) {
      carouselRef.current.prev();
    }
  };
  return (
    <section className="heroSection constantPadding  upperCartNew">
      <Carousel ref={carouselRef} controls={false}>
        <Carousel.Item>
          <img src={HeroSiderImg} className="heroSliderImg" alt="Slide 2" />
          <Carousel.Caption>
            <div className="container-fluid">
              <div className="row">
                <div className="col-3">
                  <div className="heroSliderLabel">
                    <img
                      src={HeroLabelImg}
                      alt=""
                      className="heroSliderLabelImg"
                    />
                    <div className="sliderText">
                      <h1 className="mainHeading">60%</h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Carousel.Caption>
          <div className="shimmer-cart"> </div>
        </Carousel.Item>
        <Carousel.Item>
          <img src={HeroSiderImg} className="heroSliderImg" alt="Slide 3" />
          <Carousel.Caption>
            <div className="container-fluid">
              <div className="row">
                <div className="col-3">
                  <div className="heroSliderLabel">
                    <img
                      src={HeroLabelImg}
                      alt=""
                      className="heroSliderLabelImg"
                    />
                    <div className="sliderText">
                      <h1 className="mainHeading">60%</h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Carousel.Caption>
          <div className="shimmer-cart"> </div>
        </Carousel.Item>
      </Carousel>
      <button
        className="carousel-control-prev custom-control"
        onClick={handlePrev}
      >
        <img
          src={SliderArrow}
          alt="Previous"
          className="heroSliderChangingImg"
        />
      </button>
      <button
        className="carousel-control-next custom-control"
        onClick={handleNext}
      >
        <img src={SliderArrow2} alt="Next" className="heroSliderChangingImg" />
      </button>
    </section>
  );
};

export default HeroSection;
